import styled from 'styled-components';

export const DocumentSelectModalComponent = styled.section``;

export const ModalContent = styled.section`
  padding: ${({ theme }) => theme.spacing(40)};
  padding-top: ${({ theme }) => theme.spacing(32)}; ;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colours.agedGray};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  margin-bottom: ${({ theme }) => theme.spacing(16)};

  .cancel-text {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    cursor: pointer;
  }
`;

export const InfoLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .record-text {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }

  .dot {
    margin-left: ${({ theme }) => theme.spacing(4)};
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

export const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  grid-gap: 16px;
`;

export const WarningContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  color: ${({ theme }) => theme.colours.toastInfoAccent};
  background-color: ${({ theme }) => theme.colours.toastInfoBg};
  border-left: 10px solid ${({ theme }) => theme.colours.toastInfoAccent};
  border-radius: 4px;
  margin-bottom: 1rem;

  .messageContainer {
    flex: 1;
  }

  .title {
    font-weight: bold;
    color: ${({ theme }) => theme.colours.toastInfoAccent};
  }

  svg {
    margin-top: 3px;
  }

  svg #color {
    fill: ${({ theme }) => theme.colours.toastInfoAccent};
  }
`;

export const LoadingContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(20)};
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(40)};
  margin-top: ${({ theme }) => theme.spacing(60)};

  .attach-button {
    min-width: 223px;
  }
`;

export const PreviewContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(16)};

  .risk-icon {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${({ theme }) => theme.colours.redRampage};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  gap: ${({ theme }) => theme.spacing(6)};
`;
