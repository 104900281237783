import styled, { css } from 'styled-components';
import { RiskRatingProps } from './types';

export const RiskRatingComponent = styled.span<RiskRatingProps>`
  display: inline-block;
  padding: 0 ${({ theme }) => theme.pxToRem(10)};
  border-radius: ${({ theme }) => theme.pxToRem(12)};
  color: ${({ theme }) => theme.colours.black};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.714;
  max-width: ${({ theme }) => theme.pxToRem(90)};
  word-wrap: break-word;
  text-align: center;

  ${({ risk, theme: { colours } }) => {
    const mappedColours = {
      Low: colours.limeRasp,
      'Clear findings': colours.limeRasp,
      Medium: colours.californiaDreaming,
      'Moderate findings': colours.californiaDreaming,
      High: colours.desire,
      'Significant findings': colours.desire,
      'N/A': colours.agedGray,
      Unknown: colours.aquaSphere,
    };
    return css`
      background-color: ${mappedColours[risk]};
      ${(risk === 'Unknown' || risk === 'N/A') &&
      css`
        color: #fff;
      `}
    `;
  }}
`;
