import React, { FC } from 'react';
import { IndicatorComponent } from './Indicator.styles';
import { IndicatorProps } from './types';
import { Icon } from 'components/atoms';

const Indicator: FC<IndicatorProps> = ({
  name,
  icon,
  value = 0,
}: IndicatorProps) => (
  <IndicatorComponent aria-label={name}>
    {icon ? (
      <Icon icon={icon} aria-hidden size="xxxs" />
    ) : (
      <span className="name">{name}</span>
    )}
    {value > 0 && <span className="value">{value}</span>}
  </IndicatorComponent>
);

export default Indicator;
