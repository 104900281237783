import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 10px 0 30px;
`;

export const CategoryTextContainer = styled.div<{
  required: boolean;
  valid: boolean;
}>`
  width: ${({ theme }) => theme.pxToRem(170)};
  padding-right: ${({ theme }) => theme.pxToRem(16)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  .category-text {
    color: ${({ theme, valid }) =>
      valid ? theme.colours.darkToneInk : theme.colours.redRampage};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }

  .limit-text {
    color: ${({ theme }) => theme.colours.agedGray};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(12)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }

  .required {
    margin-left: ${({ theme }) => theme.spacing(4)};
    display: ${({ required }) => (required ? 'inline' : 'none')};
    color: ${({ theme }) => theme.colours.redRampage};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(8)};
  max-height: ${({ theme }) => theme.pxToRem(36)};
  background-color: ${({ theme }) => theme.colours.lightBlueGray};
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    background-color: #ccd8dc;
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale(100%);
    cursor: auto;
  }

  .upload-icon {
    margin-right: ${({ theme }) => theme.pxToRem(8)};
  }
`;

export const ContextMenu = styled.div`
  z-index: 5;
  width: ${({ theme }) => theme.pxToRem(189)};
  position: absolute;
  top: 0px;
  left: 200px;
  background-color: ${({ theme }) => theme.colours.white};
  border: 1px solid ${({ theme }) => theme.colours.concreteGray};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  overflow: hidden;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
`;

export const ContextTitleContainer = styled.div`
  padding: ${({ theme }) => `${theme.pxToRem(6)} ${theme.pxToRem(16)}`};
  border-bottom: 1px solid ${({ theme }) => theme.colours.concreteGray};

  .text {
    color: ${({ theme }) => theme.colours.darkToneInk};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(12)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }
`;

export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.pxToRem(6)} ${theme.pxToRem(12)}`};
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  .icon {
    margin-right: ${({ theme }) => theme.pxToRem(5)};
    width: ${({ theme }) => theme.pxToRem(22)};
    height: ${({ theme }) => theme.pxToRem(22)};
  }

  .text {
    color: ${({ theme }) => theme.colours.darkToneInk};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }
`;

export const Stroke = styled.div`
  height: 1px;
  background-color: #ececec;
  width: 100%;
  margin-bottom: 20px;
`;

export const FilesContainer = styled.div<{
  showSidebar?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(16)};
  margin: ${({ theme }) => `${theme.spacing(30)} 0`};
  ${(props) =>
    props.showSidebar &&
    css`
      border-left: 5px solid #5f8591;
      padding-left: 20px;
    `};
`;
