import React, { FC, ReactNode } from 'react';
import {
  LayoutLeft,
  LayoutRight,
  LoginLayoutBrand,
  LoginMessage,
  LoginLayoutComponent,
} from './LoginLayout.styles';

interface Props {
  children: ReactNode;
}

const logoUrl =
  'https://www.controlrisks.com/our-services/creating-a-compliant-organisation/vantage/vantage-platform';

const LoginLayout: FC<Props> = ({ children }: Props) => (
  <LoginLayoutComponent>
    <LayoutLeft>
      <LoginLayoutBrand href={logoUrl}>
        <img
          src="logo-regular.png"
          alt="Control Risks"
          width="230"
          height="25"
        />
      </LoginLayoutBrand>
      <LoginMessage>
        <h2>Get started</h2>
        <p>
          Control Risks is a specialist risk consultancy. We are committed to
          helping our clients build organisations that are secure, compliant and
          resilient in an age of ever-changing risk and connectivity.
        </p>
        <a href="https://www.controlrisks.com/">More about Control Risks</a>
      </LoginMessage>
    </LayoutLeft>
    <LayoutRight>{children}</LayoutRight>
  </LoginLayoutComponent>
);

export default LoginLayout;
