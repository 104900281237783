import styled from 'styled-components';

export const SideModuleWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(30)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(15)};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
`;

export const SideModuleHeader = styled.h2.attrs(() => ({
  role: 'heading',
}))`
  font-size: ${({ theme: { typography } }) => typography.fontSize.medium};
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};
`;

export const SideModuleHeaderContainer = styled.div`
  display: grid;
  grid-gap: ${({ theme: { spacing } }) => spacing(10)};
  grid-template-columns: 1fr auto;
  align-items: first baseline;
  padding-left: ${({ theme: { spacing } }) => spacing(10)};
  margin-left: auto;
  margin-bottom: -0.625rem;
`;
