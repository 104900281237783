import styled, { css } from 'styled-components';
import { SectionHeadingProps } from './types';

export const SectionHeadingComponent = styled.div<
  Omit<SectionHeadingProps, 'text'>
>`
  display: flex;
  align-items: first baseline;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(20)};
  color: ${({ theme, dark = false }) =>
    dark ? theme.colours.white : theme.colours.crowberryBlue};

  ${({ border = false }) =>
    border &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(30)};
      border-bottom: 1px solid
        ${({ theme, dark = false }) =>
          dark ? `${theme.colours.gallery}33` : `${theme.colours.silverSand}`};
    `};

  h2 {
    flex-shrink: 0;
    margin-bottom: ${({ theme }) => theme.spacing(30)};
  }

  h2,
  a {
    color: inherit;
  }
`;

export const SectionHeadingChildren = styled.div`
  display: flex;
  align-items: first baseline;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(20)};
`;
