import React, { FC } from 'react';
import { Wrapper, Fieldset } from './FieldRadio.styles';

import { RadioGroupTypes, RadioTypes } from './types';

export const Radio: FC<RadioTypes> = ({
  value = '',
  label,
  ariaLabel = '',
  tabIndex = -1,
  id = '',
  name = '',
  disabled = false,
  checked,
  register,
  inputRef,
  className,
  required = false,
  onChange,
  children,
  showBorder = true,
  labelRight,
  small = false,
  space = false,
  leftAlign = false,
}: RadioTypes) => {
  return (
    <Wrapper
      showBorder={showBorder}
      labelRight={labelRight}
      small={small}
      space={space}
      leftAlign={leftAlign}
      className={required ? 'required' : ''}
    >
      <label htmlFor={id}>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          tabIndex={-1}
          onChange={onChange}
          {...(className ? { className } : {})}
          {...(checked ? { checked } : {})}
          {...(inputRef ? { ref: inputRef } : {})}
          {...(disabled ? { disabled } : {})}
          {...(register ? { ...register(name, { required }) } : {})}
        />
        <>
          <span
            className="radio"
            role="radio"
            tabIndex={tabIndex}
            aria-label={ariaLabel}
            aria-checked={false}
          />
          {children ? <>{children}</> : <span className="label">{label}</span>}
        </>
      </label>
    </Wrapper>
  );
};

const RadioGroup: FC<RadioGroupTypes> = ({
  options = [],
  name = '',
  label = '',
  disabled = false,
  onChange,
  register,
  required = false,
  children,
}: RadioGroupTypes) => {
  return (
    <Fieldset>
      {label && <legend>{label}</legend>}
      {options?.map((option, index) => (
        <Radio
          key={`${name}-${index}`}
          disabled={disabled}
          {...option}
          name={name}
          register={register}
          required={required}
          {...(onChange ? { onChange } : {})}
        >
          {children}
        </Radio>
      ))}
    </Fieldset>
  );
};

export default RadioGroup;
