import styled from 'styled-components';

export const SectionWrapper = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(30)} 0;
`;

export const ActionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  &.margin {
    margin-bottom: ${({ theme: { spacing } }) => spacing(20)};
  }

  .icon {
    margin-right: 20px;
  }
`;

export const ArrayItem = styled.div`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(10)};

  > div {
    flex-grow: 1;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  div {
    flex: 1;
    max-width: 170px;
  }
`;

export const FormBorderTop = styled.form`
  border-top: 1px solid ${({ theme: { colours } }) => colours.gallery};
  padding-top: ${({ theme: { spacing } }) => spacing(30)};

  .collapsible-section {
    margin: 30px 0;
  }
`;
