import React, { FC } from 'react';
import {
  ButtonContainer,
  ModalContent,
  ErrorContainer,
  StyledTable,
} from './DeleteLinkedConfirmationModal.styles';

import { DeleteLinkedConfirmationModalTypes } from './types';
import { Button, Icon, Link, Typography, Date } from 'components/atoms';
import { Modal } from 'components/molecules';
import { useTheme } from 'styled-components';

const DeleteLinkedConfirmationModal: FC<DeleteLinkedConfirmationModalTypes> = ({
  isOpen,
  conflictedScreenings = [],
  onClose,
}: DeleteLinkedConfirmationModalTypes) => {
  const theme = useTheme();

  const getLink = (
    entityType: 'Screening' | 'Draft',
    id: string,
    thirdPartyId: number
  ) => {
    return entityType === 'Screening'
      ? `/third-party/screening/${id}`
      : `/initiate-due-diligence/${thirdPartyId}?draftId=${id}`;
  };

  const getIdNode = (entityType: 'Screening' | 'Draft', id: string) =>
    entityType === 'Screening' ? (
      <td className="id">{id}</td>
    ) : (
      <td className="id not-generated">ID not generated</td>
    );
  return (
    <Modal
      removePadding
      onClose={onClose}
      isOpen={isOpen}
      title="Document cannot be deleted"
    >
      <ModalContent>
        <>
          <ErrorContainer>
            <Icon
              className="risk-icon"
              icon="risk"
              size="xxs"
              colour={theme.colours.redRampage}
            />
            <Typography
              value="You cannot delete this document because it is linked to the reports below. In order to delete this document, the below reports must first be deleted"
              tag="p"
            />
          </ErrorContainer>
          <p className="linked-reports-title">
            Reports this document has appeared in
          </p>
          <StyledTable>
            <thead>
              <tr>
                <th>Report ID</th>
                <th>Date ordered</th>
                <th>Type</th>
                <th>Level</th>
                <th>Review status</th>
              </tr>
            </thead>
            <tbody>
              {conflictedScreenings.map(
                ({
                  id,
                  createdDate,
                  dueDiligenceType,
                  level,
                  status,
                  thirdPartyId,
                  entityType,
                }) => (
                  <tr key={id}>
                    {getIdNode(entityType, id)}
                    <td>
                      <Date
                        dateTime={createdDate}
                        format="Do MMM, YYYY, HH:mm"
                      />
                    </td>
                    <td>{dueDiligenceType}</td>
                    <td>{level}</td>
                    <td>
                      <Link
                        href={getLink(entityType, id, thirdPartyId)}
                        target="_blank"
                      >
                        {status}
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </StyledTable>
          <ButtonContainer>
            <Button
              secondary
              className="button"
              title="close"
              onClick={() => onClose && onClose()}
              aria-label="Close"
            >
              Close
            </Button>
          </ButtonContainer>
        </>
      </ModalContent>
    </Modal>
  );
};

export default DeleteLinkedConfirmationModal;
