import React, { useMemo } from 'react';
import { DefaultText, Container } from './RelatedEntitiesSideModule.styles';
import { Collapsible } from 'components/atoms';
import SideModule from 'components/molecules/side-module';
import { useFormContext } from 'react-hook-form';

const RelatedEntitiesSideModule = ({ relatedEntitiesData }) => {
  const { getValues } = useFormContext();
  const selectedEntitiesIds = getValues('relatedEntities');
  const relatedEntities = useMemo(
    () =>
      relatedEntitiesData?.filter((re) => {
        return selectedEntitiesIds?.includes(re.id);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEntitiesIds]
  );
  let result: JSX.Element;

  if (relatedEntities.length === 0) {
    result = <DefaultText>No Related Entities selected </DefaultText>;
  } else if (relatedEntities.length <= 10) {
    result = (
      <>
        {relatedEntities.map((relatedEntity) => (
          <Container key={relatedEntity.id}>
            {relatedEntity.name && (
              <DefaultText>{`Name: ${relatedEntity.name}`}</DefaultText>
            )}
            {relatedEntity.linkType && (
              <DefaultText>{`Relationship to Third Party: ${relatedEntity.linkType}`}</DefaultText>
            )}
          </Container>
        ))}
      </>
    );
  } else {
    const relatedEntitiesBeforeReadMore = 10;
    const shownRelatedEntities = relatedEntities.slice(
      0,
      relatedEntitiesBeforeReadMore
    );
    const hiddenRelatedEntities = relatedEntities.slice(
      relatedEntitiesBeforeReadMore
    );
    result = (
      <>
        {shownRelatedEntities.map((relatedEntity) => (
          <Container key={relatedEntity.id}>
            {relatedEntity.name && (
              <DefaultText>{`Name: ${relatedEntity.name}`}</DefaultText>
            )}
            {relatedEntity.linkType && (
              <DefaultText>{`Relationship to Third Party: ${relatedEntity.linkType}`}</DefaultText>
            )}
          </Container>
        ))}
        <Collapsible collapsedText="Read more" expandedText="Read less">
          {hiddenRelatedEntities.map((relatedEntity) => (
            <Container key={relatedEntity.id}>
              {relatedEntity.name && (
                <DefaultText>{`Name: ${relatedEntity.name}`}</DefaultText>
              )}
              {relatedEntity.linkType && (
                <DefaultText>{`Relationship to Third Party: ${relatedEntity.linkType}`}</DefaultText>
              )}
            </Container>
          ))}
        </Collapsible>
      </>
    );
  }

  return <SideModule header="Related Entities">{result}</SideModule>;
};

export default RelatedEntitiesSideModule;
