import React, { FC, useEffect, useState } from 'react';
import { MapComponent } from './Map.styles';
import { MapTypes } from './types';
import countries from 'types/countries';

const Map: FC<MapTypes> = ({ countryCode }) => {
  const [map, setMap] = useState(null);
  const fetchCountryMap = async () => {
    if (!countries.includes(countryCode)) {
      return;
    }
    const response = await fetch(`/maps/${countryCode}.html`);
    setMap(await response.text());
  };
  useEffect(() => {
    fetchCountryMap();
  });
  if (!map) {
    return null;
  }
  return <MapComponent dangerouslySetInnerHTML={{ __html: map }} />;
};

export default Map;
