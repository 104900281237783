import styled from 'styled-components';

export const DocumentUploadsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentsSectionHeading = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  margin-bottom: ${({ theme }) => theme.pxToRem(8)};
`;

export const DocumentsInfoText = styled.p`
  color: ${({ theme }) => theme.colours.agedGray};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  margin-bottom: ${({ theme }) => theme.pxToRem(8)};
`;

export const ComboBoxContainer = styled.div`
  width: ${({ theme }) => theme.pxToRem(297)};
  margin-bottom: ${({ theme }) => theme.spacing(32)};
`;

export const Stroke = styled.div`
  height: 1px;
  background-color: #ececec;
  width: 100%;
  margin-bottom: 20px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${({ theme }) => theme.colours.redRampage};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  gap: ${({ theme }) => theme.spacing(6)};

  .risk-icon {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;
