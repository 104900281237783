import styled from 'styled-components';
import { Chip } from 'components/atoms';

interface TabContentProps {
  label: string;
}

interface StatusChipProps {
  complete: boolean;
}

interface ProfileContainerProps {
  divider?: boolean;
}

const gap = 14;

export const TabContent = styled.div<TabContentProps>`
  padding: ${({ theme }) => theme.pxToRem(20)} 0;
`;

export const SummaryContainer = styled.div`
  background-color: ${({ theme }) => theme.colours.swimmersPool};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  padding: ${({ theme }) => theme.pxToRem(40)};
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
`;

export const Matches = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TableContainer = styled.div`
  background-color: ${({ theme }) => theme.colours.white};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  padding-top: ${({ theme }) => theme.pxToRem(40)};
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
  overflow: hidden;
  flex-grow: 1;

  &:empty {
    // Hide element with no chidlren.
    display: none;
  }
`;

export const ProfileContainer = styled.div<ProfileContainerProps>`
  background-color: ${({ theme }) => theme.colours.white};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  margin-left: ${({ theme, divider }) => (divider ? theme.pxToRem(20) : 0)};
  display: flex;
  width: 40vw;
  max-width: ${({ theme }) => theme.pxToRem(480)};
`;

export const ButtonWrapper = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.pxToRem(40)} ${theme.pxToRem(40)} ${theme.pxToRem(40)}`};

  button {
    margin-right: ${({ theme }) => theme.pxToRem(20)};
  }
`;

export const Subtext = styled.p`
  padding-top: ${({ theme }) => theme.pxToRem(20)};
  padding-bottom: ${({ theme }) => theme.pxToRem(40)};
  color: ${({ theme }) => theme.colours.charcoalDust};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }) => theme.pxToRem(24)};
`;

export const NoMatchText = styled.p`
  padding-top: ${({ theme }) => theme.pxToRem(5)};
  padding-bottom: ${({ theme }) => theme.pxToRem(150)};
  font-weight: 300;
  margin: auto;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  color: ${({ theme }) => theme.colours.white};
  font-size: ${({ theme }) => theme.pxToRem(25)};
  line-height: ${({ theme }) => theme.pxToRem(71)};
`;

export const WhiteOutlineButton = styled.div`
  display: flex;
  button {
    border-color: ${({ theme }) => theme.colours.white};
    color: ${({ theme }) => theme.colours.white};
  }
`;

export const StatusChip = styled(Chip)<StatusChipProps>`
  height: ${({ theme }) => theme.pxToRem(24)};
  color: ${({ theme }) => theme.colours.black};
  background-color: ${({ theme, complete }) =>
    complete ? theme.colours.poisonIvy : theme.colours.flourescentOrange};
  border-color: ${({ theme, complete }) =>
    complete ? theme.colours.poisonIvy : theme.colours.flourescentOrange};

  span {
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: normal !important;
  }
`;

export const Description = styled.div`
  padding-top: ${({ theme }) => theme.pxToRem(20)};

  p {
    font-size: ${({ theme }) => theme.pxToRem(18)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
    padding-bottom: ${({ theme }) => theme.pxToRem(10)};
  }

  span {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }

  em {
    font-style: italic;
  }
`;

export const StatusInfo = styled.div`
  display: inline-flex;
  align-items: first baseline;

  p {
    padding: 0;
    margin: 0 1rem 0 0;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colours.black};
  padding: ${({ theme }) =>
    `0 ${theme.pxToRem(60)} ${theme.pxToRem(40)} ${theme.pxToRem(60)}`};
  font-size: ${({ theme }) => theme.pxToRem(18)};
`;

type SectionProps = { large?: boolean };

export const Section = styled.section<SectionProps>`
  padding: ${({ large = false, theme }) =>
    theme.spacing(large ? gap * 2 : gap)};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.black};
`;

export const ButtonMenu = styled.div`
  z-index: 5;
  width: 182px;
  position: absolute;
  top: 60px;
  background-color: ${({ theme }) => theme.colours.white};
  border: 1px solid ${({ theme }) => theme.colours.concreteGray};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
`;

export const ButtonMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.pxToRem(6.5)} ${theme.pxToRem(20)}`};
  cursor: pointer;
  gap: 8px;

  &:first-child {
    border-top-right-radius: ${({ theme }) => theme.pxToRem(4)};
    border-top-left-radius: ${({ theme }) => theme.pxToRem(4)};
  }

  &:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.pxToRem(4)};
    border-bottom-left-radius: ${({ theme }) => theme.pxToRem(4)};
  }

  &.label {
    padding: ${({ theme }) => `${theme.pxToRem(6.5)} ${theme.pxToRem(16)}`};
    cursor: default;
    color: ${({ theme }) => theme.colours.redRampage};

    &:hover {
      background-color: unset;
    }

    .text {
      color: ${({ theme }) => theme.colours.charcoalDust};
      font-size: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  &.divisor-top {
    border-top: 1px solid ${({ theme }) => theme.colours.spanishGrey};
  }

  &:hover {
    background-color: #f0f0f0;
  }

  .icon {
    margin-right: ${({ theme }) => theme.pxToRem(5)};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    fill: red;
  }

  .text {
    color: ${({ theme }) => theme.colours.darkToneInk};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;

    &.danger {
      color: ${({ theme }) => theme.colours.redRampage};
    }
  }
`;
