import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_LOCAL === 'true'
        ? process.env.REACT_APP_INSIGHTSKEY
        : window.ReactAppConfig.insightsKey,
    extensions: [reactPlugin], // Extensions/plugins to be used with Application Insights (including the ReactPlugin)
    enableAutoRouteTracking: true, // Enable automatic route tracking in a React application
    autoTrackPageVisitTime: true, // Enable tracking of page visit time
    enableCorsCorrelation: true, // Enable cross-origin resource sharing (CORS) correlation
    enableRequestHeaderTracking: true, // Enable tracking of request headers
    enableResponseHeaderTracking: true, // Enable tracking of response headers
  },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env) => {
  env.tags = env.tags || [];
});

export { appInsights, reactPlugin };
