import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { Link, Link as StyledLink, Typography } from 'components/atoms';
import { AppLayout } from 'layouts';
import { FixedWidthButton, Section } from './NotFound-styles';
import { useTerminologyConfig } from 'context/terminologyConfig';

function NotFound() {
  const terminologyConfig = useTerminologyConfig();
  return (
    <AppLayout
      topBarSlot={
        <StyledLink to="/third-party" dark>
          Back to {terminologyConfig?.thirdPartySingularNaming} manager
        </StyledLink>
      }
    >
      <Section>
        <Typography tag="h1" value="Page Not Found" />
        <p>The page you are looking for does not exist</p>
        <Link to="/third-party">
          <FixedWidthButton secondary type="button">
            Return home
          </FixedWidthButton>
        </Link>
      </Section>
    </AppLayout>
  );
}

export default withAITracking(reactPlugin, NotFound);
