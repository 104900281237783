import Footer from './Footer';

export const args = {
  copyright: '© Control Risks Group Holdings Ltd',
  footerLinks: [
    {
      name: 'Legal & Privacy',
      url: 'https://www.controlrisks.com/legal-1/privacy-policy',
      target: '_blank',
    },
    {
      name: 'Grievance Process',
      url: 'https://www.controlrisks.com/who-we-are/grievance-process',
      target: '_blank',
    },
    {
      name: 'Modern Slavery Act',
      url: 'https://www.controlrisks.com/-/media/corporate/files/who-we-are/corporate-responsibility/modern-slavery-statement.pdf',
      target: '_blank',
    },
    {
      name: 'Trust Centre',
      url: 'https://www.controlrisks.com/legal-1/trust-centre',
      target: '_blank',
    },
    {
      name: 'Social',
      url: 'https://www.controlrisks.com/social-media',
      target: '_blank',
    },
  ],
  socialLinks: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/ControlRisksGroup/',
      target: '_blank',
      icon: 'facebook',
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/control-risks/',
      target: '_blank',
      icon: 'linkedin',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Control_Risks',
      target: '_blank',
      icon: 'twitter',
    },
  ],
};

export default Footer;
