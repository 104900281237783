/* eslint-disable */

export const individualAdditionalFields = [
    'AddressLineOne',
    'City',
    'County',
    'ZipCode',
    'CountryTwo',
    'CountryThree',
    'Gender',
    'DateOfBirth',
    'Nationality',
    'PassportNumber',
    'NationalIDNumber',
    'OtherIDNumber',
];

export const companyAdditionalFields = [
    'AddressLineOne',
    'City',
    'County',
    'ZipCode',
    'CountryTwo',
    'CountryThree',
    'RegistrationNumber',
    'SWIFT',
    'CompanyUrl',
];