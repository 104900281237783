import styled from 'styled-components';

export const SectionedListHeading = styled.p`
  padding: ${({ theme }) => theme.spacing(10)}
    ${({ theme }) => theme.spacing(15)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.whiteSolid};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  font-weight: 600;
  line-height: 1.5888;
`;

export const SectionedListValues = styled.div`
  padding: ${({ theme }) => theme.spacing(10)}
    ${({ theme }) => theme.spacing(15)};

  p {
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: 1.5;
  }

  p + p {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }

  span {
    display: inline-block;
    font-size: 14px;
    color: ${({ theme }) => theme.colours.charcoalDust};
  }

  span + span {
    margin-left: ${({ theme }) => theme.spacing(6)};
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }
`;
