import React, { useEffect } from 'react';

import {
  PaginationComponent,
  ArrowButton,
  PaginationItemList,
  PaginationItem,
  PaginationItemButton,
} from './Pagination.styles';
import { PaginationTypes } from './types';
import { Icon } from 'components/atoms';
import { PaginationDot } from 'components/atoms/pagination-dot/PaginationDot.styles';

const Pagination: React.FC<PaginationTypes> = ({
  setCurrentPage,
  totalPages = 1,
  currentPage = 1,
  pagesToShow = 4,
  label,
  onPageChange = null,
  useDots = false,
}: PaginationTypes) => {
  const goToPage = (pageNumber: number) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    }
    if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    }
    if (pageNumber >= 1 || pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const filterPagination = (pageNumber: number) => {
    if (pagesToShow === -1) return true;
    let leftPad;
    let rightPad;

    pageNumber += 1; // pageNumber is zero based but currentPage is not, so iterate by 1

    // Calculate default left/right pagination padding
    if (pagesToShow % 2 === 0) {
      leftPad = pagesToShow / 2 - 1;
      rightPad = pagesToShow / 2;
    } else {
      leftPad = rightPad = Math.floor(pagesToShow / 2);
    }

    // Fix left padding (ie. current page is close to the beginning of pagination)
    if (currentPage + 1 - leftPad - rightPad < 0) {
      const padOffset = Math.abs(currentPage + 1 - leftPad - rightPad);
      rightPad += padOffset;
      leftPad -= padOffset;
    }

    // Fix right padding (ie. current page is close to the end of pagination)
    if (currentPage + rightPad > totalPages) {
      const padOffset = currentPage + rightPad - totalPages;
      rightPad -= padOffset;
      leftPad += padOffset;
    }

    // Test if pageNumber is within range and should be displayed
    const start = currentPage - leftPad;
    const end = currentPage + rightPad;

    if (pageNumber >= start && pageNumber <= end) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (typeof onPageChange === 'function') {
      onPageChange({ currentPage });
    }
    // ignoring to avoid onPageChange triggering everytime
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <PaginationComponent role="navigation" aria-label={label}>
      <ArrowButton
        type="button"
        onClick={() => goToPage(currentPage - 1)}
        disabled={currentPage === 1}
        $direction="previous"
        aria-label="Go to previous page"
      >
        <Icon icon="chevron-left" size="xxs" aria-hidden />
      </ArrowButton>
      <PaginationItemList>
        {[...new Array(totalPages).keys()]
          .filter(filterPagination)
          .map((pageNumber) => (
            <PaginationItem key={`page-${pageNumber}`}>
              {useDots ? (
                <PaginationDot
                  aria-label={`Go to page ${pageNumber + 1}`}
                  onClick={() => goToPage(pageNumber + 1)}
                  isActive={currentPage === pageNumber + 1}
                  dark={true}
                />
              ) : (
                <PaginationItemButton
                  type="button"
                  aria-label={`Go to page ${pageNumber + 1}`}
                  onClick={() => goToPage(pageNumber + 1)}
                  $selected={currentPage === pageNumber + 1}
                >
                  {pageNumber + 1}
                </PaginationItemButton>
              )}
            </PaginationItem>
          ))}
      </PaginationItemList>
      <ArrowButton
        type="button"
        onClick={() => goToPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        $direction="next"
        aria-label="Go to next page"
      >
        <Icon icon="chevron-right" size="xxs" aria-hidden />
      </ArrowButton>
    </PaginationComponent>
  );
};

export default Pagination;
