import React, { FC, useContext } from 'react';
import { ToastClose, ToastContainer } from './Toast.styles';

import { ToastProps, ToastType, defaultProps } from './types';
import { ReactComponent as InfoIcon } from './../../../assets/svg/Icons/InfoToast.svg';
import { ReactComponent as ErrorIcon } from './../../../assets/svg/Icons/ErrorToast.svg';
import { ReactComponent as WarningIcon } from './../../../assets/svg/Icons/WarningToast.svg';
import { ReactComponent as SuccessIcon } from './../../../assets/svg/Icons/SuccessToast.svg';
import { Icon } from 'components/atoms';
import { ToastContext } from 'toast';

const Toast: FC<ToastProps> = ({ type, ...customProps }: ToastProps) => {
  const props = { ...defaultProps[type], ...customProps };

  const { title, message, accentColor, backgroundColor, compact, onClose } =
    props;

  const toastIcon = {
    [ToastType.Information]: <InfoIcon />,
    [ToastType.Error]: <ErrorIcon />,
    [ToastType.Warning]: <WarningIcon />,
    [ToastType.Success]: <SuccessIcon />,
  };

  const { closeToast } = useContext(ToastContext);

  return (
    <ToastContainer accentColor={accentColor} backgroundColor={backgroundColor}>
      {toastIcon[type]}
      <div className="messageContainer">
        {!compact && <h3 className="title">{title}</h3>}
        <p className="message">{message}</p>
      </div>
      <ToastClose
        type="button"
        aria-label="close"
        onClick={() => {
          closeToast();
          onClose && onClose();
        }}
      >
        <Icon icon="close" className="close" size="xxxxs" />
      </ToastClose>
    </ToastContainer>
  );
};

export default Toast;
