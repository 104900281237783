import styled from 'styled-components';

export const Wrapper = styled.div.attrs({
  className: 'formcontrol',
})`
  margin-bottom: ${({ theme: { spacing } }) => spacing(16)};

  > span,
  > small {
    display: block;
    padding: 0;
    margin: ${({ theme }) => theme.spacing(4)} 0 0;
    font-size: ${({ theme: { typography } }) => typography.fontSize.small};
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.light};
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colours.redRampage} !important;
`;

export const HelperText = styled.small`
  color: ${({ theme }) => theme.colours.charcoalDust};
`;
