import { Typography } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { FC } from 'react';
import {
  LoadingUploadModalContent,
  LoadingUploadContainer,
  ProgressBarContainer,
  Progress,
} from './DocumentUploadModal.styles';
import { UploadingModalTypes } from './types';

const UploadingModal: FC<UploadingModalTypes> = ({
  isOpen,
  progress,
  progressMessage,
}) => (
  <Modal
    isClosable={false}
    removeSeparator
    removePadding
    isOpen={isOpen}
    onClose={() => {}}
  >
    <LoadingUploadModalContent>
      <LoadingUploadContainer>
        <Typography
          className="uploading-info-text"
          value={`Uploading and checking the security of the document. Please wait...`}
          tag="p"
        />
        <ProgressBarContainer>
          <Progress completed={progress}></Progress>
        </ProgressBarContainer>
        <Typography
          className="uploading-remaining-text"
          value={progressMessage}
          tag="p"
        />
      </LoadingUploadContainer>
    </LoadingUploadModalContent>
  </Modal>
);

export default UploadingModal;
