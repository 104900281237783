import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from './reducer';

import { ScreeningContextTypes } from './types';

export const ScreeningContext = createContext<ScreeningContextTypes>({
  screeningState: initialState,
  screeningDispatch: () => null,
});

export const ScreeningProvider = ({ children }) => {
  const [screeningState, screeningDispatch] = useReducer(reducer, initialState);

  return (
    <ScreeningContext.Provider
      value={{
        screeningState,
        screeningDispatch,
      }}
    >
      {children}
    </ScreeningContext.Provider>
  );
};
