import React, { FC, useContext, useMemo, useState } from 'react';
import { SectionHeading } from 'components/molecules';
import {
  Button,
  FieldCheckbox,
  Icon,
  Loading,
  Typography,
} from 'components/atoms';
import {
  DocumentsContainer,
  DocumentsPlaceholder,
  IconButtonWithLabel,
  RecordsLabel,
} from './Documents.styles';
import { FileFormat, FileInfo, getFileExtension } from 'helpers/documentHelper';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { GatewayPermissions } from 'auth/constants/permissions';
import DocumentUploadModal from 'components/organisms/document-upload-modal';
import { ThirdPartyType } from 'types/thirdPartyType';
import { ToastContext, ToastContextProps } from 'toast';
import { ToastType } from 'components/molecules/toast/types';
import { ReactComponent as DocumentsPlaceholderIcon } from './../../../../assets/svg/Icons/DocumentsPlaceholder.svg';
import DeleteLinkedConfirmationModal from 'components/organisms/delete-linked-confirmation-modal';
import ConfirmationModal from 'components/organisms/confirmation-modal';
import DocumentCard from 'components/molecules/document-card';
import ThreeDotsContextMenu from 'components/organisms/three-dots-context-menu/ThreeDotsContextMenu';

interface DocumentsProps {
  id: string;
  type: string;
}

const Documents: FC<DocumentsProps> = ({ id, type }) => {
  const { accessToken, hasPermission, email } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const [isDocumentUploadModalOpen, setIsDocumentUploadModalOpen] = useState({
    open: false,
    documentId: undefined,
  });
  const [areConflictedFilesModalOpen, setAreConflictedFilesModalOpen] =
    useState({ open: false, conflictedScreenings: undefined });
  const { openToast } = useContext<ToastContextProps>(ToastContext);
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

  const {
    data: dataGetThirdPartyFiles,
    loading: loadingGetThirdPartyFiles,
    trigger: reloadGetThirdPartyFiles,
  } = useFetch(`${vantageWebApi}/thirdparty/${id}/files`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { loading: loadingRequestFiles, trigger: fetchFileLists } = useFetch(
    `${vantageWebApi}/thirdparty/${id}/request-files`,
    {
      body: JSON.stringify(selectedFileIds),
      lazy: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: () => {
        disableMultiFileDownload();
      },
      onError: () => {
        disableMultiFileDownload();
      },
    }
  );

  const isSelectAllDisabled = useMemo(
    () => dataGetThirdPartyFiles?.length === selectedFileIds?.length,
    [dataGetThirdPartyFiles, selectedFileIds]
  );

  const disableMultiFileDownload = () => {
    setOpenModal(false);
    setIsMultiSelect(false);
    setSelectedFileIds([]);
  };

  const canUploadDocuments: boolean = hasPermission(
    GatewayPermissions.UploadDocuments
  );

  const canDeleteDocuments: boolean =
    hasPermission(GatewayPermissions.ViewDocuments) &&
    hasPermission(GatewayPermissions.DeleteDocuments);

  const canEditDocuments: boolean =
    hasPermission(GatewayPermissions.ViewDocuments) &&
    hasPermission(GatewayPermissions.EditDocument);

  const filesInfo = (dataGetThirdPartyFiles || []) as FileInfo[];
  const filesLimitExceeded = filesInfo.length >= 60;

  const handleFailView = (status) => {
    switch (status) {
      case 401:
        openToast({
          type: ToastType.Error,
          message: `You don't have enough permissions to view documents`,
        });
        break;

      default:
        openToast({
          type: ToastType.Error,
          message: 'An error occurred while downloading the document',
        });
        break;
    }
  };

  const handleFailDownload = (status) => {
    switch (status) {
      case 401:
        openToast({
          type: ToastType.Error,
          message: `You don't have enough permissions to download documents`,
        });
        break;

      default:
        openToast({
          type: ToastType.Error,
          message: 'An error occurred while downloading the document',
        });
        break;
    }
  };

  const handleSuccessDelete = () => {
    openToast({
      type: ToastType.Success,
      message: 'Document has been successfully deleted',
    });
    reloadGetThirdPartyFiles();
  };

  const handleFailDelete = (status: number, _, body: Record<string, any>) => {
    switch (status) {
      case 401:
        openToast({
          type: ToastType.Error,
          message: `You don't have enough permissions to delete documents`,
        });
        break;

      case 409:
        setAreConflictedFilesModalOpen({
          open: true,
          conflictedScreenings: [...body.conflictedScreenings.screenings],
        });
        break;

      default:
        openToast({
          type: ToastType.Error,
          message: 'An error occurred while deleting the document',
        });
        break;
    }
  };

  const handleSelectDocumentChange = (fileInfoId: string) => {
    const index = selectedFileIds.indexOf(fileInfoId);

    if (index >= 0) {
      selectedFileIds.splice(index, 1);
      setSelectedFileIds([...selectedFileIds]);
    } else {
      setSelectedFileIds([...selectedFileIds, fileInfoId]);
    }
  };

  const handleSelectAllClick = () => {
    const allIds = filesInfo.map((x) => x.id);
    if (allIds.length > selectedFileIds.length) {
      setSelectedFileIds(allIds);
    }
  };

  return (
    <>
      <section style={{ margin: 0 }}>
        <SectionHeading
          text={`${terminologyConfig?.thirdPartySingularNaming} documents`}
          border
        >
          <IconButtonWithLabel
            small
            style={{ background: 'transparent', border: 'none' }}
            onClick={() => {
              if (isMultiSelect && selectedFileIds.length > 0) {
                setOpenModal(true);
              } else {
                setIsMultiSelect((x) => !x);
              }
            }}
          >
            <Icon icon="download" size="xxxs" />
            <span style={{ color: '#053747' }}>
              {isMultiSelect
                ? `Download (${selectedFileIds.length})`
                : 'Select to download'}
            </span>
          </IconButtonWithLabel>
          {canUploadDocuments && (
            <IconButtonWithLabel
              small
              secondary
              disabled={filesLimitExceeded}
              onClick={() => {
                disableMultiFileDownload();
                setIsDocumentUploadModalOpen({
                  open: true,
                  documentId: undefined,
                });
              }}
            >
              <Icon icon="plus-circle" size="xxxs" />
              <span>Add document</span>
            </IconButtonWithLabel>
          )}
        </SectionHeading>
        {loadingGetThirdPartyFiles && <Loading />}
        {!loadingGetThirdPartyFiles && filesInfo.length <= 0 && (
          <DocumentsPlaceholder>
            <DocumentsPlaceholderIcon />
            <div className="title">You've not added anything yet</div>
            <div>Add a document and it will be shown here</div>
          </DocumentsPlaceholder>
        )}
        {!loadingGetThirdPartyFiles && filesInfo.length > 0 && (
          <>
            <div
              style={{
                display: 'flex',
                gap: '20px',
              }}
            >
              <RecordsLabel>{filesInfo.length} records</RecordsLabel>
              {isMultiSelect && (
                <>
                  <Button
                    style={{ padding: '6px 0 0 0', minHeight: 0, height: 0 }}
                    small
                    text
                    title="select all"
                    disabled={isSelectAllDisabled}
                    onClick={handleSelectAllClick}
                    aria-label="Select all"
                  >
                    Select all
                  </Button>
                  <Button
                    style={{ padding: '6px 0 0 0', minHeight: 0, height: 0 }}
                    small
                    text
                    className="button cancel"
                    title="cancel"
                    onClick={disableMultiFileDownload}
                    aria-label="cancel"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
            <DocumentsContainer>
              {filesInfo.map((fileInfo) => (
                <DocumentCard
                  key={fileInfo.id}
                  fileType={
                    getFileExtension(
                      fileInfo.fileDownloadName
                    ).toLowerCase() as FileFormat
                  }
                  name={fileInfo.name}
                  date={fileInfo.uploadedDate}
                  category={fileInfo.category}
                  extendedNames={false}
                  actionComponent={
                    isMultiSelect ? (
                      <FieldCheckbox
                        spanStyles={{
                          height: '25px',
                          width: '25px',
                          marginTop: '2px',
                          marginRight: '2px',
                        }}
                        ariaLabel="file-check"
                        id={fileInfo.id}
                        checked={selectedFileIds.includes(fileInfo.id)}
                        onChange={() => handleSelectDocumentChange(fileInfo.id)}
                      />
                    ) : (
                      <ThreeDotsContextMenu
                        fileInfo={fileInfo}
                        onEdit={() => {
                          setIsDocumentUploadModalOpen({
                            open: true,
                            documentId: fileInfo.id,
                          });
                        }}
                        onFailView={handleFailView}
                        onFailDownload={handleFailDownload}
                        onSuccessDelete={handleSuccessDelete}
                        onFailDelete={handleFailDelete}
                        showDeleteOption={canDeleteDocuments}
                        showEditOption={canEditDocuments}
                      />
                    )
                  }
                />
              ))}
            </DocumentsContainer>
          </>
        )}
      </section>
      <DocumentUploadModal
        entityType={ThirdPartyType[type]}
        isOpen={isDocumentUploadModalOpen.open}
        thirdPartyId={id}
        linkToThirdParty
        notifyOnFileUpload
        onClose={() =>
          setIsDocumentUploadModalOpen({ open: false, documentId: undefined })
        }
        onSuccessDocumentUpload={() => reloadGetThirdPartyFiles()}
        documentId={isDocumentUploadModalOpen.documentId}
        onSuccessDocumentEdit={() => {
          reloadGetThirdPartyFiles();
          setIsDocumentUploadModalOpen({ open: false, documentId: undefined });
        }}
      />
      <DeleteLinkedConfirmationModal
        isOpen={areConflictedFilesModalOpen.open}
        conflictedScreenings={areConflictedFilesModalOpen.conflictedScreenings}
        onClose={() =>
          setAreConflictedFilesModalOpen({
            open: false,
            conflictedScreenings: undefined,
          })
        }
      ></DeleteLinkedConfirmationModal>
      <ConfirmationModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onProceed={() => fetchFileLists()}
        onCancel={disableMultiFileDownload}
        title="Download documents"
        messageBody={
          <>
            <Typography
              className="info-text"
              value="The selected documents will be delivered via email to "
              tag="span"
            />
            <Typography
              styles={{ fontWeight: 'bold' }}
              className="info-text"
              value={`${email}.`}
              tag="span"
            />
          </>
        }
        proceedButtonText="Confirm"
        proceedButtonLoading={loadingRequestFiles}
        onProceedAriaLabel="Confirm"
        loading={false}
        cancelButtonProps={{
          outline: false,
          text: true,
          'aria-label': 'Cancel',
        }}
      />
    </>
  );
};

export default Documents;
