import styled from 'styled-components';
import { WelcomeMessageTypes } from './types';

type WelcomeMessageComponentProps = Pick<WelcomeMessageTypes, 'dark'>;

export const WelcomeMessageComponent = styled.div<WelcomeMessageComponentProps>`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: auto;
  height: ${({ theme }) => theme.pxToRem(45)};

  p {
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.white : theme.colours.crowberryBlue};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    letter-spacing: 0;
    line-height: 1.714;
    text-align: right;
  }

  img {
    border: ${({ theme }) => theme.pxToRem(2)} solid
      ${({ theme }) => theme.colours.swimmersPool};
    margin-left: ${({ theme }) => theme.spacing(15)};
    border-radius: 50%;
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  strong {
    font-weight: bold;
  }

  .callout-dropdown {
    right: 0;
    left: unset;
    transform: none;
  }
`;

export const WelcomeMessageButton = styled.button`
  display: block;
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(45)};
  padding: 0 ${({ theme }) => theme.spacing(20)};
  color: ${({ theme }) => theme.colours.charcoalDust};
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.714;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colours.whiteSolid};
  }
`;
