export interface FetchState<T = any, U = any> {
  loading: boolean;
  data: T | null | any;
  error: boolean;
  responseStatus?: number;
  errorData: U | null | any;
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum FetchActionTypes {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
  FETCH_RESET = 'FETCH_RESET',
}

export interface FetchAction<T> {
  type: FetchActionTypes;
  payload?: T;
  responseStatus?: number;
}

export interface FetchOptions<T, U> {
  method?: keyof typeof HttpMethod;
  headers?: Record<string, string | null | undefined>;
  lazy?: boolean;
  body?: BodyInit | null;
  initialState?: Partial<FetchState<T>>;
  onSuccess?: (data: T) => void;
  onError?: (err: U) => void;
}

export interface FetchAction<T> {
  type: FetchActionTypes;
  payload?: T;
}

export interface TriggerOptions<P extends string> {
  body?: BodyInit;
  urlParams?: Record<P, string | number>;
  queryParams?: Record<string, string | number | boolean>;
}
