import styled from 'styled-components';

export const LoginLayoutComponent = styled.main`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colours.sparklingSnow};
`;

export const LayoutLeft = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  clip-path: polygon(70% 0, 100% 50%, 70% 100%, 0 100%, 0 0);

  ${({ theme }) => theme.breakPoints.xlDesktop} {
    width: 30%;
  }
`;

export const LayoutRight = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing(180)};
  padding-top: ${({ theme }) => theme.spacing(100)};
  padding-bottom: ${({ theme }) => theme.spacing(100)};

  ${({ theme }) => theme.breakPoints.xlDesktop} {
    width: 70%;
    padding-top: ${({ theme }) => theme.spacing(150)};
    padding-bottom: ${({ theme }) => theme.spacing(150)};
  }
`;

export const LoginLayoutBrand = styled.a`
  position: absolute;
  top: ${({ theme }) => theme.spacing(50)};
  left: ${({ theme }) => theme.spacing(42)};
  z-index: ${({ theme }) => theme.zIndex.level_1};
`;

export const LoginMessage = styled.aside`
  max-width: 36ch;

  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: ${({ theme }) => theme.pxToRem(60)};
    line-height: ${({ theme }) => theme.pxToRem(71)};
    margin-bottom: ${({ theme }) => theme.spacing(19)};
  }

  p {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
    font-size: ${({ theme }) => theme.pxToRem(18)};
    line-height: 1.66667;
  }
`;
