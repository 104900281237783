import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useFetch } from 'hooks';
import { DueDiligenceComponent, ActionMenuWrapper } from './Drafts.styles';
import { Date, Link, Loading } from 'components/atoms';
import ConfirmationModal from 'components/organisms/confirmation-modal';
import { SectionHeading, Pagination, ActionMenu } from 'components/molecules';
import { SimpleTable } from 'pages/third-party-profile/ThirdPartyProfile.styles';
import { ActionMenuActions } from './types';
import generateTheme from 'theme';
// import DueDiligenceDeleteModal from './due-diligence-delete-modal';

type DueDiligenceDraftsProps = {
  thirdPartyId: string;
  draftsCount: number;
};

const DueDiligenceDrafts: React.FC<DueDiligenceDraftsProps> = ({
  thirdPartyId,
  draftsCount,
}) => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 5;
  const [draftToBeDeleted, setDraftToBeDeleted] = useState<string>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteLoadingStatus, setDeleteLoadingStatus] =
    useState<string>('not started');

  const { data, loading, error, responseStatus, trigger } = useFetch(
    `${vantageWebApi}/thirdParty/${id}/drafts`,
    {
      method: 'GET',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { loading: deleteLoading, trigger: deleteLazy } = useFetch<
    unknown,
    unknown,
    'draftToBeDeleted'
  >(`${vantageWebApi}/onlinescreening/draft/:draftToBeDeleted`, {
    method: 'DELETE',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setDraftToBeDeleted(null);
  };

  const handleDraftDelete = async () => {
    if (draftToBeDeleted) {
      deleteLazy({
        urlParams: { draftToBeDeleted },
      });
    }
  };

  useEffect(() => {
    if (deleteLoading) {
      setDeleteLoadingStatus('started');
      setDeleteModalOpen(false);
    }
    if (!deleteLoading && deleteLoadingStatus === 'started') {
      setDeleteLoadingStatus('completed');
    }
    if (deleteLoadingStatus === 'completed') {
      setDeleteLoadingStatus('not started');
      setDraftToBeDeleted(null);
      history.go(0);
    }
  }, [deleteLoading, deleteLoadingStatus, draftsCount]);

  function getPermittedActions(id: string = null): ActionMenuActions {
    let actions = [];

    actions.push({
      name: 'Delete',
      onClick: () => {
        if (id) {
          setDraftToBeDeleted(id);
          setDeleteModalOpen(true);
        }
      },
    });

    return actions;
  }

  const theme = generateTheme();

  useEffect(() => {
    trigger({
      queryParams: {
        page: currentPage,
        pageSize: PAGE_SIZE,
      },
    });
  }, [currentPage, trigger, PAGE_SIZE]);

  return (
    <DueDiligenceComponent>
      <SectionHeading
        text={`${terminologyConfig?.thirdPartySingularNaming} due diligence drafts`}
      ></SectionHeading>

      {loading || error || (responseStatus && responseStatus !== 200) ? (
        loading ? (
          <Loading />
        ) : (
          <p>Error loading drafts data.</p>
        )
      ) : (
        <>
          <SimpleTable cellSpacing="0">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Created</th>
                <th style={{ width: '25%' }}>Diligence Type</th>
                <th style={{ width: '25%' }}>Level</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.results?.map((item) => (
                <tr key={item?.draftId}>
                  <td>
                    <Date
                      dateTime={item?.creationDate}
                      format="Do MMM, YYYY, HH:mm"
                    />
                  </td>
                  <td>{item?.diligenceType}</td>
                  <td>{item?.level}</td>
                  <td>
                    <Link
                      href={`/initiate-due-diligence/${thirdPartyId}?draftId=${item?.draftId}`}
                    >
                      View Draft
                    </Link>
                  </td>
                  {getPermittedActions().length > 0 && (
                    <ActionMenuWrapper verticalAlign="middle">
                      <ActionMenu
                        actions={getPermittedActions(item?.draftId)}
                        name={`Manage draft case ${item?.draftId}`}
                        ellipsisColour={theme.colours.pacificBlue}
                      ></ActionMenu>
                    </ActionMenuWrapper>
                  )}
                </tr>
              ))}
            </tbody>
          </SimpleTable>
          <Pagination
            setCurrentPage={setCurrentPage}
            totalPages={data?.pageCount}
            currentPage={currentPage}
            onPageChange={(change) => {
              setCurrentPage(change.currentPage);
            }}
          />
        </>
      )}
      <ConfirmationModal
        data-testid="DeleteDraftConfirmationModal"
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onProceed={handleDraftDelete}
        onProceedAriaLabel="delete due diligence order draft"
        onCancel={closeDeleteModal}
        title="Delete draft?"
        message="Please note that this will delete the selected draft case"
        proceedButtonText="Delete"
        loading={deleteLoading}
      />
    </DueDiligenceComponent>
  );
};

export default DueDiligenceDrafts;
