import React from 'react';
import { FormControl, FieldTextarea, FieldInput } from 'components/atoms';
import { Expedite } from 'components/molecules/expedite';
import { OptionalAddons } from 'components/molecules/optional-addons';
import { HelpText } from './OtherInformation.styles';

const OtherInformation = ({
  register,
  dueDiligenceLevel,
  selectedReport,
  formValidationErrors,
}) => {
  return (
    <div className="screening-settings-container">
      <HelpText>
        Please include any other information which may be relevant to the
        report.
      </HelpText>
      <FormControl errorMessage={formValidationErrors?.comments?.message}>
        <FieldTextarea
          label=""
          id="comments"
          name="comments"
          register={register}
          fullWidth
        />
      </FormControl>
      {dueDiligenceLevel?.customFieldsConfig?.customField1?.isEnabled && (
        <FormControl errorMessage={formValidationErrors?.customField1?.message}>
          <FieldInput
            name="customField1"
            id="customField1"
            required={
              dueDiligenceLevel.customFieldsConfig.customField1.isMandatory
            }
            register={register}
            label={dueDiligenceLevel.customFieldsConfig.customField1.label}
            valid={!Boolean(formValidationErrors.customField1)}
          />
        </FormControl>
      )}
      {dueDiligenceLevel?.customFieldsConfig?.customField2?.isEnabled && (
        <FormControl errorMessage={formValidationErrors?.customField2?.message}>
          <FieldInput
            name="customField2"
            id="customField2"
            required={
              dueDiligenceLevel.customFieldsConfig.customField2.isMandatory
            }
            register={register}
            label={dueDiligenceLevel.customFieldsConfig.customField2.label}
            valid={!Boolean(formValidationErrors.customField2)}
          />
        </FormControl>
      )}
      {dueDiligenceLevel?.customFieldsConfig?.customField3?.isEnabled && (
        <FormControl errorMessage={formValidationErrors?.customField3?.message}>
          <FieldInput
            name="customField3"
            id="customField3"
            required={
              dueDiligenceLevel.customFieldsConfig.customField3.isMandatory
            }
            register={register}
            label={dueDiligenceLevel.customFieldsConfig.customField3.label}
            valid={!Boolean(formValidationErrors.customField3)}
          />
        </FormControl>
      )}
      {selectedReport?.customFieldsConfig?.customField1?.isEnabled && (
        <FormControl
          errorMessage={formValidationErrors?.reportCustomField1?.message}
        >
          <FieldInput
            name="reportCustomField1"
            id="reportCustomField1"
            required={
              selectedReport.customFieldsConfig.customField1.isMandatory
            }
            register={register}
            label={selectedReport.customFieldsConfig.customField1.label}
            valid={!Boolean(formValidationErrors.reportCustomField1)}
          />
        </FormControl>
      )}
      {selectedReport?.customFieldsConfig?.customField2?.isEnabled && (
        <FormControl
          errorMessage={formValidationErrors?.reportCustomField2?.message}
        >
          <FieldInput
            name="reportCustomField2"
            id="reportCustomField2"
            required={
              selectedReport.customFieldsConfig.customField2.isMandatory
            }
            register={register}
            label={selectedReport.customFieldsConfig.customField2.label}
            valid={!Boolean(formValidationErrors.reportCustomField2)}
          />
        </FormControl>
      )}
      {selectedReport?.customFieldsConfig?.customField3?.isEnabled && (
        <FormControl
          errorMessage={formValidationErrors?.reportCustomField3?.message}
        >
          <FieldInput
            name="reportCustomField3"
            id="reportCustomField3"
            required={
              selectedReport.customFieldsConfig.customField3.isMandatory
            }
            register={register}
            label={selectedReport.customFieldsConfig.customField3.label}
            valid={!Boolean(formValidationErrors.reportCustomField3)}
          />
        </FormControl>
      )}
      {selectedReport?.optionalAddOns.length > 0 && (
        <>
          <HelpText>Optional add-ons</HelpText>
          <OptionalAddons
            optionalAddOns={selectedReport.optionalAddOns}
            register={register}
          />
        </>
      )}
      {selectedReport?.canExpedite && (
        <Expedite
          register={register}
          expediteFee={selectedReport.expediteFee}
          expediteFeeCurrencyCode={selectedReport.expediteFeeCurrencyCode}
        />
      )}
    </div>
  );
};

export default OtherInformation;
