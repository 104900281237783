import React, { FC } from 'react';
import { AbbreviationComponent } from './Abbreviation.styles';
import { AbbreviationProps } from './types';
import { abbreviations } from './config';

const Abbreviation: FC<AbbreviationProps> = ({
  abbreviation,
  outline = false,
  small = false,
}) => {
  const data = abbreviations?.[abbreviation];
  if (!data) {
    return null;
  }
  return (
    <AbbreviationComponent outline={outline} small={small}>
      {data.short}
    </AbbreviationComponent>
  );
};

export default Abbreviation;
