import styled from 'styled-components';

export const ModalTopContentContainer = styled.div`
  heigth: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
`;
