import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  DocumentUploadModalComponent,
  ActionsContainer,
  ModalContent,
  FormControlRow,
  ErrorContainer,
  MultipleErrorsContainer,
  DetailsContainer,
  DetailsText,
} from './DocumentUploadModal.styles';

import { DocumentUploadModalTypes } from './types';
import { Modal } from 'components/molecules';
import {
  FieldInput,
  FormControl,
  FieldSelect,
  Button,
  Loading,
  FieldDate,
  Icon,
  Typography,
} from 'components/atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch, useSignalR } from 'hooks';
import { LoadingContainer } from 'components/molecules/table/Table.styles';
import { FileFormat, Document, getFileExtension } from 'helpers/documentHelper';
import ConfirmationModal from '../confirmation-modal';
import { ThirdPartyType } from 'types/thirdPartyType';
import { useTerminologyConfig } from 'context/terminologyConfig';
import dayjs from 'dayjs';
import UploadingModal from './UploadingModal';
import SuccessModal from './SuccessModal';
import UploadFileDashedContainer from './UploadFileDashedContainer';
import FieldUploadFile from 'components/atoms/field-upload-file';
import {
  getVerificationDateTimeFrameMessage,
  isVerificationDateValid,
} from 'helpers/verificationDateHelper';
import { useTheme } from 'styled-components';

const DocumentUploadModal: FC<DocumentUploadModalTypes> = ({
  isOpen,
  onClose,
  onSuccessDocumentUpload,
  onSuccessDocumentEdit,
  entityType,
  thirdPartyId,
  linkToThirdParty,
  categoryId = undefined,
  verificationDateConfig = null,
  notifyOnFileUpload = false,
  documentId = undefined,
}: DocumentUploadModalTypes) => {
  const isEditMode = !!documentId;

  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [uploadingModalOpen, setUploadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressMessage, setUploadProgressMessage] = useState('');
  const [signalRMessage, setSignalRMessage] = useState(null);
  const { isConnected, connectionId } = useSignalR(setSignalRMessage);

  const theme = useTheme();

  const { data: categoriesData, loading: categoriesLoading } = useFetch(
    `${vantageWebApi}/file/categories/client?entityType=${ThirdPartyType[entityType]}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const categoryOptions = useMemo(() => {
    if (!Array.isArray(categoriesData)) {
      return [];
    }
    return categoriesData.map(({ category, id }) => ({
      name: category,
      value: id,
    }));
  }, [categoriesData]);

  const {
    data: getFileByIdData,
    loading: getFileByIdLoading,
    trigger: getFileByIdLazy,
  } = useFetch(`${vantageWebApi}/file/${documentId}`, {
    method: 'GET',
    lazy: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const handleLoadingDocumentUpload = () => {
    setUploadProgress(0);
    setModalOpen(false);
    setUploadingModalOpen(true);
    setSuccessModalOpen(false);
  };

  const handleFailedDocumentUpload = (error: string) => {
    setUploadProgress(0);
    setModalOpen(true);
    setUploadingModalOpen(false);
    setSuccessModalOpen(false);
    setError('selectedFile', { type: 'unexpectedAPIError', message: error });
  };

  const handleSuccessDocumentUpload = (data) => {
    const document: Document = {
      id: data?.id,
      name: data?.name,
      category: data?.category,
      categoryId: data?.categoryId,
      size: data?.size,
      type: getFileExtension(data?.fileDownloadName),
      date: data?.uploadedDate,
      verificationDate: data?.verificationDate,
    };
    setModalOpen(false);
    setUploadingModalOpen(false);
    setSuccessModalOpen(true);
    onSuccessDocumentUpload(document);
  };

  const handleSuccessDocumentEdit = (data) => {
    const document: Document = {
      id: data?.id,
      name: data?.name,
      category: data?.category,
      categoryId: data?.categoryId,
      size: data?.size,
      type: getFileExtension(data?.fileDownloadName),
      date: data?.uploadedDate,
      verificationDate: data?.verificationDate,
    };
    setModalOpen(false);
    onSuccessDocumentEdit(document);
  };

  useEffect(() => {
    if (isConnected) {
      setUploadProgress(signalRMessage?.percentage);
      setUploadProgressMessage(signalRMessage?.message);
    }
    return () => {
      setUploadProgress(0);
      setUploadProgressMessage('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRMessage]);

  const {
    data: fileUploadData,
    responseStatus: fileUploadStatus,
    loading: fileUploadLoading,
    trigger: fileUploadLazy,
  } = useFetch(
    `${vantageWebApi}/file/upload?notifyAnalyst=${notifyOnFileUpload}`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: handleSuccessDocumentUpload,
    }
  );

  const { trigger: fileEditLazy } = useFetch(
    `${vantageWebApi}/file/${documentId}`,
    {
      method: 'PATCH',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: handleSuccessDocumentEdit,
    }
  );

  const kiloBytesTransform = (size) => {
    const kbs = Math.round(size / 1024);
    return `${kbs} KB`;
  };

  const supportedFormats = `.${FileFormat.PDF}, .${FileFormat.CSV}, 
  .${FileFormat.XLSX}, .${FileFormat.JPEG}, .${FileFormat.JPG}, 
  .${FileFormat.PNG}, .${FileFormat.DOCX}`;

  const validationSchema = yup.object().shape({
    documentTitle: yup
      .string()
      .nullable()
      .required('Document name is mandatory')
      .max(140, 'Maximum 140 characters'),
    documentCategory: yup
      .string()
      .test(
        'documentCategory-required',
        'Please select a category',
        (value) => {
          return value !== 'DEFAULT';
        }
      ),
    verificationDate: yup
      .date()
      .nullable()
      .when([], {
        is: () => verificationDateLabel,
        then: (schema) =>
          schema.required('Verification date is mandatory').when([], {
            is: () => verificationDateConfig,
            then: (schema) =>
              schema.test({
                name: 'invalidTimeframe',
                test: (dateToValidate) =>
                  isVerificationDateValid(
                    verificationDateConfig,
                    dateToValidate
                  ),
                params: {
                  label: verificationDateLabel,
                  timeFrame: getVerificationDateTimeFrameMessage(
                    verificationDateConfig
                  ),
                },
                message: 'Please ensure that the “${label}” is ${timeFrame}',
              }),
          }),
      }),
    selectedFile: yup.mixed<File>().when([], {
      is: () => !isEditMode,
      then: (schema) =>
        schema
          .required('Please note that you must upload a document to proceed.')
          .test({
            name: 'fileValidation',
            message:
              'The document you have attempted to upload is either an unsupported format or exceeds the maximum file size of 15MB. Please check and try again.',
            test: (file) => {
              if (!file) {
                return false;
              }

              if (file.size > 15728640) {
                // File size 15728640
                return false;
              }

              if (
                !Object.values(FileFormat).includes(
                  getFileExtension(file.name).toLocaleLowerCase() as FileFormat
                ) // File format
              ) {
                return false;
              }

              return true;
            },
          }),
    }),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    resetField,
    watch,
    trigger,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      documentTitle: '',
      documentCategory: categoryId ?? 'DEFAULT',
      verificationDate: null,
      selectedFile: null,
    },
  });

  const watchedCategoryId = useWatch({
    control,
    name: 'documentCategory',
  });

  const verificationDateLabel = useMemo(() => {
    if (watchedCategoryId == 'DEFAULT') {
      return undefined;
    }

    if (!Array.isArray(categoriesData)) {
      return undefined;
    }

    const foundCategory = categoriesData.find(
      (category) => category.id == watchedCategoryId
    );

    if (!foundCategory) {
      console.error(
        'Unexpected error, the selected category doesnt match with the catalog'
      );
      return undefined;
    }

    const { verificationDateLabelEnabled, verificationDateLabelValue } =
      foundCategory;

    if (!verificationDateLabelEnabled) {
      return undefined;
    }

    return verificationDateLabelValue;
  }, [watchedCategoryId, categoriesData]);

  const categoryName = useMemo(() => {
    if (watchedCategoryId == 'DEFAULT') {
      return undefined;
    }

    if (!Array.isArray(categoriesData)) {
      return undefined;
    }

    const foundCategory = categoriesData.find(
      (category) => category.id == watchedCategoryId
    );

    if (!foundCategory) {
      console.error(
        'Unexpected error, the selected category doesnt match with the catalog'
      );
      return undefined;
    }

    return foundCategory.category;
  }, [watchedCategoryId, categoriesData]);

  useEffect(() => {
    if (isEditMode) {
      getFileByIdLazy();
    }
  }, [isEditMode, documentId, getFileByIdLazy]);

  useEffect(() => {
    if (isEditMode && getFileByIdData) {
      setValue('documentTitle', getFileByIdData.name);
      setValue('documentCategory', getFileByIdData.categoryId);
      setValue(
        'verificationDate',
        getFileByIdData.verificationDate != null
          ? new Date(getFileByIdData.verificationDate)
          : null
      );
    }
    // eslint-disable-next-line
  }, [getFileByIdData, isEditMode]);

  const handleCloseOrCancel = (immediate?: boolean) => {
    if (immediate) {
      resetAllFields();
      return;
    }
    if (getValues('documentTitle')) {
      setConfirmationModalOpen(true);
      setModalOpen(false);
    } else {
      resetAllFields();
    }
  };

  const resetAllFields = () => {
    reset();
    setModalOpen(false);
    setSuccessModalOpen(false);
    setUploadingModalOpen(false);
    setConfirmationModalOpen(false);
    onClose && onClose();
  };

  const postUpload = (data) => {
    const formData = new FormData();

    formData.append('CategoryId', data.documentCategory);
    formData.append('Name', data.documentTitle);
    formData.append('ConnectionId', connectionId);

    formData.append('File', data.selectedFile);
    formData.append('Type', data.selectedFile.type);
    formData.append('Size', data.selectedFile.size.toString());

    if (thirdPartyId) {
      formData.append('ThirdPartyId', thirdPartyId.toString());
    }

    if (verificationDateLabel) {
      formData.append(
        'verificationDate',
        dayjs(data.verificationDate).toISOString()
      );
    }

    if (linkToThirdParty) {
      formData.append('LinkToThirdParty', linkToThirdParty ? 'true' : 'false');
    }

    fileUploadLazy({ body: formData });
  };

  const patchEdit = (data) => {
    fileEditLazy({
      body: JSON.stringify({
        name: data.documentTitle,
        categoryId: data.documentCategory,
        verificationDate: data.verificationDate,
      }),
    });
  };

  const onSubmit = (data) => {
    if (isEditMode) {
      patchEdit(data);
    } else {
      postUpload(data);
    }
  };

  const confirmationDiscard = () => {
    resetAllFields();
  };

  const confirmationCancel = () => {
    setModalOpen(true);
    setConfirmationModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const selectedFile = useWatch({
    control,
    name: 'selectedFile',
  });

  useEffect(() => {
    if (errors.selectedFile?.type === 'fileValidation') {
      resetField('selectedFile', {
        keepError: true,
      });
    }
    if (!errors.selectedFile) {
      setValue('documentTitle', selectedFile?.name.replace(/\.[^/.]+$/, ''));
    }
  }, [selectedFile, resetField, errors.selectedFile, setValue]);

  useEffect(() => {
    if (fileUploadStatus && fileUploadStatus !== 200) {
      handleFailedDocumentUpload(
        fileUploadData?.detail ??
          'Due to an unforeseen technical issue, it has not been possible to upload this document. Please try again. If the problem persists, please contact vantagesupport@controlrisks.com'
      );
    }
    // eslint-disable-next-line
  }, [fileUploadStatus]);

  useEffect(() => {
    if (fileUploadLoading) {
      handleLoadingDocumentUpload();
    }
  }, [fileUploadLoading]);

  return (
    <DocumentUploadModalComponent>
      <Modal
        customOverlayWidth="700px"
        removePadding
        isOpen={modalOpen}
        onClose={() => handleCloseOrCancel(isEditMode)}
        title={isEditMode ? 'Edit document' : 'Upload document'}
        removeBorderTop={false}
      >
        {categoriesLoading || getFileByIdLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <ModalContent>
            <FormControl
              errorMessage={errors?.documentTitle?.message}
              helperText={`The entered “Document name” will rename the file you choose to upload`}
            >
              <FieldInput
                id="documentTitle"
                name="documentTitle"
                label="Document name"
                valid={!errors.documentTitle}
                register={register}
                fullWidth
                required
              />
            </FormControl>
            <FormControlRow>
              <FormControl errorMessage={errors?.documentCategory?.message}>
                <FieldSelect
                  disabled={!!categoryId || isEditMode}
                  ariaLabel="Document Category Dropdown"
                  id="documentCategory"
                  placeholder="Please select a category"
                  placeholderValue="DEFAULT"
                  label="Document category"
                  required
                  register={register}
                  fullWidth
                  valid={!errors.documentCategory}
                  options={categoryOptions}
                />
              </FormControl>
              {verificationDateLabel && (
                <Controller
                  control={control}
                  name="verificationDate"
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => {
                    return (
                      <FormControl
                        errorMessage={
                          errors?.verificationDate?.type !==
                            'invalidTimeframe' &&
                          errors?.verificationDate?.message
                        }
                      >
                        <FieldDate
                          label={verificationDateLabel}
                          value={value}
                          onChange={(date) => {
                            onChange(date ? new Date(date) : null);
                          }}
                          format="DD/MM/YYYY"
                          placeholder="DD/MM/YYYY"
                          fullWidth
                          valid={!invalid}
                          required
                        />
                      </FormControl>
                    );
                  }}
                />
              )}
            </FormControlRow>

            {!isEditMode ? (
              <Controller
                control={control}
                name="selectedFile"
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => {
                  return (
                    <UploadFileDashedContainer
                      supportedFormats={supportedFormats}
                      valid={!invalid}
                      selectedFile={watch('selectedFile')}
                      documentCategory={categoryName}
                      onRemoveDocument={() => resetField('selectedFile')}
                    >
                      <FieldUploadFile
                        value={value}
                        onChange={(file) => {
                          onChange(file);
                          trigger('selectedFile');
                        }}
                        supportedFormats={supportedFormats}
                        inputProps={{
                          'data-testid': 'DocumentUploadModalFileInput',
                        }}
                      />
                    </UploadFileDashedContainer>
                  );
                }}
              />
            ) : (
              <DetailsContainer>
                <DetailsText>{`File size: ${kiloBytesTransform(
                  getFileByIdData?.size
                )}`}</DetailsText>
                <DetailsText>{`Type: ${getFileByIdData?.extension?.toUpperCase()} File`}</DetailsText>
                <DetailsText>{`Created: ${new Date(
                  getFileByIdData?.uploadedDate
                ).toLocaleDateString('en-GB')}`}</DetailsText>
              </DetailsContainer>
            )}

            {!isEditMode && (
              <MultipleErrorsContainer>
                {errors.selectedFile && (
                  <ErrorContainer>
                    <Icon
                      className="risk-icon"
                      icon="risk"
                      size="xxs"
                      colour={theme.colours.redRampage}
                    />
                    <Typography value={errors?.selectedFile?.message} tag="p" />
                  </ErrorContainer>
                )}

                {errors.verificationDate && (
                  <ErrorContainer>
                    <Icon
                      className="risk-icon"
                      icon="risk"
                      size="xxs"
                      colour={theme.colours.redRampage}
                    />
                    <Typography
                      value={errors?.verificationDate?.message}
                      tag="p"
                    />
                  </ErrorContainer>
                )}
              </MultipleErrorsContainer>
            )}

            <ActionsContainer>
              <Button onClick={() => handleCloseOrCancel(isEditMode)} text>
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit(onSubmit)()}
                className="save-button"
                secondary
              >
                Save
              </Button>
            </ActionsContainer>
          </ModalContent>
        )}
      </Modal>
      <UploadingModal
        isOpen={uploadingModalOpen}
        progress={uploadProgress}
        progressMessage={uploadProgressMessage}
      />
      <SuccessModal
        isOpen={successModalOpen}
        thirdPartySingularNaming={terminologyConfig?.thirdPartySingularNaming}
        onClose={() => handleCloseOrCancel(true)}
      />
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onProceed={confirmationDiscard}
        onProceedAriaLabel="Discard file upload changes?"
        onClose={confirmationCancel}
        onCancel={confirmationCancel}
        title="Discard changes?"
        message={
          'Please note that the document has not been uploaded yet. Would you like to discard it?'
        }
        cancelButtonText="Cancel"
        proceedButtonText="Discard"
        loading={false}
      />
    </DocumentUploadModalComponent>
  );
};

export default DocumentUploadModal;
