import { colours } from 'theme/@config';

export interface ToastProps {
  type: ToastType;
  title?: string;
  message?: string;
  accentColor?: string;
  backgroundColor?: string;
  compact?: boolean;
  onClose?: () => void;
}

export enum ToastType {
  Information = 'Information',
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
}

export const defaultProps: Record<ToastType, ToastProps> = {
  [ToastType.Information]: {
    type: ToastType.Information,
    title: 'Information',
    message: 'This is an information toast.',
    accentColor: colours.toastInfoAccent,
    backgroundColor: colours.toastInfoBg,
  },
  [ToastType.Error]: {
    type: ToastType.Error,
    title: 'Error',
    message: 'An error occurred.',
    accentColor: colours.toastErrorAccent,
    backgroundColor: colours.toastErrorBg,
  },
  [ToastType.Warning]: {
    type: ToastType.Warning,
    title: 'Warning',
    message: 'This is a warning toast.',
    accentColor: colours.toastWarnAccent,
    backgroundColor: colours.toastWarnBg,
  },
  [ToastType.Success]: {
    type: ToastType.Success,
    title: 'Success',
    message: 'Operation succeeded.',
    accentColor: colours.toastSuccessAccent,
    backgroundColor: colours.toastSuccessBg,
  },
};
