import styled from 'styled-components';

export const CardComponent = styled.article<{
  compact?: boolean;
}>`
  min-width: ${({ compact = false }) => (compact ? 250 : 300)}px;
  margin-bottom: ${({ theme }) => theme.pxToRem(15)};
  padding: ${({ theme }) => theme.spacing(8)};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.pxToRem(5)};

  header {
    display: flex;
    align-items: first baseline;
    margin-bottom: ${({ theme }) => theme.spacing(10)};

    > * {
      margin-left: ${({ theme }) => theme.spacing(5)};
    }

    h3 {
      color: ${({ theme }) => theme.colours.charcoalDust};
      margin-right: auto;
      width: ${({ compact = false }) => (compact ? 100 : 168)}px;
    }

    svg {
      transform: translateY(${({ theme }) => theme.spacing(5)});
    }
  }

  p {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    letter-spacing: 0;
    line-height: 1.5;
  }
`;

export const ExpandButton = styled.button<{
  spaceAfter?: boolean;
}>`
  cursor: pointer;
  padding: 0 0
    ${({ spaceAfter = false, theme }) => (spaceAfter ? theme.pxToRem(30) : 0)} 0;
  margin-top: ${({ theme }) => theme.pxToRem(10)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.5;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colours.swimmersPool};
  text-underline-position: under;
`;
