import styled from 'styled-components';

export const TimelineCarouselComponent = styled.section``;

export const TimelineCarouselSwiper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: ${({ theme }) => theme.spacing(60)};
    left: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colours.white};
    content: '';
  }

  .swiper-container {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
  }

  .swiper-slide {
    height: auto;
  }
`;

export const TimelineCarouselFilters = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > * + * {
    margin-left: 10px;
  }
`;
