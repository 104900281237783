import styled from 'styled-components';

interface TabContentProps {
  label: string;
}

export const ButtonWrapper = styled.div<{
  smallMargin?: boolean;
}>`
  display: flex;
  justify-content: right;
  margin-top: ${({ theme, smallMargin }) =>
    smallMargin ? theme.pxToRem(30) : theme.pxToRem(40)};
  margin-bottom: ${({ theme, smallMargin }) =>
    smallMargin ? theme.pxToRem(0) : theme.pxToRem(20)};

  button:nth-child(1) {
    margin-right: ${({ theme }) => theme.pxToRem(30)};
  }

  button:nth-child(2) {
    width: ${({ theme }) => theme.pxToRem(180)};
    margin-right: ${({ theme }) => theme.pxToRem(200)};
  }
`;

export const TabContent = styled.div<TabContentProps>`
  color: ${({ theme }) => theme.colours.crowberryBlue};
`;

export const DivisoryLine = styled.hr`
  border: 1px solid ${({ theme }) => theme.colours.gallery};
  margin-bottom: ${({ theme }) => theme.pxToRem(25)};
`;

export const Section = styled.div`
  display: flex;
`;

export const Description = styled.div`
  flex: 1;
  margin-left: ${({ theme }) => theme.pxToRem(30)};
`;

export const FieldsContainer = styled.div`
  flex: 1;
  margin-left: ${({ theme }) => theme.pxToRem(20)};
`;

export const RadioContainer = styled.div`
  flex: 1;
`;

export const FieldGroup = styled.div`
  display: flex;
  align-items: start;
  width: 70%;

  .formcontrol {
    flex: 2 0 auto;
  }

  .iconButton {
    flex 0 0 60px;
  }
`;

export const IconButtonSpacer = styled.div`
  flex: 0 0 60px;
`;

export const IconButton = styled.button.attrs({
  type: 'button',
  className: 'iconButton',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.pxToRem(10)};
  svg {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }
  svg:hover {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  color: ${({ theme }) => theme.colours.black};
  padding: ${({ theme }) => theme.spacing(10)};
  margin-top: ${({ theme }) => theme.spacing(40)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.gallery};
  border-top: 1px solid ${({ theme }) => theme.colours.gallery};
`;
