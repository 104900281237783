import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  FormControl,
  PageTitle,
  FieldInput as Input,
  Button,
  Icon,
  FieldRadio,
  FieldToggle,
  Loading,
} from 'components/atoms';
import { Modal, Tabs } from 'components/molecules';
import { AppLayout } from 'layouts';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  ButtonWrapper,
  Description,
  DivisoryLine,
  FieldGroup,
  FieldsContainer,
  IconButton,
  IconButtonSpacer,
  ModalContent,
  RadioContainer,
  Section,
  TabContent,
} from './Settings.styles';
import * as yup from 'yup';
import { colours } from 'theme/@config';
import { useFetch } from 'hooks';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useAuth } from 'auth';
import { GatewayPermissions } from 'auth/constants/permissions';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

function Settings() {
  const [selected, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const [hasSecondRecipientField, setHasSecondRecipientField] =
    useState<boolean>(false);

  const { vantageWebApi } = useConfig();
  const terminologyConfig = useTerminologyConfig();
  const { accessToken, hasPermission } = useAuth();

  const yupSchema = yup.object().shape({
    mainNotificationEmailAddress: yup
      .string()
      .required('This field is required')
      .email('Email address not valid'),
    additionalNotificationEmailAddress: yup
      .string()
      .nullable()
      .when('mainNotificationEmailAddress', (mainEmail) => {
        if (hasSecondRecipientField) {
          return yup
            .string()
            .required('This field is required')
            .email('Email address not valid')
            .test(
              'duplicated',
              'The same email address has been added twice',
              (value) => {
                return value !== mainEmail;
              }
            );
        }
      }),
    outstandingActionsSummaryFrequency: yup
      .string()
      .required('This field is required')
      .nullable(),
    isThirdPartyInternalOwnerAssignedEnabled: yup
      .boolean()
      .required('This field is required'),
    isThirdPartyInternalOwnerRemovedEnabled: yup
      .boolean()
      .required('This field is required'),
    isManagedScreeningReportsWithNoInternalOwnerEnabled: yup
      .boolean()
      .required('This field is required'),
    isDueDiligenceReportsWithNoInternalOwnerEnabled: yup
      .boolean()
      .required('This field is required'),
  });

  const {
    data: getEmailPreferencesData,
    loading: getEmailPreferencesloading,
    error: getEmailPreferencesError,
  } = useFetch(`${vantageWebApi}/preferences/email`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const {
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    control,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
    defaultValues: getEmailPreferencesData,
  });

  const isManagedScreeningConfirmedMatchesEnabled = useWatch({
    control,
    name: 'isManagedScreeningConfirmedMatchesEnabled',
  });
  const isManagedScreeningPossibleMatchesEnabled = useWatch({
    control,
    name: 'isManagedScreeningPossibleMatchesEnabled',
  });
  const isManagedScreeningAllMatchesDiscardedEnabled = useWatch({
    control,
    name: 'isManagedScreeningAllMatchesDiscardedEnabled',
  });
  const isManagedScreeningNoMatchesFoundEnabled = useWatch({
    control,
    name: 'isManagedScreeningNoMatchesFoundEnabled',
  });
  const isDueDiligenceReportReadyForReviewEnabled = useWatch({
    control,
    name: 'isDueDiligenceReportReadyForReviewEnabled',
  });
  const isThirdPartyStatusUpdateEnabled = useWatch({
    control,
    name: 'isThirdPartyStatusUpdateEnabled',
  });

  const hasAdminPermission = hasPermission(GatewayPermissions.Admin);
  const hasInitiateDueDiligenceManagedServiceScreening = hasPermission(
    GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
  );
  const hasViewAndOrderManagedDueDiligence =
    hasPermission(GatewayPermissions.OrderManagedDueDiligence) &&
    hasPermission(GatewayPermissions.ViewDueDiligenceReport);
  const hasViewTaskListPermission = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  useEffect(() => {
    reset(getEmailPreferencesData);
    if (getEmailPreferencesData?.additionalNotificationEmailAddress) {
      setHasSecondRecipientField(true);
    }
  }, [reset, getEmailPreferencesData]);

  useEffect(() => {
    if (
      !isManagedScreeningConfirmedMatchesEnabled &&
      !isManagedScreeningPossibleMatchesEnabled &&
      !isManagedScreeningAllMatchesDiscardedEnabled &&
      !isManagedScreeningNoMatchesFoundEnabled
    ) {
      setValue('isManagedScreeningReportsWithNoInternalOwnerEnabled', false);
    }
  }, [
    isManagedScreeningConfirmedMatchesEnabled,
    isManagedScreeningPossibleMatchesEnabled,
    isManagedScreeningAllMatchesDiscardedEnabled,
    isManagedScreeningNoMatchesFoundEnabled,
    setValue,
  ]);

  useEffect(() => {
    if (!isDueDiligenceReportReadyForReviewEnabled) {
      setValue('isDueDiligenceReportsWithNoInternalOwnerEnabled', false);
    }
  }, [isDueDiligenceReportReadyForReviewEnabled, setValue]);

  useEffect(() => {
    if (!isThirdPartyStatusUpdateEnabled) {
      setValue('isThirdPartyStatusUpdateWithNoInternalOwnerEnabled', false);
    }
  }, [isThirdPartyStatusUpdateEnabled, setValue]);

  const {
    data: postEmailPreferencesData,
    loading: postEmailPreferencesloading,
    error: postEmailPreferencesError,
    trigger: fetchLazy,
  } = useFetch(`${vantageWebApi}/preferences/email`, {
    method: 'POST',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const onSubmit = () => {
    fetchLazy({
      body: JSON.stringify({
        ...getValues(),
        additionalNotificationEmailAddress: hasSecondRecipientField
          ? getValues('additionalNotificationEmailAddress')
          : null,
      }),
    });
  };

  useEffect(() => {
    if (postEmailPreferencesError || postEmailPreferencesData?.status) {
      setIsOpen(true);
    }
    if (postEmailPreferencesData?.id) {
      history.push(`/third-party`);
    }
  }, [history, postEmailPreferencesData, postEmailPreferencesError]);

  return (
    <AppLayout bgColor="whiteSolid">
      <PageTitle title="Settings"></PageTitle>
      {getEmailPreferencesloading ? (
        <Loading />
      ) : getEmailPreferencesError ? (
        <h3>
          Error getting your preferences. If the problem persists please contact
          vantagesupport@controlrisks.com
        </h3>
      ) : (
        <Tabs setSelected={setSelected} selected={selected}>
          <TabContent label="Email Preferences">
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
              <ModalContent>
                There was a problem saving the changes. Please try again. If the
                problem persists please contact vantagesupport@controlrisks.com
              </ModalContent>
              <ButtonWrapper smallMargin>
                <Button secondary onClick={() => setIsOpen(false)}>
                  Close
                </Button>
              </ButtonWrapper>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion
                borders={false}
                title="Email Recipients"
                isOpen={true}
                completed={false}
                showCaret={false}
                accordionTitleProps={{
                  background: theme.colours.agedGray + '33', // add alpha channel
                  padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                  borderRadius: theme.pxToRem(4),
                }}
                caretBackground={false}
              >
                <Section>
                  <Description>
                    I would like Vantage Gateway email notifications to be sent
                    to the following recipients:
                  </Description>
                  <FieldsContainer>
                    <FieldGroup>
                      <FormControl
                        errorMessage={
                          errors?.mainNotificationEmailAddress?.message
                        }
                      >
                        <Input
                          id="mainNotificationEmailAddress"
                          name="mainNotificationEmailAddress"
                          register={register}
                          valid={
                            !errors?.mainNotificationEmailAddress?.message &&
                            touchedFields?.mainNotificationEmailAddress
                          }
                          placeholder="Email recipient"
                          fullWidth
                        />
                      </FormControl>
                      {!hasSecondRecipientField ? (
                        <IconButton
                          onClick={() => {
                            setValue('additionalNotificationEmailAddress', '');
                            setHasSecondRecipientField(true);
                          }}
                        >
                          <Icon
                            icon="plus"
                            colour={colours.crowberryBlue}
                            data-testid="addField"
                          />
                        </IconButton>
                      ) : (
                        <IconButtonSpacer></IconButtonSpacer>
                      )}
                    </FieldGroup>
                    <FieldGroup>
                      {hasSecondRecipientField && (
                        <>
                          <FormControl
                            errorMessage={
                              errors?.additionalNotificationEmailAddress
                                ?.message
                            }
                          >
                            <Input
                              id="additionalNotificationEmailAddress"
                              name="additionalNotificationEmailAddress"
                              register={register}
                              valid={
                                !errors?.additionalNotificationEmailAddress
                                  ?.message &&
                                touchedFields?.additionalNotificationEmailAddress
                              }
                              placeholder="Email recipient"
                              fullWidth
                            />
                          </FormControl>
                          <IconButton
                            onClick={() => setHasSecondRecipientField(false)}
                          >
                            <Icon
                              icon="minus"
                              colour={colours.crowberryBlue}
                              data-testid="removeField"
                            />
                          </IconButton>
                        </>
                      )}
                    </FieldGroup>
                  </FieldsContainer>
                </Section>
              </Accordion>
              <Accordion
                borders={false}
                title="Outstanding actions summary"
                isOpen={true}
                completed={false}
                showCaret={false}
                accordionTitleProps={{
                  background: theme.colours.agedGray + '33', // add alpha channel
                  padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                  borderRadius: theme.pxToRem(4),
                }}
                caretBackground={false}
              >
                <Section>
                  <Description>
                    <p>
                      I would like to receive email notifications about
                      outstanding actions*:
                    </p>
                    <br />
                    <p>
                      {`*Outstanding actions can include incomplete initial
                      screenings, new screening monitoring alerts, managed
                      screening or due diligence reports which are ready for
                      your review and ${terminologyConfig?.thirdPartyPluralNaming} that have their next review
                      date.`}
                    </p>
                  </Description>
                  <RadioContainer>
                    <div style={{ width: '11rem' }}>
                      <FormControl
                        errorMessage={
                          errors?.outstandingActionsSummaryFrequency?.message
                        }
                      >
                        <FieldRadio
                          label="Daily"
                          id="outstandingActionDaily"
                          value="Daily"
                          name="outstandingActionsSummaryFrequency"
                          disabled={!hasViewTaskListPermission}
                          showBorder={false}
                          labelRight={true}
                          leftAlign={true}
                          register={register}
                        />
                        <FieldRadio
                          label="Weekly"
                          id="outstandingActionWeekly"
                          value="Weekly"
                          name="outstandingActionsSummaryFrequency"
                          disabled={!hasViewTaskListPermission}
                          showBorder={false}
                          labelRight={true}
                          leftAlign={true}
                          register={register}
                        />
                        <FieldRadio
                          label="Never"
                          id="outstandingActioNever"
                          value="Never"
                          name="outstandingActionsSummaryFrequency"
                          disabled={!hasViewTaskListPermission}
                          showBorder={false}
                          labelRight={true}
                          leftAlign={true}
                          register={register}
                        />
                      </FormControl>
                    </div>
                  </RadioContainer>
                </Section>
              </Accordion>
              <Accordion
                borders={false}
                title={`${
                  terminologyConfig?.thirdPartySingularNaming
                } ${terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}`}
                isOpen={true}
                completed={false}
                showCaret={false}
                accordionTitleProps={{
                  background: theme.colours.agedGray + '33', // add alpha channel
                  padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                  borderRadius: theme.pxToRem(4),
                }}
                caretBackground={false}
              >
                <Section>
                  <Description>
                    <p>
                      I would like to receive email notifications when I am
                      assigned or removed as an&nbsp;
                      {terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}
                      &nbsp; of a&nbsp;
                      {terminologyConfig?.thirdPartySingularNaming}
                    </p>
                  </Description>
                  <FieldsContainer>
                    <div style={{ width: '5rem' }}>
                      <FormControl
                        errorMessage={
                          errors?.isThirdPartyInternalOwnerAssignedEnabled
                            ?.message
                        }
                      >
                        <FieldToggle
                          label="Assigned"
                          id="isThirdPartyInternalOwnerAssignedEnabled"
                          name="isThirdPartyInternalOwnerAssignedEnabled"
                          register={register}
                        />
                      </FormControl>
                      <FormControl
                        errorMessage={
                          errors?.isThirdPartyInternalOwnerRemovedEnabled
                            ?.message
                        }
                      >
                        <FieldToggle
                          label="Removed"
                          id="isThirdPartyInternalOwnerRemovedEnabled"
                          name="isThirdPartyInternalOwnerRemovedEnabled"
                          register={register}
                        />
                      </FormControl>
                    </div>
                  </FieldsContainer>
                </Section>
              </Accordion>
              <Accordion
                borders={false}
                title={`${terminologyConfig?.thirdPartySingularNaming} status update`}
                isOpen={true}
                completed={false}
                showCaret={false}
                accordionTitleProps={{
                  background: theme.colours.agedGray + '33', // add alpha channel
                  padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                  borderRadius: theme.pxToRem(4),
                }}
                caretBackground={false}
              >
                <div>
                  <Section>
                    <Description>
                      <p>
                        I would like to receive an email notification when the
                        status of a&nbsp;
                        {terminologyConfig?.thirdPartySingularNaming}, of which
                        I am the&nbsp;
                        {terminologyConfig?.internalOwnerSingularNaming},
                        changes.
                      </p>
                    </Description>
                    <FieldsContainer>
                      <div style={{ width: '5rem' }}>
                        <FormControl>
                          <FieldToggle
                            data-testid="SendEmailForThirdPartyStatusUpdateToggle"
                            id="isThirdPartyStatusUpdateEnabled"
                            name="isThirdPartyStatusUpdateEnabled"
                            register={register}
                          />
                        </FormControl>
                      </div>
                    </FieldsContainer>
                  </Section>
                  <DivisoryLine />
                  {hasAdminPermission && (
                    <Section>
                      <Description>
                        <p>
                          I would like to receive an email notification when the
                          status of a&nbsp;
                          {terminologyConfig?.thirdPartySingularNaming}, which
                          does not have an&nbsp;
                          {terminologyConfig?.internalOwnerSingularNaming},
                          changes
                        </p>
                      </Description>
                      <FieldsContainer>
                        <div style={{ width: '5rem' }}>
                          <FormControl
                            errorMessage={
                              errors
                                ?.isThirdPartyStatusUpdateWithNoInternalOwnerEnabled
                                ?.message
                            }
                          >
                            <FieldToggle
                              data-testid="SendEmailForThirdPartyStatusUpdateWithNoInternalOwnerToggle"
                              id="isThirdPartyStatusUpdateWithNoInternalOwnerEnabled"
                              name="isThirdPartyStatusUpdateWithNoInternalOwnerEnabled"
                              register={register}
                              disabled={!isThirdPartyStatusUpdateEnabled}
                            />
                          </FormControl>
                        </div>
                      </FieldsContainer>
                    </Section>
                  )}
                </div>
              </Accordion>

              {hasInitiateDueDiligenceManagedServiceScreening && (
                <Accordion
                  borders={false}
                  title="Managed screening report notifications"
                  isOpen={true}
                  completed={false}
                  showCaret={false}
                  accordionTitleProps={{
                    background: theme.colours.agedGray + '33', // add alpha channel
                    padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                    borderRadius: theme.pxToRem(4),
                  }}
                  caretBackground={false}
                >
                  <div>
                    <Section>
                      <Description>
                        <p>
                          I would like to receive email notifications when a
                          completed managed screening report is ready for review
                          and contains:
                        </p>
                      </Description>
                      <FieldsContainer>
                        <div style={{ width: '15rem' }}>
                          <FormControl
                            errorMessage={
                              errors?.managedScreeningNotification?.message
                            }
                          >
                            <FieldToggle
                              label="Confirmed matches"
                              id="isManagedScreeningConfirmedMatchesEnabled"
                              name="isManagedScreeningConfirmedMatchesEnabled"
                              register={register}
                            />
                            <FieldToggle
                              label="Possible matches"
                              id="isManagedScreeningPossibleMatchesEnabled"
                              name="isManagedScreeningPossibleMatchesEnabled"
                              register={register}
                            />
                            <FieldToggle
                              label="All matches discarded"
                              id="isManagedScreeningAllMatchesDiscardedEnabled"
                              name="isManagedScreeningAllMatchesDiscardedEnabled"
                              register={register}
                            />
                            <FieldToggle
                              label="No matches found"
                              id="isManagedScreeningNoMatchesFoundEnabled"
                              name="isManagedScreeningNoMatchesFoundEnabled"
                              register={register}
                            />
                          </FormControl>
                        </div>
                      </FieldsContainer>
                    </Section>
                    <DivisoryLine />
                    <Section>
                      <Description>
                        <p>
                          I would also like to receive email notifications on
                          managed screening reports linked to&nbsp;
                          {terminologyConfig?.thirdPartyPluralNaming} that do
                          not have an assigned&nbsp;
                          {terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}
                        </p>
                      </Description>
                      <FieldsContainer>
                        <div style={{ width: '5rem' }}>
                          <FormControl
                            errorMessage={
                              errors
                                ?.isManagedScreeningReportsWithNoInternalOwnerEnabled
                                ?.message
                            }
                          >
                            <FieldToggle
                              data-testid="SendEmailForManagedScreeningReportsWithNoInternalOwnerToggle"
                              id="isManagedScreeningReportsWithNoInternalOwnerEnabled"
                              name="isManagedScreeningReportsWithNoInternalOwnerEnabled"
                              register={register}
                              disabled={
                                !isManagedScreeningConfirmedMatchesEnabled &&
                                !isManagedScreeningPossibleMatchesEnabled &&
                                !isManagedScreeningAllMatchesDiscardedEnabled &&
                                !isManagedScreeningNoMatchesFoundEnabled
                              }
                            />
                          </FormControl>
                        </div>
                      </FieldsContainer>
                    </Section>
                  </div>
                </Accordion>
              )}
              {hasViewAndOrderManagedDueDiligence && (
                <Accordion
                  borders={false}
                  title="Due diligence report notifications"
                  isOpen={true}
                  completed={false}
                  showCaret={false}
                  accordionTitleProps={{
                    background: theme.colours.agedGray + '33', // add alpha channel
                    padding: `${theme.pxToRem(5)} ${theme.pxToRem(30)}`,
                    borderRadius: theme.pxToRem(4),
                  }}
                  caretBackground={false}
                >
                  <div>
                    <Section>
                      <Description>
                        <p>
                          I would like to receive email notifications when a Due
                          Diligence report is ready for review
                        </p>
                      </Description>
                      <FieldsContainer>
                        <div style={{ width: '5rem' }}>
                          <FormControl>
                            <FieldToggle
                              data-testid="SendEmailForDueDiligenceReportReadyForReviewToggle"
                              id="isDueDiligenceReportReadyForReviewEnabled"
                              name="isDueDiligenceReportReadyForReviewEnabled"
                              register={register}
                            />
                          </FormControl>
                        </div>
                      </FieldsContainer>
                    </Section>
                    <DivisoryLine />
                    <Section>
                      <Description>
                        <p>
                          I would also like to receive email notifications on
                          due diligence reports linked to&nbsp;
                          {terminologyConfig?.thirdPartyPluralNaming} that do
                          not have an assigned&nbsp;
                          {terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}
                        </p>
                      </Description>
                      <FieldsContainer>
                        <div style={{ width: '5rem' }}>
                          <FormControl
                            errorMessage={
                              errors
                                ?.isDueDiligenceReportsWithNoInternalOwnerEnabled
                                ?.message
                            }
                          >
                            <FieldToggle
                              data-testid="SendEmailForDueDiligenceReportsWithNoInternalOwnerToggle"
                              id="isDueDiligenceReportsWithNoInternalOwnerEnabled"
                              name="isDueDiligenceReportsWithNoInternalOwnerEnabled"
                              register={register}
                              disabled={
                                !isDueDiligenceReportReadyForReviewEnabled
                              }
                            />
                          </FormControl>
                        </div>
                      </FieldsContainer>
                    </Section>
                  </div>
                </Accordion>
              )}

              <ButtonWrapper>
                <Button
                  text
                  type="button"
                  onClick={() => history.push(`/third-party/`)}
                  disabled={
                    getEmailPreferencesloading || postEmailPreferencesloading
                  }
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  aria-label="saveButton"
                  secondary
                  disabled={
                    getEmailPreferencesloading || postEmailPreferencesloading
                  }
                >
                  Save
                </Button>
              </ButtonWrapper>
            </form>
          </TabContent>
        </Tabs>
      )}
    </AppLayout>
  );
}

export default withAITracking(reactPlugin, Settings);
