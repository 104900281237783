import { appInsights } from 'services/applicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const originalFetch = window.fetch;

window.fetch = async (url, options) => {
  try {
    const response = await originalFetch(url, options);
    return response;
  } catch (error) {
    appInsights.trackException(
      {
        exception: error,
        severityLevel: SeverityLevel.Error,
      },
      {
        method: options.method,
        url: url,
        body: options.body || null,
      }
    );
    throw new Error(error);
  }
};
