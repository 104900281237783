import { Icon } from 'components/atoms';
import React, { FC } from 'react';
import { PanelProps } from './types';
import { PanelComponent, PanelLink } from './Panel.styles';

const Panel: FC<PanelProps> = ({ children, cta, ctaLabel, icon }) => (
  <PanelComponent>
    {cta && icon && (
      <PanelLink to={cta} aria-label={ctaLabel}>
        <Icon icon={icon} aria-hidden size="xxs" />
      </PanelLink>
    )}
    {children}
  </PanelComponent>
);

export default Panel;
