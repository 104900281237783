import React from 'react';

const RiskMessage = (
  riskText: string,
  lowText: string,
  highText: string
): any => (
  <>
    <p aria-label="Risk description">{riskText}</p>
    <br></br>
    <br></br>
    <p>Rating</p>
    <br></br>
    <p>{lowText}</p>
    <br></br>
    <br></br>
    <p>{highText}</p>
  </>
);

export const ScreeningRiskSummary = {
  WatchList: RiskMessage(
    'Sanctions are legal decrees issued on a national or international level that impose restrictive measures to advance certain political or national security objectives.',
    'Low: The most recent screening identified no direct sanctions exposure for the subject.',
    'High: The most recent screening identified one or more “confirmed” or “possible” matches indicating the subject is directly sanctioned or has a known connection to a sanctioned entity.'
  ),
  Enforcement: RiskMessage(
    'Enforcement information originates from official government agencies, industry regulators and disciplinary boards for events that meet the qualifying standards and definitions.',
    'Low: The most recent screening identified no known enforcement actions concerning the subject.',
    'High: The most recent screening identified one or more “confirmed” or “possible” matches indicating a government has taken enforcement action(s) against the subject.'
  ),
  AdverseMedia: RiskMessage(
    'Adverse media information originates from credible open news sources that meet the qualifying standards and definitions.',
    'Low: The most recent screening identified no known adverse media coverage for the subject.',
    'High: The most recent screening identified one or more “confirmed” or “possible” matches indicating the subject has adverse media coverage.'
  ),
  PoliticalExposureIndividual: RiskMessage(
    'The political exposure category identifies individuals who act in a senior prominent public function or are family members or associates of an individual in a senior prominent public function.',
    'Low: No known political connection was identified as part of the most recent screening undertaken on this entity.',
    'High: A profiled identified as either “confirmed” or “possible” in the most recent screening undertaken on this entity has a known political connection.'
  ),
  PoliticalExposureBusiness: RiskMessage(
    'The political exposure category identifies companies with a connection to Government funding.',
    'Low: No known political connection was identified as part of the most recent screening undertaken on this entity.',
    'High: A profiled identified as either “confirmed” or “possible” in the most recent screening undertaken on this entity has a known political connection.'
  ),
};
