import { Component } from 'react';
import { appInsights } from 'services/applicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    appInsights.trackException(
      {
        exception: error,
        severityLevel: SeverityLevel.Error,
      },
      {
        componentStack: info,
      }
    );
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
