import React, { FC } from 'react';
import { TooltipIconTypes } from './types';
import { Tooltip, Icon } from 'components/atoms';

const TooltipIcon: FC<TooltipIconTypes> = ({ tooltipText, ...iconProps }) => (
  <Tooltip tooltipText={tooltipText} placement="bottom" trigger="click">
    <Icon {...iconProps} />
  </Tooltip>
);

export default TooltipIcon;
