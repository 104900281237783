import React, { FC, useRef, useState } from 'react';

import { Button, Link, Date } from 'components/atoms';
import { Modal } from 'components/molecules';
import {
  ButtonContainer,
  ModalContent,
  ScrollableContainer,
  StyledTable,
} from './PotentialDuplicateModal.styles';

import { DuplicatedScreening, PotentialDuplicateModalTypes } from './types';
import PotentialDuplicateDisableTooltip from './PotentialDuplicateDisableTooltip';

const ReportLinkCell: FC<{ canViewReport: boolean; link: string }> = ({
  canViewReport,
  link,
}) => {
  const disabledAnchorRef = useRef<HTMLAnchorElement>(null);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  return (
    <>
      <Link
        ref={disabledAnchorRef}
        href={canViewReport ? link : undefined}
        className={!canViewReport && 'disabled'}
        target="_blank"
        onMouseEnter={() => !canViewReport && setTooltipVisible(true)}
        onMouseLeave={() => !canViewReport && setTooltipVisible(false)}
        style={{ textAlign: 'center' }}
      >
        <PotentialDuplicateDisableTooltip
          portalContainer={disabledAnchorRef?.current ?? document.body}
          visible={tooltipVisible}
        />
        Open report
      </Link>
    </>
  );
};

const DuplicatedScreeningsTable: FC<{
  duplicatedScreenings: DuplicatedScreening[];
  canViewReport: boolean;
}> = ({ duplicatedScreenings, canViewReport }) => {
  return (
    <ScrollableContainer>
      <StyledTable>
        <thead>
          <tr>
            <th className="id">Report ID</th>
            <th className="date">Requested date</th>
            <th>Report Status</th>
            <th>Requester Name</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {duplicatedScreenings.map(
            ({ id, createdDate, reportStatus, userFullName }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>
                  <Date dateTime={createdDate} format="Do MMM, YYYY, HH:mm" />
                </td>
                <td>{reportStatus}</td>
                <td>{userFullName}</td>
                <td>
                  <ReportLinkCell
                    canViewReport={canViewReport}
                    link={`/third-party/screening/${id}`}
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </StyledTable>
    </ScrollableContainer>
  );
};

const PotentialDuplicateModal: FC<PotentialDuplicateModalTypes> = ({
  isOpen,
  clientScreenings = [],
  companyScreenings = [],
  canViewReport,
  onClose,
  onCancel,
  onProceed,
}: PotentialDuplicateModalTypes) => {
  return (
    <Modal
      removePadding
      onClose={() => onClose && onClose()}
      isOpen={isOpen}
      scrollableBody={false}
      title="Potential duplicate(s) detected"
    >
      <ModalContent>
        <>
          {companyScreenings.length > 0 && (
            <>
              <p className="subtitle">
                Potential duplicate reports requested by users from your company
              </p>
              <DuplicatedScreeningsTable
                duplicatedScreenings={companyScreenings}
                canViewReport={canViewReport}
              />
            </>
          )}
          {clientScreenings.length > 0 && (
            <>
              <p className="subtitle">
                General potential duplicate reports under the client account
              </p>

              <DuplicatedScreeningsTable
                duplicatedScreenings={clientScreenings}
                canViewReport={canViewReport}
              />
            </>
          )}

          <ButtonContainer>
            <Button
              outline
              className="button"
              title="proceed"
              onClick={() => onProceed && onProceed()}
              aria-label="Proceed"
            >
              Proceed with order
            </Button>
            <Button
              secondary
              className="button"
              title="cancel"
              onClick={() => onCancel && onCancel()}
              aria-label="Cancel"
            >
              Cancel current order
            </Button>
          </ButtonContainer>
        </>
      </ModalContent>
    </Modal>
  );
};

export default PotentialDuplicateModal;
