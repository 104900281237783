import React, { Children, FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { MessageCarouselProps } from './types';
import { CarouselNavigation } from 'components/molecules';
import { Typography } from 'components/atoms';
import 'swiper/swiper-bundle.css';
import {
  MessageCarouselComponent,
  MessageCarouselHeader,
  MessageCarouselIndicator,
  MessageCarouselSlider,
} from './MessageCarousel.styles';

SwiperCore.use([Navigation, Pagination]);

const MessageCarousel: FC<MessageCarouselProps> = ({ children, uniqueId }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const numberOfSlides = Children.count(children);
  return (
    <MessageCarouselComponent>
      <MessageCarouselHeader>
        <div>
          <MessageCarouselIndicator>{numberOfSlides}</MessageCarouselIndicator>
          <Typography
            tag="h2"
            value={`You have ${numberOfSlides} outstanding ${
              numberOfSlides === 1 ? 'action' : 'actions'
            }`}
          />
        </div>
        {numberOfSlides > 1 && (
          <CarouselNavigation
            numberOfSlides={numberOfSlides}
            activeIndex={activeIndex}
            uniqueId={uniqueId}
            swiperInstance={swiperInstance}
          />
        )}
      </MessageCarouselHeader>
      <MessageCarouselSlider>
        <Swiper
          navigation={{
            nextEl: `.custom-swiper-navigation-next-${uniqueId}`,
            prevEl: `.custom-swiper-navigation-prev-${uniqueId}`,
          }}
          loop={true}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onRealIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
        >
          {React.Children.map(children, (message) => (
            <SwiperSlide>{message}</SwiperSlide>
          ))}
        </Swiper>
      </MessageCarouselSlider>
    </MessageCarouselComponent>
  );
};

export default MessageCarousel;
