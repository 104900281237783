import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormControl, FieldCheckbox, Icon } from 'components/atoms';
import Select from 'components/atoms/field-select';

import { GatewayPermissions } from 'auth/constants/permissions';
import { GatewayReportTypeNames } from 'auth/constants/reportsTypes';

import {
  ChildContainer,
  DownloadText,
  ErrorMessage,
  FixedWidthButton,
} from '../ThirdParty.styles';

const DownloadSummary = ({
  thirdpartySearchTerm,
  filters,
  sort,
  showAllThirdparties,
  onCancel,
  isLoading,
  requestError,
  isDownloadEnabled,
  onDownloadSumaryRequest,
}) => {
  const { hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  const hasInitiateMSSorSSS: boolean =
    hasPermission(
      GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
    ) ||
    hasPermission(GatewayPermissions.InitiateDueDiligenceSelfServiceScreening);

  const hasOrderMDD: boolean = hasPermission(
    GatewayPermissions.ViewDueDiligenceReport
  );

  const downloadSummarySchema = yup.object().shape({
    reportType: yup
      .mixed()
      .test('reportType', 'Please select a report type', (value) => {
        return value !== '';
      }),
  });
  const downloadSummaryForm = useForm({
    mode: 'all',
    resolver: yupResolver(downloadSummarySchema),
    defaultValues: {
      reportType: null,
      filteredView: false,
      includeRelatedEntities: false,
    },
  });

  const {
    handleSubmit: handleSubmitDownloadSummary,
    getValues: getValuesDownloadSummary,
    watch: watchDownloadSummary,
    register: registerDownloadSummary,
    formState: {
      errors: errorsDownloadSummary,
      touchedFields: touchedFieldsDownloadSummary,
      isValid: isValidReportType,
    },
  } = downloadSummaryForm;

  const handleConfirmationModalOpen = () => {
    const formData = getValuesDownloadSummary();
    const reportSelectedOption = reportTypeOptions[formData.reportType];

    if (reportSelectedOption) {
      let reportTypeEndpoint = '';
      switch (reportSelectedOption) {
        case GatewayReportTypeNames.ThirdPartySummaryReport:
          reportTypeEndpoint = 'thirdparty/summary-report';
          break;
        case GatewayReportTypeNames.ScreeningSummaryReport:
          reportTypeEndpoint = 'onlinescreening/screening-summary-report';
          break;
        case GatewayReportTypeNames.DueDiligenceSummaryReport:
          reportTypeEndpoint = 'onlinescreening/duediligence-summary-report';
          break;
        default:
          onCancel();
          return;
      }

      const submissionData = {
        ...(formData.filteredView &&
          thirdpartySearchTerm && { Query: thirdpartySearchTerm }),
        ...(formData.filteredView && { filter: filters }),
        includeRelatedEntities: formData.includeRelatedEntities,
        sort: sort,
        ShowAll: showAllThirdparties,
        reportTypeEndpoint,
      };

      onDownloadSumaryRequest(submissionData);
    } else {
      onCancel();
    }
  };

  const reportTypeOptions = useMemo(() => {
    const options = [
      GatewayReportTypeNames.ThirdPartySummaryReport,
      ...(hasInitiateMSSorSSS
        ? [GatewayReportTypeNames.ScreeningSummaryReport]
        : []),
      ...(hasOrderMDD
        ? [GatewayReportTypeNames.DueDiligenceSummaryReport]
        : []),
    ];
    return options.sort((a, b) => a.localeCompare(b));
  }, [hasInitiateMSSorSSS, hasOrderMDD]);

  let reportTypeWatch = watchDownloadSummary
    ? reportTypeOptions[watchDownloadSummary('reportType')]
    : null;

  const reportTypeOptionsMap = (options) => {
    return options.map((value, index) => ({
      value: index,
      name:
        value === GatewayReportTypeNames.ThirdPartySummaryReport
          ? `${terminologyConfig?.thirdPartySingularNaming} Summary Report`
          : value,
    }));
  };

  const mappedReportTypeOptions = reportTypeOptionsMap(reportTypeOptions);

  useEffect(() => {
    if (
      thirdpartySearchTerm ||
      filters?.riskRating?.length > 0 ||
      filters?.thirdPartyCountryCodes?.length > 0 ||
      filters?.thirdPartyDueDiligenceTypes?.length > 0 ||
      filters?.thirdPartyTypes?.length > 0
    ) {
      setIsFiltersApplied(true);
    } else {
      setIsFiltersApplied(false);
    }
  }, [
    thirdpartySearchTerm,
    filters?.riskRating,
    filters?.thirdPartyCountryCodes,
    filters?.thirdPartyDueDiligenceTypes,
    filters?.thirdPartyTypes,
  ]);

  return (
    <>
      <form onSubmit={handleSubmitDownloadSummary(handleConfirmationModalOpen)}>
        <FormControl
          errorMessage={
            !isValidReportType && errorsDownloadSummary.reportType?.message
          }
        >
          <Select
            label="Summary Type"
            id="reportType"
            register={registerDownloadSummary}
            placeholder={'Select a report type'}
            placeholderDisabled={true}
            options={mappedReportTypeOptions}
            valid={
              isValidReportType && touchedFieldsDownloadSummary?.reportType
            }
            fullWidth
            required
          />
        </FormControl>
        <label>Options </label>
        {reportTypeWatch !=
          GatewayReportTypeNames.DueDiligenceSummaryReport && (
          <FieldCheckbox
            id="includeRelatedEntities"
            register={registerDownloadSummary}
            label="Include Related Entities"
          />
        )}
        {isFiltersApplied && (
          <FieldCheckbox
            id="filteredView"
            register={registerDownloadSummary}
            defaultChecked={false}
            label="Filtered view only"
          />
        )}
        <ChildContainer>
          <FixedWidthButton
            name="DownloadReport"
            secondary
            type="submit"
            disabled={
              !isDownloadEnabled ||
              isLoading ||
              (!reportTypeWatch && reportTypeWatch !== null)
            }
          >
            <Icon icon="download" colour="white" size="xxs" aria-hidden />
            <DownloadText>Download Summary</DownloadText>
          </FixedWidthButton>
        </ChildContainer>
        {requestError && (
          <div>
            <ErrorMessage>
              Error generating the report. Please try again. If the problem
              persists, please contact vantagesupport@controlrisks.com
            </ErrorMessage>
          </div>
        )}
      </form>
    </>
  );
};

export default DownloadSummary;
