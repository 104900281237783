import React, { FC } from 'react';
import { ReactComponent as CsvSvg } from 'assets/svg/Icons/file-formats/Csv.svg';
import { ReactComponent as DocxSvg } from 'assets/svg/Icons/file-formats/Doc.svg';
import { ReactComponent as PdfSvg } from 'assets/svg/Icons/file-formats/Pdf.svg';
import { ReactComponent as PngSvg } from 'assets/svg/Icons/file-formats/Png.svg';
import { ReactComponent as XlsxSvg } from 'assets/svg/Icons/file-formats/Xlsx.svg';
import { ReactComponent as JpegSvg } from 'assets/svg/Icons/file-formats/Jpeg.svg';
import { ReactComponent as JpgSvg } from 'assets/svg/Icons/file-formats/Jpg.svg';
import { ReactComponent as ZipSvg } from 'assets/svg/Icons/file-formats/Zip.svg';

import { FileFormatIconTypes } from './types';
import { FileFormat } from 'helpers/documentHelper';

const fileIcons = {
  [FileFormat.PNG]: PngSvg,
  [FileFormat.JPEG]: JpegSvg,
  [FileFormat.JPG]: JpgSvg,
  [FileFormat.CSV]: CsvSvg,
  [FileFormat.PDF]: PdfSvg,
  [FileFormat.DOCX]: DocxSvg,
  [FileFormat.XLSX]: XlsxSvg,
  [FileFormat.ZIP]: ZipSvg,
};

const FileFormatIcon: FC<FileFormatIconTypes> = ({ fileType, size = 30 }) => {
  const FileSvg = fileIcons[fileType] || PngSvg;
  return (
    <FileSvg
      data-testid="FileFormatIconSvg"
      width={size}
      height={size}
      aria-label={`${fileType}-icon`}
    />
  );
};

export default FileFormatIcon;
