import { useReducer } from 'react';
import { FetchAction, FetchActionTypes, FetchState } from './types';

const defaultState = {
  loading: false,
  data: null,
  error: false,
  responseStatus: null,
  errorData: null,
};
type ActionHandler<T> = (
  state: FetchState<T>,
  action: FetchAction<T>
) => FetchState<T>;

const fetchInit = <T>(state: FetchState<T>): FetchState<T> => ({
  ...state,
  loading: true,
});

const fetchSuccess = <T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> => ({
  ...state,
  loading: false,
  error: false,
  responseStatus: action.responseStatus,
  data: action.payload ?? null,
});

const fetchFailure = <T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> => ({
  ...state,
  loading: false,
  error: true,
  responseStatus: action.responseStatus,
  errorData: action.payload ?? null,
});

const fetchReset = <T>(): FetchState<T> => ({
  loading: false,
  data: null,
  error: false,
  errorData: null,
  responseStatus: null,
});

const actionHandlers = {
  [FetchActionTypes.FETCH_INIT]: fetchInit,
  [FetchActionTypes.FETCH_SUCCESS]: fetchSuccess,
  [FetchActionTypes.FETCH_FAILURE]: fetchFailure,
  [FetchActionTypes.FETCH_RESET]: fetchReset,
};

export const dataFetchReducer = <T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> => {
  const handler = actionHandlers[action.type] as ActionHandler<T>;
  if (!handler) {
    throw new Error(`Unknown action type: ${action.type}`);
  }
  return handler(state, action);
};

export const useFetchReducer = <T, U>(
  initialState?: Partial<FetchState<T, U>>
) => useReducer(dataFetchReducer, { ...defaultState, ...initialState });
