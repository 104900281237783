import styled from 'styled-components';

export const AccordionComponent = styled.section<{ borders }>`
  border-block: ${({ theme, borders }) =>
    borders ? `1px solid ${theme.colours.gallery}` : 'none'};
  padding: ${({ theme }) => theme.pxToRem(25)} 0;
`;

interface AccordionTitleProps {
  background?: string;
  padding?: string;
  borderRadius?: string;
}

export const AccordionTitle = styled.div<AccordionTitleProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  line-height: 1.6667;
  font-size: ${({ theme }) => theme.pxToRem(18)};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
  background-color: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const AccordionToggle = styled.button`
  padding: 0;
  margin-left: ${({ theme }) => theme.pxToRem(20)};
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  padding-top: ${({ theme }) => theme.pxToRem(25)};
`;

export const IconsContainer = styled.div`
  display: flex;

  .completed svg {
    color: ${({ theme }) => theme.colours.swimmersPool};
  }
`;
