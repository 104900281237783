import styled from 'styled-components';
import { Chip } from 'components/atoms';

export const TimelineCardComponent = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Time = styled(Chip)`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(90)};

  &:before,
  &:after {
    position: absolute;
    display: block;
    border-radius: 50%;
    left: 50%;
    content: '';
    transform: translateX(-50%);
  }

  &:before {
    top: calc(100% + ${({ theme }) => theme.pxToRem(20)});
    width: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(40)};
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
  }

  &:after {
    top: calc(100% + ${({ theme }) => theme.pxToRem(28)});
    width: ${({ theme }) => theme.pxToRem(24)};
    height: ${({ theme }) => theme.pxToRem(24)};
    background-color: ${({ theme }) => theme.colours.white};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(20)};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: white;
  color: ${({ theme }) => theme.colours.crowberryBlue};

  &,
  a {
    font-size: ${({ theme }) => theme.pxToRem(14)};
  }

  &:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    width: ${({ theme }) => theme.pxToRem(16)};
    height: ${({ theme }) => theme.pxToRem(8)};
    background-color: ${({ theme }) => theme.colours.white};
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    content: '';
    transform: rotate(0deg) translate(-50%, ${({ theme }) => theme.spacing(1)});
  }
`;

export const IconList = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacing(20)};

  li {
    display: flex;
    align-items: center;
  }

  li + li {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }

  li.action svg {
    color: ${({ theme }) => theme.colours.swimmersPool};
  }

  span {
    display: block;
    flex-shrink: 0;
    width: ${({ theme }) => theme.pxToRem(20)};
    margin-right: ${({ theme }) => theme.spacing(10)};
    text-align: center;

    // Icon wrpaper.
    span {
      line-height: 1;
    }
  }
`;

export const Attributes = styled.div`
  padding: ${({ theme }) => theme.spacing(10)};
  border-radius: ${({ theme }) => theme.spacing(5)};
  margin: auto ${({ theme }) => theme.spacing(-10)} 0;
  background: ${({ theme }) => theme.colours.whiteSolid};

  dt,
  dl {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: ${({ theme }) => theme.pxToRem(12)};
  }
`;
