import styled from 'styled-components';

export const FormHeader = styled.h2`
  font-size: 24px;
  padding-bottom: ${({ theme }) => theme.pxToRem(30)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.silverSand};
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(40)};
  border-radius: 5px;

  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(30)};
  }
`;

export const EditForm = styled.form`
  display: flex;
  flex-direction: column;
  column-gap: ${({ theme }) => theme.spacing(40)};
  padding-top: ${({ theme }) => theme.spacing(30)};
`;

export const Separator = styled.div`
  width: 1;
  border: 1px solid ${({ theme }) => theme.colours.silverSand};
`;

export const ColumnsContainer = styled.section`
  flex 1;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(20)};
`;

export const Column = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`;

export const EditNote = styled.aside`
  display: flex;
  gap: ${({ theme }) => theme.spacing(20)};
  padding: ${({ theme }) => theme.spacing(18)};
  border-radius: ${({ theme }) => theme.spacing(5)};
  margin-top: ${({ theme }) => theme.spacing(35)};
  background-color: ${({ theme }) => theme.colours.whiteSolid};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.3;

  span {
    flex-shrink: 0;
    color: ${({ theme }) => theme.colours.charcoalDust};
    font-size: inherit;
  }

  p {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: inherit;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 10px;

  div {
    flex-grow: 1;
  }
`;
