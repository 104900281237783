import React, { FC } from 'react';
import { ModalTopContentContainer } from './DocumentViewerModal.styles';

import { DocumentViewerModalTypes } from './types';
import { Button, Loading } from 'components/atoms';
import { Modal } from 'components/molecules';

const DocumentViewerModal: FC<DocumentViewerModalTypes> = ({
  isOpen,
  onClose,
  isLoading,
  blobUrl,
  backMessage,
}: DocumentViewerModalTypes) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      transparent
      isClosable={false}
      removeSeparator
      removeBorderTop
      topContent={
        backMessage ? (
          <ModalTopContentContainer>
            <Button onClick={onClose} text dark>
              {backMessage}
            </Button>
          </ModalTopContentContainer>
        ) : null
      }
    >
      {isLoading && <Loading />}
      {!isLoading && blobUrl && (
        <object
          data={blobUrl}
          type="application/json"
          style={{ width: '100%', height: '100vh' }}
        />
      )}
    </Modal>
  );
};

export default DocumentViewerModal;
