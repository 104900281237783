import React, { FC } from 'react';
import { Spinner } from './Loading.styles';

import { LoadingTypes } from './types';

const Loading: FC<LoadingTypes> = () => {
  // Do something here
  return <Spinner className="spinner" />;
};

export default Loading;
