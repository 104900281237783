import { useState, useEffect } from 'react';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { useConfig } from 'config';

const useSignalR = (setMessage) => {
  const [connection, setConnection] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [connectionId, setConnectionId] = useState('');
  const { signalRUrl, signalRSubscriptionKey } = useConfig();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(signalRUrl, {
        transport: HttpTransportType.WebSockets,
        headers: { 'Ocp-Apim-Subscription-Key': signalRSubscriptionKey },
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setIsConnected(true);
          setConnectionId(connection.connectionId);
        })
        .catch((e) => {
          console.log(e);
        });

      connection.on('ReceiveMessage', (message) => {
        setMessage(message);
      });
    }

    return () => {
      if (connection) {
        connection.stop();
        setIsConnected(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return {
    isConnected,
    connectionId,
  };
};

export default useSignalR;
