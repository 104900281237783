import styled from 'styled-components';

import { Link } from 'react-router-dom';

interface ICollapsable {
  $collapsed: boolean;
}

interface ISelectable {
  $selected?: boolean;
}

export const NavigationComponent = styled.nav<ICollapsable>`
  --animation-timing: 0.25s ease-out;
  position: relative;
  width: ${({ $collapsed, theme }) => theme.pxToRem($collapsed ? 136 : 317)};
  height: calc(100vh - ${({ theme }) => theme.pxToRem(110)});
  background-color: ${({ theme }) => theme.colours.white};
  transition: width var(--animation-timing);

  .navigation-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${({ theme }) => theme.pxToRem(110)});
    padding: ${({ $collapsed, theme }) =>
      $collapsed
        ? `${theme.spacing(50)} ${theme.spacing(48)}`
        : `${theme.spacing(50)} ${theme.spacing(32)}`};
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CollapseButton = styled.button<ICollapsable>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${({ theme }) => theme.pxToRem(148)};
  right: ${({ theme }) => theme.pxToRem(-20)};
  width: ${({ theme }) => theme.pxToRem(40)};
  height: ${({ theme }) => theme.pxToRem(40)};
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colours.white};
  cursor: pointer;

  svg {
    transform: scaleX(${({ $collapsed }) => ($collapsed ? -1 : 1)});
    transition: transform var(--animation-timing);
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(60)};
`;

export const NavigationLinkList = styled.ul`
  list-style-type: none;
  margin: 0 0 ${({ theme }) => theme.spacing(100)};
  padding: 0;

  li + li {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;

export const NavigationLink = styled(Link)<ICollapsable & ISelectable>`
  display: flex;
  align-items: center;
  padding: ${({ $collapsed }) => ($collapsed ? '0px' : '10px')};
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(18)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  min-height: ${({ theme }) => theme.pxToRem(60)};
  border-radius: 5px;
  background-color: ${({ theme, $selected, $collapsed }) =>
    $collapsed
      ? 'transparent'
      : $selected
      ? theme.colours.swimmersPool
      : 'transparent'};
  white-space: nowrap;
  overflow-x: hidden;
  transition: padding var(--animation-timing);

  .navigation-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.spacing(40)};
    min-width: ${({ theme }) => theme.spacing(40)};
    max-width: ${({ theme }) => theme.spacing(40)};
    height: ${({ theme }) => theme.spacing(40)};
    border-radius: 50%;
    background-color: ${({ theme, $selected, $collapsed }) =>
      $collapsed
        ? $selected
          ? theme.colours.swimmersPool
          : theme.colours.superSilver
        : $selected
        ? theme.colours.white
        : theme.colours.superSilver};
    margin-right: ${({ $collapsed }) => ($collapsed ? '0px' : '15px')};
    transition: margin-right var(--animation-timing);
  }

  &:hover {
    background-color: ${({ theme, $collapsed }) =>
      $collapsed ? 'transparent' : theme.colours.swimmersPool};

    .navigation-icon {
      background-color: ${({ theme, $collapsed }) =>
        $collapsed ? theme.colours.swimmersPool : theme.colours.white};
    }
  }
`;

export const ExplainerVideo = styled.aside<ICollapsable>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ $collapsed }) => ($collapsed ? '40px' : '100%')};
  padding: ${({ $collapsed }) => ($collapsed ? '0px' : '40px 34px 20px 34px')};
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacing(30)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  background-color: ${({ theme }) => theme.colours.whiteSolid};
  text-align: center;
  border-radius: ${({ $collapsed }) => ($collapsed ? '50%' : '5px')};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: ${({ $collapsed }) => ($collapsed ? 'static' : 'absolute')};
    top: ${({ theme }) => theme.spacing(-40)};
    left: 50%;
    transform: ${({ $collapsed }) =>
      $collapsed ? 'none' : 'translateX(-50%)'};
    width: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(40)};
    align-self: center;
    background-color: ${({ theme, $collapsed }) =>
      $collapsed ? theme.colours.superSilver : theme.colours.white};
    border-radius: 50%;
    border: ${({ $collapsed, theme }) =>
      $collapsed ? 'none' : `20px solid ${theme.colours.whiteSolid}`};
    box-sizing: content-box;
  }

  p {
    display: ${({ $collapsed }) => ($collapsed ? 'none' : 'block')};

    &:first-of-type {
      font-size: ${({ theme }) => theme.pxToRem(18)};
    }

    &:last-of-type {
      font-size: ${({ theme }) => theme.pxToRem(12)};
      padding: ${({ theme }) => theme.spacing(20)} 0;
    }
  }
`;
