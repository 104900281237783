import styled from 'styled-components';

export const CollapsibleSectionLabel = styled.label`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  margin: 0;
  line-height: 1.6667;
  font-size: ${({ theme }) => theme.pxToRem(18)};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
  cursor: pointer;
`;
export const CollapsibleSectionLabelContainer = styled.div.attrs({
  className: 'collapsible-section',
})`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconButton = styled.button.attrs({
  type: 'button',
  ariaLabel: 'collapse-toggle',
  title: 'collapse-toggle',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg:hover {
    cursor: pointer;
  }
`;
