export const downloadBlob = (container = document.body, blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  container.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
