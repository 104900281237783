/* eslint-disable no-case-declarations */
import { ThirdPartyDataConfigDictionary } from 'services/getThirdPartyConfiguration';
import { StateType, ReducerActionsType } from './types';

export const entityInitialState = {
  name: null,
  alternativeName: null,
  primaryCountry: null,
  linkType: null,
  addressLineOne: null,
  zipCode: null,
  city: null,
  county: null,
  countryTwo: null,
  countryThree: null,
  duns: null,
  companyUrl: null,
  registrationNumber: null,
  swift: null,
  gender: '2',
  dateOfBirth: null,
  nationality: null,
  passportNumber: null,
  nationalIDNumber: null,
  otherIDNumber: null,
  referenceField1: null,
  referenceField2: null,
  referenceField3: null,
};

export const initialState: StateType = {
  formData: null,
  activeStep: 1,
  viewedStep: 1,
  matchedRecords: {},
  selectedCompany: null,
  relatedEntities: null,
  modalActive: false,
  entityInitialState,
  thirdPartyDataConfig: {},
};

export const reducer = (state: StateType, action: ReducerActionsType) => {
  const { type } = action;

  switch (type) {
    case 'FORM_DATA_INIT':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_ACTIVE_STEP':
      return {
        ...state,
        activeStep: action.payload.activeStep,
        viewedStep: action.payload.activeStep,
      };
    case 'UPDATE_VIEWED_STEP':
      return {
        ...state,
        viewedStep: action.payload.viewedStep,
      };
    case 'SAVE_FORM_DATA':
      return {
        ...state,
        formData: { ...state.formData, ...action.payload.form },
        selectedCompany: action.payload.selectedCompany,
      };
    case 'GET_FORM_DATA':
      return {
        ...state.formData,
      };

    case 'SAVE_MATCHED_RECORDS':
      return {
        ...state,
        matchedRecords: [...action.payload.matchedRecords],
      };
    case 'UPDATE_RELATED_ENTITY':
      return {
        ...state,
        relatedEntities: [...action.payload],
      };
    case 'DELETE_RELATED_ENTITY':
      return {
        ...state,
        relatedEntities: state.relatedEntities.filter(
          (value) => value.id !== action.payload
        ),
      };
    case 'CREATE_RELATED_ENTITY':
      // eslint-disable-next-line no-case-declarations
      const tempArray = [...state.relatedEntities];
      const array = tempArray.map((match) => {
        let obj = { ...match };
        if (obj.id === action.payload.id) obj = action.payload;
        return obj;
      });
      if (state.relatedEntities.find((elem) => elem.id === action.payload.id)) {
        return {
          ...state,
          relatedEntities: array,
        };
      } else {
        return {
          ...state,
          relatedEntities: [
            ...state.relatedEntities,
            ...[{ id: state.relatedEntities.length + 1, ...action.payload }],
          ],
        };
      }
    case 'EDIT_RELATED_ENTITY':
      return {
        ...state,
        entityInitialState: {
          ...entityInitialState,
          ...action.payload,
        },
        modalActive: true,
      };
    case 'INIT_CREATE_RELATED_ENTITY':
      return {
        ...state,
        entityInitialState: {
          ...entityInitialState,
          type: action.payload,
        },
        modalActive: true,
      };
    case 'CLEAR_CREATE_RELATED_ENTITY':
      return {
        ...state,
        entityInitialState: null,
      };
    case 'GET_RELATED_ENTITY':
      return {
        ...state,
        relatedEntities: state.relatedEntities.filter(
          (value) => value.id == action.payload
        ),
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalActive: !state.modalActive,
        initialState: null,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        modalActive: true,
        initialState: initialState,
      };

    case 'SAVE_THIRDPARTY_DATA_CONFIG':
      const dict = action.payload as ThirdPartyDataConfigDictionary;

      return {
        ...state,
        thirdPartyDataConfig: { ...dict },
      } as StateType;
  }
};
