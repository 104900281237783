import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FieldRadio, FormControl, Icon } from 'components/atoms';
import {
  Wrapper,
  FormHeader,
  ButtonWrapper,
  FixedWidthButton,
} from '../form.styles';
import { SideBySide } from './step1.styles';
import { FormContext } from 'context/form';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useConfig } from 'config';
import { ThirdPartyType } from 'types/thirdPartyType';
import { getThirdPartyConfiguration } from 'services/getThirdPartyConfiguration';
import {
  isTokenExpired,
  refreshTokenOnDemand,
} from 'services/refreshTokenService';

const Step1 = () => {
  const { formDispatch } = useContext(FormContext);
  const { setToken, accessToken, refreshToken, setRefreshToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi, msalSubscriptionKey, accessTokenId, refreshTokenId } =
    useConfig();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      type: 'Business',
    },
  });
  const history = useHistory();

  const onSubmit = async (data) => {
    const entityTypeParam =
      data?.type?.toLowerCase() === ThirdPartyType.Business.toLowerCase()
        ? ThirdPartyType.Business.toLowerCase()
        : ThirdPartyType.Individual.toLowerCase();

    setIsSubmitLoading(true);
    if (isTokenExpired(accessToken)) {
      const tokens = await refreshTokenOnDemand(
        vantageWebApi,
        {
          'Ocp-Apim-Subscription-Key': msalSubscriptionKey,
          'Content-Type': 'application/x-www-form-urlencoded',
          mode: 'no-cors',
        },
        {
          accessTokenId: accessTokenId,
          refreshTokenId: refreshTokenId,
          refreshToken: refreshToken,
        }
      );
      setToken(tokens?.accessToken);
      setRefreshToken(tokens?.refreshToken);
    }
    const thirdPartyConfig = await getThirdPartyConfiguration(
      vantageWebApi,
      {
        'Ocp-Apim-Subscription-Key': msalSubscriptionKey,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      entityTypeParam
    );

    setIsSubmitLoading(false);
    formDispatch({
      type: 'SAVE_THIRDPARTY_DATA_CONFIG',
      payload: thirdPartyConfig,
    });
    formDispatch({
      type: 'SAVE_FORM_DATA',
      payload: { form: data },
    });
    formDispatch({
      type: 'UPDATE_ACTIVE_STEP',
      payload: { activeStep: 2 },
    });
  };

  return (
    <Wrapper>
      <FormHeader>{`${terminologyConfig?.thirdPartySingularNaming} type`}</FormHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl errorMessage={errors.type?.message}>
          <SideBySide>
            <FieldRadio
              label="Company"
              id="company"
              value="Business"
              name="type"
              register={register}
            >
              <Icon background size="xxxs" icon="company" className="icon" />
              <span>Company</span>
            </FieldRadio>
            <FieldRadio
              label="Individual"
              id="individual"
              value="Individual"
              name="type"
              register={register}
            >
              <Icon background size="xxxs" icon="user" className="icon" />
              <span>Individual</span>
            </FieldRadio>
          </SideBySide>
        </FormControl>
        <ButtonWrapper>
          <FixedWidthButton disabled={isSubmitLoading} type="submit" secondary>
            Next step
          </FixedWidthButton>
          <Button
            text
            type="button"
            onClick={() => history.push(`/third-party/`)}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};

export default Step1;
