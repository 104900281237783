import React, { FC } from 'react';
import {
  SectionedListHeading,
  SectionedListValues,
} from './SectionedList.styles';
import { SectionedListProps } from './types';

const SectionedList: FC<SectionedListProps> = ({ sections }) => (
  <div>
    {sections.map(({ heading, items }) => (
      <div key={heading}>
        {heading && <SectionedListHeading>{heading}</SectionedListHeading>}
        <SectionedListValues>
          {items.map(({ key, value }) => (
            <p key={`${key}-${value}`}>
              <span>{key}:</span> <span>{value}</span>
            </p>
          ))}
        </SectionedListValues>
      </div>
    ))}
  </div>
);

export default SectionedList;
