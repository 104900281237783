import React, { FC, useRef } from 'react';
import { HiddenInput } from './FieldUploadFile.styles';

import { FieldUploadFileTypes } from './types';
import Button from '../button';

const FieldUploadFile: FC<FieldUploadFileTypes> = ({
  value,
  onChange,
  ref: registerRef,
  supportedFormats,
  buttonProps = {},
  inputProps = {},
}: FieldUploadFileTypes) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    onChange(file);
    event.target.value = '';
  };

  return (
    <>
      <HiddenInput
        type="file"
        accept={supportedFormats}
        onChange={handleChange}
        ref={(e) => {
          registerRef && registerRef(e);
          fileInputRef.current = e;
        }}
        {...inputProps}
      />
      <Button
        aria-label="upload file button"
        onClick={handleUploadButtonClick}
        className="upload-button"
        outline
        {...buttonProps}
      >
        {value ? 'Replace file' : 'Choose file'}
      </Button>
    </>
  );
};

export default FieldUploadFile;
