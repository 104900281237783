import React, { MouseEventHandler, useEffect, useState } from 'react';

import { useTerminologyConfig } from 'context/terminologyConfig';

import { useLocation } from 'react-router-dom';

import { Button, Icon } from 'components/atoms';
import { Icons } from 'components/atoms/icon/types';
import { GatewayPermissions } from 'auth/constants/permissions';

import {
  NavigationComponent,
  CollapseButton,
  LogoContainer,
  NavigationLinkList,
  NavigationLink,
  ExplainerVideo,
} from './Navigation.styles';
import { useAuth } from 'auth';

interface INavigationLink {
  to?: string;
  title: string;
  icon: Icons;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const Navigation = () => {
  const screenWidth = window.screen.availWidth;
  const collapsedPreference = localStorage.getItem('navigation-collapsed');
  const isCollapsedOnStart = collapsedPreference
    ? collapsedPreference === 'true'
    : screenWidth <= 1440;
  const terminologyConfig = useTerminologyConfig();
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedOnStart);
  const { hasPermission } = useAuth();

  const hasViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const navigationLinks: INavigationLink[] = [];

  if (hasViewTaskList) {
    navigationLinks.push({
      title: 'Task list',
      to: '/tasklist',
      icon: 'report',
    });
  }

  navigationLinks.push(
    {
      title: `${terminologyConfig?.thirdPartySingularNaming} manager`,
      to: '/third-party',
      icon: 'error',
    },
    {
      title: 'Settings',
      to: '/settings',
      icon: 'settings',
    },
    {
      title: 'Support',
      onClick: (e) => {
        window.location.href = 'mailto:vantagesupport@controlrisks.com';
        e.preventDefault();
      },
      icon: 'user',
      to: 'mailto:vantagesupport@controlrisks.com',
    }
  );

  useEffect(() => {
    localStorage.setItem(
      'navigation-collapsed',
      isCollapsed ? 'true' : 'false'
    );
  }, [isCollapsed]);

  const toggleCollapse = () => {
    return setIsCollapsed(!isCollapsed);
  };

  return (
    <NavigationComponent $collapsed={isCollapsed}>
      <CollapseButton
        aria-label={`Toggle ${isCollapsed ? 'larger' : 'smaller'} navigation`}
        $collapsed={isCollapsed}
        onClick={toggleCollapse}
      >
        <Icon icon="chevron-left" aria-hidden size="xxs" />
      </CollapseButton>
      <div className="navigation-content">
        <LogoContainer>
          <img
            src={isCollapsed ? '/logo-small.png' : '/logo-regular.png'}
            alt="Control Risks"
          />
        </LogoContainer>
        <NavigationLinkList>
          {navigationLinks.length > 0 &&
            navigationLinks.map((navigationLink) => (
              <li key={navigationLink.title}>
                <NavigationLink
                  to={navigationLink.to}
                  $collapsed={isCollapsed}
                  $selected={
                    (navigationLink.to !== '/' &&
                      pathname.startsWith(navigationLink.to)) ||
                    (navigationLink.to === '/' &&
                      pathname === navigationLink.to)
                  }
                  aria-label={navigationLink.title}
                  onClick={navigationLink.onClick}
                >
                  <span className="navigation-icon">
                    <Icon icon={navigationLink.icon} aria-hidden size="xxs" />
                  </span>
                  {!isCollapsed && <span> {navigationLink.title}</span>}
                </NavigationLink>
              </li>
            ))}
        </NavigationLinkList>
        <ExplainerVideo role="complementary" $collapsed={isCollapsed}>
          <div aria-hidden>
            <Icon icon="notification" size="xs" />
          </div>
          {!isCollapsed && (
            <>
              <p>Vantage Gateway</p>
              <p>
                Watch a selection of "How to" video tutorials for Vantage
                Gateway
              </p>
            </>
          )}
          {!isCollapsed && (
            <Button
              as="a"
              outline
              href={
                'https://www.youtube.com/playlist?list=PLGq4kDaT8Mh9oOsuUbPwbBwJJD2fN3R-K'
              }
              target="_blank"
            >
              Watch Videos
            </Button>
          )}
        </ExplainerVideo>
      </div>
    </NavigationComponent>
  );
};

export default Navigation;
