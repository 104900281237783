import Toast from 'components/molecules/toast';
import { ToastProps, ToastType } from 'components/molecules/toast/types';
import React, {
  FC,
  PropsWithChildren,
  Reducer,
  createContext,
  useEffect,
  useReducer,
} from 'react';

export const contextDefaultValues: ToastContextProps = {
  state: { open: false, args: { type: ToastType.Information } },
  openToast: () => {},
  closeToast: () => {},
};

export const ToastContext =
  createContext<ToastContextProps>(contextDefaultValues);

const initialState: ToastState = {
  open: false,
  args: {
    type: ToastType.Information,
  },
};

const reducer: Reducer<ToastState, Partial<ToastAction>> = (state, action) => {
  const { type, payload } = action;
  const { args } = payload || { args: { type: ToastType.Information } };

  switch (type) {
    case ToastActionKind.OPEN:
      return { ...state, open: true, args: args };
    case ToastActionKind.CLOSE:
      return { ...state, open: false, args: { type: ToastType.Information } };
    default:
      return state;
  }
};

export const ToastProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    openToast: (toastArgs: ToastProps) =>
      dispatch({ type: ToastActionKind.OPEN, payload: { args: toastArgs } }),
    closeToast: () => dispatch({ type: ToastActionKind.CLOSE }),
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.open) {
        dispatch({ type: ToastActionKind.CLOSE });
        state?.args?.onClose && state.args.onClose();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [state.open, state.args]);

  return (
    <ToastContext.Provider value={value}>
      {state.open && <Toast {...state.args} />}
      {children}
    </ToastContext.Provider>
  );
};

export interface ToastContextProps {
  state: ToastState;
  openToast: (toastArgs: ToastProps) => void;
  closeToast: () => void;
}

enum ToastActionKind {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

interface ToastAction {
  type: ToastActionKind;
  payload: Partial<ToastState>;
}

interface ToastState {
  open: boolean;
  args: ToastProps;
}
