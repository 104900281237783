import styled from 'styled-components';

export const SummaryPanels = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(20)};
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const GeneralPanels = styled.div`
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(20)};
  }
`;

export const ItallicNote = styled.span`
  font-style: italic;
  font-weight: 400;
  margin: 0px 6px;
`;

export const InternalNotes = styled.div`
  font-size: ${({ theme }) => theme.pxToRem(14)};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  line-height: 1.5;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }

  span {
    color: ${({ theme }) => theme.colours.charcoalDust};
  }

  p {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  span,
  p,
  a {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    display: inline-block;
  }
`;

export const CommentsContainer = styled.div`
  font-size: ${({ theme }) => theme.pxToRem(14)};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  margin-top: ${({ theme }) => theme.spacing(8)};
  line-height: 1.5;
  > div {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }

  span {
    color: ${({ theme }) => theme.colours.charcoalDust};
  }

  p {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  span,
  p,
  a {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    display: inline-block;
  }
`;
