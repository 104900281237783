import styled from 'styled-components';

export const CountryComponent = styled.span<{ fullWidth }>`
  ${({ fullWidth }) => fullWidth && 'width:max-content;'}
  display: inline-flex;
  align-items: flex-start;
  gap: 0.7em;
  color: ${({ theme }) => theme.colours.charcoalDust};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.334;

  img {
    display: inline-block;
    height: 1.429em;
    aspect-ratio: 28 / 20;
    border-radius: 0.2em;
    transform: translateY(-0.07em);
  }
`;
