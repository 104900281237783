import React, { FC, forwardRef } from 'react';
import { Wrapper } from './FieldDate.styles';
import { FieldDateProps } from './types';
import DatePicker from 'react-multi-date-picker';
import { IMaskMixin } from 'react-imask';
import styled from 'styled-components';
import { MaskedRange } from 'imask';

const StyledInput = styled.input``;

// eslint-disable-next-line react/display-name
const MyInput = forwardRef((props: any, ref: any) => (
  <StyledInput
    className="input"
    autoComplete="nope"
    type="text"
    ref={ref}
    {...props}
  />
));

const MyIMaskInput = IMaskMixin((props: any): any => (
  <MyInput {...props} ref={props.inputRef} />
));

const FieldDate: FC<FieldDateProps> = ({
  label,
  name,
  valid,
  register,
  id = '',
  disabled,
  required,
  onChange,
  onTextChange = () => {},
  onBlur,
  value = new Date(),
  fullWidth = false,
  format,
  placeholder,
}: FieldDateProps) => {
  const checkValidationIsBool = typeof valid === 'boolean';

  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="required"> *</span>}
        </label>
      )}
      <Wrapper
        {...(checkValidationIsBool
          ? { className: !valid ? 'invalid' : '' }
          : {})}
        $fullWidth={fullWidth}
      >
        <DatePicker
          {...(register ? { ...register(id, { required }) } : {})}
          {...(disabled ? { disabled } : {})}
          {...onBlur}
          value={value}
          onChange={onChange}
          format={format}
          render={(value, openCalendar, handleValueChange) => {
            return (
              <MyIMaskInput
                mask="00/00/`Y"
                aria-label={label}
                onChange={(element) => {
                  onTextChange(element.target.value);
                  handleValueChange(element);
                }}
                value={value.toString()}
                onClick={openCalendar}
                placeholder={placeholder}
                blocks={{
                  Y: {
                    mask: MaskedRange,
                    from: 1,
                    to: 9999,
                  },
                }}
              />
            );
          }}
        />
      </Wrapper>
    </>
  );
};

export default FieldDate;
