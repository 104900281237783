import React, { FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Icon from 'components/atoms/icon';

import {
  AccordionComponent,
  AccordionTitle,
  AccordionContent,
  IconsContainer,
  AccordionToggle,
} from './Accordion.styles';

import { AccordionTypes } from './types';

const Accordion: FC<AccordionTypes> = ({
  title,
  children,
  onClick,
  isOpen,
  completed,
  setIsOpen,
  accordionTitleProps,
  showCaret = true,
  caretBackground = true,
  borders = true,
}: AccordionTypes) => {
  return (
    <AccordionComponent borders={borders}>
      <AccordionTitle {...accordionTitleProps}>
        {title}
        <IconsContainer>
          {completed && (
            <Icon
              icon="check"
              size="xs"
              className="completed"
              aria-label="Completed"
            />
          )}
          {showCaret && (
            <AccordionToggle
              type="button"
              onClick={(event) => {
                if (typeof onClick === 'function') onClick(event);
                if (typeof setIsOpen === 'function') setIsOpen(!isOpen);
              }}
            >
              <Icon
                background={caretBackground}
                icon={isOpen ? 'chevron-up' : 'chevron-down'}
                size="xs"
                className="icon"
              />
            </AccordionToggle>
          )}
        </IconsContainer>
      </AccordionTitle>
      <AnimatePresence>
        {isOpen && (
          <AccordionContent>
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: isOpen && !showCaret ? 0 : 0.25 }}
            >
              {children}
            </motion.div>
          </AccordionContent>
        )}
      </AnimatePresence>
    </AccordionComponent>
  );
};

export default Accordion;
