import React, { FC } from 'react';
import { TabComponent } from './Tab.styles';

import { TabTypes } from './types';

const Tab: FC<TabTypes> = ({ label, onClick, active, dark }: TabTypes) => (
  <TabComponent onClick={onClick} active={active} dark={dark}>
    {label}
  </TabComponent>
);

export default Tab;
