import styled from 'styled-components';

export const DefaultText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  margin: 0 ${({ theme }) => theme.spacing(10)};
`;

export const LoadingText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  margin: 0 ${({ theme }) => theme.spacing(10)};
  text-align: center;
`;

export const ChildContainer = styled.div`
  color: ${({ theme }) => theme.colours.black};

  div {
    margin-top: ${({ theme }) => theme.spacing(25)};
    color: ${({ theme }) => theme.colours.black};
  }
`;
