import styled from 'styled-components';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  }

  input {
    display: block;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    color: inherit;
    opacity: 0;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing(30)};
    height: ${({ theme }) => theme.spacing(30)};
    border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
    border-radius: ${({ theme }) => theme.spacing(3)};
    pointer-events: none;
    margin-right: ${({ theme: { spacing } }) => spacing(10)};
    background-color: transparent;
    color: #fff;
    transition: all 200ms ease-in;
  }

  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colours.crowberryBlue};

    svg {
      opacity: 1;
    }
  }

  input:disabled ~ .checkmark {
    opacity: 0.2;
    filter: grayscale(100%);
    cursor: auto;
  }
`;
