import styled from 'styled-components';

interface TabContainerProps {
  dark?: boolean;
}

export const TabContainer = styled.div<TabContainerProps>`
  position: relative;
  display: flex;
  flex-direxction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colours.gallery};
`;

export const CtaSlot = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-bottom: ${({ theme }) => theme.pxToRem(3)};

  > * + * {
    margin-left: ${({ theme }) => theme.pxToRem(20)};
  }
`;

export const CTAContainer = styled.span`
  display: inherit;
`;
