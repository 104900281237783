import styled, { css } from 'styled-components';
import { DrawerOverlayProps } from './types';

export const DrawerComponent = styled.div<
  Pick<DrawerOverlayProps, 'opaqueOverlay'>
>`
  &:before {
    z-index: ${({ theme }) => theme.zIndex.level_2};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    ${({ opaqueOverlay = false, theme }) =>
      opaqueOverlay
        ? `background-image: linear-gradient(
          270deg,
          ${theme.colours.porpoisePlace} 0%,
          ${theme.colours.crowberryBlue} 100%
        );`
        : 'background-color: rgba(0, 0, 0, 0.5)'}
`;

export const DrawerOverlayComponent = styled.div<
  Pick<DrawerOverlayProps, 'opaqueOverlay' | 'transparent'>
>`
  z-index: ${({ theme }) => theme.zIndex.level_2 + 1};
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  height: 100vh;
  display: flex-end;
  flex-direction: flex-end;
  border-radius: ${({ theme }) => theme.pxToRem(5)}
    ${({ theme }) => theme.pxToRem(0)} ${({ theme }) => theme.pxToRem(0)}
    ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.colours.white};
  transform: translate(0, 0);
  box-shadow: 0 ${({ theme }) => theme.pxToRem(10)}
    ${({ theme }) => theme.pxToRem(30)} 0 rgba(0, 0, 0, 0.05);
`;

export const DrawerHeading = styled.div<{ compact?: boolean }>`
  display: flex;

  ${({ compact = false, theme: { colours, spacing } }) =>
    compact
      ? css`
          padding: ${spacing(40)} ${spacing(40)} ${spacing(30)};
          border-bottom: 1px solid ${colours.gallery};
        `
      : css`
          position: absolute;
          width: 100%;
          padding: ${spacing(20)};
          top: 0;
          right: 0;
        `}
`;

export const DrawerClose = styled.button`
  margin-left: auto;
  cursor: pointer;
`;

export const DrawerTitle = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  letter-spacing: 0;
  line-height: 1.25;
`;

export const DrawerBody = styled.div<{
  compact?: boolean;
  removePadding?: boolean;
}>`
  overflow: auto;
  ${({ compact = false, removePadding, theme }) =>
    removePadding
      ? css`
          padding: 0;
        `
      : css`
          padding-left: 40px;
          padding-right: 40px;
          padding-bottom: ${theme.spacing(compact ? 60 : 40)};
        `};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DrawerButtonsContainer = styled.div`
  heigth: 20px;
  width: 100%;
  left: 0px;
  top: 25px;
  padding-left: 5px;
  padding-right: 10px;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: ${({ theme }) => theme.zIndex.level_2 + 2};
`;
