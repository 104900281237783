import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useFetch } from 'hooks';
import { OutstandingActionsComponent } from './OutstandingActions.styles';
import { MessageCarousel } from 'components/organisms';
import { Message } from 'components/molecules/';
import NextReviewDateModal from 'components/organisms/next-review-date-modal';
import { Button, Link } from 'components/atoms';

const OutstandingActions = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(`${vantageWebApi}/thirdParty/${id}/tasks`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedThirdPartyid, setSelectedScreeningId] = useState(null);

  if (!data || data?.length === 0) {
    return null;
  }

  const handleNextReviewModalOpen = (screeningId) => {
    setSelectedScreeningId(screeningId);
    setModalOpen(true);
  };

  const handleNextReviewModalClose = () => {
    setModalOpen(false);
  };

  const handleNextReviewModalProceed = () => {
    handleNextReviewModalClose();
  };

  const itsNextReviewDate = (status) => {
    return status === 'Next Review Date';
  };

  const hasReportAvailable = (status) => {
    return status.toLowerCase().includes('report ready for review');
  };

  const ctaMapper = (
    screeningRequest: any,
    status: string,
    defaultText: string,
    href: string
  ) => {
    let linkText = 'Review' + defaultText.toLowerCase();
    if (!screeningRequest && itsNextReviewDate(status)) {
      return (
        <Button
          text={true}
          small={true}
          onClick={() => handleNextReviewModalOpen(data[0].thirdParty?.id)}
        >
          {linkText}
        </Button>
      );
    }
    let isSelfService = screeningRequest?.dueDiligenceLevel === 'Self Service';
    if (hasReportAvailable(status) && !isSelfService) {
      linkText = 'View report';
    } else if (isSelfService) {
      switch (screeningRequest.currentScreeningStateId) {
        case 'UnresolvedMatches':
          linkText = 'View unresolved matches';
          break;
        case 'UnresolvedAlerts':
          linkText = 'View monitoring alert';
          break;
      }
    }

    return <Link to={href}>{linkText}</Link>;
  };

  return (
    <OutstandingActionsComponent>
      <MessageCarousel uniqueId="outstanding-tasks">
        {data?.length > 0 &&
          data.map(
            ({ id, thirdParty, screeningRequest, status, relatedEntity }) => {
              const screening = [
                screeningRequest?.dueDiligenceLevel,
                screeningRequest?.dueDiligenceType,
              ]
                .filter((n) => n)
                .join(' ');
              const cta = ctaMapper(
                screeningRequest,
                status,
                screening,
                `/third-party/screening/${screeningRequest?.screeningId}`
              );
              return (
                <div key={id}>
                  <Message
                    icon="attention"
                    message={`${screening ? `${screening} - ` : ''}${
                      itsNextReviewDate(status)
                        ? 'Next review date due'
                        : status
                    } for ${relatedEntity?.name || thirdParty.name}`}
                    cta={cta}
                  />
                </div>
              );
            }
          )}
      </MessageCarousel>
      <NextReviewDateModal
        isOpen={modalOpen}
        title={`Initiate ${terminologyConfig?.thirdPartySingularNaming} review now?`}
        onClose={handleNextReviewModalClose}
        loading={false}
        onProceed={handleNextReviewModalProceed}
        thirdPartyId={selectedThirdPartyid}
      />
    </OutstandingActionsComponent>
  );
};

export default OutstandingActions;
