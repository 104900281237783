import styled from 'styled-components';

export const ChipComponent = styled.div`
  display: inline-flex;
  align-items: center;
  height: ${({ theme: { spacing } }) => spacing(22)};
  border: 1px solid ${({ theme: { colours } }) => colours.pacificBlue};
  border-radius: ${({ theme: { spacing } }) => spacing(20)};
  background: ${({ theme: { colours } }) => colours.pacificBlue};

  ${({ outline, theme: { colours } }) =>
    outline
      ? `
  background: transparent;
  color: ${colours.pacificBlue};
  `
      : ` color: ${colours.white};`}
`;

export const ChipLabel = styled.span`
  padding: 0 ${({ theme: { spacing } }) => spacing(10)};
  font-size: ${({ theme: { pxToRem } }) => pxToRem(12)};
  white-space: nowrap;
`;
