import React, { FC } from 'react';
import { Wrapper } from './FieldCheckbox.styles';
import { FieldCheckboxProps } from './types';
import { Icon } from 'components/atoms';

const FieldCheckbox: FC<FieldCheckboxProps> = ({
  value = '',
  ariaLabel = '',
  tabIndex = 0,
  id = '',
  label,
  name = '',
  disabled = false,
  register,
  inputRef,
  onChange,
  labelStyles,
  spanStyles,
  ...props
}: FieldCheckboxProps) => (
  <Wrapper disabled={disabled}>
    <label htmlFor={id}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        tabIndex={-1}
        onChange={onChange}
        {...(disabled ? { disabled } : {})}
        {...(inputRef ? { ref: inputRef } : {})}
        {...(register ? { ...register(id) } : {})}
        {...props}
      />
      <span
        className="checkmark"
        role="checkbox"
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        aria-checked={false}
        {...(spanStyles ? { style: spanStyles } : {})}
      >
        <Icon icon="check" aria-hidden size="xs" />
      </span>
      {(label || value) && (
        <span {...(labelStyles ? { style: labelStyles } : {})}>
          {label || value}
        </span>
      )}
    </label>
  </Wrapper>
);

export default FieldCheckbox;
