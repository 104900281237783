import styled, { css } from 'styled-components';
import { ButtonBase } from 'globalStyles/GlobalStyles';
import { DualButtonProps, DualButtonRightProps } from './types';

export const DualButtonContainerComponent = styled.div`
  display: flex;
`;

export const DualButtonPrimaryComponent = styled.button<DualButtonProps>`
  ${ButtonBase}
  border-top-right-radius: ${({ theme }) => theme.pxToRem(0)};
  border-bottom-right-radius: ${({ theme }) => theme.pxToRem(0)};
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  background-color: ${({ theme }) => theme.colours.crowberryBlue};
  color: ${({ theme }) => theme.colours.white};

  &:hover {
    transition: all 0.25s ease-in-out;
    background: ${({ theme }) => theme.colours.swimmersPool};
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
  }

  ${({ outline }) =>
    outline &&
    css`
      border-color: currentColor;
      background: transparent;
      color: ${({ theme }) => theme.colours.charcoalDust};
    `}

  ${({ outline, secondary }) =>
    secondary &&
    css`
      border-color: ${({ theme }) => theme.colours.swimmersPool};
      border-left-color: ${({ theme }) => theme.colours.white};
      background-color: ${outline
        ? 'transparent'
        : ({ theme }) => theme.colours.swimmersPool};
      color: ${({ theme }) =>
        outline ? theme.colours.swimmersPool : theme.colours.crowberryBlue};

      &:hover {
        background: ${({ theme }) => theme.colours.crowberryBlue};
        border-color: ${({ theme }) => theme.colours.white};
        color: ${({ theme }) => theme.colours.white};
        text-decoration-color: ${({ theme: { colours } }) => colours.white};
      }
    `}

    ${({ dark, text }) =>
    text &&
    css`
      padding-inline: 0;
      text-decoration: underline;
      text-decoration-color: ${({ theme: { colours } }) =>
        colours.swimmersPool};
      text-underline-position: under;
      border: none;
      background: transparent;
      color: ${({ theme }) =>
        dark ? theme.colours.whiteSolid : theme.colours.charcoalDust};

      &:hover {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colours.swimmersPool};
        text-decoration-color: ${({ theme: { colours } }) =>
          colours.swimmersPool};
      }

      &:disabled {
        background: transparent;
        border: 0;
        color: ${({ theme }) => theme.colours.spanishGrey};
        text-decoration-color: ${({ theme }) => theme.colours.spanishGrey};
      }
    `}

    ${({ small, text }) =>
    small &&
    css`
      min-height: ${({ theme }) => theme.pxToRem(38)};
      height: ${({ theme }) => theme.pxToRem(38)};
      padding: 0 ${({ theme }) => (text ? theme.spacing(0) : theme.spacing(20))};
      font-size: ${({ theme }) => theme.typography.fontSize.small};
    `}
`;

export const DualButtonChevronComponent = styled.button<DualButtonRightProps>`
  ${ButtonBase}
  border-top-left-radius: ${({ theme }) => theme.pxToRem(0)};
  border-bottom-left-radius: ${({ theme }) => theme.pxToRem(0)};
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  background-color: ${({ theme }) => theme.colours.crowberryBlue};
  color: ${({ theme }) => theme.colours.white};
  max-width: 30px;
  padding: 6px;
  justify-content: center;
  align-content: center;

  &:hover {
    transition: all 0.25s ease-in-out;
    background: ${({ theme }) => theme.colours.swimmersPool};
    color: ${({ theme }) => theme.colours.white};
  }

  svg {
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.swimmersPool : 'currentColor'};
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }

  ${({ dark, text }) =>
    text &&
    css`
      padding-inline: 0;
      text-decoration: underline;
      text-decoration-color: ${({ theme: { colours } }) =>
        colours.swimmersPool};
      text-underline-position: under;
      border: none;
      background: transparent;
      color: ${({ theme }) =>
        dark ? theme.colours.whiteSolid : theme.colours.charcoalDust};

      &:hover {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colours.swimmersPool};
        text-decoration-color: ${({ theme: { colours } }) =>
          colours.swimmersPool};
      }

      &:disabled {
        background: transparent;
        border: 0;
        color: ${({ theme }) => theme.colours.spanishGrey};
        text-decoration-color: ${({ theme }) => theme.colours.spanishGrey};
      }
    `}

  ${({ small, text }) =>
    small &&
    css`
      min-height: ${({ theme }) => theme.pxToRem(38)};
      height: ${({ theme }) => theme.pxToRem(38)};
      padding: 0 ${({ theme }) => (text ? theme.spacing(0) : theme.spacing(20))};
      font-size: ${({ theme }) => theme.typography.fontSize.small};
    `}

  ${({ outline, secondary }) =>
    secondary &&
    css`
      border-color: ${({ theme }) => theme.colours.swimmersPool};
      border-left-color: ${({ theme }) => theme.colours.white};
      background-color: ${outline
        ? 'transparent'
        : ({ theme }) => theme.colours.swimmersPool};
      color: ${({ theme }) =>
        outline ? theme.colours.swimmersPool : theme.colours.crowberryBlue};

      &:hover {
        background: ${({ theme }) => theme.colours.crowberryBlue};
        border-color: ${({ theme }) => theme.colours.white};
        color: ${({ theme }) => theme.colours.white};
        text-decoration-color: ${({ theme: { colours } }) => colours.white};
      }
    `}
`;
export const DropdownMenu = styled.div`
  z-index: 5;
  width: 273px;
  position: absolute;
  top: 60px;
  background-color: ${({ theme }) => theme.colours.white};
  border: 1px solid ${({ theme }) => theme.colours.concreteGray};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
`;

export const DropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.pxToRem(6.5)} ${theme.pxToRem(20)}`};
  cursor: pointer;
  gap: 8px;

  &:first-child {
    border-top-right-radius: ${({ theme }) => theme.pxToRem(4)};
    border-top-left-radius: ${({ theme }) => theme.pxToRem(4)};
  }

  &:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.pxToRem(4)};
    border-bottom-left-radius: ${({ theme }) => theme.pxToRem(4)};
  }

  &.label {
    padding: ${({ theme }) => `${theme.pxToRem(6.5)} ${theme.pxToRem(16)}`};
    cursor: default;
    color: ${({ theme }) => theme.colours.black};

    &:hover {
      background-color: unset;
    }

    .text {
      color: ${({ theme }) => theme.colours.charcoalDust};
      font-size: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  &.divisor-top {
    border-top: 1px solid ${({ theme }) => theme.colours.spanishGrey};
  }

  &:hover {
    background-color: #f0f0f0;
  }

  .icon {
    margin-right: ${({ theme }) => theme.pxToRem(5)};
    font-size: ${({ theme }) => theme.pxToRem(16)};
  }

  .text {
    color: ${({ theme }) => theme.colours.darkToneInk};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
      `;
