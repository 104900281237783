import React, { Children, FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { TimelineCarouselProps } from './types';
import { CarouselNavigation, SectionHeading } from 'components/molecules';
import {
  TimelineCarouselComponent,
  TimelineCarouselFilters,
  TimelineCarouselSwiper,
} from './TimelineCarousel.styles';
import { Loading } from 'components/atoms';
import { useTerminologyConfig } from 'context/terminologyConfig';

SwiperCore.use([Navigation, Pagination]);

const TimelineCarousel: FC<TimelineCarouselProps> = ({
  children,
  error = false,
  filters,
  loading = false,
  uniqueId,
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const terminologyConfig = useTerminologyConfig();
  const numberOfSlides = Children.count(children);
  return (
    <TimelineCarouselComponent>
      <SectionHeading
        dark
        border
        text={`${terminologyConfig?.thirdPartySingularNaming} audit`}
      >
        {filters && (
          <TimelineCarouselFilters>{filters}</TimelineCarouselFilters>
        )}
      </SectionHeading>
      {loading ? (
        <Loading />
      ) : error ? (
        <p>Timeline could not be loaded</p>
      ) : (
        <TimelineCarouselSwiper>
          <Swiper
            navigation={{
              nextEl: `.custom-swiper-navigation-next-${uniqueId}`,
              prevEl: `.custom-swiper-navigation-prev-${uniqueId}`,
            }}
            loop={numberOfSlides > 6}
            slidesPerView={6}
            slidesPerGroup={6}
            spaceBetween={20}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            onRealIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
          >
            {React.Children.map(children, (message) => (
              <SwiperSlide>{message}</SwiperSlide>
            ))}
          </Swiper>
          {numberOfSlides > 6 && (
            <CarouselNavigation
              dark={true}
              numberOfSlides={Math.ceil(numberOfSlides / 6)}
              activeIndex={Math.floor(activeIndex / 6)}
              uniqueId={uniqueId}
              slidesPerPage={6}
              swiperInstance={swiperInstance}
            />
          )}
        </TimelineCarouselSwiper>
      )}
    </TimelineCarouselComponent>
  );
};

export default TimelineCarousel;
