import React, { PropsWithChildren, forwardRef } from 'react';
import { AnchorLink, RouterLink } from './Link.styles';

import { LinkTypes } from './types';

const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkTypes>>(
  ({ children, href, to, target, ...props }, ref) => {
    const { dark, ...rest } = props;
    if (to) {
      return (
        <RouterLink innerRef={ref} to={to} {...rest} dark={dark ? 1 : 0}>
          {children}
        </RouterLink>
      );
    }

    return (
      <AnchorLink ref={ref} href={href} target={target} {...props}>
        {children}
      </AnchorLink>
    );
  }
);

Link.displayName = 'Link';

export default Link;
