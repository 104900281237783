import { Button, Icon, Typography } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { FC } from 'react';
import {
  SuccessModalContent,
  SuccessContainer,
  SuccessActionsContainer,
} from './DocumentUploadModal.styles';
import { SuccessModalTypes } from './types';

const SuccessModal: FC<SuccessModalTypes> = ({
  isOpen,
  thirdPartySingularNaming,
  onClose,
}) => (
  <Modal
    removeSeparator
    removePadding
    isOpen={isOpen}
    onClose={() => onClose(true)}
  >
    <SuccessModalContent>
      <SuccessContainer>
        <Icon className="check-icon" icon="check" size="xxs" background />
        <Typography
          className="upload-info-text"
          value={`The document has been successfully uploaded and associated with the
      selected ${thirdPartySingularNaming} profile`}
          tag="p"
        />
      </SuccessContainer>
      <SuccessActionsContainer>
        <Button onClick={() => onClose(true)} className="save-button" secondary>
          Okay
        </Button>
      </SuccessActionsContainer>
    </SuccessModalContent>
  </Modal>
);

export default SuccessModal;
