import styled from 'styled-components';

export const SearchComponent = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: #fff;
  color: ${({ theme }) => theme.colours.darkToneInk};

  &,
  input {
    height: ${({ theme }) => theme.pxToRem(50)};
  }

  &:focus-within {
    box-shadow: 0 0 0 2px Highlight;
  }

  input {
    flex: 1;
    min-width: ${({ theme }) => theme.pxToRem(300)};
    padding: 0 ${({ theme }) => theme.spacing(15)} 0
      ${({ theme }) => theme.spacing(50)};
    appearance: none;
    border: none;
    background: none;
    color: inherit;
    font-family: 'Open Sans';
    font-size: ${({ theme }) => theme.pxToRem(15)};
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.pxToRem(50)};

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      opacity: 0.5;
    }
  }

  > span {
    position: absolute;
    top: 50%;
    left: ${({ theme }) => theme.spacing(15)};
    display: block;
    height: ${({ theme }) => theme.spacing(20)};
    margin-right: ${({ theme }) => theme.spacing(12)};
    color: inheit;
    pointer-events: none;
    transform: translateY(-50%);
  }

  svg {
    color: inherit;
  }
`;
