import React, { FC, useContext } from 'react';
import { SectionHeading } from 'components/molecules';
import {
  DocumentsContainer,
  RecordsLabel,
} from './DueDiligenceDocuments.styles';
import { FileFormat, FileInfo, getFileExtension } from 'helpers/documentHelper';
import { ToastContext, ToastContextProps } from 'toast';
import { ToastType } from 'components/molecules/toast/types';
import DocumentCard from 'components/molecules/document-card';
import ThreeDotsContextMenu from 'components/organisms/three-dots-context-menu';

interface DocumentsProps {
  documents: object;
}

const DueDiligenceDocuments: FC<DocumentsProps> = ({ documents }) => {
  const { openToast } = useContext<ToastContextProps>(ToastContext);

  const filesInfo = (documents || []) as FileInfo[];
  return (
    <section style={{ margin: 0 }}>
      <SectionHeading text={`Report attachments`} border></SectionHeading>
      <>
        <RecordsLabel>{filesInfo.length} records</RecordsLabel>
        <DocumentsContainer>
          {filesInfo.map((fileInfo) => (
            <DocumentCard
              key={fileInfo.id}
              fileType={
                getFileExtension(
                  fileInfo.fileDownloadName
                ).toLowerCase() as FileFormat
              }
              date={fileInfo.uploadedDate}
              category={fileInfo.category}
              extendedNames={true}
              name={fileInfo.name}
              actionComponent={
                <ThreeDotsContextMenu
                  fileInfo={fileInfo}
                  onFailView={(status) => {
                    switch (status) {
                      case 401:
                        openToast({
                          type: ToastType.Error,
                          message: `You don't have enough permissions to view documents`,
                        });
                        break;

                      default:
                        openToast({
                          type: ToastType.Error,
                          message:
                            'An error occurred while downloading the document',
                        });
                        break;
                    }
                  }}
                  onFailDownload={(status) => {
                    switch (status) {
                      case 401:
                        openToast({
                          type: ToastType.Error,
                          message: `You don't have enough permissions to download documents`,
                        });
                        break;

                      default:
                        openToast({
                          type: ToastType.Error,
                          message:
                            'An error occurred while downloading the document',
                        });
                        break;
                    }
                  }}
                  showDeleteOption={false}
                />
              }
            />
          ))}
        </DocumentsContainer>
      </>
    </section>
  );
};

export default DueDiligenceDocuments;
