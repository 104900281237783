import React from 'react';
import {
  MultiStepNavigationComponent,
  MultiStepNavigationItemComponent,
  CompletedIndicator,
  CurrentIndicator,
  NextIndicator,
} from './MultiStepNavigation.styles';
import { MultiStepNavigationProps } from './types';
import { ReactComponent as Check } from 'assets/svg/Icons/Check.svg';

// Navigation item visual indicator.
const renderIndicator = (
  status: 'completed' | 'current' | 'next',
  index: number
) => {
  switch (status) {
    case 'completed':
      return (
        <CompletedIndicator>
          <Check aria-hidden />
        </CompletedIndicator>
      );
    case 'current':
      return <CurrentIndicator />;
    case 'next':
      return <NextIndicator>{index}</NextIndicator>;
    default:
      return null;
  }
};

// Accessible navigation item label.
const indicatorLabel = (
  status: 'completed' | 'current' | 'next',
  index: number
) => {
  switch (status) {
    case 'completed':
      return `Step ${index + 1} completed`;
    case 'current':
      return `Current step`;
    case 'next':
      return `Step ${index + 1} incomplete`;
    default:
      return null;
  }
};

const MultiStepNavigation: React.FC<MultiStepNavigationProps> = ({
  activeStep = 0,
  viewedStep = 0,
  steps,
  onClick,
}) => (
  <MultiStepNavigationComponent>
    {steps.map((step, index) => {
      const status =
        viewedStep === index + 1
          ? 'current'
          : activeStep > index + 1
          ? 'completed'
          : 'next';
      return (
        <MultiStepNavigationItemComponent
          key={index}
          label={step.title}
          muted={status === 'next'}
          number={index}
          aria-label={indicatorLabel(status, index)}
          onClick={
            onClick && (status !== 'next' || activeStep === index + 1)
              ? () => onClick(index + 1)
              : null
          }
        >
          {renderIndicator(status, index + 1)}
          <span>{step.title}</span>
        </MultiStepNavigationItemComponent>
      );
    })}
  </MultiStepNavigationComponent>
);

export default MultiStepNavigation;
