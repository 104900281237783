import { useAuth } from 'auth';
import RelatedEntitiesFormModal, {
  ModalContextProvider,
} from 'components/organisms/related-entities-form-modal';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RelatedEntityModalFormValues } from 'components/organisms/related-entities-form-modal/types';

type RelatedEntitiesModalProps = {
  isOpen?: boolean;
  onClose?: (isComplete: boolean) => void;
  onSuccess?: (createdOrUpdatedResponse: Record<string, any>) => void;
  relatedEntity?: Record<string, any> | undefined;
  thirdPartyId: number;
  performScreening?: boolean;
  noIndividualRelatedEntitiesAllowed?: boolean;
  noBusinessRelatedEntitiesAllowed?: boolean;
  notifyToAnalist?: boolean;
  relatedEntityFieldsConfigs: {
    isIndividualRelationshipToThirdPartyMandatory: boolean;
    isCompanyRelationshipToThirdPartyMandatory: boolean;
    isIndividualDateOfBirthMandatory: boolean;
  };
};
const RelatedEntitiesModal: React.FC<RelatedEntitiesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  relatedEntity,
  thirdPartyId,
  performScreening = true,
  noIndividualRelatedEntitiesAllowed = false,
  noBusinessRelatedEntitiesAllowed = false,
  notifyToAnalist: notifyToAnalyst = false,
  relatedEntityFieldsConfigs,
}) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const history = useHistory();

  const getRelatedEntityBody = (formState: RelatedEntityModalFormValues) =>
    JSON.stringify({
      name: formState.name,
      thirdPartyId,
      type: formState.type,
      alternativeName: formState?.alternativeName || null,
      primaryCountry:
        formState.primaryCountry !== 'DEFAULT'
          ? formState.primaryCountry
          : null,
      addressLineOne: formState?.addressLineOne || null,
      zipCode: formState?.zipCode || null,
      city: formState?.city || null,
      county: formState?.city || null,
      countryTwo:
        formState.countryTwo !== 'DEFAULT' ? formState.countryTwo : null,
      countryThree:
        formState.countryThree !== 'DEFAULT' ? formState.countryThree : null,
      gender: formState.gender ? parseInt(formState.gender) : 2,
      dateOfBirth: formState?.dateOfBirth
        ? new Date(formState.dateOfBirth).toLocaleDateString('fr-CA') // we are using fr-CA to establish format yyyy-mm-dd since we were usig ISO standards
        : null,
      duns: formState?.duns ? parseInt(formState.duns) : null,
      linkType: formState?.linkType || null,
      registrationNumber: formState?.registrationNumber || null,
      swift: formState?.swift || null,
      passportNumber: formState?.passportNumber || null,
      nationalIDNumber: formState?.nationalIDNumber || null,
      nationality:
        formState?.nationality !== 'DEFAULT' ? formState.nationality : null,
      otherIDNumber: formState?.otherIDNumber || null,
      referenceFieldOne: formState?.referenceFieldOne || null,
      referenceFieldTwo: formState?.referenceFieldTwo || null,
      referenceFieldThree: formState?.referenceFieldThree || null,
    });

  const {
    trigger: triggerEditRelatedEntity,
    data: dataEdit,
    loading: loadingEdit,
  } = useFetch(`${vantageWebApi}/relatedEntity/${relatedEntity?.id}/edit`, {
    method: 'POST',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    onSuccess: onSuccess,
  });

  const {
    trigger: triggerCreateRelatedEntity,
    data: dataCrea,
    loading: loadingCreate,
  } = useFetch(
    `${vantageWebApi}/relatedEntity/create?notifyAnalyst=${notifyToAnalyst}`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: onSuccess,
    }
  );

  const setCountries = (data) => {
    const newData = { ...data };
    newData.countryTwo = data?.countryTwo;
    newData.countryThree = data?.countryThree;
    return newData;
  };

  const handleOnSubmit = (data: RelatedEntityModalFormValues) => {
    setCountries(data);
    if (relatedEntity) {
      triggerEditRelatedEntity({ body: getRelatedEntityBody(data) });
    } else {
      triggerCreateRelatedEntity({ body: getRelatedEntityBody(data) });
    }
  };

  const handleInitiateScreening = () => {
    history.push(
      `/initiate-related-entity-due-diligence/${dataEdit?.id || dataCrea?.id}`,
      {
        thirdPartyId: thirdPartyId,
      }
    );
  };

  return (
    <ModalContextProvider>
      <RelatedEntitiesFormModal
        isOpen={isOpen}
        title={
          relatedEntity ? 'Edit a related entity' : 'Create a related entity'
        }
        loading={loadingCreate || loadingEdit}
        onSubmit={handleOnSubmit}
        onClose={onClose}
        relatedEntity={relatedEntity}
        onInitiateScreening={handleInitiateScreening}
        performScreening={performScreening}
        noIndividualRelatedEntitiesAllowed={noIndividualRelatedEntitiesAllowed}
        noBusinessRelatedEntitiesAllowed={noBusinessRelatedEntitiesAllowed}
        relatedEntityFieldsConfigs={relatedEntityFieldsConfigs}
      />
    </ModalContextProvider>
  );
};

export default RelatedEntitiesModal;
