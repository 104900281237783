import React, { useEffect, useState } from 'react';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { TimelineCard } from 'components/molecules';
import { TimelineCarousel } from 'components/organisms';
import { useFetch } from 'hooks';
import { MultiSelect } from 'components/atoms';
import { useParams } from 'react-router';

const entityTypes = ['Individual', 'Business'];

const dueDilligenceTypes = [
  {
    value: 'SelfServiceScreening',
    name: 'Self Service Screening',
  },
  {
    value: 'ManagedServiceScreening',
    name: 'Managed Service Screening',
  },
  {
    value: 'ManagedServiceDueDiligence',
    name: 'Managed Service Due Diligence',
  },
];

const Audit = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const [filteredEntity, setFilteredEntity] = useState([]);
  const [filteredAuditEvent, setFilteredAuditEvent] = useState([]);
  const [filteredDueDiligence, setFilteredDueDiligence] = useState([]);
  const [filteredPerformedBy, setFilteredPerformedBy] = useState([]);
  const { data, loading, error, trigger, responseStatus } = useFetch(
    `${vantageWebApi}/thirdparty/search-audit?thirdPartyId=${id}`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  useEffect(() => {
    trigger({
      body: JSON.stringify({
        page: 1,
        pageSize: 5,
        filter: {
          auditEvent: filteredAuditEvent,
          dueDiligenceType: filteredDueDiligence,
          performedBy: filteredPerformedBy,
          entity: filteredEntity,
        },
      }),
    });
  }, [
    filteredAuditEvent,
    filteredDueDiligence,
    filteredPerformedBy,
    filteredEntity,
    trigger,
  ]);

  const { data: users } = useFetch(`${vantageWebApi}/account/team`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const auditEvents = [
    `${terminologyConfig?.thirdPartySingularNaming} - Created`,
    `${terminologyConfig?.thirdPartySingularNaming} - Modified`,
    `${terminologyConfig?.thirdPartySingularNaming} - Next review date applied`,
    `${terminologyConfig?.thirdPartySingularNaming} - Approval status changed`,
    `${terminologyConfig?.thirdPartySingularNaming} - Screening initiated`,
    `${terminologyConfig?.thirdPartySingularNaming} - Monitoring settings modified `,
    `${terminologyConfig?.thirdPartySingularNaming} - Review finalised`,
    `${terminologyConfig?.thirdPartySingularNaming} - Monitoring alert(s) received`,
    `${terminologyConfig?.thirdPartySingularNaming} - Review status modified`,
    `${terminologyConfig?.thirdPartySingularNaming} - Screening type converted`,
    `${terminologyConfig?.thirdPartySingularNaming} - Report ready for review`,
    `${terminologyConfig?.thirdPartySingularNaming} - Updated report ready for review`,
    `${terminologyConfig?.thirdPartySingularNaming} - Due Diligence report ordered`,
    'Related Entity - Created',
    'Related Entity - Modified',
    'Related Entity - Deleted',
    'Related Entity - Screening initiated',
    'Related Entity - Monitoring settings modified ',
    'Related Entity - Review finalised',
    'Related Entity - Monitoring alert(s) received',
    'Related Entity - Review status modified ',
    'Related Entity - Screening type converted ',
    'Related Entity - Screening report ordered',
    'Related Entity - Report ready for review',
    'Related Entity - Updated report ready for review',
  ];

  const parseAction = (action) => {
    if (action.includes('Third Party')) {
      return action.replace(
        'Third Party',
        terminologyConfig?.thirdPartySingularNaming
      );
    }
    return action;
  };

  const multiSelectUpdate = (state, setState, data) =>
    setState(
      data.checked
        ? [...state, data.value]
        : state.filter((item) => item !== data.value)
    );
  const filters = () => (
    <>
      <MultiSelect
        dark
        label="Entity"
        defaultValues={filteredEntity}
        items={entityTypes.map((entity) => ({
          value: entity,
          name: entity,
        }))}
        onChange={(filter) =>
          multiSelectUpdate(filteredEntity, setFilteredEntity, filter)
        }
      />
      <MultiSelect
        dark
        label="Audit event"
        defaultValues={filteredAuditEvent}
        items={auditEvents.map((event) => ({
          value: event,
          name: event,
        }))}
        onChange={(filter) =>
          multiSelectUpdate(filteredAuditEvent, setFilteredAuditEvent, filter)
        }
      />
      <MultiSelect
        dark
        label="Due dilligence type"
        items={dueDilligenceTypes}
        onChange={(filter) =>
          multiSelectUpdate(
            filteredDueDiligence,
            setFilteredDueDiligence,
            filter
          )
        }
      />
      <MultiSelect
        dark
        label="Performed by"
        items={[
          { value: 'Control Risks', name: 'Control Risks' },
          { value: 'Monitoring', name: 'Monitoring' },
          ...(users && users.length > 0
            ? users?.map((user) => ({
                value: user.firstName,
                name: `${user.firstName} ${user.lastName}`,
              }))
            : []),
        ]}
        onChange={(filter) =>
          multiSelectUpdate(filteredPerformedBy, setFilteredPerformedBy, filter)
        }
      />
    </>
  );
  return (
    <TimelineCarousel
      uniqueId="third-party-audit-timeline"
      filters={filters()}
      loading={loading}
      error={error || (responseStatus && responseStatus !== 200)}
    >
      {data?.results?.map((item, index) => (
        <TimelineCard
          key={`audit-${index}`}
          content={JSON.parse(item.content)}
          action={parseAction(item.auditEvent)}
          dateTime={item.performedDate}
          name={item?.name}
          screeningType={item?.dueDiligenceType}
          type={item?.type}
          performedBy={item?.performedBy}
          screeningLevel={JSON.parse(item.content)?.ReportLevel || ''}
        />
      ))}
    </TimelineCarousel>
  );
};

export default Audit;
