import styled from 'styled-components';

export const DocumentUploadModalComponent = styled.section``;

export const ModalContent = styled.section`
  padding: ${({ theme }) => theme.spacing(40)};
`;

export const UploadBoxContainer = styled.div<{ error }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => theme.spacing(30)};
  border: 2px dashed ${({ theme }) => theme.colours.spanishGrey};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  margin-bottom: ${({ theme }) => theme.spacing(20)};

  .upload-info-text {
    color: ${({ theme, error }) =>
      error ? theme.colours.redRampage : theme.colours.agedGray};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
    user-select: none;
    width: ${({ theme }) => theme.pxToRem(200)};
    text-align: center;
  }

  .upload-button {
    margin-top: ${({ theme }) => theme.spacing(8)};
    min-width: 166px;
  }
`;

export const LoadingContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(20)};
`;

export const PreviewContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${({ theme }) => theme.colours.redRampage};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  gap: ${({ theme }) => theme.spacing(6)};

  .risk-icon {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const MultipleErrorsContainer = styled.div`
  > * {
    &:first-child {
      margin-top: ${({ theme }) => theme.spacing(20)};
    }

    &:last-child {
      margin-bottom: ${({ theme }) => theme.spacing(20)};
    }
  }
`;

export const DetailsText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colours.charcoalDust};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 46px;
  margin-bottom: 76px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(40)};

  .save-button {
    min-width: 223px;
  }
`;

export const SuccessActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(40)};

  .save-button {
    min-width: 223px;
  }
`;

export const SuccessModalContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 250px;
  padding: ${({ theme }) => theme.spacing(40)};
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(16)};
  width: 100%;

  .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colours.swimmersPool};
    width: 30px;
    height: 30px;
  }

  .upload-info-text {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }
`;

export const LoadingUploadModalContent = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const LoadingUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(8)};
  width: 80%;

  .uploading-info-text {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }

  .uploading-remaining-text {
    color: ${({ theme }) => theme.colours.agedGray};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(12)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
  }
`;

export const ProgressBarContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.pxToRem(9)};
  width: 100%;
  background-color: ${({ theme }) => theme.colours.gallery};
  border-radius: ${({ theme }) => theme.pxToRem(50)};
`;

export const Progress = styled.div<{ completed }>`
  height: 100%;
  width: ${({ completed }) => completed}%;
  background-color: ${({ theme }) => theme.colours.crowberryBlue};
  border-radius: inherit;
  text-align: right;
`;

export const FormControlRow = styled.div`
  display: flex;
  justifycontent: space-between;
  gap: 20px;

  .formcontrol {
    flex: 1;
  }
`;
