import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContextType, AuthProviderProps, JwtPayload } from './types';
import { useConfig } from 'config';
import {
  isTokenExpired,
  refreshTokenOnDemand,
} from 'services/refreshTokenService';
import Loading from 'components/atoms/loading';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  margin: ${({ theme }) => theme.spacing(20)} 0;
  justify-content: center;
`;

const defaultContext: AuthContextType = {
  accessToken: null,
  userName: '',
  email: '',
  redirectUrl: null,
  setRedirectUrl: () => {},
  setToken: () => {},
  setRefreshToken: () => {},
  hasPermission: () => false,
  hasSubscription: () => false,
  logOut: () => {},
};

function parseJwt(token: string): JwtPayload {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    global.window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function getPermissions(token: string) {
  if (!token) {
    return null;
  }

  try {
    const parsedToken = parseJwt(token);
    const permissions = parsedToken.permissions.map((x) => parseInt(x));
    return permissions;
  } catch (error) {
    return [];
  }
}

function getSubscriptions(token: string) {
  if (!token) {
    return null;
  }

  try {
    const parsedToken = parseJwt(token);
    const parsedTokenSubscriptions = parsedToken.subscriptions;
    if (Array.isArray(parsedTokenSubscriptions)) {
      return parsedTokenSubscriptions.map((x) => parseInt(x));
    } else {
      return [parseInt(parsedTokenSubscriptions)];
    }
  } catch (error) {
    return [];
  }
}

function getGatewayUserId(token: string) {
  if (!token) {
    return null;
  }

  try {
    const parsedToken = parseJwt(token);
    const parsedTokenGatewayUserId = parsedToken.gateway_user_id;
    if (Array.isArray(parsedTokenGatewayUserId)) {
      return parsedTokenGatewayUserId.map((x) => parseInt(x));
    } else {
      return parsedTokenGatewayUserId;
    }
  } catch (error) {
    return [];
  }
}

function getUserName(token: string) {
  if (!token) {
    return '';
  }

  try {
    const parsedToken = parseJwt(token);
    return parsedToken.username;
  } catch (error) {
    return '';
  }
}

function getUserEmail(token: string) {
  if (!token) {
    return '';
  }

  try {
    const parsedToken = parseJwt(token);
    return parsedToken.name;
  } catch (error) {
    return '';
  }
}

/**
 * Context for managing authentication data.
 */
export const AuthContext = createContext<AuthContextType>(defaultContext);

/**
 * Provides a React context provider for authentication, initializing and providing the auth state.
 * @param {AuthProviderProps} props - Props for the AuthProvider component, including children.
 * @returns {JSX.Element} The provider component that wraps its children, providing them with access to the authentication context.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { accessTokenId, refreshTokenId, vantageWebApi, msalSubscriptionKey } =
    useConfig();
  const accessToken = localStorage.getItem(accessTokenId) || null;
  const refreshAccessToken = localStorage.getItem(refreshTokenId) || null;
  const [token, setToken] = useState<string | null>(accessToken);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(
    refreshAccessToken
  );
  const [loading, setLoading] = useState(true);
  const permissions = getPermissions(token);
  const subscriptions = getSubscriptions(token);
  const gatewayUserId = getGatewayUserId(token);
  const userName = getUserName(token);
  const email = getUserEmail(token);

  /**
   * Handles user logout by removing access and refresh tokens from storage.
   */
  const handleLogout = useCallback(() => {
    localStorage.removeItem(accessTokenId);
    localStorage.removeItem(refreshTokenId);
  }, [accessTokenId, refreshTokenId]);

  useEffect(() => {
    if (isTokenExpired(token)) {
      setLoading(true);
      refreshTokenOnDemand(
        vantageWebApi,
        {
          'Ocp-Apim-Subscription-Key': msalSubscriptionKey,
          'Content-Type': 'application/x-www-form-urlencoded',
          mode: 'no-cors',
        },
        {
          accessTokenId,
          refreshTokenId,
          refreshToken,
        },
        () => {
          handleLogout();
        }
      ).then((tokens) => {
        setToken(tokens?.accessToken);
        setRefreshToken(tokens?.refreshToken);
        setLoading(false);
      });
      return;
    }
    setLoading(false);
  }, [
    accessTokenId,
    handleLogout,
    msalSubscriptionKey,
    refreshToken,
    refreshTokenId,
    vantageWebApi,
    token,
  ]);

  /**
   * Checks if the current user has a specific permission.
   * @param {number} permission - The permission to check.
   * @returns {boolean} True if the user has the permission, false otherwise.
   */
  function hasPermission(permission: number): boolean {
    return permissions ? permissions.includes(permission) : false;
  }

  /**
   * Checks if the current user has a specific subscription.
   * @param {number} subscription - The subscription to check.
   * @returns {boolean} True if the user has the subscription, false otherwise.
   */
  function hasSubscription(subscription: number): boolean {
    return subscriptions ? subscriptions.includes(subscription) : false;
  }

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        accessToken: token,
        gatewayUserId,
        setToken,
        setRefreshToken,
        logOut: handleLogout,
        hasPermission,
        hasSubscription,
        userName,
        email,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

/**
 * Custom React hook for accessing the authentication context.
 * @returns {AuthContextType} The current authentication context, providing access and utility functions for managing authentication state.
 */
export const useAuth = () => useContext(AuthContext);
