export enum GatewaySubscriptionTypes {
  SelfServiceScreening = 1,
  SelfServiceUBO = 2,
  ManagedServiceScreening = 3,
  ManagedServiceUBO = 4,
  ManagedServiceDueDiligence = 5,
}

export enum GatewaySubscriptionTypeNames {
  SelfServiceScreening = 'SelfServiceScreening',
  SelfServiceUBO = 'SelfServiceUbo',
  ManagedServiceScreening = 'ManagedServiceScreening',
  ManagedServiceUBO = 'ManagedServiceUbo',
  ManagedServiceDueDiligence = 'ManagedServiceDueDiligence',
}
