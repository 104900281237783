export const InitialState = {
  name: '',
  alternativeName: '',
  primaryCountry: '',
  linkType: '',
  addressLineOne: '',
  zipCode: '',
  city: '',
  county: '',
  countryTwo: '',
  countryThree: '',
  duns: 0,
  registrationNumber: '',
  swift: '',
  gender: 0,
  dateOfBirth: '',
  nationality: '',
  passportNumber: '',
  nationalIDNumber: '',
  otherIDNumber: '',
  referenceFieldOne: '',
  referenceFieldTwo: '',
  referenceFieldThree: '',
};

export const FormReducer = (data, { type, payload }) => {
  switch (type) {
    case 'UPDATE_FORM_DATA':
      return {
        ...data,
        ...payload,
      };

    default:
      return data;
  }
};
