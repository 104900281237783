export const getThirdPartyConfiguration = async (
  vantageWebApi: string,
  headers: Record<string, any>,
  entityType: string
) => {
  const response = await fetch(
    `${vantageWebApi}/client/thirdparty-fields-configurations?entityType=${entityType}`,
    {
      method: 'GET',
      headers: headers,
    }
  );

  if (response.ok) {
    const configList = await response.json();
    return configList[
      entityType.toLowerCase()
    ] as ThirdPartyDataConfigDictionary;
  }
};

export type ThirdPartyDataConfigDictionary = Record<
  string,
  {
    isMandatory: boolean | null;
    customName: string | null;
    isEnabled: boolean | null;
    configurationValue: string | null;
  }
>;
