import React, { useEffect, FC } from 'react';
import FocusTrap from 'focus-trap-react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  ModalComponent,
  ModalOverlayComponent,
  ModalHeading,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalButtonsContainer,
  ModalSeparator,
} from './Modal.styles';
import { ModalOverlayProps, ModalProps } from './types';
import { Icon } from 'components/atoms';

const ModalOverlay: FC<ModalOverlayProps> = ({
  children,
  onClose,
  fullScreen = false,
  title,
  transparent,
  removePadding,
  isClosable = true,
  bodyContainerRef,
  customOverlayWidth = '',
  removeSeparator = false,
  removeBorderTop = false,
  scrollableBody = true,
}) => {
  useEffect(() => {
    if (!onClose) {
      return;
    }
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);
  return (
    <ModalOverlayComponent
      customOverlayWidth={customOverlayWidth}
      fullScreen={fullScreen}
      transparent={transparent}
      removeBorderTop={removeBorderTop}
    >
      {isClosable && (
        <ModalHeading compact={!!title}>
          {typeof title === 'string' ? <ModalTitle>{title}</ModalTitle> : title}
          <ModalClose
            type="button"
            data-testid="ModalOverlayCloseButton"
            aria-label="Close"
            onClick={onClose}
          >
            <Icon icon="close" aria-hidden size="xxs" />
          </ModalClose>
        </ModalHeading>
      )}
      <a href={'#'} aria-label={'#'} />
      {!removeSeparator ? <ModalSeparator /> : null}
      <ModalBody
        ref={bodyContainerRef}
        removePadding={removePadding}
        scrollable={scrollableBody}
      >
        {children}
      </ModalBody>
    </ModalOverlayComponent>
  );
};

const Modal: FC<ModalProps> = ({
  children,
  isOpen = false,
  onClose,
  title,
  fullScreen = false,
  opaqueOverlay = false,
  topContent,
  transparent,
  removePadding,
  bodyContainerRef,
  customOverlayWidth = '',
  isClosable = true,
  removeSeparator = false,
  removeBorderTop = false,
  scrollableBody = true,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
            }}
          >
            <ModalComponent
              fullScreen={fullScreen}
              opaqueOverlay={opaqueOverlay}
            >
              <ModalButtonsContainer>{topContent}</ModalButtonsContainer>
              <ModalOverlay
                customOverlayWidth={customOverlayWidth}
                title={title}
                onClose={onClose}
                fullScreen={fullScreen}
                transparent={transparent}
                removePadding={removePadding}
                bodyContainerRef={bodyContainerRef}
                isClosable={isClosable}
                removeSeparator={removeSeparator}
                removeBorderTop={removeBorderTop}
                scrollableBody={scrollableBody}
              >
                {children}
              </ModalOverlay>
            </ModalComponent>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
