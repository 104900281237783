import React, { FC } from 'react';
import { DateProps } from './types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const DateComponent: FC<DateProps> = ({
  dateTime,
  format = 'YYYY/MM/DD',
  additionalText = null,
}) => {
  // Invalid datetime strings will cause an exception.
  try {
    const userTz = dayjs.tz.guess();
    const tz = userTz != 'Etc/Unknown' ? userTz : 'Etc/UTC';
    const dateObject = new Date(dateTime);
    const dateString = dayjs.tz(dateObject, tz).format(format);

    return (
      <time dateTime={dateTime}>
        {dateString === 'Invalid Date' ? '' : dateString}
        {additionalText && ` ${additionalText}`}
      </time>
    );
  } catch (error) {
    return null;
  }
};

export default DateComponent;
