import React, { FC, useContext, useState } from 'react';
import { FormControl, Tooltip } from 'components/atoms';
import { FieldsContainer } from 'form/form.styles';
import Input from 'components/atoms/field-input';
import { useFormContext } from 'react-hook-form';
import { getData } from 'country-list';
import Select from 'components/atoms/field-select';
import { FormContext } from 'context/form';
import { ThirdPartyType } from 'types/thirdPartyType';
import { ReactComponent as InfoIcon } from './../../../assets/svg/Icons/Info.svg';
import PerformEntityResolution from 'components/molecules/perform-entity-resolution';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { GatewayPermissions } from 'auth/constants/permissions';
import EntityResolutionModal from 'components/organisms/entity-resolution-modal/EntityResolutionModal';
import { Organization } from 'components/organisms/entity-resolution-modal/types';

const countries = getData()
  .map(({ name, code }) => ({
    name,
    value: code,
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

interface EssentialInfoProps {
  setIsAdditionalInfoCollapsed: React.Dispatch<boolean>;
}

const EssentialInfo: FC<EssentialInfoProps> = ({
  setIsAdditionalInfoCollapsed,
}) => {
  const {
    register,
    setValue,
    formState: { errors, touchedFields },
    watch,
  } = useFormContext();

  const {
    formDispatch,
    formState: { formData, thirdPartyDataConfig },
  } = useContext(FormContext);

  const [isEntityResolutionModalOpen, setEntityResolutionModalOpen] =
    useState(false);

  const { hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();

  const isBusiness = formData?.type === ThirdPartyType.Business;

  const companyName = watch('name');
  const primaryCountry = watch('primaryCountry');

  const hasEntityResolutionPermission = hasPermission(
    GatewayPermissions.ThirdPartyLookup
  );

  const isEntityResolutionModalEnabled =
    hasPermission(GatewayPermissions.ThirdPartyLookup) &&
    companyName &&
    primaryCountry != 'DEFAULT';

  const populateFields = (organization: Organization) => {
    const dataToPopulate = {
      name: organization?.primaryName ?? null,
      duns: organization?.duns ?? null,
      swift: organization?.swift ?? null,
      registrationNumber:
        organization?.registrationNumbers[0]?.registrationNumber ?? null,
      addressLineOne:
        organization.primaryAddress?.streetAddress?.lineOne ?? null,
      zipCode: organization.primaryAddress?.postalCode ?? null,
      companyUrl: organization?.websiteAddress[0] ?? null,
    };

    formDispatch({
      type: 'SAVE_FORM_DATA',
      payload: {
        form: {
          name: null,
          duns: null,
          swift: null,
          registrationNumber: null,
          addressLineOne: null,
          zipCode: null,
          companyUrl: null,
        },
      },
    });

    formDispatch({
      type: 'SAVE_FORM_DATA',
      payload: {
        form: { ...dataToPopulate },
      },
    });

    setValue('name', null);
    setValue('duns', null);
    setValue('swift', null);
    setValue('registrationNumber', null);
    setValue('addressLineOne', null);
    setValue('zipCode', null);
    setValue('companyUrl', null);

    setValue('name', dataToPopulate.name);
    setValue('duns', dataToPopulate.duns);
    setValue('swift', dataToPopulate.swift);
    setValue('registrationNumber', dataToPopulate.registrationNumber);
    setValue('addressLineOne', dataToPopulate.addressLineOne);
    setValue('zipCode', dataToPopulate.zipCode);
    setValue('companyUrl', dataToPopulate.companyUrl);
  };

  const isValid = (field: string) => {
    return !errors?.[field]?.message && !touchedFields?.[field]?.message;
  };

  return (
    <FieldsContainer>
      <FormControl errorMessage={errors.name?.message}>
        <Input
          label={`${isBusiness ? 'Company' : 'Individual'} name`}
          id="name"
          name="name"
          register={register}
          fullWidth
          valid={isValid('name')}
          required={thirdPartyDataConfig.name.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.alternativeName?.message}>
        <Input
          label={`${
            isBusiness ? 'Company' : 'Individual'
          } name (alternative script)`}
          id="alternativeName"
          name="alternativeName"
          register={register}
          fullWidth
          valid={isValid('alternativeName')}
          required={thirdPartyDataConfig.alternativeName.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.primaryCountry?.message}>
        <Select
          label="Primary country"
          id="primaryCountry"
          register={register}
          options={countries}
          placeholder="Please select"
          placeholderValue="DEFAULT"
          fullWidth
          valid={isValid('primaryCountry')}
          required={thirdPartyDataConfig.primaryCountry.isMandatory}
        />
      </FormControl>
      {isBusiness && (
        <>
          <div style={{ margin: '25px 0 30px' }}>
            <PerformEntityResolution
              enabled={isEntityResolutionModalEnabled}
              onClick={() => setEntityResolutionModalOpen(true)}
              tooltipText={
                hasEntityResolutionPermission ? (
                  <>
                    <p>
                      The Company Lookup function can be used to quickly
                      auto-populate a new Third Party profile with additional
                      information you may not immediately have access to.
                    </p>
                    <br />
                    <br />
                    <p>
                      Once the minimum required information of Company Name and
                      Primary Country has been provided, you can select the
                      "Perform Company Lookup" function which will automatically
                      cross-check the input information against a global
                      database of registered companies. If one or more potential
                      matches are identified, they will be presented to you.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      The Company Lookup function is a supplementary service,
                      which when subscribed to, can be used to quickly
                      auto-populate a new Third Party profile with additional
                      information you may not immediately have access to.
                    </p>
                    <br />
                    <br />
                    <p>
                      Once enabled on your Gateway account, after the minimum
                      required information of Company Name and Primary Country
                      has been provided, you can select the "Perform Company
                      Lookup" function which will automatically cross-check the
                      input information against a global database of registered
                      companies. If one or more potential matches are
                      identified, they will be presented to you.
                    </p>
                  </>
                )
              }
            />
          </div>
          {isEntityResolutionModalOpen && (
            <EntityResolutionModal
              isOpen={true}
              onClose={() => setEntityResolutionModalOpen(false)}
              onCancel={() => setEntityResolutionModalOpen(false)}
              onProceed={(organization) => {
                setEntityResolutionModalOpen(false);
                populateFields(organization);
                setIsAdditionalInfoCollapsed(false);
              }}
              searchPayload={{
                name: companyName,
                countryISOAlpha2Code: primaryCountry,
              }}
            />
          )}
        </>
      )}
      <FormControl errorMessage={errors.internalId?.message}>
        <Input
          label={terminologyConfig?.internalIdSingularNaming}
          id="internalId"
          name="internalId"
          register={register}
          fullWidth
          valid={isValid('internalId')}
          required={thirdPartyDataConfig.internalId.isMandatory}
        />
      </FormControl>
      {isBusiness && (
        <FormControl errorMessage={errors.duns?.message}>
          <Input
            label="DUNS"
            id="duns"
            name="duns"
            register={register}
            fullWidth
            valid={isValid('duns')}
            required={thirdPartyDataConfig.duns.isMandatory}
            labelEndIcon={
              <Tooltip
                trigger="click"
                tooltipText={
                  (
                    <>
                      DUNS is a unique nine-digit identification number assigned
                      to businesses to help distinguish one business from
                      another.
                    </>
                  ) as any
                }
                hideArrow
                placement="right-start"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ]}
              >
                <span style={{ marginLeft: 5 }}>
                  <InfoIcon className="clickable" />
                </span>
              </Tooltip>
            }
          />
        </FormControl>
      )}
    </FieldsContainer>
  );
};

export default EssentialInfo;
