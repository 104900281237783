import styled from 'styled-components';

interface DropdownType {
  dark?: boolean;
  isOpen: boolean;
}

export const MultiSelectComponent = styled.section<DropdownType>`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(240)};

  button {
    width: 100%;
    border-color: ${({ dark = false, theme }) =>
      dark ? theme.colours.white : theme.colours.crowberryBlue};
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.white : theme.colours.crowberryBlue};

    svg {
      color: ${({ dark = false, theme }) =>
        dark ? theme.colours.swimmersPool : 'currentColor'};
      transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
      transition: all 0.25s ease-in-out;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colours.crowberryBlue};
      svg {
        color: white;
      }
    }
  }
`;

export const Dropwdown = styled.div<DropdownType>`
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: ${({ theme }) => theme.pxToRem(10)};
  min-width: ${({ theme }) => theme.pxToRem(240)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  transition: opacity 0.25s ease-in-out;
  z-index: ${({ isOpen }) =>
    isOpen ? ({ theme }) => theme.zIndex.level_1 : -1};
  ${({ isOpen }) => !isOpen && 'pointer-events: none'};
  overflow: hidden;
`;

export const Menu = styled.div`
  background-color: ${({ theme }) => theme.colours.white};
  padding: ${({ theme }) => `${theme.pxToRem(30)} ${theme.pxToRem(20)}`};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  max-height: ${({ theme }) => theme.pxToRem(400)};
  border: 1px solid ${({ theme }) => theme.colours.charcoalDust};
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
