import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, FormControl } from 'components/atoms';
import { FormHeader } from 'form/form.styles';
import { ButtonWrapper } from '../index.styles';
import { RelatedEntityModalContext } from '../../Context';
import { RelatedEntityModalFormValues } from '../../types';

type StepThreeProps = {
  onSubmit?: (relatedEntity: RelatedEntityModalFormValues) => void;
  setFormTitle: (title: string) => void;
};

const StepThree: React.FC<StepThreeProps> = ({ onSubmit, setFormTitle }) => {
  const history = useHistory();
  const { state: formState } = useContext(RelatedEntityModalContext);

  useEffect(() => {
    setFormTitle('Screen related entity?');
  }, [setFormTitle]);

  return (
    <FormControl>
      <FormHeader>Do you want to screen this related entity now?</FormHeader>
      <ButtonWrapper>
        <Button secondary onClick={() => onSubmit && onSubmit(formState)}>
          Initiate screening
        </Button>
        <Button outline text onClick={() => history.go(0)}>
          No
        </Button>
      </ButtonWrapper>
    </FormControl>
  );
};

export default StepThree;
