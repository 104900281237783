import React, { FC } from 'react';
import { MessageComponent, MessageMain } from './Message.styles';
import { MessageProps } from './types';
import { Icon, Typography } from 'components/atoms';

const Message: FC<MessageProps> = ({
  cta,
  dark = false,
  icon,
  highlighted = false,
  message,
  large = false,
}) => (
  <MessageComponent dark={dark} highlighted={highlighted} large={large}>
    <MessageMain>
      {icon && <Icon icon={icon} size={large ? 'sm' : 'xxs'} aria-hidden />}
      <Typography tag="p" value={message} />
    </MessageMain>
    {cta ? cta : null}
  </MessageComponent>
);

export default Message;
