import styled from 'styled-components';

export const IconButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.colours.spanishGrey : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colours.spanishGrey};
  }
`;

export const ContextMenu = styled.div`
  z-index: 5;
  width: 173px;
  position: absolute;
  top: 30px;
  left: -170px;
  background-color: ${({ theme }) => theme.colours.white};
  border: 1px solid ${({ theme }) => theme.colours.concreteGray};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
`;

export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.pxToRem(10)} ${theme.pxToRem(12)}`};
  cursor: pointer;
  gap: 8px;

  &:first-child {
    border-top-right-radius: ${({ theme }) => theme.pxToRem(8)};
    border-top-left-radius: ${({ theme }) => theme.pxToRem(8)};
  }

  &:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.pxToRem(8)};
    border-bottom-left-radius: ${({ theme }) => theme.pxToRem(8)};
  }

  &:hover {
    background-color: #f0f0f0;
  }

  .icon {
    margin-right: ${({ theme }) => theme.pxToRem(5)};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    fill: red;
  }

  .text {
    color: ${({ theme }) => theme.colours.darkToneInk};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;

    &.danger {
      color: ${({ theme }) => theme.colours.redRampage};
    }
  }
`;
