import React from 'react';
import { Link, Link as StyledLink, Typography } from 'components/atoms';
import { AppLayout } from 'layouts';
import { FixedWidthButton, Section } from './Unauthorised-styles';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

function Unauthorised() {
  const terminologyConfig = useTerminologyConfig();
  return (
    <AppLayout
      topBarSlot={
        <StyledLink to="/" dark>
          Back to {terminologyConfig?.thirdPartySingularNaming} manager
        </StyledLink>
      }
    >
      <Section>
        <Typography tag="h1" value="Unauthorised" />
        <p>
          You are not authorised to access this&nbsp;
          {terminologyConfig?.thirdPartySingularNaming} profile.
          <br></br>
          <br></br>
          Only the {terminologyConfig?.internalOwnerPluralNaming} of this&nbsp;
          {terminologyConfig?.thirdPartySingularNaming} or users with Admin
          <br></br>
          permissions can access this&nbsp;
          {terminologyConfig?.thirdPartySingularNaming} profile.
        </p>
        <Link to="/third-party">
          <FixedWidthButton secondary type="button">
            Return home
          </FixedWidthButton>
        </Link>
      </Section>
    </AppLayout>
  );
}

export default withAITracking(reactPlugin, Unauthorised);
