import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import { useTerminologyConfig } from 'context/terminologyConfig';
import {
  DueDiligenceComponent,
  ViewCase,
  EndlessIcon,
  ActionMenuWrapper,
} from './DueDiligence.styles';
import { Date, Link, Loading } from 'components/atoms';
import { SectionHeading, Pagination, ActionMenu } from 'components/molecules';
import { SimpleTable } from 'pages/third-party-profile/ThirdPartyProfile.styles';
import { statusMapper } from 'services/status-mapper';
import { GatewayPermissions } from 'auth/constants/permissions';
import { ActionMenuActions } from './types';
import generateTheme from 'theme';
import DueDiligenceDeleteModal from './due-diligence-delete-modal';

type DueDiligenceProps = {
  thirdPartyId: string;
};

const DueDiligence: React.FC<DueDiligenceProps> = () => {
  const { accessToken, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 5;
  const [screeningCaseToBeDeleted, setScreeningCaseToBeDeleted] =
    useState<string>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isMDD, setIsMDD] = useState(null);

  const { data, loading, error, trigger, responseStatus } = useFetch(
    `${vantageWebApi}/thirdParty/${id}/screenings`,
    {
      method: 'GET',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const canDeleteScreeningCase: boolean = hasPermission(
    GatewayPermissions.DeleteThirdParty
  );

  const canViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setScreeningCaseToBeDeleted(null);
  };

  function getPermittedActions(
    id: string = null,
    dueDiligence: string = null
  ): ActionMenuActions {
    let actions = [];

    if (canDeleteScreeningCase) {
      actions.push({
        name: 'Delete',
        onClick: () => {
          if (id) {
            setScreeningCaseToBeDeleted(id);
            setDeleteModalOpen(true);
          }
          if (dueDiligence) {
            setIsMDD(dueDiligence === 'Due Diligence');
          }
        },
      });
    }

    return actions;
  }

  const theme = generateTheme();

  useEffect(() => {
    trigger({
      queryParams: {
        page: currentPage,
        pageSize: PAGE_SIZE,
      },
    });
  }, [currentPage, trigger, id, PAGE_SIZE]);

  return (
    <DueDiligenceComponent>
      <SectionHeading
        text={`${terminologyConfig?.thirdPartySingularNaming} due diligence`}
      />
      {loading || error || (responseStatus && responseStatus !== 200) ? (
        loading ? (
          <Loading />
        ) : (
          <p>Error loading due dilligence data.</p>
        )
      ) : (
        <>
          <SimpleTable cellSpacing="0">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Initiated</th>
                <th style={{ width: '25%' }}>Diligence Type</th>
                <th style={{ width: '20%' }}>Level</th>
                {canViewTaskList && <th>Review status</th>}
                <th></th>
                {canDeleteScreeningCase && <th></th>}
              </tr>
            </thead>
            <tbody>
              {data?.results?.map(({ screeningDueDiligenceDetails }) => (
                <tr key={screeningDueDiligenceDetails?.screeningId}>
                  <td>
                    <Date
                      dateTime={screeningDueDiligenceDetails?.ordered}
                      format="Do MMM, YYYY, HH:mm"
                    />
                  </td>
                  <td>
                    {screeningDueDiligenceDetails?.description}{' '}
                    {screeningDueDiligenceDetails?.hasMonitoring && (
                      <EndlessIcon />
                    )}
                  </td>
                  <td>{screeningDueDiligenceDetails?.level}</td>
                  {canViewTaskList && (
                    <td>
                      <Link
                        to={`/third-party/screening/${screeningDueDiligenceDetails?.screeningId}`}
                      >
                        {statusMapper(
                          screeningDueDiligenceDetails?.status,
                          screeningDueDiligenceDetails?.state,
                          screeningDueDiligenceDetails?.level,
                          screeningDueDiligenceDetails?.reportStatus
                        )}
                      </Link>
                    </td>
                  )}
                  <td>
                    <ViewCase
                      to={`/third-party/screening/${screeningDueDiligenceDetails?.screeningId}`}
                    >
                      View case
                    </ViewCase>
                  </td>

                  {getPermittedActions().length > 0 && (
                    <ActionMenuWrapper verticalAlign="middle">
                      <ActionMenu
                        actions={getPermittedActions(
                          screeningDueDiligenceDetails?.screeningId,
                          screeningDueDiligenceDetails?.description
                        )}
                        name={`Manage third party case ${screeningDueDiligenceDetails?.screeningId}`}
                        ellipsisColour={theme.colours.pacificBlue}
                      ></ActionMenu>
                    </ActionMenuWrapper>
                  )}
                </tr>
              ))}
            </tbody>
          </SimpleTable>
          <Pagination
            setCurrentPage={setCurrentPage}
            totalPages={data?.pageCount}
            currentPage={currentPage}
            onPageChange={(change) => {
              setCurrentPage(change.currentPage);
            }}
          />
        </>
      )}
      {canDeleteScreeningCase && (
        <DueDiligenceDeleteModal
          isOpen={deleteModalOpen}
          onClose={closeDeleteModal}
          screeningId={screeningCaseToBeDeleted}
          isMDD={isMDD}
          thirdPartyId={id}
        />
      )}
    </DueDiligenceComponent>
  );
};

export default DueDiligence;
