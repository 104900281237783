import React from 'react';
import { FieldError } from 'react-hook-form';
import { Props } from './types';

const Typography: React.FC<Props> = ({
  tag = 'p',
  value = '',
  className,
  styles,
}: Props) => {
  const availableTags = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'blockquote',
    'q',
  ];
  const checkTag = () => (availableTags.includes(tag) ? tag : 'p');

  // func to manipulate value if needed - allows flexibility down the line
  const manipulateValue = (): string | FieldError => value;

  return React.createElement(checkTag(), { className, style: styles }, [
    manipulateValue(),
  ]);
};

export default Typography;
