import styled from 'styled-components';

export const LoginForm = styled.div`
  max-width: ${({ theme }) => theme.spacing(607.5)};

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing(49)};
  }

  form {
    max-width: ${({ theme }) => theme.spacing(420)};

    > * {
      margin-bottom: ${({ theme }) => theme.spacing(20)};
    }

    input[type='text'],
    input[type='password'] {
      width: 100%;
    }

    button {
      width: ${({ theme }) => theme.spacing(190)};
    }
  }

  .support-links {
    max-width: ${({ theme }) => theme.spacing(420)};
    border-top: 1px solid ${({ theme }) => theme.colours.spanishGrey};
    margin-top: ${({ theme }) => theme.spacing(30)};
    padding-top: ${({ theme }) => theme.spacing(30)};
    color: ${({ theme }) => theme.colours.charcoalDust};

    .bold {
      margin-bottom: ${({ theme }) => theme.spacing(12)};
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: 1.714;
    }

    a {
      display: block;
    }

    a + a {
      margin-top: ${({ theme }) => theme.spacing(10)};
    }
  }

  & .login-error {
    max-width: ${({ theme }) => theme.spacing(420)};
    margin-top: ${({ theme }) => theme.spacing(24)};
    margin-bottom: ${({ theme }) => theme.spacing(24)};
    color: ${({ theme }) => theme.colours.redRampage};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const ErrorMessage = styled.p`
  margin-top: 0;
  font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.light};
  color: ${({ theme }) => theme.colours.redRampage};
`;

export const LoadingScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colours.sparklingSnow};

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
`;
