import React, { useState } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { useLocation, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { urlParams } from 'services/url-params';

import { Button, FormControl, FieldInput, Link } from 'components/atoms';

import { CreatePasswordContainer, ResponseMessage } from './Register.styles';

import LoginLayout from 'layouts/login-layout';

import { useConfig } from 'config';

import { ResetPasswordResponse } from './types';

function ForgotPassword() {
  const { vantageWebApi, msalSubscriptionKey } = useConfig();

  const [resetPasswordResponse, setResetPasswordResponse] =
    useState<ResetPasswordResponse>(null);
  const [registering, setRegistering] = useState(false);

  const { search: queryString } = useLocation();

  // manually parse params to prevent stripping of special characters
  let { email } = urlParams(queryString);
  const { token } = urlParams(queryString);
  email = decodeURIComponent(email);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('You must enter a password')
      .min(14, 'Password must be at least 14 characters long.'),
    passwordConfirm: yup
      .string()
      .test(
        'passwords-match',
        'Passwords do not match. Please try again',
        function (value) {
          return this.parent.password === value;
        }
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isValid, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      emailAddress: email,
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = async (formData) => {
    setRegistering(true);
    const response = await fetch(`${vantageWebApi}/account/resetpassword`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        token,
        password: formData?.password,
      }),
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
      },
    });

    if (response.status === 200) {
      setResetPasswordResponse('SUCCESS');
    } else {
      setResetPasswordResponse('FAIL');
      setRegistering(false);
    }
  };

  if (!email || !token) {
    return <Redirect to="/login" />;
  }

  const cTAStyle = { fontSize: '1.3rem' };

  return (
    <LoginLayout>
      <CreatePasswordContainer>
        <h1>Vantage Gateway Registration</h1>
        <p style={cTAStyle}>
          Please create a password to complete the registration process.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FieldInput
              name="emailAddress"
              id="emailAddress"
              disabled
              register={register}
              placeholder="Email Address"
              label="Email Address"
              fullWidth={true}
            />
          </FormControl>
          <FormControl errorMessage={errors?.password?.message}>
            <FieldInput
              name="password"
              id="password"
              required
              register={register}
              valid={errors?.password?.message && touchedFields.password}
              placeholder="Password"
              label="Password"
              fullWidth={true}
              type="password"
            />
          </FormControl>
          <FormControl errorMessage={errors?.passwordConfirm?.message}>
            <FieldInput
              name="passwordConfirm"
              id="passwordConfirm"
              required
              register={register}
              valid={
                errors?.passwordConfirm?.message &&
                touchedFields.passwordConfirm
              }
              placeholder="Confirm Password"
              label="Confirm Password"
              fullWidth={true}
              type="password"
            />
          </FormControl>
          <Button
            type="submit"
            disabled={!dirtyFields || (dirtyFields && !isValid) || registering}
          >
            Register
          </Button>
        </form>
        {resetPasswordResponse !== null && (
          <ResponseMessage $responseType={resetPasswordResponse}>
            {resetPasswordResponse === 'SUCCESS' && (
              <span style={cTAStyle}>
                Your password has been succesfully created.{' '}
                <Link
                  style={{
                    fontWeight: 'bold',
                    ...cTAStyle,
                  }}
                  to="/login"
                >
                  Login
                </Link>{' '}
                to Vantage Gateway with your new password.
              </span>
            )}
            {resetPasswordResponse === 'FAIL' && (
              <>Something went wrong, please try again later.</>
            )}
          </ResponseMessage>
        )}
        <hr />
        <h2>Still need help?</h2>
        <Button as="a" href="mailto:vantagesupport@controlrisks.com" outline>
          Contact support
        </Button>
        <Link to="/login">Back to login page</Link>
      </CreatePasswordContainer>
    </LoginLayout>
  );
}

export default withAITracking(reactPlugin, ForgotPassword);
