import { isVerificationDateValid } from './verificationDateHelper';

export interface Document {
  id: string;
  name: string;
  category: string;
  categoryId: number;
  type: string;
  date: string;
  verificationDate: string | null;
  size: number;
  isOptionalDocument?: boolean;
}

export interface FileInfo {
  id: string;
  name: string;
  category: string;
  categoryId: number;
  type: string;
  uploadedDate: string;
  verificationDate: string | null;
  size: number;
  uploadedBy: string;
  fileDownloadName: string;
  extension: string;
  isChecked?: boolean;
}

export enum FileFormat {
  PNG = 'png',
  JPEG = 'jpeg',
  JPG = 'jpg',
  CSV = 'csv',
  PDF = 'pdf',
  DOCX = 'docx',
  XLSX = 'xlsx',
  ZIP = 'zip',
}

export const calculateFileSize = (size: number): string => {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getFileExtension = (filename: string): string => {
  const regex = /(?:\.([^.]+))?$/;
  const match = regex.exec(filename);
  if (match) {
    return match[1];
  }
  return '';
};

export const validateRequiredDocumentData = (data) => {
  let tempData = { ...data };
  for (let key in tempData) {
    const categoryArr = tempData[key].mandatory;
    let valid = true;

    for (let i = 0; i < categoryArr.length; i++) {
      const required = categoryArr[i].required;

      if (required) {
        if (categoryArr[i].documents.length === 0) {
          categoryArr[i].valid = false;
          valid = false;
        } else {
          categoryArr[i].valid = true;
        }
      }
    }
    tempData[key].valid = valid;
  }
  return tempData;
};

export const validateNoDuplicatedDocumentData = (data) => {
  let tempData = { ...data };
  const documentNames = new Set();
  for (let key in tempData) {
    const mandatoryCategoryArr = tempData[key].mandatory;
    const optionalCategoryArr = tempData[key].optional;

    let valid = true;

    for (
      let categoryIdx = 0;
      categoryIdx < mandatoryCategoryArr.length;
      categoryIdx++
    ) {
      const documents = mandatoryCategoryArr[categoryIdx].documents;
      for (let docIdx = 0; docIdx < documents.length; docIdx++) {
        const documentName = documents[docIdx].name;

        if (documentNames.has(documentName)) {
          mandatoryCategoryArr[categoryIdx].valid = false;
          valid = false;
        } else {
          mandatoryCategoryArr[categoryIdx].valid = true;
        }

        documentNames.add(documentName);
      }
    }

    for (
      let categoryIdx = 0;
      categoryIdx < optionalCategoryArr.length;
      categoryIdx++
    ) {
      const documents = optionalCategoryArr[categoryIdx].documents;
      for (let docIdx = 0; docIdx < documents.length; docIdx++) {
        const documentName = documents[docIdx].name;

        if (documentNames.has(documentName)) {
          optionalCategoryArr[categoryIdx].valid = false;
          valid = false;
        } else {
          optionalCategoryArr[categoryIdx].valid = true;
        }

        documentNames.add(documentName);
      }
    }

    tempData[key].valid = valid;
  }

  return tempData;
};

export const validateVerificationDateDocumentData = (data) => {
  let tempData = { ...data };
  for (let key in tempData) {
    const mandatoryCategoryArr = tempData[key].mandatory;

    let valid = true;

    for (
      let categoryIdx = 0;
      categoryIdx < mandatoryCategoryArr.length;
      categoryIdx++
    ) {
      const documents = mandatoryCategoryArr[categoryIdx].documents;
      for (let docIdx = 0; docIdx < documents.length; docIdx++) {
        const verificationDateEnabled =
          mandatoryCategoryArr[categoryIdx].verificationDateEnabled;

        const verificationDateConfig = verificationDateEnabled
          ? mandatoryCategoryArr[categoryIdx].verificationDateConfig
          : null;

        const verificationDate = documents[docIdx].verificationDate;

        if (!verificationDateConfig) {
          mandatoryCategoryArr[categoryIdx].valid = true;
          break;
        }

        if (!verificationDate) {
          mandatoryCategoryArr[categoryIdx].valid = false;
          valid = false;
          break;
        }

        const innerValid = isVerificationDateValid(
          verificationDateConfig,
          verificationDate
        );
        mandatoryCategoryArr[categoryIdx].valid = innerValid;
        valid = innerValid;
      }
    }

    tempData[key].valid = valid;
  }

  return tempData;
};
