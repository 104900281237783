import React, { createContext, useReducer } from 'react';
import { initialState, reducer } from './reducer';

import { FormContextTypes } from './types';

export const FormContext = createContext<FormContextTypes>({
  formState: initialState,
  formDispatch: () => null,
});

export const FormProvider = ({ children }) => {
  const [formState, formDispatch] = useReducer(reducer, initialState);

  return (
    <FormContext.Provider
      value={{
        formState,
        formDispatch,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
