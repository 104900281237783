import React, { FC } from 'react';
import { Wrapper } from './FieldInput.styles';
import { FieldInputProps } from './types';

const FieldInput: FC<FieldInputProps> = ({
  label,
  name,
  valid,
  register,
  id = '',
  type = 'text',
  disabled,
  required,
  placeholder = '',
  onChange,
  onBlur,
  fullWidth = false,
  labelEndIcon,
}: FieldInputProps) => {
  const checkValidationIsBool = typeof valid === 'boolean';

  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="required bold"> *</span>}
          {labelEndIcon}
        </label>
      )}
      <Wrapper
        {...(checkValidationIsBool
          ? { className: !valid ? 'invalid' : '' }
          : {})}
        $fullWidth={fullWidth}
      >
        <input
          {...(register ? { ...register(id, { required }) } : {})}
          {...(disabled ? { disabled } : {})}
          {...(onChange
            ? {
                onChange: (event: any) =>
                  onChange({ value: event.currentTarget.value, name: id }),
              }
            : {})}
          {...(onBlur
            ? {
                onBlur: (event: any) =>
                  onBlur({ value: event.currentTarget.value, name: id }),
              }
            : {})}
          label={label}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
        />
      </Wrapper>
    </>
  );
};

export default FieldInput;
