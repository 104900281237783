import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Dropwdown,
  Menu,
  MultiSelectComponent,
  ButtonContent,
} from './MultiSelect.styles';
import { MultiSelectTypes } from './types';
import Button from 'components/atoms/button/Button';
import FieldCheckbox from 'components/atoms/field-checkbox/FieldCheckbox';
import Icon from 'components/atoms/icon/Icon';

const MultiSelect: FC<MultiSelectTypes> = ({
  dark = false,
  disabled,
  defaultValues = [],
  label,
  items,
  onChange,
}: MultiSelectTypes) => {
  const [selected, setSelected] = useState(defaultValues);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <MultiSelectComponent ref={dropdownRef} dark={dark} isOpen={isOpen}>
      <Button
        disabled={disabled}
        color={selected.length === 0 ? 'secondary' : 'primary'}
        aria-haspopup
        outline
        onClick={() => setIsOpen(!isOpen)}
      >
        <ButtonContent>
          <span>{label}</span>
          <Icon icon="chevron-down" size="xs" />
        </ButtonContent>
      </Button>
      <Dropwdown isOpen={isOpen}>
        <Menu>
          {items &&
            items.map(({ value, name }, index) => (
              <div key={`${index}-${value}`}>
                <FieldCheckbox
                  value={value}
                  ariaLabel={name}
                  id={value}
                  label={name}
                  name={name}
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checked) {
                      setSelected([
                        ...selected,
                        ...[{ value, name, checked: true }],
                      ]);
                      onChange({ value, name, checked: true });
                      return;
                    }
                    setSelected(
                      selected.filter((selected) => selected.value !== value)
                    );
                    onChange({ value, name, checked: false });
                  }}
                ></FieldCheckbox>
              </div>
            ))}
        </Menu>
      </Dropwdown>
    </MultiSelectComponent>
  );
};

export default MultiSelect;
