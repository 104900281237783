import styled from 'styled-components';
export const UploadedItemName = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
`;
export const UploadedItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 5px;
`;

export const IconButton = styled.button`
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colours.swimmersPool};
  }
`;
