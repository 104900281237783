import styled from 'styled-components';

export const PaginationComponent = styled.nav`
  display: flex;
`;

interface ArrowButtonTypes {
  $direction: string;
}

export const ArrowButton = styled.button<ArrowButtonTypes>`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  svg {
    transform: translateY(${({ theme }) => theme.pxToRem(2)});
  }
`;

export const PaginationItemList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.spacing(10)};
  list-style-type: none;
  margin: 0 ${({ theme }) => theme.pxToRem(23)};
  padding: 0;
`;

export const PaginationItem = styled.li``;

interface PaginationItemButtonTypes {
  $selected: boolean;
}

export const PaginationItemButton = styled.button<PaginationItemButtonTypes>`
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
  border-radius: 3px;
  background: ${({ $selected, theme }) =>
    $selected ? theme.colours.swimmersPool : theme.colours.white};
  padding: 0;
  margin: 0;
  width: ${({ theme }) => theme.pxToRem(43)};
  height: ${({ theme }) => theme.pxToRem(43)};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  font-weight: bold;
  color: ${({ $selected, theme }) =>
    $selected ? theme.colours.white : theme.colours.crowberryBlue};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colours.swimmersPool};
    color: ${({ theme }) => theme.colours.white};
  }
`;
