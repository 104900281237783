import React, { createContext, useContext, useMemo } from 'react';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';

import Loading from 'components/atoms/loading';

import { TerminologyConfigContextType } from './types';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  margin: ${({ theme }) => theme.spacing(20)} 0;
  justify-content: center;
`;

const defaultContext: TerminologyConfigContextType = {
  thirdPartySingularNaming: 'Third party',
  thirdPartyPluralNaming: 'Third parties',
  internalOwnerSingularNaming: 'Internal owner',
  internalOwnerPluralNaming: 'Internal owners',
  internalIdSingularNaming: 'Internal ID',
};

export const TerminologyConfigContext = createContext(defaultContext);

export const TerminologyConfigProvider = ({ children }) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const {
    loading,
    error,
    data: terminologyConfig,
  } = useFetch<TerminologyConfigContextType>(
    `${vantageWebApi}/client/naming-conventions`,
    {
      initialState: { loading: !!accessToken },
      lazy: !accessToken,
      method: 'GET',
      headers,
    }
  );

  if (error) {
    return;
  }
  return (
    <TerminologyConfigContext.Provider value={terminologyConfig}>
      {loading ? (
        <LoadingContainer>
          {error ? (
            <h1>An unexpected error occurred. Please try again.</h1>
          ) : (
            <Loading />
          )}
        </LoadingContainer>
      ) : (
        children
      )}
    </TerminologyConfigContext.Provider>
  );
};

/**
 * @function useTerminologyConfig
 * @description Custom hook for accessing the terminology configuration context.
 * Facilitates the reuse of the context in components and avoids repeated imports of useContext.
 * @returns {TerminologyConfigContextType} The current terminology configuration.
 */
export const useTerminologyConfig = () => useContext(TerminologyConfigContext);
