import styled from 'styled-components';

export const ExpediteIconBox = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.pxToRem(5)} 0
    ${({ theme }) => theme.pxToRem(20)} ${({ theme }) => theme.pxToRem(20)};
`;

export const ExpediteContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.pxToRem(30)};
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
`;
