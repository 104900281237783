import styled, { css } from 'styled-components';

export const DescriptionListComponent = styled.dl``;

export const DescriptionListItem = styled.div<{
  spaced?: boolean;
  titleFontWeight?: string;
  valueFontWeight?: string;
  detailsMargin?: boolean;
  detailsFlexDirection?: string;
  fullInformation?: boolean;
  truncateText?: boolean;
}>`
  display: flex;
  flex-direction: ${({ detailsFlexDirection = 'row' }) => detailsFlexDirection};
  align-items: first baseline;
  white-space: pre-line;
  margin-left: ${({ fullInformation, theme }) =>
    fullInformation ? theme.pxToRem(35) : theme.pxToRem(0)};
  margin-top: ${({ fullInformation, theme }) =>
    fullInformation ? theme.pxToRem(0) : theme.pxToRem(10)};
  margin-bottom: ${({ fullInformation, theme }) =>
    fullInformation ? theme.pxToRem(0) : theme.pxToRem(10)};

  ${({ spaced = false, theme }) =>
    spaced &&
    css`
      & + & {
        margin-top: ${theme.spacing(8)};
      }
    `};

  dt,
  dd {
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    letter-spacing: 0;
    line-height: 1.7;
  }

  dt {
    flex-shrink: 0;
    color: ${({ theme }) => theme.colours.charcoalDust};
    font-weight: ${({ titleFontWeight = 'normal' }) => titleFontWeight};
  }

  dd {
    margin-left: ${({
      theme,
      detailsFlexDirection = 'row',
      detailsMargin = true,
    }) =>
      detailsMargin && detailsFlexDirection === 'row' ? theme.spacing(8) : ''};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    white-space: pre-line;
    font-weight: ${({ valueFontWeight = 'normal' }) => valueFontWeight};

    ${({ truncateText }) =>
      truncateText &&
      css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `}
  }
`;

export const ShowMore = styled.div`
  margin-top: 10px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  text-decoration-color: ${({ theme }) => theme.colours.swimmersPool};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(16)};
  text-decoration-skip-ink: auto;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  cursor: pointer;
  font-weight: 600;
`;
