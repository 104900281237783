import React, { FC } from 'react';
import { CollapsibleSectionProps } from './types';
import {
  CollapsibleSectionLabel,
  CollapsibleSectionLabelContainer,
  IconButton,
} from './CollapsiblepPlus.styles';
import { Icon } from 'components/atoms';
import { colours } from 'theme/@config';

const CollapsibleSection: FC<CollapsibleSectionProps> = ({
  label,
  collapsed,
  setCollapsed,
  children,
}) => {
  return (
    <>
      <CollapsibleSectionLabelContainer>
        <CollapsibleSectionLabel onClick={() => setCollapsed(!collapsed)}>
          {label}
        </CollapsibleSectionLabel>
        <IconButton onClick={() => setCollapsed(!collapsed)}>
          <Icon
            icon={collapsed ? 'plus-circle' : 'minus-circle'}
            colour={colours.crowberryBlue}
            background
            size="xxs"
          />
        </IconButton>
      </CollapsibleSectionLabelContainer>
      <div style={{ display: collapsed ? 'none' : 'block' }}>{children}</div>
    </>
  );
};

export default CollapsibleSection;
