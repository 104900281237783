import React from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import AppLayout from 'layouts/app-layout';
import { Link, PageTitle, Loading } from 'components/atoms';
import { useFetch } from 'hooks';
import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { ThirdPartyEdit } from 'components/organisms/third-party-edit';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

const ThirdPartyEditPage = () => {
  const { id } = useParams<{ id?: string }>();
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const history = useHistory();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const onComplete = () => {
    history.push(`/third-party/profile/${id}`);
  };

  const { data, loading } = useFetch(`${vantageWebApi}/thirdParty/${id}`, {
    method: 'GET',
    headers,
  });

  if (data?.status === 404) {
    return <Redirect to="/not-found" />;
  }
  if (data?.status === 401) {
    return <Redirect to="/unauthorised" />;
  }

  return (
    <AppLayout
      topBarSlot={
        <Link to={`/third-party/profile/${id}`} dark>
          Back to {terminologyConfig?.thirdPartySingularNaming} manager
        </Link>
      }
    >
      {loading || !data ? (
        <Loading />
      ) : (
        <>
          <PageTitle
            title={data?.name ? `Third party: ${data?.name}` : 'Third party'}
          />
          <ThirdPartyEdit
            onCancel={onComplete}
            onSave={onComplete}
            id={id}
            thirdParty={data}
            inModal={false}
          />
        </>
      )}
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, ThirdPartyEditPage);
