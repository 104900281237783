import styled from 'styled-components';

interface WrapperProps {
  showBorder: boolean;
  labelRight: boolean;
  small: boolean;
  space?: boolean;
  leftAlign: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  border: ${({ theme: { colours }, showBorder }) =>
    showBorder ? `1px solid ${colours.crowberryBlue}` : 'none'};
  border-radius: ${({ theme }) => theme.pxToRem(3)};

  &.required {
    border: ${({ theme: { colours }, showBorder }) =>
      showBorder ? `1px solid ${colours.redRampage}` : 'none'};
  }

  ${({ space = false }) => space && `gap: ${({ theme }) => theme.pxToRem(20)}`};

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative;
    padding: ${({ theme, small }) => (small ? 0 : theme.pxToRem(20))};
    margin: 0;
    flex-direction: ${({ labelRight }) => (labelRight ? 'row-reverse' : 'row')};
    gap: ${({ theme, small }) =>
      small ? theme.pxToRem(10) : theme.pxToRem(20)};
    justify-content: ${({ leftAlign = false }) =>
      leftAlign ? 'flex-end' : 'initial'};

    .label {
      margin-right: ${({ theme, small }) =>
        small ? theme.pxToRem(15) : theme.pxToRem(30)};
    }

    .radio {
      margin: ${({ leftAlign = false }) =>
        leftAlign ? '0' : 'auto 0 auto auto'};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: ${({ small, theme }) =>
        small ? theme.pxToRem(18) : theme.pxToRem(30)};
      width: ${({ small, theme }) =>
        small ? theme.pxToRem(18) : theme.pxToRem(30)};
      background-color: transparent;
      border: 1px solid ${({ theme: { colours } }) => colours.crowberryBlue};
      border-radius: 50%;
      font-size: 14px;
      box-sizing: border-box;
      order: 3;
    }

    input:checked ~ .radio {
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        height: ${({ small, theme }) =>
          small ? theme.pxToRem(12) : theme.pxToRem(20)};
        width: ${({ small, theme }) =>
          small ? theme.pxToRem(12) : theme.pxToRem(20)};
        background-color: ${({ theme: { colours } }) => colours.crowberryBlue};
        left: ${({ small, theme }) => (small ? theme.pxToRem(3) : 'auto')};
      }
    }

    input:disabled ~ .radio {
      cursor: not-allowed;
      border: 1px solid ${({ theme }) => theme.colours.dustyGray};
    }

    input:disabled ~ .label {
      color: ${({ theme }) => theme.colours.dustyGray};
      cursor: not-allowed;
    }

    input ~ .icon {
      margin-right: ${({ theme }) => theme.pxToRem(15)};
    }
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  gap: ${({ theme }) => theme.pxToRem(20)};
`;
