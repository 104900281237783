import styled from 'styled-components';
import { AvatarSizes } from './types';

export const containerSizes = {
  xs: '20px',
  sm: '25px',
  md: '30px',
  lg: '35px',
  xl: '40px',
};

export const fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '16px',
  lg: '20px',
  xl: '24px',
};

export const Container = styled.div<{ size: AvatarSizes; bgColor: string }>`
  border-radius: 100px;
  height: ${({ size }) => containerSizes[size]};
  width: ${({ size }) => containerSizes[size]};
  font-size: ${({ size }) => fontSizes[size]};
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
