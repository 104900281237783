import styled from 'styled-components';

export const CollapsibleComponent = styled.div``;

export const CollapsibleToggle = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

export const CollapsibleText = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
`;

export const CollapsibleContent = styled.div``;
