import React, { useState } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { useLocation, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, FormControl, FieldInput, Link } from 'components/atoms';

import {
  ResetPasswordContainer,
  ResponseMessage,
} from './ResetPassword.styles';

import LoginLayout from 'layouts/login-layout';

import { useConfig } from 'config';

import { ResetPasswordResponse } from './types';
import { urlParams } from 'services/url-params';

function ForgotPassword() {
  const { vantageWebApi, msalSubscriptionKey } = useConfig();

  const [resetPasswordResponse, setResetPasswordResponse] =
    useState<ResetPasswordResponse>(null);

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const { search: queryString } = useLocation();
  // manually parse params to prevent stripping of special characters
  let { email } = urlParams(queryString);
  const { token } = urlParams(queryString);
  email = decodeURIComponent(email);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('You must enter a password')
      .min(14, 'Password must be at least 14 characters long.'),
    passwordConfirm: yup
      .string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
  });

  const {
    handleSubmit,
    register,
    formState: { errors: formValidationErrors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    setSubmitDisabled(true);

    const response = await fetch(`${vantageWebApi}/account/resetpassword`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        token,
        password: formData?.password,
      }),
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
      },
    });

    if (response.status === 200) {
      setResetPasswordResponse('SUCCESS');
    } else {
      setResetPasswordResponse('FAIL');
      setSubmitDisabled(false);
    }
  };

  if (!email || !token) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout>
      <ResetPasswordContainer>
        <h1>Password Reset</h1>
        <p>Reset your Vantage Gateway password.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl errorMessage={formValidationErrors?.password?.message}>
            <FieldInput
              name="password"
              id="password"
              required
              register={register}
              valid={formValidationErrors?.password}
              placeholder="Password"
              label="Password"
              fullWidth={true}
              type="password"
            />
          </FormControl>
          <FormControl
            errorMessage={formValidationErrors?.passwordConfirm?.message}
          >
            <FieldInput
              name="passwordConfirm"
              id="passwordConfirm"
              required
              register={register}
              valid={formValidationErrors?.passwordConfirm}
              placeholder="Confirm Password"
              label="Confirm Password"
              fullWidth={true}
              type="password"
            />
          </FormControl>
          <Button type="submit" disabled={submitDisabled}>
            Reset Password
          </Button>
        </form>
        {resetPasswordResponse !== null && (
          <ResponseMessage $responseType={resetPasswordResponse}>
            {resetPasswordResponse === 'SUCCESS' && (
              <>
                Your password has been succesfully changed.{' '}
                <Link to="/login">Login</Link> with your new password.
              </>
            )}
            {resetPasswordResponse === 'FAIL' && (
              <>Something went wrong, please try again later.</>
            )}
          </ResponseMessage>
        )}
        <hr />
        <h2>Still need help?</h2>
        <Button as="a" href="mailto:vantagesupport@controlrisks.com" outline>
          Contact support
        </Button>
        <Link to="/login">Back to login page</Link>
      </ResetPasswordContainer>
    </LoginLayout>
  );
}

export default withAITracking(reactPlugin, ForgotPassword);
