import styled, { css } from 'styled-components';
import { ButtonBase } from 'globalStyles/GlobalStyles';
import { ButtonProps } from './types';

export const ButtonComponent = styled.button<ButtonProps>`
  ${ButtonBase}
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  background-color: ${({ theme }) => theme.colours.crowberryBlue};
  color: ${({ theme }) => theme.colours.white};

  &:disabled {
    background: ${({ theme }) => theme.colours.spanishGrey};
    border: 1px solid ${({ theme }) => theme.colours.spanishGrey};
    pointer-events: none;
  }

  &:hover {
    transition: all 0.25s ease-in-out;
    background: ${({ theme }) => theme.colours.swimmersPool};
    color: ${({ theme }) => theme.colours.white};
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
  }

  ${({ outline }) =>
    outline &&
    css`
      border-color: currentColor;
      background: transparent;
      color: ${({ theme }) => theme.colours.charcoalDust};
    `}

  ${({ outline, secondary }) =>
    secondary &&
    css`
      border-color: ${({ theme }) => theme.colours.swimmersPool};
      background-color: ${outline
        ? 'transparent'
        : ({ theme }) => theme.colours.swimmersPool};
      color: ${({ theme }) =>
        outline ? theme.colours.swimmersPool : theme.colours.crowberryBlue};
    `}

    ${({ dark, text }) =>
    text &&
    css`
      padding-inline: 0;
      text-decoration: underline;
      text-decoration-color: ${({ theme: { colours } }) =>
        colours.swimmersPool};
      text-underline-position: under;
      border: none;
      background: transparent;
      color: ${({ theme }) =>
        dark ? theme.colours.whiteSolid : theme.colours.charcoalDust};

      &:hover {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colours.swimmersPool};
        text-decoration-color: ${({ theme: { colours } }) =>
          colours.swimmersPool};
      }

      &:disabled {
        background: transparent;
        border: 0;
        color: ${({ theme }) => theme.colours.spanishGrey};
        text-decoration-color: ${({ theme }) => theme.colours.spanishGrey};
      }
    `}

    ${({ small, text }) =>
    small &&
    css`
      min-height: ${({ theme }) => theme.pxToRem(38)};
      height: ${({ theme }) => theme.pxToRem(38)};
      padding: 0 ${({ theme }) => (text ? theme.spacing(0) : theme.spacing(20))};
      font-size: ${({ theme }) => theme.typography.fontSize.small};
    `}
`;

export const ButtonToggle = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    white-space: nowrap;
    min-height: 2.375rem;
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
    background-color: ${({ theme }) => theme.colours.whiteSolid};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: ${({ theme }) => theme.pxToRem(12)};
    padding: ${({ theme }) => theme.spacing(2)}
      ${({ theme }) => theme.spacing(15)};
    font-weight: 600;
  }

  button:hover,
  button[hover] {
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
    background-color: ${({ theme }) => theme.colours.swimmersPool};
    color: ${({ theme }) => theme.colours.whiteSolid};
  }

  button:disabled,
  button[disabled] {
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
    background-color: ${({ theme }) => theme.colours.swimmersPool};
    color: ${({ theme }) => theme.colours.whiteSolid};
  }

  div {
    white-space: nowrap;
    max-height: 2.375rem;
    padding: ${({ theme }) => theme.spacing(2)}
      ${({ theme }) => theme.spacing(8)};
  }
`;
