import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { AppLayout } from 'layouts';
import { Icon, Link, Loading, Typography } from 'components/atoms';
import { SectionHeading, Tabs } from 'components/molecules';
import {
  Audit,
  DueDiligence,
  OutstandingActions,
  Profile,
  ThirdPartyProfileRelatedEntities,
  ScreeningRisks,
  Documents,
  DueDiligenceDrafts,
} from './sections';
import {
  Column,
  InitiateButton,
  Layout,
  Section,
  TabContent,
} from './ThirdPartyProfile.styles';
import DueDiligenceRiskModule from 'components/organisms/duediligence-risk-module';
import { GatewaySubscriptionTypes } from 'auth/constants/subscriptions';
import { GatewayPermissions } from 'auth/constants/permissions';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

const ThirdPartyProfile = () => {
  const { accessToken, hasSubscription, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, responseStatus } = useFetch(
    `${vantageWebApi}/thirdParty/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const [selected, setSelected] = useState(0);
  const history = useHistory();

  const canViewDocuments: boolean = hasPermission(
    GatewayPermissions.ViewDocuments
  );

  const canViewDrafts = () => {
    return data.draftsCount > 0;
  };

  const canViewScreeningRisks: boolean =
    hasSubscription(GatewaySubscriptionTypes.ManagedServiceScreening) ||
    hasSubscription(GatewaySubscriptionTypes.SelfServiceScreening);

  const canViewDueDiligenceRiskModule: boolean =
    hasSubscription(GatewaySubscriptionTypes.ManagedServiceDueDiligence) &&
    hasPermission(GatewayPermissions.ViewDueDiligenceReport);

  const canInitiateDueDiligence = !(
    hasPermission(
      GatewayPermissions.InitiateDueDiligenceSelfServiceScreening
    ) ||
    hasPermission(
      GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
    ) ||
    hasPermission(GatewayPermissions.OrderManagedDueDiligence)
  );

  const canViewAudits: boolean = hasPermission(GatewayPermissions.ViewAudit);

  const canViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const backLink = (
    <Link to="/third-party" dark>
      Back to&nbsp;{terminologyConfig?.thirdPartySingularNaming.toLowerCase()}
      &nbsp;manager
    </Link>
  );

  if (error) {
    if (responseStatus === 404) {
      return <Redirect to="/not-found" />;
    }
    if (responseStatus === 401) {
      return <Redirect to="/unauthorised" />;
    }
    return (
      <AppLayout topBarSlot={backLink}>
        <Section large>
          <SectionHeading text="Error" />
          <Typography tag="p" value="Oops something went wrong!" />
        </Section>
      </AppLayout>
    );
  }

  if (loading || error) {
    return (
      <AppLayout topBarSlot={backLink}>
        <Section large>
          {loading ? (
            <Loading />
          ) : (
            <>
              <SectionHeading text="Error" />
              <Typography
                tag="p"
                value="There was an error with the request."
              />
            </>
          )}
        </Section>
      </AppLayout>
    );
  }

  return (
    <AppLayout topBarSlot={backLink}>
      {data && (
        <>
          <Layout>
            <Column>
              <Profile id={id} profile={data} />
            </Column>
            <Column>
              {canViewTaskList && <OutstandingActions />}
              <Tabs
                dark
                selected={selected}
                setSelected={setSelected}
                ctas={[
                  {
                    i: [0, 1, 2],
                    cta: (
                      <InitiateButton
                        secondary
                        disabled={canInitiateDueDiligence}
                        onClick={() =>
                          history.push(`/initiate-due-diligence/${id}`)
                        }
                      >
                        <span>
                          <Icon icon="report" size="xs" colour="inherit" />
                          <span>Initiate compliance checks</span>
                        </span>
                      </InitiateButton>
                    ),
                  },
                ]}
              >
                <TabContent label="Risk summary">
                  <Section large>
                    <DueDiligence thirdPartyId={id} />
                  </Section>
                  {canViewScreeningRisks && (
                    <Section large>
                      <ScreeningRisks
                        {...data?.screeningRequestRiskModel}
                        type={data?.type}
                      />
                    </Section>
                  )}
                  {canViewDueDiligenceRiskModule && (
                    <Section large>
                      <DueDiligenceRiskModule
                        riskRating={data?.dueDiligenceRequestRiskModel}
                        riskRatingMethodology={data?.riskRatingMethodologyId}
                      />
                    </Section>
                  )}
                </TabContent>
                {canViewDocuments && (
                  <TabContent
                    label={`${terminologyConfig?.thirdPartySingularNaming} documents`}
                  >
                    <Section large>
                      <Documents id={id} type={data?.type} />
                    </Section>
                  </TabContent>
                )}
                {canViewDrafts() && (
                  <TabContent
                    label={'Drafts Summary (' + data?.draftsCount + ')'}
                  >
                    <Section large>
                      <DueDiligenceDrafts
                        thirdPartyId={id}
                        draftsCount={data?.draftsCount}
                      />
                    </Section>
                  </TabContent>
                )}
              </Tabs>
            </Column>
          </Layout>
          <ThirdPartyProfileRelatedEntities
            relatedEntityFieldsConfigs={{
              isIndividualDateOfBirthMandatory:
                data.isIndividualDateOfBirthMandatory,
              isIndividualRelationshipToThirdPartyMandatory:
                data.isIndividualRelationshipToThirdPartyMandatory,
              isCompanyRelationshipToThirdPartyMandatory:
                data.isCompanyRelationshipToThirdPartyMandatory,
            }}
          />
          {canViewAudits && <Audit />}
        </>
      )}
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, ThirdPartyProfile);
