import styled from 'styled-components';

export const TableCount = styled.div`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  line-height: ${({ theme }) => theme.pxToRem(30)};
`;

export const ListContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.pxToRem(40)};
  overflow-x: auto;
`;

export const Spacer = styled.div`
  padding: ${({ theme }) => `0 ${theme.pxToRem(40)} ${theme.pxToRem(40)}`};
`;

export const Notes = styled.div`
  div {
    padding-bottom: ${({ theme }) => theme.pxToRem(40)};
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.pxToRem(10)};

  div {
    padding-right: ${({ theme }) => theme.pxToRem(25)};
  }
`;

export const NotesWrapper = styled.div<{ optionsPadding?: boolean }>`
  padding-bottom: ${({ optionsPadding }) => (optionsPadding ? '2rem' : '0')};
`;

export const NoMatches = styled.div`
  color: ${({ theme }) => theme.colours.black};
  padding: ${({ theme }) =>
    `0 ${theme.pxToRem(60)} ${theme.pxToRem(40)} ${theme.pxToRem(60)}`};
`;

export const MultiSelectContainer = styled.div`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.pxToRem(40)} ${theme.pxToRem(40)}`};
  display: flex;
  flex-direction: row;

  section {
    margin-right: 20px;
  }
`;
