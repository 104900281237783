import styled from 'styled-components';
import React from 'react';
import { Button } from 'components/atoms';
import { Link } from 'react-router-dom';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(30)};
`;

export const ThirdPartyName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(10)};
  }
`;

export const ThirdpartyNameLink = styled(Link)`
  width: max-content;
`;

export const ThirdPartyDetail = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};

  h3 {
    padding: ${({ theme }) => theme.spacing(10)}
      ${({ theme }) => theme.spacing(15)};
    border-bottom: 1px solid ${({ theme }) => theme.colours.whiteSolid};

    font-size: ${({ theme }) => theme.pxToRem(12)};
    font-weight: 600;
    line-height: 1.5888;
  }

  > div {
    padding: ${({ theme }) => theme.spacing(10)}
      ${({ theme }) => theme.spacing(15)} ${({ theme }) => theme.spacing(20)};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: 1.357;
  }

  h3,
  a {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  > div + h3 {
    padding-top: 0;
  }

  > div p {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
    line-height: 1.714;
  }
`;

export const ChildContainer = styled.div`
  display: inline-block;
  width: 50%;

  div {
    max-height: 2.375rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme: { spacing } }) => spacing(32)};
  height: 0.5rem;

  p.error {
    margin: 0;
    margin-bottom: ${({ theme: { spacing } }) => spacing(15)};
  }
`;
export const DownloadText = styled.p`
  margin-left: ${({ theme }) => theme.spacing(5)};
`;
export const FixedWidthButton = styled(Button)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-height: 2.375rem;
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  background-color: ${({ theme }) => theme.colours.crowberryBlue};
  color: ${({ theme }) => theme.colours.whiteSolid};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(15)};
  font-weight: 600;
`;

export const ErrorMessage = styled.p`
  margin-top: 0;
  font-size: 12px;
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.light};
  color: ${({ theme }) => theme.colours.redRampage};
`;

export const HelperText = styled.p`
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colours.agedGray};
`;

export const ReportIdResult = ({
  reportId,
  thirdParty,
  thirdPartyTerminology,
}) => (
  <>
    <h3
      style={{
        fontWeight: 'bold',
        color: 'black',
      }}
    >
      Search Results:
    </h3>
    <p>
      Report Id: <strong>{reportId}</strong>
    </p>
    <p>
      {thirdPartyTerminology}:{' '}
      <a
        href={`/third-party/profile/${thirdParty.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>{thirdParty.name}</strong>
      </a>
    </p>
  </>
);

export const ActionMenuWrapper = styled.td`
  .callout-trigger {
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
