import styled from 'styled-components';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  input {
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
    border: 1px solid ${({ theme }) => theme?.colours?.charcoalDust};
    border-radius: ${({ theme }) => theme.spacing(5)};
    font-size: ${({ theme }) => theme.spacing(14)};
    line-height: 1.57;
    padding: ${({ theme }) =>
      theme.spacing({ left: 20, top: 15, right: 20, bottom: 15 })};
  }

  &.invalid {
    input {
      border: 1px solid ${({ theme }) => theme?.colours?.redRampage};
      background: rgba(239, 56, 38, 0.25);
      outline: 1px solid ${({ theme }) => theme?.colours?.redRampage};
    }
  }

  &.valid {
    input {
      border: 1px solid ${({ theme }) => theme?.colours?.swimmersPool};
      outline: none;
    }
  }

  &:not(.invalid) input:focus-visible {
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
    background-color: #f6fdfc;
    outline: none;
  }
`;
