import styled from 'styled-components';

export const ActionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colours.crowberryBlue};

  .icon {
    margin-right: 20px;
  }
`;

export const DocumentGridContainer = styled.div`
  display: grid;
  margin: 16px 0px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const DocumentWrapper = styled.div`
  width: 288px;
  margin-bottom: 5px;
`;

export const ActionMenuWrapper = styled.td`
  .callout-trigger {
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const CardDeleteButton = styled.button`
  margin-left: auto;
  cursor: pointer;
`;

export const ArrayItem = styled.div`
  display: flex;
`;

export const DocumentUploadsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin-bottom: ${({ theme }) => theme.spacing(10)};

  .record-text {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }
`;
