import React, { FC } from 'react';
import { ButtonProps } from './types';
import { ButtonComponent } from './Button.styles';

const Button: FC<ButtonProps> = ({
  as = 'button',
  children,
  ...props
}: ButtonProps) => (
  <ButtonComponent as={as} disabled={props.disabled ? true : false} {...props}>
    {children}
  </ButtonComponent>
);

export default Button;
