import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';

import jwt from 'jsonwebtoken';

export function ProtectedRoute({
  children = null,
  renderComponent = null,
  ...rest
}) {
  const { accessToken, setRedirectUrl, redirectUrl } = useAuth();
  const isLoggedIn = jwt.decode(accessToken);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoggedIn && !redirectUrl && pathname !== '/') {
      setRedirectUrl(pathname);
    }
    // eslint-disable-next-line
  }, []);

  if (renderComponent) {
    const Component = renderComponent;
    return (
      <Route
        {...rest}
        render={({ ...props }) => {
          return isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          );
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={() => {
        return isLoggedIn ? children : <Redirect to="/login" />;
      }}
    />
  );
}
