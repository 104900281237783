import React from 'react';
import Modal from 'components/molecules/modal';
import { Button, Loading, Typography } from 'components/atoms';
import { ConfirmationModalProps } from './types';
import {
  ButtonContainer,
  LoadingText,
  ModalContent,
} from './ConfirmationModal.styles';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onCloseDisabled,
  onCancel,
  onProceed,
  onProceedAriaLabel,
  title,
  message,
  loading,
  loadingMessage,
  proceedButtonText: proceedButtonText = 'Proceed',
  proceedButtonLoading,
  cancelButtonText = 'Cancel',
  bodyContainerRef,
  proceedButtonProps,
  cancelButtonProps,
  messageBody,
}) => {
  return (
    <Modal
      removePadding
      onClose={onCloseDisabled ? null : () => onClose()}
      isOpen={isOpen}
      title={title}
      bodyContainerRef={bodyContainerRef}
    >
      <ModalContent>
        {loading && <Loading />}
        {loading && loadingMessage && (
          <LoadingText>{loadingMessage}</LoadingText>
        )}
        {!loading && (
          <>
            <div>
              {messageBody ? (
                messageBody
              ) : message.includes('<br/>') ? (
                <Typography className="info-text" value={message} tag="p" />
              ) : (
                <>
                  {message.split('\n').map((text, i) => {
                    if (text) {
                      return (
                        <Typography
                          key={i}
                          className="info-text"
                          value={text}
                          tag="p"
                        />
                      );
                    } else {
                      return <br key={i} />;
                    }
                  })}
                </>
              )}
            </div>

            <ButtonContainer>
              <Button
                outline
                className="button"
                onClick={() => onCancel()}
                {...cancelButtonProps}
              >
                {cancelButtonText}
              </Button>
              <Button
                secondary
                className="button"
                onClick={() => onProceed()}
                aria-label={onProceedAriaLabel}
                {...proceedButtonProps}
              >
                {proceedButtonLoading ? <Loading /> : proceedButtonText}
              </Button>
            </ButtonContainer>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
