import { zIndex as defaultZIndex } from './@config/z-index';
import { colours, typography, breakPoints } from './@config';

const generateTheme = (props?: any) => {
  const baseUnits = props?.baseUnits || 'rem';
  const baseSize = props?.baseUnits || 16;
  const themeType = 'colorPrimary';
  const customColours = props?.theming;
  const zIndex = { ...defaultZIndex };

  const spacing = (baseUnits, baseSize) => (size, auto) => {
    if (typeof size !== 'object' && auto === 'auto') {
      if (baseUnits === 'px') {
        return `${size}${baseUnits} auto`;
      }
      return `${size / baseSize}${baseUnits} auto`;
    }

    if (typeof size === 'object') {
      const { top, right, bottom, left } = size;

      if (!top || !right || !bottom || !left) {
        throw Error(`
        Unknown or missing size property passed to theme spacing function.
        Please make sure you provide the size in the following format:
        {
          top: number,
          right: number,
          bottom: number,
          left: number
        }
        `);
      }

      if (baseUnits === 'px') {
        return `${top}${baseUnits} ${right}${baseUnits} ${bottom}${baseUnits} ${left}${baseUnits}`;
      }

      return `${top / baseSize}${baseUnits} ${right / baseSize}${baseUnits} ${
        bottom / baseSize
      }${baseUnits} ${left / baseSize}${baseUnits}`;
    }

    if (baseUnits === 'px') {
      return `${size}${baseUnits}`;
    }

    return `${size / baseSize}${baseUnits}`;
  };

  const pxToRem = (baseSize) => (pixelSize) => {
    return `${pixelSize / baseSize}rem`;
  };

  return {
    typography,
    zIndex: zIndex,
    spacing: spacing(baseUnits, baseSize),
    colours: customColours || colours,
    themeType,
    breakPoints,
    pxToRem: pxToRem(baseSize),
  };
};

export default generateTheme;
