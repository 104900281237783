import React, { FC } from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import {
  TooltipArrow,
  TooltipBody,
  CloseIconWrapper,
  TriggerSpan,
} from './Tooltip.styles';
import Icon from 'components/atoms/icon';
import { TooltipTypes } from './types';
import { colours } from 'theme/@config';

const Tooltip: FC<TooltipTypes> = ({
  children,
  tooltipText,
  hideArrow = false,
  portalContainer,
  tooltipShown,
  bodyStyles,
  stretchChildren = false,
  onClose,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltipShown={tooltipShown}
    portalContainer={portalContainer}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <TooltipBody
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}
        bodyStyles={bodyStyles}
      >
        {!hideArrow && (
          <TooltipArrow
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              placement: placement,
            })}
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{tooltipText}</span>
          {onClose && (
            <CloseIconWrapper
              className="clickable"
              onClick={onClose}
              style={{ marginLeft: '30px' }}
            >
              <Icon
                icon="close"
                aria-hidden
                size="xxxs"
                colour={colours.white}
              />
            </CloseIconWrapper>
          )}
        </div>
      </TooltipBody>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <TriggerSpan
        role="tooltip"
        aria-label="Tooltip content"
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
        stretchChildren={stretchChildren}
      >
        {children}
      </TriggerSpan>
    )}
  </TooltipTrigger>
);

export default Tooltip;
