import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(20)};
  border-radius: 5px;
`;

export const FieldsContainer = styled.div`
  max-width: 100%;
`;

export const ActionButtonText = styled.span`
  color: ${({ theme: { colours } }) => colours.charcoalDust};
  margin-left: ${({ theme: { pxToRem } }) => pxToRem(15)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme: { spacing } }) => spacing(20)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};

  p.error {
    margin: 0;
    margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
  }

  > button ~ button {
    margin-left: ${({ theme: { spacing } }) => spacing(50)};
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  & span {
    color: ${({ theme: { colours } }) => colours.crowberryBlue} !important;
    margin-right: ${({ theme: { spacing } }) => spacing(15)};
  }

  & > div {
    min-width: 280px;
  }
`;

export const GridFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(20)};
`;

export const GridRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const RadioFieldset = styled.fieldset`
  display: flex;
  gap: ${({ theme }) => theme.pxToRem(2)};
`;
