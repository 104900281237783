import React, { FC } from 'react';
import { Wrapper, ErrorMessage, HelperText } from './FormControl.styles';

import { Props } from './types';

const FormControl: FC<Props> = ({
  children,
  errorMessage = '',
  helperText = '',
  style = {},
}: Props) => (
  <Wrapper style={{ ...style }}>
    {children}
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    {!errorMessage && helperText && <HelperText>{helperText}</HelperText>}
  </Wrapper>
);

export default FormControl;
