import styled from 'styled-components';

export const EntityResolutionModalContainer = styled.div`
  color: ${({ theme }) => theme.colours.crowberryBlue};

  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  p.error {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0;
    margin: ${({ theme: { spacing } }) => spacing(30)} 0;
    color: ${({ theme }) => theme.colours.redRampage};
  }
`;

export const ModalTitle = styled.h3`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  letter-spacing: 0;
  line-height: 1.25;
`;

export const MatchRadioWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colours.gallery};
  border-radius: 0.1875rem;
  margin: 12px 0;
  cursor: pointer;

  &:has(input:checked) {
    border-color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  * {
    cursor: pointer;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:checked ~ .radio {
    &:after {
      content: '';
      position: relative;
      border-radius: 50%;
      height: ${({ theme }) => theme.pxToRem(10)};
      width: ${({ theme }) => theme.pxToRem(10)};
      background-color: ${({ theme: { colours } }) => colours.crowberryBlue};
    }
  }

  label {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    padding: 16px;
    display: flex;
    width: 100%;
    gap: ${({ theme }) => theme.pxToRem(8)};
    margin-bottom: 0;

    .radio {
      margin-top: ${({ theme }) => theme.pxToRem(4)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: ${({ theme }) => theme.pxToRem(20)};
      width: ${({ theme }) => theme.pxToRem(20)};
      background-color: transparent;
      border: 1px solid ${({ theme: { colours } }) => colours.crowberryBlue};
      border-radius: 50%;
      font-size: 14px;
      box-sizing: border-box;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(30)};
  margin-bottom: ${({ theme }) => theme.spacing(30)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;

  button:last-child {
    width: ${({ theme }) => theme.pxToRem(223)};
  }

  > button ~ button {
    margin-left: ${({ theme: { spacing } }) => spacing(35)};
  }
`;
