import React, { FC } from 'react';
import { SearchComponent } from './Search.styles';
import { Icon } from 'components/atoms';
import { SearchProps } from './types';

const Search: FC<SearchProps> = ({
  ariaLabel,
  placeholder = 'Search...',
  ...inputProps
}) => (
  <SearchComponent aria-label={ariaLabel}>
    <Icon icon="search" aria-hidden size="xs" />
    <input type="search" placeholder={placeholder} {...inputProps} />
  </SearchComponent>
);

export default Search;
