import { useState, useRef, Dispatch, SetStateAction } from 'react';

function isDeepEqual(obj1: Record<string, any>, obj2: Record<string, any>) {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

/**
 * Custom React hook that manages an object state with deep comparison.
 * This hook is useful when the state to be managed is a complex object and shallow comparison
 * (default behavior of React's useState and useEffect) is not sufficient to detect changes.
 *
 * @param {Object} initialValue - The initial value of the state.
 * @returns {Array} A tuple where the first element is the current state and the second element
 *                  is a setter function that updates the state if the new value is not deeply
 *                  equal to the current state.
 *
 * @example
 * const [filters, setFilters] = useDeepState({ name: '', age: null });
 * setFilters({ name: 'John', age: 30 }); // Sets the state only if the new object is different.
 */
function useDeepState<T extends Record<string, any>>(
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(initialValue);
  const prevValueRef = useRef<T>(initialValue);

  const setDeepValue = (newValue) => {
    if (!isDeepEqual(newValue, prevValueRef.current)) {
      setValue(newValue);
      prevValueRef.current = newValue;
    }
  };

  return [value, setDeepValue];
}

export default useDeepState;
