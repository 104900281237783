import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';
import FilterContext from 'context/third-party-filter';
import { useTerminologyConfig } from 'context/terminologyConfig';
import {
  TableComponent,
  TableHeaderWrapper,
  TableHeader,
  TableHeadingText,
  TableFilterComponent,
  TableSortButton,
  TableBody,
  LoadingContainer,
  TableFilterCallout,
  NoResults,
  ScrollableContainer,
} from './Table.styles';
import Loading from 'components/atoms/loading';
import { TableComponentProps, TableFilterProps } from './types';
import { Button, Callout, FieldCheckbox, Icon } from 'components/atoms';

const TableFilter: React.FC<TableFilterProps> = ({ field, options, title }) => {
  const [filters, setFilters] = useContext(FilterContext);
  const [selected, setSelected] = useState(filters[field] || []);
  const { handleSubmit, register, reset } = useForm({
    mode: 'onSubmit',
    // Fields are all checkboxes, so just convert array values into object keys with truthy values.
    defaultValues: filters[field]?.reduce(
      (ac, a) => ({ ...ac, [a]: true }),
      {}
    ),
  });

  const updateFilter = (data) => {
    const newFilters = { ...filters, pageNumber: 1 };
    newFilters[field] = data;
    setFilters(newFilters);
  };
  const resetForm = () => {
    setSelected([]);
    updateFilter([]);
    // Reset form to falsy defaults.
    reset(filters[field]?.reduce((ac, a) => ({ ...ac, [a]: false }), {}));
  };
  const onSubmit = (data) =>
    updateFilter(Object.keys(data).filter((key) => data[key]));
  return (
    <TableFilterComponent onSubmit={handleSubmit(onSubmit)}>
      {title && <p className="title">{title}:</p>}
      <div className="options">
        {options.map((option) => (
          <FieldCheckbox
            key={option.name}
            id={option.value}
            register={register}
            label={option.name}
            onClick={(ev) => {
              if (ev.currentTarget.checked) {
                setSelected([option.value, ...selected]);
              } else {
                setSelected(selected.filter((i) => i !== option.value));
              }
            }}
            name={field}
          />
        ))}
      </div>
      <div className="actions">
        <button
          type="reset"
          className="clear"
          onClick={resetForm}
          disabled={selected.length === 0}
        >
          Clear
        </button>
        <Button
          type="submit"
          small
          disabled={
            (!(filters[field] && filters[field].length > 0) &&
              selected.length === 0) ||
            _.isEqual(filters[field]?.sort() || [], selected.sort())
          }
        >
          Save
        </Button>
      </div>
    </TableFilterComponent>
  );
};

const Table: React.FC<TableComponentProps> = ({
  children,
  headings = [],
  onHeadingClick,
  rows = [],
  showPlaceholder = false,
  sortedColumn,
}) => {
  const terminologyConfig = useTerminologyConfig();

  return (
    <ScrollableContainer>
      <TableComponent cellSpacing="0">
        {headings?.length > 0 && (
          <TableHeader>
            <tr>
              {headings.map(
                ({ name, orderable = false, filter, width }, index) => (
                  <th key={`heading-${name}-${index}`} style={{ width: width }}>
                    <TableHeaderWrapper>
                      {orderable ? (
                        <TableSortButton
                          type="button"
                          onClick={() => onHeadingClick({ index })}
                          highlighted={index === sortedColumn}
                          title={name}
                        >
                          {name}
                          <Icon icon="sort" size="xxs" aria-hidden />
                        </TableSortButton>
                      ) : (
                        <TableHeadingText
                          title={
                            name === 'ID'
                              ? terminologyConfig?.internalIdSingularNaming
                              : name
                          }
                        >
                          {name}
                        </TableHeadingText>
                      )}
                      {filter && (
                        <TableFilterCallout>
                          <Callout
                            direction="down"
                            name={filter.title}
                            trigger={() => (
                              <Icon icon="filter" aria-hidden size="xxxs" />
                            )}
                            height={filter?.height}
                            width={filter?.width}
                          >
                            <TableFilter {...filter} />
                          </Callout>
                        </TableFilterCallout>
                      )}
                    </TableHeaderWrapper>
                  </th>
                )
              )}
            </tr>
          </TableHeader>
        )}
        {!showPlaceholder && (
          <TableBody>
            {rows && rows.length > 0
              ? rows.map((row, index) => (
                  <tr key={`row-${row}-${index}`}>
                    {row.map((cell) => (
                      <td key={cell}>
                        {typeof cell === 'function' ? cell() : cell}
                      </td>
                    ))}
                  </tr>
                ))
              : children}
          </TableBody>
        )}
      </TableComponent>
      {!showPlaceholder &&
        rows.length === 0 &&
        (children as React.ReactNode[])?.length === 0 && (
          <NoResults>No matching Third Parties</NoResults>
        )}
      {showPlaceholder && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </ScrollableContainer>
  );
};

export default Table;
