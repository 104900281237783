import React, { FC, useState } from 'react';

import { Button } from 'components/atoms';
import { ExpandableParagraphProps } from './types';
import { ellipsisText } from 'helpers/strings-functions';

const ExpandableParagraph: FC<ExpandableParagraphProps> = ({
  text,
  maximumTextLength = 300,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandButton = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
    return false;
  };

  return (
    <div>
      <p>
        {text?.length > maximumTextLength && !isExpanded
          ? `${ellipsisText(text, maximumTextLength)}`
          : text}
      </p>
      {text && text?.length > maximumTextLength && (
        <Button text small onClick={handleExpandButton}>
          {isExpanded ? 'Read less' : 'Read more'}
        </Button>
      )}
    </div>
  );
};

export default ExpandableParagraph;
