import React, { FC } from 'react';
import { SectionSummaryComponent, ListItem } from './SectionSummary.styles';

import { ColumnType, ListItemType, SectionSummaryTypes } from './types';

const SectionSummary: FC<SectionSummaryTypes> = ({
  summaryList,
  keepProps,
}: SectionSummaryTypes) => {
  if (!summaryList) return null;

  const columns: Array<ColumnType> = [];

  const validItems = summaryList.filter(
    (item: ListItemType) => keepProps || (item.label && item.value)
  );

  for (let i = 0, j = validItems.length; i < j; i += 5) {
    columns.push({
      columnList: validItems.slice(i, i + 5),
      key: `column-${j}${i}`,
    });
  }

  return (
    <SectionSummaryComponent>
      {columns.map((column: ColumnType) => (
        <div key={column.key}>
          {column.columnList.map((item: ListItemType) => (
            <ListItem key={item.label}>
              {item.label}: {item.value}
            </ListItem>
          ))}
        </div>
      ))}
    </SectionSummaryComponent>
  );
};

export default SectionSummary;
