import * as yup from 'yup';

export const phoneNumber = yup
  .string()
  .nullable()
  .test('isValidPhoneNumber', function (value) {
    if (
      typeof value === 'undefined' ||
      value?.trim() === '' ||
      value === null
    ) {
      return true;
    }
    const nonDigitRegex = /[\D]/gi;
    const digitRepetitionRegex = /^\d{1,3}$/g;

    if (nonDigitRegex.test(value)) {
      return this.createError({
        message:
          'Primary contact phone field must not contain letters, symbols or spaces',
        path: 'keyPhoneNumber',
      });
    }

    if (digitRepetitionRegex.test(value)) {
      return this.createError({
        message: 'Invalid Phone Number',
        path: 'keyPhoneNumber',
      });
    }

    return true;
  });

export const alphanumeric = yup.string().test(
  'hasAlphanumeric',
  () => 'Input value must contain letters or numbers',
  function (value) {
    const regex =
      /[^\u200B-\u200D\uFEFF!@#$%&*()_+=|<>?{}~.,"';:`^[\]\-\s\\/]+/;

    if (value && (value.trim() === '' || !regex.test(value))) {
      return false;
    }

    return true;
  }
);

export const csvInjectionFilteredString = yup.string().test(
  'noCsvInjectionString',
  () => 'Input value is not valid',
  function (value) {
    const regex = /^(?![-+=@\t\r]).*$/;

    if (value && (value.trim() === '' || !regex.test(value))) {
      return false;
    }

    return true;
  }
);
