import React from 'react';
import { PageTitleComponent, PageTitleChildren } from './PageTitle.styles';
import { PageTitleProps } from './types';
import { Typography } from 'components/atoms';

const PageTitle: React.FC<PageTitleProps> = ({ children, title }) => (
  <PageTitleComponent>
    <Typography tag="h1" value={title} />
    <PageTitleChildren>{children}</PageTitleChildren>
  </PageTitleComponent>
);

export default PageTitle;
