import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';

import AppLayout from 'layouts/app-layout';
import { FieldsContainer, FormHeader, Wrapper } from 'form/form.styles';

import Loading from 'components/atoms/loading';
import { Date, FormControl, RiskRating, Button } from 'components/atoms';
import Select from 'components/atoms/field-select';
import { ButtonWrapper, SelectedItemCard } from 'form/form.styles';
import TextArea from 'components/atoms/field-textarea/FieldTextarea';
import { SimpleTable } from 'pages/third-party-profile/ThirdPartyProfile.styles';
import { FormsWrapper } from 'pages/third-party-create/ThirdPartyCreate.styles';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

const ThirdPartyEditApproval = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const history = useHistory();
  const { id } = useParams();
  const [thirdPartyApproval, setThirdPartyApproval] = useState();

  const { data, loading, error } = useFetch(
    `${vantageWebApi}/thirdParty/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        approvalStatus: yup
          .mixed()
          .test('approval-status', 'This field is required', (value) => {
            return value !== 'DEFAULT';
          }),
        riskRating: yup
          .mixed()
          .test('risk-rating', 'This field is required', (value) => {
            return value !== 'DEFAULT';
          }),
        comments: yup.string().max(2000, 'Maximum 2000 characters').nullable(),
      })
    ),
  });

  useEffect(() => {
    const lastThirdPartyApproval =
      data && data?.thirdPartyApproval
        ? data?.thirdPartyApproval[data?.thirdPartyApproval?.length - 1]
        : null;
    setThirdPartyApproval(lastThirdPartyApproval);
    reset({
      approvalStatus: lastThirdPartyApproval?.approvalStatus || 'DEFAULT',
      riskRating:
        lastThirdPartyApproval?.riskRating === ''
          ? 'N/A'
          : lastThirdPartyApproval?.riskRating || 'DEFAULT',
      comments: lastThirdPartyApproval?.comments || '',
    });
  }, [data, reset]);

  const { data: editData, trigger: triggerEdit } = useFetch(
    `${vantageWebApi}/thirdParty/${id}/edit-approval`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ...getValues(),
      }),
    }
  );

  const onSubmit = () => {
    triggerEdit();
  };

  if (data?.status === 404) {
    return <Redirect to="/not-found" />;
  }
  if (data?.status === 401) {
    return <Redirect to="/unauthorised" />;
  }

  if (error) return <h1>ERROR</h1>;

  if (editData) return <Redirect to={`/third-party/profile/${id}`} />;

  return (
    <AppLayout>
      <h1>
        {terminologyConfig?.thirdPartySingularNaming}: {data?.name}
      </h1>
      <FormsWrapper>
        <Wrapper>
          {loading ? (
            <Loading />
          ) : (
            <FieldsContainer>
              <SelectedItemCard>
                <div className="row">
                  <span className="label">Current Approval Status: </span>
                  <span className="value">
                    {thirdPartyApproval?.approvalStatus}
                  </span>
                </div>
                <div className="row">
                  <span className="label">Current Risk Rating: </span>
                  <span className="value">
                    <RiskRating risk={thirdPartyApproval?.riskRating} />
                  </span>
                </div>
                <div className="row">
                  <span className="label">Last Approved Date: </span>
                  <span className="value">
                    <Date
                      format="DD/MM/YYYY"
                      dateTime={thirdPartyApproval?.reviewDate}
                    />
                  </span>
                </div>
                <div className="row">
                  <span className="label">Last Approved By: </span>
                  <span className="value">
                    {thirdPartyApproval?.approverName}
                  </span>
                </div>
              </SelectedItemCard>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl errorMessage={errors.approvalStatus?.message}>
                  <Select
                    label="Approval Decision"
                    id="approvalStatus"
                    name="approvalStatus"
                    register={register}
                    placeholder="Please select a decision"
                    placeholderValue="DEFAULT"
                    options={[
                      {
                        name: 'Approved',
                        value: 'Approved',
                      },
                      {
                        name: 'Rejected',
                        value: 'Rejected',
                      },
                      {
                        name: 'To Be Determined',
                        value: 'To Be Determined',
                      },
                    ]}
                    valid={
                      !errors?.approvalStatus?.message &&
                      touchedFields?.approvalStatus
                    }
                    fullWidth
                    required
                  />
                </FormControl>

                <FormControl errorMessage={errors.riskRating?.message}>
                  <Select
                    label="Internal Risk Rating"
                    id="riskRating"
                    name="riskRating"
                    register={register}
                    placeholder="Please select a risk"
                    placeholderValue="DEFAULT"
                    options={[
                      {
                        name: 'Low',
                        value: 'Low',
                      },
                      {
                        name: 'Medium',
                        value: 'Medium',
                      },
                      {
                        name: 'High',
                        value: 'High',
                      },
                      {
                        name: 'N/A',
                        value: 'N/A',
                      },
                    ]}
                    valid={
                      !errors?.riskRating?.message && touchedFields?.riskRating
                    }
                    fullWidth
                    required
                  />
                </FormControl>

                <FormControl errorMessage={errors.comments?.message}>
                  <TextArea
                    label="Comments"
                    id="comments"
                    name="comments"
                    register={register}
                    fullWidth
                  />
                </FormControl>

                <ButtonWrapper>
                  <Button secondary type="submit">
                    Save
                  </Button>
                  <Button
                    text
                    type="button"
                    disabled={loading}
                    onClick={() => history.push(`/third-party/profile/${id}`)}
                  >
                    Cancel
                  </Button>
                </ButtonWrapper>
              </form>
            </FieldsContainer>
          )}
        </Wrapper>
      </FormsWrapper>

      <FormsWrapper>
        <Wrapper>
          {loading ? (
            <Loading />
          ) : (
            <>
              <FormHeader>Approval History</FormHeader>
              <SimpleTable cellSpacing="0">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Approver</th>
                    <th>Status</th>
                    <th>Risk Rating</th>
                    <th style={{ width: '30%' }}>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.thirdPartyApproval
                    ?.slice(0)
                    ?.reverse()
                    ?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Date
                              dateTime={item?.reviewDate}
                              format="DD/MM/YYYY"
                            />
                          </td>
                          <td>{item?.approverName}</td>
                          <td>{item?.approvalStatus}</td>
                          <td>
                            <RiskRating risk={item?.riskRating} />
                          </td>
                          <td>{item?.comments}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </SimpleTable>
            </>
          )}
        </Wrapper>
      </FormsWrapper>
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, ThirdPartyEditApproval);
