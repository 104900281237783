import { useHistory } from 'react-router-dom';
import { Button } from 'components/atoms';
import { Label } from 'components/organisms/profile-modal/ProfileModal.styles';
import React, { FC } from 'react';
import {
  Actions,
  Detail,
  Heading,
  ScreeningDetailsComponent,
  ScreeningDetailsData,
  Subheading,
  Value,
} from './ScreeningDetails.styles';

import { ScreeningDetailsTypes } from './types';

const ScreeningDetails: FC<ScreeningDetailsTypes> = ({
  heading,
  subHeading,
  details,
  ctaLabel,
  ctaClick,
  reportLink,
}: ScreeningDetailsTypes) => {
  const history = useHistory();
  return (
    <ScreeningDetailsComponent>
      <Heading>{heading}</Heading>
      {details && (
        <ScreeningDetailsData>
          <Subheading>{subHeading}</Subheading>
          {details.map(
            (detail) =>
              detail.value && (
                <Detail key={detail.label}>
                  <Label>{detail.label}</Label>
                  <Value lineBreaks={Array.isArray(detail.value)}>
                    {Array.isArray(detail.value)
                      ? detail.value.join('\n')
                      : detail.value}
                  </Value>
                </Detail>
              )
          )}
        </ScreeningDetailsData>
      )}
      <Actions>
        {reportLink && (
          <Button outline onClick={() => history.push(reportLink)}>
            View vantage report
          </Button>
        )}
        <Button outline onClick={ctaClick}>
          {ctaLabel}
        </Button>
      </Actions>
    </ScreeningDetailsComponent>
  );
};

export default ScreeningDetails;
