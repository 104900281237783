import React, { FC, useState } from 'react';
import { DualButtonProps } from './types';
import {
  DualButtonContainerComponent,
  DualButtonPrimaryComponent,
  DualButtonChevronComponent,
  DropdownMenuItem,
  DropdownMenu,
} from './DualButton.styles';
import Icon from '../icon';
import { ButtonContent } from '../multi-select/MultiSelect.styles';
import Tooltip from '../tooltip';

const DualButton: FC<DualButtonProps> = ({
  as = 'div',
  label,
  dropdownLabel,
  dropdownOnClick,
  ...props
}: DualButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const storedPreference = JSON.parse(
    localStorage.getItem('show-finalize-guide-tooltip')
  );
  const [tooltipVisible, setTooltipVisible] = useState(
    storedPreference !== null ? storedPreference : true
  );

  const handleTooltipClose = () => {
    setTooltipVisible(false);
    localStorage.setItem('show-finalize-guide-tooltip', JSON.stringify(false));
  };

  return (
    <DualButtonContainerComponent>
      <DualButtonPrimaryComponent as={as} {...props}>
        {label}
      </DualButtonPrimaryComponent>
      <Tooltip
        trigger="click"
        bodyStyles={'padding: 10px; padding-bottom: 10px;'}
        tooltipText={
          (
            <>
              To finalize the review, click on the <br /> button with the arrow
              icon.
            </>
          ) as any
        }
        placement="top"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-100, 20],
            },
          },
        ]}
        tooltipShown={tooltipVisible}
        onClose={handleTooltipClose}
        stretchChildren={true}
      >
        <DualButtonChevronComponent
          as={as}
          {...props}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <ButtonContent>
            <Icon
              icon="chevron-down"
              size="xs"
              data-testid="chevron-dropdown"
            />
          </ButtonContent>
        </DualButtonChevronComponent>
      </Tooltip>
      {isOpen && (
        <DropdownMenu>
          <DropdownMenuItem
            title={dropdownLabel}
            className="divisor-top"
            onClick={() => {
              dropdownOnClick();
              setIsOpen(false);
            }}
          >
            <Icon icon="check" size="xxs" />
            <span className="text">{dropdownLabel}</span>
          </DropdownMenuItem>
        </DropdownMenu>
      )}
    </DualButtonContainerComponent>
  );
};

export default DualButton;
