import { useState, useEffect } from 'react';

const useLocalStorage = (key, { initialValue } = {}) => {
  const [item, setItem] = useState(initialValue);

  const [method, setMethod] = useState('getItem');

  const [newValue, setNewValue] = useState();

  useEffect(() => {
    try {
      if (method === 'getItem') {
        const item = window.localStorage[method](key);

        setItem(item);

        return;
      }

      if (method === 'setItem') {
        window.localStorage[method](key, newValue);

        setItem(newValue);
      }

      if (method === 'removeItem') {
        window.localStorage[method](key);

        setItem(null);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('useLocalStorage Error: ', error);
      }
    }
  }, [key, method, newValue]);

  return {
    item,

    setItem: (newValue) => {
      setMethod('setItem');
      setNewValue(newValue);
    },

    removeItem: () => {
      setMethod('removeItem');
    },
  };
};

export default useLocalStorage;
