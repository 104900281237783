import dayjs from 'dayjs';

export const genderMapper = (value) => {
  if (typeof value !== 'number') {
    return undefined;
  }
  const obj = ['Male', 'Female', 'Unknown'];
  return obj[value];
};

export const calculateBusinessDays = (date, days) => {
  if (!days || days < 0) {
    return 'Invalid date calculation';
  }

  let result = dayjs(date);
  let count = 0;
  while (count < days) {
    result = result.add(1, 'day');
    if (result.day() !== 0 && result.day() !== 6) {
      count++;
    }
  }
  return result.toDate();
};
