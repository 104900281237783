import styled from 'styled-components';
import { Button } from 'components/atoms';
import DatePicker from 'react-multi-date-picker';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(30)};
  border-radius: 5px;

  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(30)};
  }
`;

export const FieldsContainer = styled.div.attrs<{ maxWidth?: string | number }>(
  ({ maxWidth }) => ({
    maxWidth: maxWidth ?? '50%',
  })
)<{ maxWidth?: string | number }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const FormHeader = styled.h2`
  font-size: 24px;
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};
`;

export const SubHeader = styled.h3`
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};
`;

export const FormSubheading = styled.p`
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(18)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  letter-spacing: 0;
  line-height: 1.6667;
`;

export const ButtonWrapper = styled.div.attrs<{ justifyContent?: string }>(
  ({ justifyContent }) => ({
    justifyContent: justifyContent ?? 'start',
  })
)<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: ${({ theme: { spacing } }) => spacing(32)};

  p.error {
    margin: 0;
    margin-bottom: ${({ theme: { spacing } }) => spacing(15)};
  }

  > button ~ button {
    margin-left: ${({ theme: { spacing } }) => spacing(35)};
  }

  .button-spinner {
    min-width: ${({ theme }) => theme.pxToRem(166)};

    &:has(.spinner) {
      padding: 10px;
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }
`;

export const FixedWidthButton = styled(Button)`
  min-width: ${({ theme: { spacing } }) => spacing(196)};
`;

export const DatePickerComponent = styled(DatePicker)`
  border: 1px solid ${({ theme }) => theme?.colours?.charcoalDust};
  border-radius: 5px;
  font-size: ${({ theme }) => theme.spacing(14)};
  line-height: ${({ theme }) => theme.spacing(22)};
  padding: ${({ theme }) =>
    theme.spacing({ left: 20, top: 15, right: 20, bottom: 15 })};
`;

export const FormInlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SelectedItemCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme: { colours } }) => colours.gallery};
  color: ${({ theme: { colours } }) => colours.crowberryBlue};
  border-radius: 6px;
  padding: ${({ theme: { pxToRem } }) => pxToRem(20)};
  margin-bottom: ${({ theme: { pxToRem } }) => pxToRem(20)};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.light};
  background-color: ${({ theme: { colours } }) => colours.gallery};

  .row {
    display: flex;
    margin-bottom: ${({ theme: { pxToRem } }) => pxToRem(10)};
  }

  .label {
    color: ${({ theme }) => theme.colours.charcoalDust};
    margin-right: ${({ theme: { pxToRem } }) => pxToRem(10)};
  }

  .value {
    color: ${({ theme: { colours } }) => colours.crowberryBlue};
  }
`;

export const ThirdPartyError = styled.div`
  margin-top: 0;
  font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.light};
  color: ${({ theme }) => theme.colours.redRampage} !important;
`;

export const EntityWrapper = styled.div`
  column-count: 2;
  column-gap: ${({ theme: { spacing } }) => spacing(20)};

  > * {
    break-inside: avoid;
  }

  > * + * {
    margin-top: ${({ theme: { spacing } }) => spacing(20)};
  }
`;

export const EntityContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(20)};
  border: 1px solid
    ${(props) =>
      props.selected
        ? ({ theme: { colours } }) => colours.crowberryBlue
        : ({ theme: { colours } }) => colours.gallery};
  color: ${({ theme: { colours } }) => colours.charcoalDust};
  border-radius: 6px;
`;

export const EntityHeader = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom, theme: { spacing } }) =>
    marginBottom ? marginBottom : spacing(10)};

  strong {
    text-transform: capitalize;
    color: ${({ theme: { colours } }) => colours.crowberryBlue};
  }

  .chip {
    margin-left: ${({ theme: { spacing } }) => spacing(12)};
    transform: ${({ theme: { spacing } }) => spacing(2)};
  }
`;

export const EntityTray = styled.div`
  display: flex;
  padding-left: ${({ theme: { spacing } }) => spacing(10)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
  margin-left: auto;
`;

export const EntityActionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme: { colours } }) => colours.swimmersPool};
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 20px;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(30)};
  margin-top: ${({ theme: { spacing } }) => spacing(32)};
`;

export const ArrayItem = styled.div`
  display: flex;
`;
