import styled from 'styled-components';

export const DialogComponent = styled.div`
  &:before {
    z-index: ${({ theme }) => theme.zIndex.level_3};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    content: '';
  }
`;

export const DialogOverlayComponent = styled.div`
  z-index: ${({ theme }) => theme.zIndex.level_3 + 1};
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: min(90vw, ${({ theme }) => theme.pxToRem(984)});
  padding: ${({ theme }) => theme.spacing(40)};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ theme }) => theme.colours.white};
  transform: translate(-50%, -50%);
  box-shadow: 0 ${({ theme }) => theme.pxToRem(10)}
    ${({ theme }) => theme.pxToRem(30)} 0 rgba(0, 0, 0, 0.05);
`;

export const DialogHeading = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(30)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.gallery};
`;

export const DialogClose = styled.button`
  margin-left: auto;
  cursor: pointer;
`;

export const DialogTitle = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  letter-spacing: 0;
  line-height: 1.25;
`;

export const DialogBody = styled.div`
  max-height: 75vh;
  padding: ${({ theme }) => theme.spacing(30)} 0;
  color: ${({ theme }) => theme.colours.charcoalDust};
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DialogFooter = styled.div`
  padding-top: ${({ theme }) => theme.spacing(30)};
  border-top: 1px solid ${({ theme }) => theme.colours.gallery};
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: ${({ theme }) => theme.spacing(20)};
  }
`;
