import React, { useState } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ForgotPasswordResponse } from './types';

import { Button, FormControl, FieldInput, Link } from 'components/atoms';

import {
  ForgotPasswordContainer,
  ResponseMessage,
} from './ForgotPassword.styles';

import LoginLayout from 'layouts/login-layout';

import { useConfig } from 'config';

function ForgotPassword() {
  const { vantageWebApi, msalSubscriptionKey } = useConfig();

  const [emailAddress, setEmailAddress] = useState(null);
  const [forgotPasswordResponse, setForgotPasswordResponse] =
    useState<ForgotPasswordResponse>(null);
  const [sendingEmail, setSendingEmail] = useState(false);

  const schema = yup.object().shape({
    emailAddress: yup
      .string()
      .required('You must enter a valid email address')
      .email('You must enter a valid email address'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors: formValidationErrors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    setSendingEmail(true);
    setEmailAddress(formData.emailAddress);

    const response = await fetch(`${vantageWebApi}/account/forgotpassword`, {
      method: 'POST',
      body: JSON.stringify({
        email: formData.emailAddress,
      }),
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
      },
    });

    if (response.status === 200) {
      setForgotPasswordResponse('SUCCESS');
    } else {
      setForgotPasswordResponse('FAIL');
      setSendingEmail(false);
    }
  };

  return (
    <LoginLayout>
      <ForgotPasswordContainer>
        <h1>Password Reset</h1>
        <p>
          Can't remember your password? Just tell us your email address and
          we'll send you an email so you can reset it.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            errorMessage={formValidationErrors?.emailAddress?.message}
          >
            <FieldInput
              name="emailAddress"
              id="emailAddress"
              required
              register={register}
              valid={formValidationErrors?.emailAddress}
              placeholder="Email address"
              label="Email address"
              fullWidth={true}
            />
          </FormControl>

          <Button type="submit" disabled={sendingEmail}>
            Email secure link
          </Button>
        </form>
        {forgotPasswordResponse !== null && (
          <ResponseMessage $responseType={forgotPasswordResponse}>
            {forgotPasswordResponse === 'SUCCESS' && (
              <>
                If we have an account for {emailAddress} we'll send an email.
                Please check your inbox. If you can’t find the email please
                check your junk folder.
              </>
            )}
            {forgotPasswordResponse === 'FAIL' && (
              <>Something went wrong, please try again later.</>
            )}
          </ResponseMessage>
        )}
        <hr />
        <h2>Still need help?</h2>
        <Button as="a" href="mailto:vantagesupport@controlrisks.com" outline>
          Contact support
        </Button>
        <Link to="/login">Back to login page</Link>
      </ForgotPasswordContainer>
    </LoginLayout>
  );
}

export default withAITracking(reactPlugin, ForgotPassword);
