import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import {
  Abbreviation,
  Button,
  Country,
  Date,
  Icon,
  Loading,
  Tooltip,
} from 'components/atoms';
import { ActionMenu, Indicator, SectionHeading } from 'components/molecules';
import { SimpleTable } from 'pages/third-party-profile/ThirdPartyProfile.styles';
import {
  ActionMenuWrapper,
  RelatedEntitiesComponent,
  RelatedEntityName,
  RiskRatingIcon,
} from './RelatedEntities.styles';
import { statusMapper } from 'services/status-mapper';
import RelatedEntitiesModal, {
  ModalContextProvider,
} from './related-entity-modal';
import { GatewayPermissions } from 'auth/constants/permissions';
import { ActionMenuActions } from './types';
import RelatedEntityDeleteModal from './related-entity-delete-modal';
import { ScreeningRiskSummary } from '../screening-risks/config';
import generateTheme from 'theme';

const theme = generateTheme();

type ThirdPartyProfileRelatedEntitiesProps = {
  relatedEntityFieldsConfigs: {
    isIndividualRelationshipToThirdPartyMandatory: boolean;
    isCompanyRelationshipToThirdPartyMandatory: boolean;
    isIndividualDateOfBirthMandatory: boolean;
  };
};

const ThirdPartyProfileRelatedEntities: React.FC<ThirdPartyProfileRelatedEntitiesProps> =
  ({ relatedEntityFieldsConfigs }) => {
    const history = useHistory();
    const { accessToken, hasPermission } = useAuth();
    const { vantageWebApi } = useConfig();
    const { id } = useParams<{ id: string }>();
    const [openEditModal, setOpenEditModal] = useState(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [relatedEntityToBeDeleted, setRelatedEntityToBeDeleted] =
      useState<number>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const {
      data: relatedEntities,
      responseStatus: responseStatus,
      loading,
      error,
      trigger,
    } = useFetch(`${vantageWebApi}/thirdParty/${id}/relatedentities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const handleOnCloseModal = (isComplete: boolean) => {
      setOpenCreateModal(false);
      setOpenEditModal(null);

      if (isComplete) {
        trigger();
      }
    };

    const closeDeleteModal = () => {
      setDeleteModalOpen(false);
      setRelatedEntityToBeDeleted(null);
    };

    const canDeleteRelatedEntities: boolean = hasPermission(
      GatewayPermissions.DeleteThirdParty
    );

    const hasActions: boolean = canDeleteRelatedEntities;

    function getPermittedActions(id: number): ActionMenuActions {
      let actions = [];

      if (canDeleteRelatedEntities) {
        actions.push({
          name: 'Delete',
          onClick: () => {
            setRelatedEntityToBeDeleted(id);
            setDeleteModalOpen(true);
          },
        });
      }

      return actions;
    }

    return (
      <ModalContextProvider>
        <RelatedEntitiesComponent>
          <SectionHeading dark border text="Related entities">
            {hasPermission(
              GatewayPermissions.CreateThirdPartyAndRelatedEntity
            ) && (
              <Button text dark onClick={() => setOpenCreateModal(true)}>
                Create new related entity
              </Button>
            )}
          </SectionHeading>
          {loading ? (
            <Loading />
          ) : error || (responseStatus && responseStatus !== 200) ? (
            <p>Error loading related entities</p>
          ) : (
            <SimpleTable dark>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Country</th>
                  <th>Created</th>
                  <th>Relationship</th>
                  <th>DD Type</th>
                  <th>Status</th>
                  <th>Screening Summary</th>
                  {canDeleteRelatedEntities && <th></th>}
                </tr>
              </thead>
              <tbody>
                {relatedEntities?.length > 0 &&
                  relatedEntities?.map((relatedEntity, i) => (
                    <tr key={relatedEntity?.id}>
                      <td>
                        <RelatedEntityName
                          onClick={() => {
                            if (relatedEntity?.screeningRelatedEntityResponse) {
                              history.push(
                                `/third-party/screening/${relatedEntity?.screeningRelatedEntityResponse?.screeningId}`
                              );
                            }
                            setOpenEditModal(i);
                          }}
                        >
                          {relatedEntity?.name}
                          <Indicator
                            name={relatedEntity?.name}
                            icon={
                              relatedEntity?.type === 'Individual'
                                ? 'user'
                                : 'company'
                            }
                          />
                        </RelatedEntityName>
                      </td>
                      <td>{relatedEntity?.id}</td>
                      <td>
                        {relatedEntity?.primaryCountry && (
                          <Country
                            countryCode={relatedEntity?.primaryCountry}
                          />
                        )}
                      </td>
                      <td>
                        {relatedEntity.createdDate && (
                          <Date dateTime={relatedEntity.createdDate} />
                        )}
                      </td>
                      <td>{relatedEntity?.linkType}</td>
                      <td>
                        <Abbreviation
                          abbreviation={
                            relatedEntity?.screeningRelatedEntityResponse
                              ?.dueDiligenceType
                          }
                        />
                      </td>
                      <td>
                        {relatedEntity?.screeningRelatedEntityResponse
                          ? statusMapper(
                              relatedEntity?.screeningRelatedEntityResponse
                                ?.screeningReportStatusId,
                              relatedEntity?.screeningRelatedEntityResponse
                                ?.currentScreeningStateId,
                              relatedEntity?.screeningRelatedEntityResponse
                                ?.dueDiligenceType,
                              relatedEntity?.screeningRelatedEntityResponse
                                ?.reportStatus
                            )
                          : 'New'}
                        {relatedEntity?.screeningRelatedEntityResponse
                          ?.currentScreeningStateId && (
                          <Abbreviation
                            abbreviation={
                              relatedEntity?.screeningRelatedEntityResponse
                                ?.currentScreeningStateId
                            }
                          />
                        )}
                      </td>
                      <td>
                        <RiskRatingIcon
                          aria-label="Watchlist"
                          risk={
                            relatedEntity?.screeningRelatedEntityResponse
                              ?.watchlistNotesRiskScore || 'Unknown'
                          }
                        >
                          <Tooltip
                            tooltipText={ScreeningRiskSummary.WatchList}
                            placement="bottom"
                            trigger="click"
                          >
                            <Icon icon="sanctions" aria-hidden />
                          </Tooltip>
                        </RiskRatingIcon>
                        <RiskRatingIcon
                          aria-label="Enforcements"
                          risk={
                            relatedEntity?.screeningRelatedEntityResponse
                              ?.enforcementsNotesRiskScore || 'Unknown'
                          }
                        >
                          <Tooltip
                            tooltipText={ScreeningRiskSummary.Enforcement}
                            placement="bottom"
                            trigger="click"
                          >
                            <Icon icon="enforcements" aria-hidden />
                          </Tooltip>
                        </RiskRatingIcon>
                        <RiskRatingIcon
                          aria-label="Adverse Media"
                          risk={
                            relatedEntity?.screeningRelatedEntityResponse
                              ?.adverseNotesRiskScore || 'Unknown'
                          }
                        >
                          <Tooltip
                            tooltipText={ScreeningRiskSummary.AdverseMedia}
                            placement="bottom"
                            trigger="click"
                          >
                            <Icon icon="adverseMedia" aria-hidden />
                          </Tooltip>
                        </RiskRatingIcon>
                        <RiskRatingIcon
                          aria-label="Political Exposure"
                          risk={
                            relatedEntity?.screeningRelatedEntityResponse
                              ?.politicalNotesRiskScore || 'Unknown'
                          }
                        >
                          <Tooltip
                            tooltipText={
                              ScreeningRiskSummary[
                                `PoliticalExposure${relatedEntity?.type}`
                              ]
                            }
                            placement="bottom"
                            trigger="click"
                          >
                            <Icon icon="political" aria-hidden size="xxs" />
                          </Tooltip>
                        </RiskRatingIcon>
                      </td>
                      {hasActions && (
                        <ActionMenuWrapper>
                          <ActionMenu
                            actions={getPermittedActions(relatedEntity.id)}
                            name={`Manage ${relatedEntity.id}`}
                            ellipsisColour={theme.colours.white}
                          />
                        </ActionMenuWrapper>
                      )}
                      <RelatedEntitiesModal
                        relatedEntity={relatedEntity}
                        relatedEntityFieldsConfigs={relatedEntityFieldsConfigs}
                        thirdPartyId={parseInt(id)}
                        isOpen={openEditModal === i}
                        onClose={handleOnCloseModal}
                      />
                    </tr>
                  ))}
              </tbody>
            </SimpleTable>
          )}
          <RelatedEntitiesModal
            isOpen={openCreateModal}
            onClose={handleOnCloseModal}
            thirdPartyId={parseInt(id)}
            notifyToAnalist
            relatedEntityFieldsConfigs={relatedEntityFieldsConfigs}
          />
          {canDeleteRelatedEntities && (
            <RelatedEntityDeleteModal
              isOpen={deleteModalOpen}
              onClose={closeDeleteModal}
              relatedEntityId={relatedEntityToBeDeleted}
              thirdPartyId={parseInt(id)}
            />
          )}
        </RelatedEntitiesComponent>
      </ModalContextProvider>
    );
  };

export default ThirdPartyProfileRelatedEntities;
