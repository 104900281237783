import styled from 'styled-components';

export const DefaultText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  margin: 0 ${({ theme }) => theme.spacing(10)};
`;

export const LoadingText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  margin: 0 ${({ theme }) => theme.spacing(10)};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 0.5;
  }
`;

export const ModalTitle = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-size: ${({ theme }) => theme.pxToRem(32)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.25;
`;
