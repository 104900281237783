import styled from 'styled-components';

export const ModalContent = styled.div`
  font-size: 16px;
  padding: ${({ theme }) =>
    `${theme.spacing(20)} ${theme.spacing(40)} ${theme.spacing(40)}`};

  .subtitle {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiRegular};
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  a {
    color: ${({ theme }) => theme.colours.deepWater};
    font-size: 14px;
  }
`;

export const ScrollableContainer = styled.div`
  max-height: 141px;
  overflow-y: auto;
  margin-bottom: ${({ theme }) => theme.pxToRem(40)};

  table thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .trigger {
    display: block; //center tooltip
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-radius: 4px;
  text-align: left;
  color: ${({ theme }) => theme.colours.carbonFiber};

  th,
  td {
    padding: 11px 30px;
    font-size: 14px;
  }

  thead {
    background-color: ${({ theme }) => theme.colours.crowberryBlue};
    color: ${({ theme }) => theme.colours.white};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    th:first-child {
      border-top-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.colours.farsighted};
      }

      td.id {
        max-width: 160px;
      }

      td:first-child {
        border-left: 1px solid ${({ theme }) => theme.colours.farsighted};
      }

      td:last-child {
        border-right: 1px solid ${({ theme }) => theme.colours.farsighted};
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }

      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  a.disabled {
    cursor: default;
    color: ${({ theme }) => theme.colours.agedGray};
    text-decoration-color: ${({ theme }) => theme.colours.agedGray};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(30)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(32)};

  .button {
    width: ${({ theme }) => theme.pxToRem(223)};
  }
`;
