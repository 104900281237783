import styled from 'styled-components';

export const ModalParagraph = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(18)};
`;

export const ReportProceedingModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTopContentContainer = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ApprovalModalTitle = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(32)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.25;
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(32)};

  .button {
    width: ${({ theme }) => theme.pxToRem(166)};

    &:has(.spinner) {
      padding: 10px;
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }
`;
