import styled from 'styled-components';

export const Wrapper = styled.aside`
  width: 100%;
  color: ${({ theme: { colours } }) => colours.charcoalDust};
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(30)};
  border-radius: 5px;
`;

export const SummaryHeader = styled.h2`
  font-size: 24px;
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};
`;

export const ItemLabel = styled.span`
  display: block;
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
`;

export const SummaryDetails = styled.div``;

export const SummaryItem = styled.div`
  font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  border-top: 1px solid ${({ theme: { colours } }) => colours.gallery};
  padding: ${({ theme: { spacing } }) => spacing(10)} 0;
  display: grid;
  grid-gap: ${({ theme: { spacing } }) => spacing(10)};
  grid-template-columns: 1fr auto;
  align-items: first baseline;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme: { colours } }) => colours.gallery};
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  animation: all 0.5s ease-in-out;

  &:hover {
    .icon {
      svg {
        color: ${({ theme: { colours } }) => colours.crowberryBlue};
      }
    }
  }
`;
