import React, { FC } from 'react';
import { Callout, Icon } from 'components/atoms';
import { ActionMenuItem } from './ActionMenu.styles';
import { ActionMenuProps } from './types';

const ActionMenu: FC<ActionMenuProps> = ({
  actions,
  name,
  ellipsisColour = 'black',
}) => (
  <Callout
    direction="left"
    name={name}
    trigger={() => (
      <Icon icon="more" aria-hidden size="xs" colour={ellipsisColour} />
    )}
  >
    {actions.map(({ name, onClick }) => (
      <ActionMenuItem key={name} onClick={onClick}>
        {name}
      </ActionMenuItem>
    ))}
  </Callout>
);

export default ActionMenu;
