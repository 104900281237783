/* eslint-disable no-case-declarations */
import { StateType, ReducerActionsType } from './types';

export const entityInitialState = {
  name: null,
  alternativeName: null,
  primaryCountry: null,
  linkType: null,
  addressLineOne: null,
  zipCode: null,
  city: null,
  county: null,
  countryTwo: null,
  countryThree: null,
  duns: null,
  registrationNumber: null,
  swift: null,
  gender: null,
  dateOfBirth: null,
  nationality: null,
  passportNumber: null,
  nationalIDNumber: null,
  otherIDNumber: null,
  referenceFieldOne: null,
  referenceFieldTwo: null,
  referenceFieldThree: null,
};

export const initialState: StateType = {
  countryFilter: {
    countryList: [],
    selectedCountries: [],
  },
  reasonListedFilter: {
    selectedReasons: [],
  },
  matches: [],
};

export const reducer = (state: StateType, action: ReducerActionsType) => {
  switch (action.type) {
    case 'ADD_MATCH': {
      const tempArray = [...state.matches];
      const array = tempArray.map((match) => {
        const obj = { ...match };
        if (obj.id === action.payload.id) obj.state = action.payload.value;
        return obj;
      });

      if (state.matches.find((elem) => elem.id === action.payload.id)) {
        return {
          ...state,
          matches: array,
        };
      } else {
        return {
          ...state,
          matches: [
            ...state.matches,
            ...[{ id: action.payload.id, state: action.payload.value }],
          ],
        };
      }
    }
    case 'REMOVE_MATCH':
      return {
        ...state,
        matches: state.matches.filter(({ id }) => id !== action.payload.id),
      };
    case 'ADD_SELECT_COUNTRY':
      return {
        ...state,
        countryFilter: {
          ...state.countryFilter,
          selectedCountries: [
            ...state.countryFilter.selectedCountries,
            ...[action.payload],
          ],
        },
      };
    case 'REMOVE_SELECT_COUNTRY':
      return {
        ...state,
        countryFilter: {
          ...state.countryFilter,
          selectedCountries: state.countryFilter.selectedCountries.filter(
            (countryID) => countryID !== action.payload
          ),
        },
      };
    case 'CLEAR_COUNTRY_FILTER':
      return {
        ...state,
        countryFilter: {
          ...state.countryFilter,
          selectedCountries: [],
        },
      };
    case 'ADD_REASON_LISTED':
      return {
        ...state,
        reasonListedFilter: {
          ...state.reasonListedFilter,
          selectedReasons: [
            ...state.reasonListedFilter.selectedReasons,
            ...[action.payload],
          ],
        },
      };
    case 'REMOVE_REASON_LISTED':
      return {
        ...state,
        reasonListedFilter: {
          ...state.reasonListedFilter,
          selectedReasons: state.reasonListedFilter.selectedReasons.filter(
            (reason) => reason !== action.payload
          ),
        },
      };
    case 'CLEAR_REASON_FILTER':
      return {
        ...state,
        reasonListedFilter: {
          ...state.reasonListedFilter,
          selectedReasons: [],
        },
      };
    default:
      return {
        ...state,
      };
  }
};
