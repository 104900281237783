import ConfirmationModal from 'components/organisms/confirmation-modal';
import React, { useState, useEffect } from 'react';
import { ThirdPartyDeleteModalProps } from './types';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';

const ThirdPartyDeleteModal: React.FC<ThirdPartyDeleteModalProps> = ({
  isOpen,
  onClose,
  thirdPartyId,
  updateResults,
}) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const [loadingStatus, setLoadingStatus] = useState<string>('not started');
  const [title, setTitle] = useState<string>('Are you sure?');

  const message =
    'Please note that this will delete the Third Party plus any associated documents, screenings, due diligence reports and related entities.\n\n Additionally, if there are currently any "in progress" due dilgence reports, the order will be cancelled and there may be a cost for this.  A member of the Control Risks team will contact you to discuss.';

  const { loading, trigger } = useFetch(
    `${vantageWebApi}/thirdParty/${thirdPartyId}`,
    {
      method: 'DELETE',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  useEffect(() => {
    if (loading) {
      setLoadingStatus('started');
      setTitle('Deleting, please wait...');
    }
    if (!loading && loadingStatus === 'started') {
      setLoadingStatus('completed');
    }
    if (loadingStatus === 'completed') {
      setLoadingStatus('not started');
      onClose();
      updateResults();
      setTitle('Are you sure?');
    }
  }, [loading, loadingStatus, updateResults, onClose]);

  const handleProceed = (): void => {
    if (thirdPartyId == null) {
      return;
    }
    trigger();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      title={title}
      onProceed={handleProceed}
      message={message}
      loading={loading}
    ></ConfirmationModal>
  );
};

export default ThirdPartyDeleteModal;
