import React, { FC } from 'react';
import {
  WelcomeMessageComponent,
  WelcomeMessageButton,
} from './WelcomeMessage.styles';
import { WelcomeMessageTypes } from './types';
import { Callout } from 'components/atoms';

const WelcomeMessage: FC<WelcomeMessageTypes> = ({
  avatar,
  dark = false,
  name,
}) => (
  <WelcomeMessageComponent dark={dark}>
    <Callout
      name="User account menu"
      trigger={() => (
        <>
          {name && (
            <p>
              Welcome Back, <strong>{name}</strong>
            </p>
          )}
          {avatar && (
            <img
              src={avatar}
              alt={`${name} user avatar`}
              width="45"
              height="45"
            />
          )}
        </>
      )}
    >
      <WelcomeMessageButton
        onClick={() => {
          localStorage.clear();
          window.location.href = '/login';
        }}
      >
        Logout
      </WelcomeMessageButton>
    </Callout>
  </WelcomeMessageComponent>
);

export default WelcomeMessage;
