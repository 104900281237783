import React, { FC } from 'react';
import { Abbreviation, Date, DescriptionList, Icon } from 'components/atoms';
import { abbreviations } from 'components/atoms/abbreviation/config';
import {
  TimelineCardComponent,
  Attributes,
  Time,
  Content,
  IconList,
} from './TimelineCard.styles';
import { TimelineCardProps } from './types';

const TimelineCard: FC<TimelineCardProps> = ({
  content,
  action,
  dateTime,
  name,
  performedBy,
  screeningType,
  type,
  screeningLevel,
}) => (
  <TimelineCardComponent>
    <Time>
      <Date dateTime={dateTime} format="D MMMM, YYYY, HH:mm z" />
    </Time>
    <Content>
      <IconList>
        <li>
          <span>
            <Icon
              icon={type === 'Individual' ? 'user' : 'company'}
              size="xxs"
              aria-hidden
            />
          </span>
          {name}
        </li>
        {screeningType && (
          <li>
            <span>
              <Abbreviation abbreviation={screeningType} outline small />
            </span>
            {abbreviations?.[screeningType]?.long || screeningType}{' '}
            {screeningLevel || ''}
          </li>
        )}
        {action && (
          <li className="action">
            <span>
              <Icon icon="check" size="xxs" aria-hidden />
            </span>
            {action}
          </li>
        )}
        {content?.ScreeningId && (
          <li className="action">
            <span></span>
            {screeningType.includes('Screening')
              ? 'Screening ID: '
              : 'Report ID: '}
            {content.ScreeningId}
          </li>
        )}
        {content?.ReportId && (
          <li className="action">
            <span></span>
            Report ID: {content.ReportId}
          </li>
        )}
      </IconList>
      {performedBy && (
        <Attributes>
          <DescriptionList
            items={[{ key: 'Performed by', value: performedBy }]}
          />
        </Attributes>
      )}
      {/* <Link href={href}>Read more</Link> */}
    </Content>
  </TimelineCardComponent>
);

export default TimelineCard;
