import styled from 'styled-components';
import { Typography } from 'components/atoms';
import { Panel } from 'components/molecules';

export const ProfileContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: masonry;
  grid-gap: ${({ theme }) => theme.spacing(36)};
`;

export const MessageContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(40)};
`;

export const ContentSection = styled.section``;

export const ContentTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(19)};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
`;

export const ContentContainer = styled(Panel)``;

export const ItemMatch = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(20)};
  margin-top: ${({ theme }) => theme.spacing(20)};

  > * {
    background-color: #fff;
    padding: ${({ theme }) => theme.spacing(10)};
  }
`;

export const QuickLinks = styled.div`
  padding: ${({ theme }) => theme.spacing(20)} 0;

  a {
    border-color: currentColor;
    margin: 0 ${({ theme }) => theme.pxToRem(10)}
      ${({ theme }) => theme.pxToRem(10)} 0;
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  }
`;

export const QuickLinksContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(20)} 0;
`;

export const SourceLinks = styled.p`
  a {
    display: inline-block;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &,
  a {
    color: ${({ theme }) => theme.colours.pacificBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: 1.5;
  }
`;

export const WorldComplianceLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colours.pacificBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: 1.5;
  text-decoration: none;
`;

export const Label = styled.div`
  display: inline;
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1;
  color: ${({ theme }) => theme.colours.charcoalDust};
`;

export const ProfileNotes = styled.p`
  color: ${({ theme }) => theme.colours.charcoalDust};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  white-space: pre-line;
  line-height: 1.5;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(20)} 0;
  margin-top: ${({ theme }) => theme.spacing(20)};
  border-top: ${({ theme: { colours } }) => `1px solid ${colours.whiteSolid}`};
`;
