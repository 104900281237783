import styled from 'styled-components';
import IcoMoon from 'react-icomoon';
import { IconSizes } from 'components/atoms/icon/types';

export const sizes = [
  { size: 'xxxxxs', value: '10px' },
  { size: 'xxxxs', value: '12px' },
  { size: 'xxxs', value: '14px' },
  { size: 'xxs', value: '16px' },
  { size: 'xs', value: '20px' },
  { size: 'sm', value: '25px' },
  { size: 'md', value: '30px' },
  { size: 'lg', value: '35px' },
  { size: 'xl', value: '40px' },
];

const renderSize = ({ size = 'md' }: { size: IconSizes }) =>
  sizes.find((item) => item.size === size).value;

export const IconComponent = styled(IcoMoon)`
  width: ${({ size }: { size: IconSizes }) => renderSize({ size })};
  min-width: ${({ size }: { size: IconSizes }) => renderSize({ size })};
  height: ${({ size }: { size: IconSizes }) => renderSize({ size })};
  cursor: ${({ cursor }) => cursor || 'auto'};
`;

type WrapperProps = { background?: string };

export const Wrapper = styled.span<WrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({ theme: { pxToRem }, background }) =>
    background
      ? `
  background: ${background};
  padding: ${pxToRem(10)};
  `
      : ``}
`;
