import styled, { css } from 'styled-components';

interface IViewContainer {
  $bgColor: string;
}

export const AppLayoutComponent = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const TopContainer = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.pxToRem(64)});
`;

export const ViewContainer = styled.main<IViewContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - ${({ theme }) => theme.pxToRem(64)});
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(30)}
    ${({ theme }) => theme.spacing(60)};

  color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ $bgColor }) =>
    $bgColor
      ? css`
          background-color: ${({ theme }) => theme?.colours?.[$bgColor]};
        `
      : css`
          background-image: linear-gradient(
            270deg,
            ${({ theme }) => theme.colours.porpoisePlace} 0%,
            ${({ theme }) => theme.colours.crowberryBlue} 100%
          );

          h1 {
            color: white;
          }
        `};
`;

export const TopBar = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(20)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
