import React, { FC, PropsWithChildren } from 'react';
import {
  ButtonContainer,
  ModalContent,
  StyledTable,
} from './DueDiligenceConfirmationModal.styles';

import { DueDiligenceConfirmationModalTypes } from './types';
import { Modal } from 'components/molecules';
import { Button } from 'components/atoms';
import { ReactComponent as PaperclipIcon } from './../../../assets/svg/Icons/Paperclip.svg';

const DueDiligenceConfirmationModal: FC<DueDiligenceConfirmationModalTypes> = ({
  isOpen,
  thirdPartyInfo,
  section,
  onClose,
  terminologyConfig,
  onCancel,
  onProceed,
}: DueDiligenceConfirmationModalTypes) => {
  const {
    thirdPartyLabel,
    name,
    internalId,
    dueDiligenceType,
    level,
    attachments,
  } = thirdPartyInfo;

  const { title, blocks } = section;
  return (
    <Modal
      removePadding
      onClose={onClose}
      isOpen={isOpen}
      title="Submit order?"
      scrollableBody={false}
    >
      <ModalContent>
        <>
          <p className="description">
            Your Due Diligence order is ready to be submitted.
          </p>
          <StyledTable>
            <thead>
              <tr>
                <th>{thirdPartyLabel} name</th>
                <th>
                  {terminologyConfig?.internalIdSingularNaming ?? 'Internal ID'}
                </th>
                <th>Compliance check type</th>
                <th>Level</th>
                <th>Attachments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{name}</td>
                <td>{internalId}</td>
                <td>{dueDiligenceType}</td>
                <td>{level}</td>
                <td>
                  <span>
                    <PaperclipIcon />
                    <span>{attachments}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </StyledTable>
          <Section title={title}>
            {blocks.map(({ title, lines }, idx) => (
              <Block key={title + idx} title={title} lines={lines} />
            ))}
          </Section>
          <ButtonContainer>
            <Button
              text
              className="button cancel"
              title="cancel"
              onClick={() => onCancel && onCancel()}
              aria-label="cancel"
            >
              Cancel
            </Button>
            <Button
              secondary
              className="button"
              title="submit"
              type="button"
              onClick={() => onProceed && onProceed()}
              aria-label="Submit"
              data-testid="confirmation-modal-submit"
            >
              Submit order
            </Button>
          </ButtonContainer>
        </>
      </ModalContent>
    </Modal>
  );
};

const Section: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <section>
      <div className="section-title">
        <span className="section-title-text">{title}</span>
        <span className="section-title-line"></span>
      </div>
      <div className="section-container">{children}</div>
    </section>
  );
};

const Block: FC<{ title: string; lines: string[] }> = ({ title, lines }) => {
  return (
    <div>
      <div className="block-title">{title}</div>
      <div className="block-lines">
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </div>
  );
};

export default DueDiligenceConfirmationModal;
