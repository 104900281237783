import React, { useState } from 'react';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { TerminologyConfigContextType } from 'context/terminologyConfig/types';
import { GatewayPermissions } from 'auth/constants/permissions';
import { ThirdPartyOverviewSideModuleProps, ThirdPartyOwner } from './types';
import { Container, DefaultText } from './ThirdPartyOverviewSideModule.styles';
import { Collapsible } from 'components/atoms';
import SideModule from 'components/molecules/side-module';
import { ThirdPartyEdit } from 'components/organisms/third-party-edit';
import Modal from 'components/molecules/modal';
import { getName } from 'country-list';

const ThirdPartyOverviewSideModule: React.FC<ThirdPartyOverviewSideModuleProps> =
  ({ thirdParty, thirdPartyType }) => {
    let overview: JSX.Element;
    const { hasPermission } = useAuth();
    const terminologyConfig = useTerminologyConfig();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [thirdPartyDetails, setThirdPartyDetails] = useState(thirdParty);

    const onEditThirdParty = (updated: any) => {
      updated.referenceField1 = updated.referenceFieldOne;
      updated.referenceField2 = updated.referenceFieldTwo;
      updated.referenceField3 = updated.referenceFieldThree;
      setThirdPartyDetails(updated);
      setIsModalOpen(false);
    };

    if (thirdParty?.type === 'Business') {
      overview = (
        <BusinessOverview
          thirdParty={thirdPartyDetails}
          thirdPartyType={thirdPartyType}
          terminologyConfig={terminologyConfig}
        />
      );
    } else if (thirdParty?.type === 'Individual') {
      overview = (
        <IndividualOverview
          thirdParty={thirdPartyDetails}
          thirdPartyType={thirdPartyType}
          terminologyConfig={terminologyConfig}
        />
      );
    } else {
      overview = (
        <DefaultText>
          Unsupported {terminologyConfig?.thirdPartySingularNaming} Type
        </DefaultText>
      );
    }

    let sideModuleProps = {};
    if (thirdPartyType === 'third party') {
      sideModuleProps = {
        header: terminologyConfig?.thirdPartySingularNaming + ' overview',
        onEdit: hasPermission(GatewayPermissions.EditThirdPartyRelatedEntity)
          ? () => setIsModalOpen(true)
          : undefined,
      };
    } else {
      sideModuleProps = {
        header: 'Related entity overview',
      };
    }

    return (
      <>
        <SideModule {...sideModuleProps}>
          <Container>{overview}</Container>
        </SideModule>
        {thirdParty && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={`Edit ${terminologyConfig?.thirdPartySingularNaming} details`}
            removePadding={true}
          >
            <ThirdPartyEdit
              thirdParty={thirdPartyDetails}
              onCancel={() => setIsModalOpen(false)}
              onSave={onEditThirdParty}
              inModal={true}
            />
          </Modal>
        )}
      </>
    );
  };

const GeneralThirdPartyInformation: React.FC<ThirdPartyOverviewSideModuleProps> =
  ({ thirdParty, thirdPartyType, terminologyConfig }) => {
    return (
      <>
        <DefaultText>{`Name: ${
          thirdParty?.name ? thirdParty.name : '-'
        }`}</DefaultText>
        <DefaultText>{`Name (alternative script): ${
          thirdParty?.alternativeName ? thirdParty.alternativeName : '-'
        }`}</DefaultText>
        {thirdPartyType === 'third party' && (
          <>
            <DefaultText>{`${terminologyConfig?.internalIdSingularNaming}: ${
              thirdParty?.internalId ? thirdParty.internalId : '-'
            }`}</DefaultText>
            <DefaultText>{`Next Review Date: ${
              thirdParty?.nextReviewDate
                ? new Date(thirdParty.nextReviewDate).toLocaleDateString(
                    'en-US'
                  )
                : '-'
            }`}</DefaultText>
            <ThirdPartyOwners
              thirdPartyOwners={thirdParty?.thirdPartyOwners}
              terminologyConfig={terminologyConfig}
            />
          </>
        )}
        <Address
          addressLine={thirdParty?.addressLineOne}
          city={thirdParty?.city}
          state={thirdParty?.county}
          postCode={thirdParty?.zipCode}
        />
        <DefaultText>{`Primary Country: ${
          thirdParty?.primaryCountry ? getName(thirdParty.primaryCountry) : '-'
        }`}</DefaultText>
      </>
    );
  };

const BusinessOverview: React.FC<ThirdPartyOverviewSideModuleProps> = ({
  thirdParty,
  thirdPartyType,
  terminologyConfig,
}) => {
  return (
    <>
      <GeneralThirdPartyInformation
        thirdParty={thirdParty}
        thirdPartyType={thirdPartyType}
        terminologyConfig={terminologyConfig}
      />
      <DefaultText>{`DUNS: ${
        thirdParty?.duns ? String(thirdParty.duns) : '-'
      }`}</DefaultText>
      <DefaultText>{`Registration Number: ${
        thirdParty?.registrationNumber
          ? String(thirdParty.registrationNumber)
          : '-'
      }`}</DefaultText>
      <DefaultText>{`SWIFT / BIC: ${
        thirdParty?.swift ? String(thirdParty.swift) : '-'
      }`}</DefaultText>
      <Collapsible collapsedText="Read more" expandedText="Read less">
        {thirdPartyType === 'third party' && (
          <>
            <DefaultText>{`Reference Field 1: ${
              thirdParty?.referenceField1 ? thirdParty.referenceField1 : '-'
            }`}</DefaultText>
            <DefaultText>{`Reference Field 2: ${
              thirdParty?.referenceField2 ? thirdParty.referenceField2 : '-'
            }`}</DefaultText>
            <DefaultText>{`Reference Field 3: ${
              thirdParty?.referenceField3 ? thirdParty.referenceField3 : '-'
            }`}</DefaultText>
          </>
        )}
        {thirdPartyType === 'related entity' && (
          <DefaultText>{`Relationship: ${
            thirdParty?.linkType ? thirdParty.linkType : '-'
          }`}</DefaultText>
        )}
      </Collapsible>
    </>
  );
};

const IndividualOverview: React.FC<ThirdPartyOverviewSideModuleProps> = ({
  thirdParty,
  thirdPartyType,
  terminologyConfig,
}) => {
  const getGender = (intGender: number): string => {
    return intGender === 0 ? 'Male' : 'Female';
  };

  return (
    <>
      <GeneralThirdPartyInformation
        thirdParty={thirdParty}
        thirdPartyType={thirdPartyType}
        terminologyConfig={terminologyConfig}
      />
      <DefaultText>{`Gender: ${
        thirdParty && thirdParty?.gender !== null
          ? getGender(thirdParty.gender)
          : '-'
      }`}</DefaultText>
      <DefaultText>{`Date of birth: ${
        thirdParty && thirdParty?.dateOfBirth
          ? new Date(thirdParty.dateOfBirth).toLocaleDateString('en-US')
          : '-'
      }`}</DefaultText>
      <DefaultText>{`Nationality: ${
        thirdParty && thirdParty?.nationality
          ? getName(thirdParty.nationality)
          : '-'
      }`}</DefaultText>
      <Collapsible collapsedText="Read more" expandedText="Read less">
        <DefaultText>{`Passport Number: ${
          thirdParty && thirdParty?.passportNumber
            ? thirdParty.passportNumber
            : '-'
        }`}</DefaultText>
        <DefaultText>{`National ID: ${
          thirdParty && thirdParty?.nationalIDNumber
            ? thirdParty.nationalIDNumber
            : '-'
        }`}</DefaultText>
        <DefaultText>{`Other ID: ${
          thirdParty && thirdParty?.otherIDNumber
            ? thirdParty.otherIDNumber
            : '-'
        }`}</DefaultText>
        {thirdPartyType && thirdPartyType === 'third party' && (
          <>
            <DefaultText>{`Reference Field 1: ${
              thirdParty && thirdParty?.referenceField1
                ? thirdParty.referenceField1
                : '-'
            }`}</DefaultText>
            <DefaultText>{`Reference Field 2: ${
              thirdParty && thirdParty?.referenceField2
                ? thirdParty.referenceField2
                : '-'
            }`}</DefaultText>
            <DefaultText>{`Reference Field 3: ${
              thirdParty && thirdParty?.referenceField3
                ? thirdParty.referenceField3
                : '-'
            }`}</DefaultText>
          </>
        )}
        {thirdPartyType === 'related entity' && (
          <DefaultText>{`Relationship: ${
            thirdParty && thirdParty?.linkType ? thirdParty.linkType : '-'
          }`}</DefaultText>
        )}
      </Collapsible>
    </>
  );
};

interface ThirdPartyOwnerProps {
  thirdPartyOwners: ThirdPartyOwner[];
  terminologyConfig: TerminologyConfigContextType;
}

const ThirdPartyOwners: React.FC<ThirdPartyOwnerProps> = ({
  thirdPartyOwners,
  terminologyConfig,
}) => {
  let result: JSX.Element;

  if (!thirdPartyOwners) {
    result = (
      <DefaultText>{`${terminologyConfig?.internalOwnerPluralNaming}: -`}</DefaultText>
    );
  } else if (thirdPartyOwners?.length === 0) {
    result = (
      <DefaultText>{`${terminologyConfig?.internalOwnerPluralNaming}: -`}</DefaultText>
    );
  } else {
    result = (
      <>
        <DefaultText>{`${terminologyConfig?.internalOwnerPluralNaming}:`}</DefaultText>
        {thirdPartyOwners.map((owner) => (
          <DefaultText
            key={owner.users.id}
          >{`${owner?.users?.firstName} ${owner?.users?.lastName}`}</DefaultText>
        ))}
      </>
    );
  }

  return result;
};

interface AddressProps {
  addressLine: string;
  city: string;
  state: string;
  postCode: string;
}

const Address: React.FC<AddressProps> = ({
  addressLine,
  city,
  state,
  postCode,
}) => {
  const address = [
    addressLine ? addressLine : null,
    city ? city : null,
    state ? state : null,
    postCode ? postCode : null,
  ]
    .filter((x) => x !== null)
    .join(' ');

  return <DefaultText>{`Address: ${address || '-'}`}</DefaultText>;
};

export default ThirdPartyOverviewSideModule;
