import React, { useContext, useRef, useState } from 'react';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import FilterContext from 'context/third-party-filter';
import { Tabs } from 'components/molecules';
import Drawer from 'components/organisms/drawer-modal';
import DownloadSummaryTab from '../report-actions/DownloadSummary';
import SearchReportTab from '../report-actions/SearchReportId';
import ConfirmationModal from 'components/organisms/confirmation-modal';
import { TabContent } from 'pages/third-party-profile/ThirdPartyProfile.styles';

const ReportActionsDrawer = ({
  thirdpartySearchTerm,
  showAllThirdparties,
  isOpen,
  isDownloadEnabled,
  sort,
  onClose,
  onProceed,
  onCancel,
}) => {
  const [filters] = useContext(FilterContext);
  const [submissionData, setSubmissionData] = useState(null);
  const [submitDownloadConfirmationOpen, setDownloadSubmitConfirmationOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const { accessToken, userName } = useAuth();
  const { vantageWebApi, msalSubscriptionKey } = useConfig();
  const [selected, setSelected] = useState(0);
  const bodyContainerRef = useRef(null);

  const handleOpenDownloadConfirmation = (data) => {
    setSubmissionData(data);
    setDownloadSubmitConfirmationOpen(true);
  };

  const handleDownloadSummaryConfirmationSubmit = async () => {
    if (!submissionData) return;
    setRequestError(false);
    setIsLoading(true);

    const response = await fetch(
      `${vantageWebApi}/${submissionData.reportTypeEndpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(submissionData),
      }
    );

    setIsLoading(false);
    setDownloadSubmitConfirmationOpen(false);

    if (response.status !== 200) {
      setRequestError(true);
    } else {
      onProceed();
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        onProceed={() => {}}
        title="Report Actions"
        loading={false}
        onCancel={onCancel}
        proceedButtonText="Submit"
      >
        <Tabs setSelected={setSelected} selected={selected}>
          <TabContent label="Download summary">
            <DownloadSummaryTab
              thirdpartySearchTerm={thirdpartySearchTerm}
              filters={filters}
              sort={sort}
              showAllThirdparties={showAllThirdparties}
              onCancel={onCancel}
              isLoading={isLoading}
              requestError={requestError}
              isDownloadEnabled={isDownloadEnabled}
              onDownloadSumaryRequest={handleOpenDownloadConfirmation}
            />
          </TabContent>
          <TabContent label="Search for a report">
            <SearchReportTab />
          </TabContent>
        </Tabs>
      </Drawer>
      <ConfirmationModal
        isOpen={submitDownloadConfirmationOpen}
        onClose={() => setDownloadSubmitConfirmationOpen(false)}
        onCloseDisabled={isLoading}
        onProceed={handleDownloadSummaryConfirmationSubmit}
        onProceedAriaLabel="Download Report"
        onCancel={() => setDownloadSubmitConfirmationOpen(false)}
        title="Download Report?"
        message={
          'The selected report will be delivered via email to ' + userName
        }
        proceedButtonText="Confirm"
        loading={isLoading}
        loadingMessage="Please wait."
        bodyContainerRef={bodyContainerRef}
      />
    </>
  );
};

export default ReportActionsDrawer;
