import React, { FC } from 'react';
import { ChipComponent, ChipLabel } from './Chip.styles';

import { ChipTypes } from './types';

const Chip: FC<ChipTypes> = ({ children, className }: ChipTypes) => {
  // Do something here
  return (
    <ChipComponent className={className}>
      <ChipLabel>{children}</ChipLabel>
    </ChipComponent>
  );
};

export default Chip;
