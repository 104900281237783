import React from 'react';
import { Banner, Card, SectionHeading } from 'components/molecules';
import { RiskCategoriesContainer } from './DueDiligenceRiskModule.styles';
import { DueDiligenceRiskModuleProps } from './types';
import { Risks } from 'components/atoms/risk-rating/types';
import { ReadMoreTextArea } from 'components/atoms';
import { useParams } from 'react-router-dom';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';

const DueDiligenceRiskModule: React.FC<DueDiligenceRiskModuleProps> = ({
  riskRating,
  riskRatingMethodology,
}) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();

  const { data } = useFetch(
    `${vantageWebApi}/thirdparty/search-audit?thirdPartyId=${id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        page: 1,
        pageSize: 5,
        filter: {
          dueDiligenceType: [
            GatewaySubscriptionTypeNames.ManagedServiceDueDiligence,
          ],
        },
      }),
    }
  );

  const riskRatingMapper = (
    rating: number,
    riskRatingMethodology: number
  ): Risks => {
    switch (riskRatingMethodology) {
      case 1:
        switch (rating) {
          case 1:
            return 'N/A';
          case 2:
            return 'Low';
          case 3:
            return 'Medium';
          case 4:
            return 'High';
          default:
            return 'Unknown';
        }
      case 2:
        switch (rating) {
          case 1:
            return 'N/A';
          case 2:
            return 'Clear findings';
          case 3:
            return 'Moderate findings';
          case 4:
            return 'Significant findings';
          default:
            return 'Unknown';
        }
      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <SectionHeading text="Due Diligence Findings" />
      {data?.results?.length === 0 && (
        <Banner
          text="The following risk categories are only available by ordering a Due Diligence report"
          icon="lock"
        />
      )}
      {riskRating?.commentaryNote && (
        <ReadMoreTextArea
          maxTextLength={400}
          text={riskRating?.commentaryNote}
        />
      )}
      <RiskCategoriesContainer>
        {riskRating?.dueDiligenceRiskRatings?.map((item, index) => {
          return (
            <Card
              compact={true}
              key={index}
              title={item?.description}
              risk={riskRatingMapper(item?.rating, riskRatingMethodology)}
            />
          );
        })}
      </RiskCategoriesContainer>
    </>
  );
};

export default DueDiligenceRiskModule;
