import React, { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  CollapsibleComponent,
  CollapsibleContent,
  CollapsibleText,
  CollapsibleToggle,
} from './Collapsible.styles';
import { CollapsibleTypes } from './types';

const Collapsible: FC<CollapsibleTypes> = ({
  expandedText,
  collapsedText,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CollapsibleComponent>
      <AnimatePresence>
        {isOpen && (
          <CollapsibleContent>
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.25 }}
            >
              {children}
            </motion.div>
          </CollapsibleContent>
        )}
      </AnimatePresence>
      <CollapsibleToggle onClick={() => toggle()}>
        <CollapsibleText>
          {isOpen ? expandedText : collapsedText}
        </CollapsibleText>
      </CollapsibleToggle>
    </CollapsibleComponent>
  );
};

export default Collapsible;
