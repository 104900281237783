import React from 'react';
import { FieldCheckbox, Tooltip, Icon } from 'components/atoms';

import { ExpediteContainer, ExpediteIconBox } from './Expedite.styles';
import getSymbolFromCurrency from 'currency-symbol-map';

const Expedite = ({ expediteFee, expediteFeeCurrencyCode, register }) => {
  const TOOLTIP_MESSAGE =
    'Reports can be expedited for an additional fee. If requested, Control Risks will be in contact with you before proceeding with the request to confirm fees and delivery dates.';

  return (
    <ExpediteContainer>
      <FieldCheckbox
        key={'expedite'}
        id={'expedite'}
        label={`Expedite Report ${
          expediteFeeCurrencyCode
            ? getSymbolFromCurrency(expediteFeeCurrencyCode)
            : ''
        }${expediteFee ? String(expediteFee.toFixed()) : ''}`}
        register={register}
      />
      <ExpediteIconBox>
        <Tooltip
          tooltipText={TOOLTIP_MESSAGE}
          placement="bottom"
          trigger="click"
        >
          <Icon icon="attention" aria-hidden size="xs" />
        </Tooltip>
      </ExpediteIconBox>
    </ExpediteContainer>
  );
};

export default Expedite;
