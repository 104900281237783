import { Tooltip } from 'components/atoms';
import React, { FC } from 'react';

const PotentialDuplicateDisableTooltip: FC<{
  portalContainer: HTMLElement;
  visible: boolean;
}> = ({ portalContainer, visible }) => {
  return (
    <Tooltip
      trigger="click"
      placement="top"
      tooltipText={
        (
          <div style={{ maxWidth: 200 }}>
            You are not able to view the original report details without admin
            permission
          </div>
        ) as any
      }
      portalContainer={portalContainer}
      tooltipShown={visible}
    />
  );
};

export default PotentialDuplicateDisableTooltip;
