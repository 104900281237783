import styled from 'styled-components';

export const DueDiligence = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  .button {
    width: ${({ theme }) => theme.pxToRem(166)};

    &:has(.spinner) {
      padding: 10px;
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(40)};
  border-radius: 5px;
`;

export const ThankYouMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  line-height: ${({ theme }) => theme.pxToRem(27)};

  & > button {
    margin-left: ${({ theme }) => theme.pxToRem(40)};
    margin-top: 35px;
    align-self: end;
    max-width: 294px;
    a {
      font-size: 16px;
    }
  }

  .description {
    display: flex;
    gap: 24px;
  }

  .details-section {
    text-align: center;
    margin: 16px 100px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 150px;

    row-gap: 16px;
    column-gap: 80px;

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .separator {
      flex: 1 0 auto;
      height: 1px;
      border-bottom: 1px dashed ${({ theme }) => theme.colours.crowberryBlue};
      margin: 0 8px;
      font-weight: 300;
    }

    .text-container {
      width: 200px;
      text-align: left;
    }

    .title {
      font-weight: 700;
    }
  }
`;

export const DueDiligenceForm = styled.div`
  & abbr {
    margin-right: 15px;
  }
  & .radio-group {
    & > div {
      min-width: ${({ theme }) => theme.pxToRem(288)};
    }
  }
  & .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(40)};
    margin-top: ${({ theme }) => theme.spacing(40)};

    .submit-button {
      min-width: 223px;
    }
    .next-button {
      min-width: 223px;
    }
  }
`;

export const DueDiligenceType = styled.div`
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
`;

export const NoteContainer = styled.div`
  display: flex;
`;

export const AcknowledgeWrapper = styled.div`
  margin-left: 10px;
`;

export const FieldRadioContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const SideModulesWrapper = styled.div`
  width: 100%;
  padding-left: ${({ theme: { spacing } }) => spacing(30)};
`;

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.pxToRem(20)};
`;

export const LoadingMessage = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.pxToRem(24)};

  & > p {
    font-size: ${({ theme }) => theme.pxToRem(24)};
    line-height: ${({ theme }) => theme.pxToRem(34)};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    margin: ${({ theme }) => theme.pxToRem(12)} 0;
  }
`;

export const GlobalSearch = styled.div`
  align-self: flex-end;
  margin-bottom: ${({ theme: { spacing } }) => spacing(20)};
`;

export const ModalSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colours.concreteGray};
  width: auto;
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
  margin-top: ${({ theme }) => theme.pxToRem(20)};
`;

export const ComboBoxContainer = styled.div`
  width: ${({ theme }) => theme.pxToRem(250)};
`;
