import { Button } from 'components/atoms';
import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(30)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(40)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  padding: ${({ theme }) => theme.spacing(28)};
  border-radius: 5px;
  text-align: center;
  background-color: ${({ theme }) => theme.colours.white};

  h1 {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }
`;

export const FixedWidthButton = styled(Button)`
  min-width: ${({ theme: { spacing } }) => spacing(196)};
  max-width: ${({ theme: { spacing } }) => spacing(40)};
`;
