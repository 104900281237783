import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${({ theme: { colours } }) => colours.charcoalDust};
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

const ButtonBase = css`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  ${ButtonBase}
  margin-top: ${({ theme }) => theme.pxToRem(12)};
  margin-left: ${({ theme }) => theme.pxToRem(16)};
`;

export const AddReferenceButton = styled.button`
  ${ButtonBase}
  padding: 0;
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};

  .icon {
    margin-right: ${({ theme }) => theme.pxToRem(20)};
  }
`;

export const ArrayItem = styled.div`
  display: flex;
`;
