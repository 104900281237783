import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    display: block;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  .toggleCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme: { colours } }) => colours.crowberryBlue};
    border-radius: ${({ theme: { spacing } }) => spacing(50)};
    width: ${({ theme }) => theme.spacing(28)};
    height: ${({ theme }) => theme.spacing(28)};
  }

  .toggled {
    display: none;
  }
  .untoggled {
    display: inline-flex;
  }

  .disabled {
    opacity: 0.6;
  }

  .toggleSpace {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing(53)};
    height: ${({ theme }) => theme.spacing(30)};
    pointer-events: none;
    margin-right: ${({ theme: { spacing } }) => spacing(10)};
    margin-top: ${({ theme }) => theme.pxToRem(8)};
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};
    border-radius: ${({ theme: { spacing } }) => spacing(50)};
    border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
    background-color: ${({ theme: { colours } }) => colours.white};
    color: ${({ theme: { colours } }) => colours.crowberryBlue};
  }

  input:checked ~ .toggleSpace {
    justify-content: flex-end;
    background-color: ${({ theme: { colours } }) => colours.crowberryBlue};
    .toggleCircle {
      background-color: ${({ theme: { colours } }) => colours.white};
    }
    .toggled {
      display: inline-flex;
    }
    .untoggled {
      display: none;
    }
  }
`;
