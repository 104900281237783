import React, { useEffect, FC } from 'react';
import FocusTrap from 'focus-trap-react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  DialogComponent,
  DialogOverlayComponent,
  DialogHeading,
  DialogTitle,
  DialogClose,
  DialogBody,
  DialogFooter,
} from './Dialog.styles';
import Button from 'components/atoms/button';

import { DialogOverlayProps, ModalProps } from './types';
import { Icon } from 'components/atoms';

const DialogOverlay: FC<DialogOverlayProps> = ({
  children,
  onDecline,
  onConfirm,
  title,
}) => {
  useEffect(() => {
    if (!onDecline) {
      return;
    }
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onDecline();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onDecline]);
  return (
    <DialogOverlayComponent>
      <DialogHeading>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogClose aria-label="Close" onClick={onDecline}>
          <Icon icon="close" aria-hidden size="xxs" />
        </DialogClose>
      </DialogHeading>
      <DialogBody>{children}</DialogBody>
      <DialogFooter>
        <Button secondary type="button" onClick={onDecline}>
          Decline
        </Button>
        <Button type="button" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogFooter>
    </DialogOverlayComponent>
  );
};

const Dialog: FC<ModalProps> = ({
  children,
  isOpen = false,
  onDecline,
  onConfirm,
  title,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap>
            <DialogComponent>
              <DialogOverlay
                title={title}
                onDecline={onDecline}
                onConfirm={onConfirm}
              >
                {children}
              </DialogOverlay>
            </DialogComponent>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dialog;
