export const abbreviations = {
  SelfServiceScreening: { long: 'Self Service Screening', short: 'S' },
  Screening: { long: 'Service', short: 'S' },
  DueDiligence: { long: 'Due Diligence', short: 'DD' },
  SelfServiceUbo: { long: 'Self Service Ubo', short: 'UBO' },
  ManagedServiceScreening: { long: 'Managed Service Screening', short: 'S' },
  ManagedServiceUbo: { long: 'Managed Service Ubo', short: 'MSUBO' },
  ManagedServiceDueDiligence: {
    long: 'Managed Service Due Diligence',
    short: 'DD',
  },
};
