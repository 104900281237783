/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { FieldTextareaTypes } from './types';
import { Wrapper } from './FieldTextarea.styles';

export const TextArea: FC<FieldTextareaTypes> = ({
  label = '',
  id = '',
  placeholder = '',
  disabled = false,
  inputRef,
  onChange,
  onBlur,
  register,
  required = false,
  valid = null,
  fullWidth,
  defaultValue,
}: FieldTextareaTypes) => {
  const checkValidationIsBool = typeof valid === 'boolean';

  return (
    <Wrapper
      $fullWidth={fullWidth}
      {...(checkValidationIsBool
        ? { className: valid ? 'valid' : 'invalid' }
        : {})}
    >
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span className="required bold"> *</span>}
        </label>
      )}
      <textarea
        id={id}
        name={id}
        placeholder={placeholder}
        ref={inputRef}
        data-valid={!!valid}
        defaultValue={defaultValue}
        {...(disabled ? { disabled } : {})}
        {...(onChange
          ? {
              onChange: (event) =>
                onChange({ value: event.currentTarget.value, name: id }),
            }
          : {})}
        {...(onBlur
          ? {
              onBlur: (event) =>
                onBlur({ value: event.currentTarget.value, name: id }),
            }
          : {})}
        {...(register ? { ...register(id, { required }) } : {})}
      />
    </Wrapper>
  );
};

export default TextArea;
