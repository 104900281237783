import React, { FC } from 'react';
import { UsersCounterTypes } from './types';
import { Button, Callout } from 'components/atoms';
import NamesList from 'components/molecules/names-list';

const UsersCounter: FC<UsersCounterTypes> = ({
  names = [],
}: UsersCounterTypes) => {
  const count = names.length;

  if (count == 0) {
    return null;
  }

  return (
    <Callout
      direction="right"
      name="names list"
      trigger={() => <Button text>+{count}</Button>}
      triggerType="hover"
    >
      <NamesList names={names} />
    </Callout>
  );
};

export default UsersCounter;
