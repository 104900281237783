import styled from 'styled-components';

export const ModalContent = styled.div`
  font-size: 14px;
  padding: ${({ theme }) => theme.spacing(40)};

  .linked-reports-title {
    margin-bottom: 8px;
  }

  a {
    color: ${({ theme }) => theme.colours.deepWater};
    font-size: 14px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colours.redRampage};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  gap: ${({ theme }) => theme.spacing(6)};
  margin-bottom: 25px;

  .risk-icon {
    margin-top: 5px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-radius: 4px;
  text-align: left;

  th,
  td {
    padding: 18px 30px;
  }

  thead {
    background-color: ${({ theme }) => theme.colours.crowberryBlue};
    color: ${({ theme }) => theme.colours.white};
    th:first-child {
      border-top-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.colours.farsighted};
      }

      td.id {
        max-width: 160px;

        &.not-generated {
          font-style: italic;
          color: ${({ theme }) => theme.colours.charcoalDust};
        }
      }

      td:first-child {
        border-left: 1px solid ${({ theme }) => theme.colours.farsighted};
      }

      td:last-child {
        border-right: 1px solid ${({ theme }) => theme.colours.farsighted};
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }

      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(32)};

  .button {
    width: ${({ theme }) => theme.pxToRem(166)};
  }
`;
