import React, { FC, useState } from 'react';
import { CardComponent, ExpandButton } from './Card.styles';
import { CardProps } from './types';
import { Icon, RiskRating, TooltipIcon, Typography } from 'components/atoms';
import { colours } from 'theme/@config';

const Card: FC<CardProps> = ({
  icon,
  risk = 'Unknown',
  text = '',
  title = '',
  tooltip,
  showRiskRating = true,
  compact = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <CardComponent compact={compact ?? false}>
      <header>
        {icon && <Icon icon={icon} size="xs" aria-hidden />}
        <Typography tag="h3" value={title} />
        {tooltip && (
          <TooltipIcon
            tooltipText={tooltip}
            icon={'attention'}
            size="xs"
            colour={colours.pacificBlue}
            cursor="pointer"
          />
        )}
        {showRiskRating && <RiskRating risk={risk} />}
      </header>
      <main>
        <Typography
          tag="p"
          value={
            text && text.length > 144 && !expanded
              ? `${text.substring(0, 144)}…`
              : text
          }
        />
        {text && text.length > 144 && (
          <ExpandButton onClick={() => setExpanded(!expanded)}>
            show {expanded ? 'less' : 'more'}
          </ExpandButton>
        )}
      </main>
    </CardComponent>
  );
};

export default Card;
