import React, { useEffect, useRef, useState } from 'react';
import {
  CalloutComponent,
  CalloutTrigger,
  CalloutDropdown,
  CalloutDropdownContent,
} from './Callout.styles';
import { CalloutProps } from './types';

const Callout: React.FC<CalloutProps> = ({
  active = false,
  children,
  direction = 'down',
  height,
  maxHeight,
  name,
  trigger,
  triggerType = 'click',
  width,
}) => {
  const [isActive, setIsActive] = useState(active);
  const componentRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  return (
    <CalloutComponent ref={componentRef}>
      <CalloutTrigger
        aria-label={`Toggle ${name}`}
        className="callout-trigger"
        onClick={
          triggerType == 'click' ? () => setIsActive(!isActive) : undefined
        }
        onMouseEnter={
          triggerType == 'hover' ? () => setIsActive(true) : undefined
        }
        onMouseLeave={
          triggerType == 'hover' ? () => setIsActive(false) : undefined
        }
      >
        {typeof trigger === 'function' ? trigger() : trigger}
      </CalloutTrigger>
      {isActive && (
        <CalloutDropdown
          className="callout-dropdown"
          direction={direction}
          style={{
            width,
            height,
            maxHeight,
          }}
        >
          <CalloutDropdownContent>{children}</CalloutDropdownContent>
        </CalloutDropdown>
      )}
    </CalloutComponent>
  );
};

export default Callout;
