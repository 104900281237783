import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getName } from 'country-list';
import {
  Wrapper,
  SummaryHeader,
  SummaryItem,
  ItemLabel,
  SummaryDetails,
  ActionButton,
} from './FormSummary.styles';
import { DescriptionList, Icon } from 'components/atoms';

import { FormSummaryTypes } from './types';
import { genderMapper } from 'helpers/formDataHelper';
import { ThirdPartyType } from 'types/thirdPartyType';
import { ellipsisText } from 'helpers/strings-functions';
import { useTerminologyConfig } from 'context/terminologyConfig';

const FormSummarySection = ({
  action,
  title,
  items = [],
}: {
  action?: () => void;
  title: string;
  items: { key: string; value: string }[];
}) => {
  const theme = useContext(ThemeContext);
  return (
    <SummaryItem>
      <SummaryDetails>
        <ItemLabel>{title}</ItemLabel>
        <DescriptionList
          items={items}
          valueClassName="bold"
          spaced
          filterProperties={false}
          emptyValue=""
        />
      </SummaryDetails>
      {action && (
        <ActionButton onClick={action} aria-label={`Edit ${title}`}>
          <Icon
            icon="edit-pencil"
            size="xxs"
            className="icon"
            colour={theme.colours.swimmersPool}
            aria-hidden
          />
        </ActionButton>
      )}
    </SummaryItem>
  );
};

const FormSummary: FC<FormSummaryTypes> = ({ formData }: FormSummaryTypes) => {
  const terminologyConfig = useTerminologyConfig();
  let items = [];

  const isIndividual = formData?.type === ThirdPartyType.Individual;
  const isCompany = formData?.type === ThirdPartyType.Business;

  const generalThirdPartyDetails = [
    {
      key: 'Primary country',
      value: formData?.primaryCountry && getName(formData?.primaryCountry),
    },
    {
      key: `${terminologyConfig?.internalIdSingularNaming}`,
      value: ellipsisText(formData?.internalId, 20),
    },
  ];

  const addressThirdPartyDetails = [
    { key: 'Address', value: ellipsisText(formData?.addressLineOne, 20) },
    { key: 'City', value: ellipsisText(formData?.city, 20) },
    {
      key: 'State / Region / Province',
      value: ellipsisText(formData?.county, 20),
    },
    { key: 'Post Code / Zip Code', value: ellipsisText(formData?.zipCode, 20) },
    {
      key: 'Country 2',
      value: formData?.countryTwo && getName(formData?.countryTwo),
    },
    {
      key: 'Country 3',
      value: formData?.countryThree && getName(formData?.countryThree),
    },
  ];

  if (isIndividual) {
    items = [
      {
        key: 'Individual name',
        value: ellipsisText(formData?.name, 20),
      },
      {
        key: 'Individual (alternative script)',
        value: ellipsisText(formData?.alternativeName, 20),
      },
      ...generalThirdPartyDetails,
      ...addressThirdPartyDetails,
      {
        key: 'Gender',
        value: genderMapper(formData?.gender),
      },
      {
        key: 'Date of birth',
        value:
          formData?.dateOfBirth &&
          new Date(formData?.dateOfBirth).toLocaleDateString('en-GB'),
      },
      {
        key: 'Nationality',
        value: formData?.nationality && getName(formData?.nationality),
      },
      {
        key: 'Passport number',
        value: ellipsisText(formData?.passportNumber, 20),
      },
      {
        key: 'National ID number',
        value: ellipsisText(formData?.nationalIDNumber, 20),
      },
      {
        key: 'Other ID number',
        value: ellipsisText(formData?.otherIDNumber, 20),
      },
    ];
  } else {
    items = [
      {
        key: 'Company name',
        value: ellipsisText(formData?.name, 20),
      },
      {
        key: 'Company (alternative script)',
        value: ellipsisText(formData?.alternativeName, 20),
      },
      ...generalThirdPartyDetails,
      { key: 'DUNS', value: ellipsisText(formData?.duns, 20) },
      ...addressThirdPartyDetails,
      {
        key: 'Registration number',
        value: ellipsisText(formData?.registrationNumber, 20),
      },
      { key: 'SWIFT / BIC Number', value: ellipsisText(formData?.swift, 20) },
      {
        key: 'Company website',
        value: ellipsisText(formData?.companyUrl, 20),
      },
    ];
  }

  const mappedDefaultItems = items.map(({ key, value }) => {
    if (value !== 'DEFAULT' && value !== undefined) {
      return { key, value };
    } else {
      return { key, value: '' };
    }
  });

  const somethingToShow = mappedDefaultItems.some(({ value }) => value !== '');

  return (
    <Wrapper>
      <SummaryHeader>
        {terminologyConfig?.thirdPartySingularNaming} Overview
      </SummaryHeader>
      {formData?.type && (
        <>
          <FormSummarySection
            title={`${terminologyConfig?.thirdPartySingularNaming} type`}
            items={[
              {
                key: 'Type',
                value: isCompany ? 'Company' : 'Individual',
              },
            ]}
          />
          {somethingToShow && (
            <FormSummarySection
              title={`${terminologyConfig?.thirdPartySingularNaming} details`}
              items={mappedDefaultItems}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default FormSummary;
