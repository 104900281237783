import styled from 'styled-components';

import { IResponseMessage } from './types';

export const CreatePasswordContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(20)};
  width: ${({ theme }) => theme.spacing(540)};

  h1 {
    font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-size: ${({ theme }) => theme.pxToRem(60)};
    line-height: ${({ theme }) => theme.pxToRem(71)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
  }

  form > div {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
  }

  hr {
    border: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colours.spanishGrey};
    padding: 0;
    margin: 0;
  }

  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${({ theme }) => theme.colours.charcoalDust};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
  }
`;

export const ResponseMessage = styled.p<IResponseMessage>`
  color: ${({ theme, $responseType }) => {
    if ($responseType === 'FAIL') return theme.colours.redRampage;
    if ($responseType === 'SUCCESS') return theme.colours.porpoisePlace;
  }};
`;
