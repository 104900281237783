import styled, { css } from 'styled-components';

type CalloutDropdownProps = {
  direction: 'up' | 'right' | 'down' | 'left';
  width?: number;
};

export const CalloutComponent = styled.div`
  position: relative;
  display: inline-block;
`;

export const CalloutTrigger = styled.button`
  padding: 0;
  cursor: pointer;

  svg {
    pointer-events: none;
  }

  .highlight {
    color: ${({ theme }) => theme.colours.pacificBlue};
    text-decoration: underline;
    text-decoration-color: currentColor;
  }
`;

export const CalloutDropdown = styled.div<CalloutDropdownProps>`
  position: absolute;
  display: flex;
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  width: ${({ theme, width }) => width || theme.pxToRem(220)};
  z-index: ${({ theme }) => theme.zIndex.level_1};

  &:after {
    position: absolute;
    display: block;
    width: ${({ theme }) => theme.pxToRem(16)};
    height: ${({ theme }) => theme.pxToRem(8)};
    background-color: ${({ theme }) => theme.colours.white};
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    content: '';
  }

  ${({ direction }) => {
    switch (direction) {
      case 'up':
        return css`
          bottom: calc(100% + ${({ theme }) => theme.spacing(15)});
          left: 50%;
          transform: translateX(-50%);

          &:after {
            top: 100%;
            left: 50%;
            transform: rotate(180deg) translateX(50%);
          }
        `;
      case 'right':
        return css`
          left: calc(100% + ${({ theme }) => theme.spacing(15)});
          top: 50%;
          transform: translateY(-50%);

          &:after {
            top: 50%;
            right: 100%;
            transform: rotate(270deg)
              translate(20%, ${({ theme }) => theme.spacing(4)});
          }
        `;
      case 'down':
        return css`
          top: calc(100% + ${({ theme }) => theme.spacing(15)});
          left: 50%;
          transform: translateX(-50%);

          &:after {
            bottom: 100%;
            left: 50%;
            transform: rotate(0deg)
              translate(-50%, ${({ theme }) => theme.spacing(1)});
          }
        `;
      case 'left':
        return css`
          right: calc(100% + ${({ theme }) => theme.spacing(15)});
          top: 50%;
          transform: translateY(-50%);

          &:after {
            top: 50%;
            left: 100%;
            transform: rotate(90deg)
              translate(-20%, ${({ theme }) => theme.spacing(4)});
          }
        `;
    }
  }}
`;

export const CalloutDropdownContent = styled.div`
  overflow: auto;
  width: 100%;
`;
