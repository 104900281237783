import React, { FC, ReactNode } from 'react';
import jwt from 'jsonwebtoken';
import { useAuth } from 'auth/Auth';
import {
  AppLayoutComponent,
  TopContainer,
  ViewContainer,
  TopBar,
} from './AppLayout.styles';
import Navigation from 'components/organisms/navigation';
import Footer, { args } from 'components/organisms/footer';
import WelcomeMessage from 'components/molecules/welcome-message';

interface Props {
  children: ReactNode;
  bgColor?: string;
  topBarSlot?: ReactNode;
}

const AppLayout: FC<Props> = ({ children, bgColor, topBarSlot }: Props) => {
  const { accessToken } = useAuth();
  const token = jwt.decode(accessToken);
  return (
    <AppLayoutComponent>
      <TopContainer>
        <Navigation />
        <ViewContainer $bgColor={bgColor}>
          <TopBar>
            {topBarSlot}
            <WelcomeMessage name={token?.name} dark={!bgColor} />
          </TopBar>
          {children}
        </ViewContainer>
      </TopContainer>
      <Footer {...args} />
    </AppLayoutComponent>
  );
};

export default AppLayout;
