import React, { FC } from 'react';
import {
  SectionHeadingComponent,
  SectionHeadingChildren,
} from './SectionHeading.styles';
import { SectionHeadingProps } from './types';
import { Typography } from 'components/atoms';

const SectionHeading: FC<SectionHeadingProps> = ({
  children,
  border = false,
  dark = false,
  text,
}) => (
  <SectionHeadingComponent border={border} dark={dark}>
    <Typography tag="h2" value={text} />
    <SectionHeadingChildren>{children}</SectionHeadingChildren>
  </SectionHeadingComponent>
);

export default SectionHeading;
