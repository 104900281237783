import styled from 'styled-components';

export const SectionSummaryComponent = styled.section`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.pxToRem(20)};
  width: 100%;

  > * {
    flex-basis: 25%;
  }
`;

export const ListItem = styled.div`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(21)};
  padding-bottom: ${({ theme }) => theme.pxToRem(10)};
`;
