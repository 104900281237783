import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  float: right;
`;

export const AddRelatedEntityWrapper = styled.div`
  float: left;
  clear: both;
`;

export const RelatedEntityOptionContainer = styled.div`
  display: flex;
`;

export const RelatedEntityInfoContainer = styled.div`
  width: 100%;
`;

export const FooterContainer = styled.div`
  padding-bottom: 43px;
  gap: ${({ theme: { spacing } }) => spacing(30)};
  margin-top: ${({ theme: { spacing } }) => spacing(32)};
`;

export const EntityIconWrapper = styled.span<{
  background?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${(props) =>
    props.background
      ? ({ theme: { colours } }) => colours.spanishGrey
      : 'transparent'};
  padding: ${(props) => (props.background ? '7px' : '0')};
  margin-right: 5px;
`;
