export enum GatewayReportTypes {
  ThirdPartySummaryReport = 1,
  ScreeningSummaryReport = 2,
  DueDiligenceSummaryReport = 3,
}

export enum GatewayReportTypeNames {
  ThirdPartySummaryReport = 'Third Party Summary Report',
  ScreeningSummaryReport = 'Screening Summary Report',
  DueDiligenceSummaryReport = 'DueDiligence Summary Report',
}
