import React, { FC } from 'react';
import { IconComponent, Wrapper } from './Icon.styles';
import { IconTypes } from './types';
import iconSet from './selection.json';
import { useTheme } from 'styled-components';

const Icon: FC<IconTypes> = ({
  icon,
  background,
  className,
  size = 'md',
  colour = 'black',
  ...props
}: IconTypes) => {
  const theme = useTheme();
  let color = '';

  if (typeof background === 'string') {
    color = background;
  } else if (background === true) {
    color = theme.colours.spanishGrey;
  } else {
    color = undefined;
  }

  return (
    <Wrapper background={color} className={className}>
      <IconComponent
        {...props}
        size={size}
        icon={icon}
        iconSet={iconSet}
        color={colour}
      />
    </Wrapper>
  );
};

export default Icon;
