import React, { useState, useEffect } from 'react';
import AppLayout from 'layouts/app-layout';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { FormHeader } from 'form/form.styles';
import {
  Accordion,
  Button,
  Loading,
  Link as StyledLink,
  PageTitle,
  Icon,
} from 'components/atoms';
import { useFetch } from 'hooks/useFetch';
import {
  Link,
  useHistory,
  useParams,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';

import {
  ThankYouMessage,
  ErrorMessageContainer,
  Wrapper,
} from './InitiateDueDiligence.styles';

import ConfirmationModal from 'components/organisms/confirmation-modal';
import InitiateDueDiligenceForm from './Initiate-due-diligence-form/InitiateDueDiligenceForm';

import DueDiligenceConfirmationModal from 'components/organisms/due-diligence-confirmation-modal';
import {
  Section,
  ThirdPartyInfo,
} from 'components/organisms/due-diligence-confirmation-modal/types';
import { useTheme } from 'styled-components';

const InitiateDueDiligence = () => {
  const [submitConfirmationOpen, setSubmitConfirmationOpen] = useState({
    open: false,
    data: undefined,
  });
  const [draftConfirmationOpen, setDraftConfirmationOpen] = useState({
    open: false,
    data: undefined,
  });

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();

  const [draftId, setDraftId] = useState(null);
  const { search } = useLocation();
  const theme = useTheme();

  const urlSearchParams = new URLSearchParams(search);
  const draftIdParam = urlSearchParams ? urlSearchParams.get('draftId') : null;

  const [responseDescription, setResponseDescription] = useState({
    selectedReport: null,
    selectedRelatedEntities: null,
    dueDiligenceType: null,
    dueDiligenceLevel: null,
    hasPermittedRelatedEntities: null,
    screeningType: null,
  });

  const { data: businessCategoryData } = useFetch(
    `${vantageWebApi}/file/categories/client?entityType=Business`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    }
  );

  const { data: individualCategoryData } = useFetch(
    `${vantageWebApi}/file/categories/client?entityType=Individual`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    }
  );

  const { data: ddTypesData, loading: ddTypesLoading } = useFetch(
    `${vantageWebApi}/onlinescreening/duediligencetypes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const {
    data: thirdPartyData,
    responseStatus,
    loading: loadingThirdParty,
  } = useFetch(`${vantageWebApi}/thirdParty/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const {
    data: draftData,
    loading: draftInformationLoading,
    trigger: fetchDraftInformationLazy,
  } = useFetch(`${vantageWebApi}/onlinescreening/draft/${draftIdParam}`, {
    method: 'GET',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  useEffect(() => {
    if (draftIdParam) {
      setDraftId(draftIdParam);
      fetchDraftInformationLazy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalSubmit = async (data) => {
    setSubmitConfirmationOpen({ open: false, data: undefined });
    createDueDiligenceFetchLazy({
      body: JSON.stringify(data),
      urlParams: { screeningType: 'managedduediligence' },
    });
  };

  const handleDraftSubmit = (data) => {
    setDraftConfirmationOpen({ open: false, data: undefined });

    const body = JSON.stringify(data);
    const trigger = draftId ? updateDraftLazy : createDraftLazy;

    trigger({ body });
  };

  const {
    data: createDueDiligenceData,
    trigger: createDueDiligenceFetchLazy,
    error: createDueDiligenceError,
    loading: createDueDiligenceLoading,
  } = useFetch<unknown, unknown, 'screeningType'>(
    `${vantageWebApi}/thirdParty/${id}/:screeningType`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { data: optionalAddonsData, loading: loadingOptionalAddonsData } =
    useFetch(`${vantageWebApi}/client/managedDueDiligence/optionalAddOns`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    });

  const { loading: loadingDueDiligenceDraft, trigger: createDraftLazy } =
    useFetch(`${vantageWebApi}/thirdparty/${id}/managedduediligence/draft`, {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: () => {
        history.push(`/third-party/profile/${id}`);
      },
    });

  const { loading: loadingUpdateDueDiligenceDraft, trigger: updateDraftLazy } =
    useFetch(`${vantageWebApi}/onlinescreening/draft/${draftId}`, {
      method: 'PUT',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: () => {
        history.push(`/third-party/profile/${id}`);
      },
    });

  const onScreeningSubmit = (data) => {
    const screeningType =
      data.summaryDescriptions.screeningType === 'Self Service'
        ? 'selfservicescreening'
        : 'managedservicescreening';
    createDueDiligenceFetchLazy({
      body: JSON.stringify(data),
      urlParams: { screeningType },
    });
  };

  if (responseStatus === 404) {
    return <Redirect to="/not-found" />;
  }
  if (responseStatus === 401) {
    return <Redirect to="/unauthorised" />;
  }

  if (
    !thirdPartyData ||
    !ddTypesData ||
    (!draftData && draftIdParam) ||
    loadingThirdParty ||
    draftInformationLoading ||
    ddTypesLoading
  ) {
    return (
      <AppLayout>
        <div>
          <Loading />
        </div>
      </AppLayout>
    );
  }
  // fetchLazy errors

  const RenderError = (
    screeningType: string,
    dueDiligenceType: string,
    hasPermittedRelatedEntities: number
  ) => {
    if (
      (screeningType === 'Managed Service' ||
        screeningType === 'Self Service') &&
      dueDiligenceType === 'Screening'
    ) {
      return (
        <AppLayout
          topBarSlot={
            <StyledLink to={`/third-party/profile/${id}`} dark>
              {`Back to ${terminologyConfig?.thirdPartySingularNaming} manager`}
            </StyledLink>
          }
        >
          <PageTitle
            title={`${terminologyConfig?.thirdPartySingularNaming}: ${thirdPartyData?.name}`}
          />
          <Wrapper>
            <FormHeader>Initiate compliance check</FormHeader>
            <Accordion
              title="Compliance check type"
              isOpen={false}
              completed={true}
            />
            <Accordion
              title="Screening settings"
              isOpen={false}
              completed={true}
            />
            <ErrorMessageContainer>
              <Icon icon="risk" size="lg" colour="red" />
              <p>
                {`There was a problem processing your ${
                  screeningType === 'Managed Service'
                    ? 'Managed Screening order'
                    : 'Self Service Screening request'
                }.
                  Please try again. If the problem persists, please contact
                  vantagesupport@controlrisks.com`}
              </p>
              <Button secondary>
                <Link to={`/third-party/profile/${id}`}>Okay</Link>
              </Button>
            </ErrorMessageContainer>
          </Wrapper>
        </AppLayout>
      );
    }

    if (screeningType === 'DEFAULT' && dueDiligenceType === 'Due Diligence') {
      return (
        <AppLayout
          topBarSlot={
            <StyledLink to={`/third-party/profile/${id}`} dark>
              {`Back to ${terminologyConfig?.thirdPartySingularNaming} manager`}
            </StyledLink>
          }
        >
          <PageTitle
            title={`${terminologyConfig?.thirdPartySingularNaming}: ${thirdPartyData?.name}`}
          />
          <Wrapper>
            <FormHeader>Initiate compliance check</FormHeader>
            <Accordion
              title="Compliance check type"
              isOpen={false}
              completed={true}
            />
            {hasPermittedRelatedEntities > 0 && (
              <Accordion
                title="Related entities"
                isOpen={false}
                completed={true}
              />
            )}

            <Accordion
              title="Other information"
              isOpen={false}
              completed={true}
            />
            <ErrorMessageContainer>
              <Icon icon="risk" size="lg" colour="red" />
              <p>
                There was a problem processing your Due Diligence order. Please
                try again. If the problem persists, please contact
                vantagesupport@controlrisks.com
              </p>
              <Button secondary>
                <Link to={`/third-party/profile/${id}`}>Okay</Link>
              </Button>
            </ErrorMessageContainer>
          </Wrapper>
        </AppLayout>
      );
    }
  };

  const RenderSuccess = (screeningType: string, dueDiligenceType: string) => {
    if (screeningType === 'Self Service' && dueDiligenceType === 'Screening') {
      return (
        <Redirect
          to={`/third-party/screening/${createDueDiligenceData.screeningId}`}
        />
      );
    }

    if (
      screeningType === 'Managed Service' &&
      dueDiligenceType === 'Screening'
    ) {
      return (
        <AppLayout
          topBarSlot={
            <StyledLink to={`/third-party/profile/${id}`} dark>
              {`Back to ${terminologyConfig?.thirdPartySingularNaming} manager`}
            </StyledLink>
          }
        >
          <PageTitle
            title={`${terminologyConfig?.thirdPartySingularNaming}: ${thirdPartyData?.name}`}
          />
          <Wrapper>
            <ThankYouMessage>
              <p className="description">
                <Icon
                  className="success-icon"
                  icon="check"
                  colour={theme.colours.poisonIvy}
                  size="md"
                />
                <span>
                  <strong>Your Managed Service: Screening order </strong>
                  has been successfully submitted and is currently being
                  processed. One of our team members will reach out to you to
                  confirm costs and timeframes shortly.
                </span>
              </p>
              <Button secondary>
                <Link to={`/third-party/profile/${id}`}>
                  Return to {terminologyConfig?.thirdPartySingularNaming}{' '}
                  Profile Page
                </Link>
              </Button>
            </ThankYouMessage>
          </Wrapper>
        </AppLayout>
      );
    }

    if (screeningType === 'DEFAULT' && dueDiligenceType === 'Due Diligence') {
      return (
        <AppLayout
          topBarSlot={
            <StyledLink to={`/third-party/profile/${id}`} dark>
              {`Back to ${terminologyConfig?.thirdPartySingularNaming} manager`}
            </StyledLink>
          }
        >
          <PageTitle
            title={`${terminologyConfig?.thirdPartySingularNaming}: ${thirdPartyData?.name}`}
          />
          <Wrapper>
            <ThankYouMessage>
              <p className="description">
                <Icon
                  className="success-icon"
                  icon="check"
                  colour={theme.colours.poisonIvy}
                  size="md"
                />
                <span>
                  <strong>
                    Your Due Diligence{' '}
                    {createDueDiligenceData.request.reportTemplate.reportName}{' '}
                    order{' '}
                  </strong>
                  has been successfully submitted and is currently being
                  processed. One of our team members will reach out to you to
                  confirm costs and timeframes shortly.
                </span>
              </p>

              <section className="details-section">
                <div className="detail">
                  <span className="title">{`${terminologyConfig?.thirdPartySingularNaming} Name `}</span>
                  <span className="separator"></span>
                  <div className="text-container">
                    <span>{`${createDueDiligenceData.thirdPartyName}`}</span>
                  </div>
                </div>
                <div className="detail">
                  <span className="title">
                    {terminologyConfig?.internalIdSingularNaming}
                  </span>
                  <span className="separator"></span>
                  <div className="text-container">
                    <span>{`${createDueDiligenceData.request.internalId}`}</span>
                  </div>
                </div>
                {createDueDiligenceData.request.reportTemplate
                  .customFieldsConfig.customField1.isEnabled &&
                  createDueDiligenceData.request.reportCustomField1 && (
                    <div className="detail">
                      <span className="title">
                        {
                          createDueDiligenceData.request.reportTemplate
                            .customFieldsConfig.customField1.label
                        }
                      </span>
                      <span className="separator"></span>
                      <div className="text-container">
                        <span>{`${createDueDiligenceData.request.reportCustomField1}`}</span>
                      </div>
                    </div>
                  )}
                {createDueDiligenceData.request.reportTemplate
                  .customFieldsConfig.customField2.isEnabled &&
                  createDueDiligenceData.request.reportCustomField2 && (
                    <div className="detail">
                      <span className="title">
                        {
                          createDueDiligenceData.request.reportTemplate
                            .customFieldsConfig.customField2.label
                        }
                      </span>
                      <span className="separator"></span>
                      <div className="text-container">
                        <span>{`${createDueDiligenceData.request.reportCustomField2}`}</span>
                      </div>
                    </div>
                  )}
                {createDueDiligenceData.request.reportTemplate
                  .customFieldsConfig.customField3.isEnabled &&
                  createDueDiligenceData.request.reportCustomField3 && (
                    <div className="detail">
                      <span className="title">
                        {
                          createDueDiligenceData.request.reportTemplate
                            .customFieldsConfig.customField3.label
                        }
                      </span>
                      <span className="separator"></span>
                      <div className="text-container">
                        <span>{`${createDueDiligenceData.request.reportCustomField3}`}</span>
                      </div>
                    </div>
                  )}
                <div className="detail">
                  <span className="title">Report ID </span>
                  <span className="separator"></span>
                  <div className="text-container">
                    <span>{`${createDueDiligenceData.request.reportId}`}</span>
                  </div>
                </div>
              </section>
              <Button secondary>
                <Link to={`/third-party/profile/${id}`}>
                  Return to {terminologyConfig?.thirdPartySingularNaming}{' '}
                  Profile Page
                </Link>
              </Button>
            </ThankYouMessage>
          </Wrapper>
        </AppLayout>
      );
    }
  };

  if (createDueDiligenceError) {
    return RenderError(
      responseDescription.screeningType,
      responseDescription.dueDiligenceType,
      responseDescription.hasPermittedRelatedEntities
    );
  }

  if (createDueDiligenceData) {
    return RenderSuccess(
      responseDescription.screeningType,
      responseDescription.dueDiligenceType
    );
  }

  return (
    <>
      <InitiateDueDiligenceForm
        loading={
          createDueDiligenceLoading ||
          loadingDueDiligenceDraft ||
          loadingUpdateDueDiligenceDraft ||
          loadingOptionalAddonsData
        }
        businessCategoryData={businessCategoryData}
        individualCategoryData={individualCategoryData}
        ddTypesData={ddTypesData}
        thirdPartyData={{ ...thirdPartyData, id }}
        draftData={draftData}
        optionalAddonsData={optionalAddonsData}
        onScreeningSubmit={(data) => {
          onScreeningSubmit(data);
          setResponseDescription(data.summaryDescriptions);
        }}
        onDueDiligenceSubmit={(data) => {
          setSubmitConfirmationOpen({ open: true, data });
          setResponseDescription(data.summaryDescriptions);
        }}
        onSaveDraft={handleDraftSubmit}
      />
      {submitConfirmationOpen.open && (
        <DueDiligenceConfirmationModal
          data-testid="InitiateDueDiligenceConfirmationModal"
          isOpen={submitConfirmationOpen.open}
          thirdPartyInfo={
            submitConfirmationOpen.data.thirdPartyInfo as ThirdPartyInfo
          }
          terminologyConfig={terminologyConfig}
          section={submitConfirmationOpen.data.section as Section}
          onProceed={() => handleModalSubmit(submitConfirmationOpen.data)}
          onCancel={() =>
            setSubmitConfirmationOpen({ open: false, data: undefined })
          }
          onClose={() =>
            setSubmitConfirmationOpen({ open: false, data: undefined })
          }
        />
      )}

      <ConfirmationModal
        data-testid="SaveDraftConfirmationModal"
        isOpen={draftConfirmationOpen.open}
        onClose={() =>
          setDraftConfirmationOpen({ open: false, data: undefined })
        }
        onProceed={() => handleDraftSubmit(draftConfirmationOpen.data)}
        onProceedAriaLabel="save due diligence order draft"
        onCancel={() =>
          setDraftConfirmationOpen({ open: false, data: undefined })
        }
        title="Save draft?"
        message="If you opt to save this Due Diligence order as a draft, you will be able to revisit your saved work on the draft tab under the profile page at a later time."
        proceedButtonText="Save draft"
        loading={false}
      />
    </>
  );
};

export default withAITracking(reactPlugin, InitiateDueDiligence);
