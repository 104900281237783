import React from 'react';
import { FieldCheckbox } from 'components/atoms';
import { useFetch } from 'hooks/useFetch';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import getSymbolFromCurrency from 'currency-symbol-map';

const OptionalAddons = ({ optionalAddOns, register }) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();

  const { data, loading } = useFetch(
    `${vantageWebApi}/client/managedDueDiligence/optionalAddOns`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    }
  );

  function getDescription(id) {
    const matchingObj = data.find((obj) => obj.id === id);
    return matchingObj.description;
  }

  return (
    <>
      {data && !loading && (
        <>
          {optionalAddOns.map((addOn, index) => (
            <FieldCheckbox
              key={`addOns.${index}`}
              id={`addOns.${index}`}
              label={`${getDescription(addOn.optionalAddOnId)}${
                addOn.fee ? ' - ' : ''
              }${
                addOn.feeCurrencyCode
                  ? getSymbolFromCurrency(addOn.feeCurrencyCode)
                  : ''
              }${addOn.fee ? String(addOn.fee.toFixed()) : ''}`}
              register={register}
              value={addOn.optionalAddOnId}
            />
          ))}
        </>
      )}
    </>
  );
};

export default OptionalAddons;
