import styled from 'styled-components';
export const ModalContent = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colours.black};
  padding: ${({ theme }) => theme.spacing(40)};

  .description {
    font-size: 16px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  a {
    color: ${({ theme }) => theme.colours.deepWater};
    font-size: 14px;
  }

  .button.cancel {
    width: fit-content;
  }

  section {
    .section-title {
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 13px;
      margin-top: 32px;
    }

    .section-title-text {
      color: ${({ theme }) => theme.colours.crowberryBlue};
      flex: 0 0 auto;
      font-weight: 700;
    }

    .section-title-line {
      flex: 1 0 auto;
      background-color: ${({ theme }) => theme.colours.silverSand};
      height: 1px;
    }

    .section-container {
      padding: 16px 0;
      overflow: auto;
      height: 150px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      gap: 30px;
    }
  }

  .block-title {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  .block-lines {
    color: ${({ theme }) => theme.colours.dustyGray};
    font-size: 14px;
    font-weight: 400;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-radius: 4px;
  th,
  td {
    padding: 18px 30px;
    text-align: left;
  }
  thead {
    background-color: ${({ theme }) => theme.colours.crowberryBlue};
    color: ${({ theme }) => theme.colours.white};
    font-weight: 700;

    th:first-child {
      border-top-left-radius: 4px;
    }
    th:last-child {
      border-top-right-radius: 4px;
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.colours.farsighted};
      }

      td:first-child {
        border-left: 1px solid ${({ theme }) => theme.colours.farsighted};
      }
      td:last-child {
        border-right: 1px solid ${({ theme }) => theme.colours.farsighted};
        > span {
          display: flex;
          align-content: center;
          gap: 8px;
        }
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
`;
export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(32)};
  .button {
    width: ${({ theme }) => theme.pxToRem(166)};
  }
`;
