import styled from 'styled-components';

export const ToastContainer = styled.div<{
  accentColor: string;
  backgroundColor: string;
}>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.level_5};
  display: flex;
  gap: 8px;
  max-width: 650px;
  width: 100%;
  top: 50px;
  left: 30px;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  color: ${({ accentColor }) => accentColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ accentColor }) => accentColor};
  border-left: 10px solid ${({ accentColor }) => accentColor};
  border-radius: 4px;
  box-shadow: 4px 4px 4px 4px rgba(57, 57, 57, 0.5);

  .messageContainer {
    flex: 1;
  }

  .title {
    font-weight: bold;
    color: ${({ accentColor }) => accentColor};
  }

  svg {
    margin-top: 3px;
  }

  svg #color {
    fill: ${({ accentColor }) => accentColor};
  }
`;

export const ToastClose = styled.button`
  align-self: start;
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.level_5 + 1};

  * {
    cursor: pointer;
  }
`;
