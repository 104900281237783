import React from 'react';
import { useReducer, createContext } from 'react';
import { RelatedEntityModalFormValues } from './types';

export const UPDATE_FORM = 'UPDATE_FORM';
export const RESET_FORM = 'RESET_FROM';

const initialState: RelatedEntityModalFormValues = {};

const modalContextReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_FORM:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export const RelatedEntityModalContext = createContext<{
  state: RelatedEntityModalFormValues;
  updateForm: (data: RelatedEntityModalFormValues) => void;
  resetForm: () => void;
}>({
  state: initialState,
  updateForm: () => {},
  resetForm: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalContextReducer, initialState);

  const value = {
    state,
    updateForm: (data: RelatedEntityModalFormValues) => {
      dispatch({ type: UPDATE_FORM, payload: data });
    },
    resetForm: () => dispatch({ type: RESET_FORM }),
  };

  return (
    <RelatedEntityModalContext.Provider value={value}>
      {children}
    </RelatedEntityModalContext.Provider>
  );
};
