import styled, { css } from 'styled-components';
import { AbbreviationProps } from './types';

export const AbbreviationComponent = styled.abbr<
  Omit<AbbreviationProps, 'title'>
>`
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;

  & + & {
    margin-left: ${({ theme }) => theme.spacing(10)};
  }

  ${({ outline = false, small = false }) =>
    outline
      ? css`
          height: ${({ theme }) => theme.pxToRem(small ? 20 : 30)};
          width: ${({ theme }) => theme.pxToRem(small ? 20 : 30)};
          border: 1px solid currentColor;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colours.white};
          color: ${({ theme }) => theme.colours.crowberryBlue};
          font-family: ${({ theme }) => theme.typography.fontFamily.primary};
          font-size: ${({ theme }) => theme.pxToRem(10)};
          font-weight: bold;
          letter-spacing: 0;
          line-height: ${({ theme }) => theme.pxToRem(small ? 18 : 30)};
          text-align: center;
        `
      : css`
          height: ${({ theme }) => theme.pxToRem(small ? 20 : 30)};
          width: ${({ theme }) => theme.pxToRem(small ? 20 : 30)};
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colours.spanishGrey};
          color: ${({ theme }) => theme.colours.crowberryBlue};
          font-family: ${({ theme }) => theme.typography.fontFamily.primary};
          font-size: ${({ theme }) => theme.pxToRem(10)};
          font-weight: bold;
          letter-spacing: 0;
          line-height: ${({ theme }) => theme.pxToRem(small ? 18 : 30)};
          text-align: center;
        `}
`;
