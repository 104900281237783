import styled from 'styled-components';
import { CarouselNavigationProps } from './types';

type CarouselNavigationComponentProps = Pick<CarouselNavigationProps, 'dark'>;

export const CarouselNavigationComponent = styled.nav<CarouselNavigationComponentProps>`
  --nav-base-colour: ${({ dark = false, theme }) =>
    dark ? theme.colours.white : theme.colours.darkToneInk};
  --nav-accent-colour: ${({ dark = false, theme }) =>
    dark ? theme.colours.swimmersPool : theme.colours.darkToneInk};
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(25)};
  }
`;

export const PageNavigationButton = styled.button`
  height: ${({ theme }) => theme.spacing(15)};
  padding: 0;
  cursor: pointer;
  line-height: 1;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  svg {
    color: var(--nav-base-colour);
  }
`;

export const Pagination = styled.div`
  display: flex;
  max-width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
