import styled from 'styled-components';
import { ReactComponent as Endless } from 'assets/svg/Icons/Endless.svg';
import { Link } from 'components/atoms';

export const DueDiligenceComponent = styled.section``;

export const ViewCase = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.crowberryBlue} !important;

  svg {
    margin-left: ${({ theme }) => theme.spacing(12)};
    color: ${({ theme }) => theme.colours.swimmersPool};
  }
`;

export const EndlessIcon = styled(Endless)`
  heigth: 14px;
  width: 14px;
`;

export const ActionMenuWrapper = styled.td<{ verticalAlign: string }>`
  vertical-align: ${({ verticalAlign }: any) => verticalAlign};
  .callout-trigger {
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
