import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedRoute from './auth/protected-route';

import { Login } from 'pages/login';
import { ForgotPassword } from 'pages/forgot-password';
import { ResetPassword } from 'pages/reset-password';
import { Register } from 'pages/register';
import { TaskList } from 'pages/tasklist';
import { ThirdParty } from 'pages/third-party';
import { ThirdPartyEditPage } from 'pages/third-party-edit';
import { ThirdPartyEditApproval } from 'pages/third-party-edit-approval';
import { ThirdPartyCreate } from 'pages/third-party-create';
import { ThirdPartyProfile } from 'pages/third-party-profile';
import { ThirdPartyScreening } from 'pages/third-party-screening';
import InitiateDueDiligence from 'pages/initiate-due-diligence';
import InitiateRelatedEntityDueDiligence from 'pages/initiate-related-entity-due-diligence';
import { ReportHtml, ReportPdf } from 'pages/report';
import { Settings } from 'pages/settings';
import { NotFound } from 'pages/not-found';
import { Unauthorised } from 'pages/unauthorised';

const App = () => {
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword />
      </Route>
      <Route path="/reset-password" exact>
        <ResetPassword />
      </Route>
      <Route path="/register" exact>
        <Register />
      </Route>
      <ProtectedRoute path={['/', '/tasklist']} exact>
        <TaskList />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party" exact>
        <ThirdParty />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party/profile/:id" exact>
        <ThirdPartyProfile />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party/create" exact>
        <ThirdPartyCreate />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party/edit/:id" exact>
        <ThirdPartyEditPage />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party/edit-approval/:id" exact>
        <ThirdPartyEditApproval />
      </ProtectedRoute>
      <ProtectedRoute path="/third-party/screening/:id" exact>
        <ThirdPartyScreening />
      </ProtectedRoute>
      <ProtectedRoute
        path="/initiate-due-diligence/:id"
        renderComponent={InitiateDueDiligence}
        exact
      />
      <ProtectedRoute
        path="/initiate-related-entity-due-diligence/:id"
        renderComponent={InitiateRelatedEntityDueDiligence}
        exact
      />
      <ProtectedRoute path="/report/:id" exact>
        <ReportHtml />
      </ProtectedRoute>
      <ProtectedRoute path="/report/:id/pdf" exact>
        <ReportPdf />
      </ProtectedRoute>
      <ProtectedRoute path="/settings" exact>
        <Settings />
      </ProtectedRoute>
      <Route path="/unauthorised" exact>
        <Unauthorised />
      </Route>
      <Route path="/not-found" exact>
        <NotFound />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default App;
