import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colours } }) => colours.white};
  padding: ${({ theme: { spacing } }) => spacing(40)};
  border-radius: 5px;
`;

export const ThankYouMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${({ theme }) => theme.colours.crowberryBlue};
  line-height: ${({ theme }) => theme.pxToRem(27)};

  & > button {
    margin-left: ${({ theme }) => theme.pxToRem(40)};
    margin-top: 35px;
    align-self: end;
    max-width: 294px;
    a {
      font-size: 16px;
    }
  }

  .description {
    display: flex;
    gap: 24px;
  }
`;

export const DueDiligenceForm = styled.form`
  & abbr {
    margin-right: 15px;
  }
  & .radio-group {
    & > div {
      min-width: ${({ theme }) => theme.pxToRem(288)};
    }
  }

  & .buttons {
    display: flex;
    justify-content: end;
    margin-bottom: ${({ theme }) => theme.pxToRem(30)};

    & > button:first-child {
      margin-right: ${({ theme }) => theme.pxToRem(30)};
    }
  }
`;

export const PageHeader = styled.h1`
  font-weight: 300;
  margin-bottom: ${({ theme }) => theme.pxToRem(30)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const SideModulesWrapper = styled.div`
  width: 100%;
  padding-left: ${({ theme: { spacing } }) => spacing(30)};
`;

export const GlobalSearch = styled.div`
  align-self: flex-end;
  margin-bottom: ${({ theme: { spacing } }) => spacing(20)};
`;

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.pxToRem(20)};
`;

export const DueDiligenceType = styled.div`
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
`;

export const ComboBoxContainer = styled.div`
  width: ${({ theme }) => theme.pxToRem(250)};
`;
