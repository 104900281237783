import React, { FC } from 'react';
import {
  Container,
  FileInfoContainer,
  InfoContainer,
  Name,
  SizeCategoryContainer,
  ActionContainer,
  CategoryTextContainer,
} from './DocumentCard.styles';
import FileFormatIcon from 'components/atoms/file-format-icon';

import { DocumentCardTypes } from './types';
import { ellipsisText } from 'helpers/strings-functions';
import { useTheme } from 'styled-components';
import { Icon } from 'components/atoms';

const DocumentCard: FC<DocumentCardTypes> = ({
  fileType,
  name,
  date,
  category,
  actionComponent,
  fullWidth,
  option,
  tapSelect,
  onOptionClick,
  onSelect,
  isSelected,
  extendedNames = false,
}: DocumentCardTypes) => {
  const theme = useTheme();

  const handleSelect = () => {
    if (option === 'withSelect') {
      onSelect(!isSelected);
    }
    handleOnSelectOptionClick();
  };

  const handleOnSelectOptionClick = () => {
    if (onOptionClick) {
      onOptionClick();
    }
  };

  return (
    <Container
      data-testid="DocumentCardContainer"
      className="documentCard"
      selected={isSelected}
      fullWidth={fullWidth}
      title={name}
      onClick={tapSelect ? () => handleSelect() : () => {}}
    >
      <FileInfoContainer>
        <FileFormatIcon fileType={fileType} />
        <InfoContainer>
          <Name>{ellipsisText(name, extendedNames ? 31 : 24)}</Name>
          <SizeCategoryContainer>
            <CategoryTextContainer>
              {ellipsisText(category, extendedNames ? 42 : 32)}
            </CategoryTextContainer>
            <p>
              <span>Created: {new Date(date).toLocaleDateString('en-GB')}</span>
            </p>
          </SizeCategoryContainer>
        </InfoContainer>
      </FileInfoContainer>
      <ActionContainer isSelected={isSelected}>
        {actionComponent ? actionComponent : null}
        {option === 'withRemove' ? (
          <div className="close-box" onClick={handleOnSelectOptionClick}>
            <Icon
              icon="close"
              size="xxxxxs"
              colour={theme.colours.agedGray}
              cursor="pointer"
            />
          </div>
        ) : null}
        {option === 'withSelect' ? (
          <div className="select-box" onClick={handleSelect}>
            <Icon
              icon="check"
              size="xxxxs"
              colour={theme.colours.white}
              cursor="pointer"
            />
          </div>
        ) : null}
      </ActionContainer>
    </Container>
  );
};

export default DocumentCard;
