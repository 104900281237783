import React, { FC } from 'react';
import { FlagIcon } from 'react-flag-kit';
import { CountryComponent } from './Country.styles';
import { CountryProps } from './types';
import countries from 'types/countries';

import { getName, overwrite } from 'country-list';

overwrite([
  { code: 'GB', name: 'UK' },
  { code: 'US', name: 'US' },
  { code: 'TW', name: 'Taiwan' },
]);

const Country: FC<CountryProps> = ({ countryCode, fullWidth = false }) => (
  <CountryComponent fullWidth={fullWidth}>
    {countries.includes(countryCode) && (
      <FlagIcon code={countryCode} aria-hidden />
    )}
    <span>{getName(countryCode)}</span>
  </CountryComponent>
);

export default Country;
