import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { LinkTypes } from './types';

interface RouterLinkProps {
  dark?: number;
  style?: any;
}

export const RouterLink = styled(Link)<RouterLinkProps>`
  ${(props) => {
    return {
      color: props.dark
        ? props.theme.colours.white
        : props.theme.colours.crowberryBlue,
      ...props.style,
    };
  }};
`;

export const AnchorLink = styled.a<LinkTypes>``;
