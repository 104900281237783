import styled, { css } from 'styled-components';
import { Risks } from 'components/atoms/risk-rating/types';
import { Fieldset } from 'components/atoms/field-radio/FieldRadio.styles';

export const RelatedEntitiesComponent = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing(100)};

  th {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }
`;

export const RelatedEntityName = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

type RiskRatingIconProps = { risk: Risks };

export const RiskRatingIcon = styled.span<RiskRatingIconProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(26)};
  height: ${({ theme }) => theme.spacing(26)};
  border-radius: 50%;

  & + & {
    margin-left: ${({ theme }) => theme.spacing(10)};
  }

  svg {
    width: ${({ theme }) => theme.spacing(15)};
    height: ${({ theme }) => theme.spacing(15)};
    cursor: pointer;
  }

  ${({ risk, theme: { colours } }) => {
    const mappedColours = {
      Low: colours.arcadianGreen,
      Medium: colours.californiaDreaming,
      High: colours.deepCoral,
      Unknown: colours.agedGray,
    };
    return css`
      background-color: ${mappedColours[risk]};
      ${risk === 'Unknown' &&
      css`
        svg {
          color: ${colours.agedGray};
        }
      `}
    `;
  }}
`;

export const RadioContainer = styled(Fieldset)`
  div {
    margin-right: ${({ theme }) => theme.spacing(15)};
  }
`;

export const ActionMenuWrapper = styled.td`
  .callout-trigger {
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
