import styled from 'styled-components';

export const BannerBackground = styled.div`
  background: ${({ theme }) => theme.colours.porpoisePlace};
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const BannerText = styled.p`
  font-size: 15px;
  color: white;
  padding: 0px 15px;
`;
