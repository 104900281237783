import styled from 'styled-components';

export const NamesListComponent = styled.ul`
  display: flex;
  flex-direction: column;

  li:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colours.concreteGray};
  }
`;

export const UserItem = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;

  .avatar {
    flex: 0 0 auto;
  }
`;
