import React, { useState, useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import AppLayout from 'layouts/app-layout';

import { FormHeader } from 'form/form.styles';
import { Fieldset } from 'components/atoms/field-radio/FieldRadio.styles';
import {
  Abbreviation,
  Accordion,
  Button,
  FormControl,
  FieldSelect,
  FieldRadio,
  Loading,
  Link as StyledLink,
  PageTitle,
  FieldCheckbox,
  Icon,
} from 'components/atoms';
import { useFetch } from 'hooks/useFetch';
import { Link, useHistory, Redirect } from 'react-router-dom';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';

import {
  DueDiligenceForm,
  ThankYouMessage,
  SideModulesWrapper,
  Grid,
  ResponsiveContainer,
  GlobalSearch,
  DueDiligenceType,
  ComboBoxContainer,
  Wrapper,
} from './InitiateDueDiligence.styles';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GatewayPermissions } from 'auth/constants/permissions';
import ThirdPartyOverviewSideModule from 'components/organisms/third-party-overview-side-module';
import { useTheme } from 'styled-components';

const InitiateDueDiligence = ({ match }) => {
  const [isGlobalSearchEnabled, setIsGlobalSearchEnabled] = useState(false);
  const history = useHistory();
  const thirdPartyId = history.location?.state?.['thirdPartyId'];
  const entityId = match?.params?.id;

  const { accessToken, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();

  const theme = useTheme();

  const schema = yup.object().shape({
    screeningSettings: yup
      .mixed()
      .test('screening-setting', 'Please select one!', (value) => {
        return value !== 'DEFAULT';
      }),
    monitoring: yup
      .mixed()
      .test('screening-setting', 'Please select one!', (value) => {
        if (
          screeningType === 'Self Service' &&
          isSelfServiceMonitoringEnabled
        ) {
          return value !== 'DEFAULT';
        }

        return true;
      }),
    monitoringScreeningSettings: yup
      .mixed()
      .test('screening-setting', 'Please select one!', function (value) {
        if (
          this.parent.monitoring === 'enabled' &&
          screeningType === 'Self Service'
        ) {
          return value !== 'DEFAULT';
        } else {
          return true;
        }
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors: formValidationErrors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      dueDiligenceType: 'Screening',
      screeningType: undefined,
      screeningSettings: 'DEFAULT',
      monitoring: 'DEFAULT',
      monitoringScreeningSettings: 'DEFAULT',
      isGlobalSearch: false,
    },
  });

  const dueDiligenceType = useWatch({
    control,
    name: 'dueDiligenceType',
  });
  const screeningType = useWatch({
    control,
    name: 'screeningType',
  });
  const monitoringValue = useWatch({ control, name: 'monitoring' });

  const [openDueDiligenceAccordion, setOpenDueDiligenceAccordion] =
    useState(true);
  const [goToSecondSection, setGoToSecondSection] = useState(false);
  const [openScreenSettingsAccordion, setOpenScreenSettingsAccordion] =
    useState(false);

  const {
    data,
    loading,
    trigger: fetchLazyDDTypes,
  } = useFetch(`${vantageWebApi}/onlinescreening/duediligencetypes`, {
    method: 'GET',
    lazy: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const {
    data: relatedEntityData,
    loading: loadingRelatedEntity,
    responseStatus,
  } = useFetch(`${vantageWebApi}/relatedEntity/${entityId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const screeningLevel =
    data && data.find((x) => x.name === 'Screening')?.dueDiligenceTypes;

  const dueDiligenceTypes =
    screeningLevel &&
    screeningLevel.reduce((acc, value) => {
      if (value.name === 'Self Service') {
        acc.selfService = true;
      }
      if (value.name === 'Managed Service') {
        acc.managedService = true;
      }
      return acc;
    }, {});
  const isSelfServiceMonitoringEnabledByDefault =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Self Service')
      ?.isMonitoringEnabledByDefaultOnNewCases;

  const isSelfServiceMonitoringEnabled =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Self Service')
      ?.isMonitoringEnabled;

  const isManagingServiceMonitoringEnabled =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Managed Service')
      ?.isMonitoringEnabled;

  const initialScreeningSettings =
    screeningLevel && Array.isArray(screeningLevel)
      ? screeningLevel
          .find(({ name }) => name === screeningType)
          ?.screeningSearchTypes?.map(({ id, description }) => ({
            name: description,
            value: id,
          }))
      : [];

  const monitoringScreeningSettings =
    screeningLevel && Array.isArray(screeningLevel)
      ? screeningLevel
          ?.find(({ name }) => name === screeningType)
          ?.ongoingScreeningSearchTypes?.map(({ id, description }) => ({
            name: description,
            value: id,
          }))
      : [];

  const {
    data: createDueDiligenceData,
    loading: loadingDueDiligenceData,
    trigger: triggerDueDiligenceData,
  } = useFetch<unknown, unknown, 'screeningType'>(
    `${vantageWebApi}/relatedEntity/${entityId}/:screeningType`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  useEffect(() => {
    if (isSelfServiceMonitoringEnabledByDefault) {
      setValue('monitoring', 'enabled');
    }

    if (
      dueDiligenceTypes?.selfService &&
      !isSelfServiceMonitoringEnabled &&
      !isSelfServiceMonitoringEnabledByDefault
    ) {
      setValue('monitoring', 'disabled');
    }

    if (dueDiligenceTypes?.selfService && !dueDiligenceTypes?.managedService) {
      setValue('screeningType', 'Self Service');
    }
    if (!dueDiligenceTypes?.selfService && dueDiligenceTypes?.managedService) {
      setValue('screeningType', 'Managed Service');
    }
  }, [
    screeningType,
    isSelfServiceMonitoringEnabled,
    isSelfServiceMonitoringEnabledByDefault,
    setValue,
    dueDiligenceTypes?.selfService,
    dueDiligenceTypes?.managedService,
  ]);

  useEffect(() => {
    setValue('screeningSettings', 'DEFAULT');
    let hasGlobalSearchEnabled;
    if (screeningType === 'Self Service') {
      hasGlobalSearchEnabled = screeningLevel?.find(
        (x) => x.name === 'Self Service'
      )?.globalSearchEnabled;
      if (!isSelfServiceMonitoringEnabled) setValue('monitoring', 'disabled');
      if (isSelfServiceMonitoringEnabledByDefault)
        setValue('monitoring', 'enabled');
    } else {
      hasGlobalSearchEnabled = screeningLevel?.find(
        (x) => x.name === 'Managed Service'
      )?.globalSearchEnabled;
      setValue('monitoring', 'DEFAULT');
    }
    setIsGlobalSearchEnabled(hasGlobalSearchEnabled);
    setValue('monitoringScreeningSettings', 'DEFAULT');
    clearErrors('screeningSettings');
  }, [
    screeningType,
    setValue,
    clearErrors,
    isSelfServiceMonitoringEnabledByDefault,
    isSelfServiceMonitoringEnabled,
    setIsGlobalSearchEnabled,
    screeningLevel,
  ]);

  useEffect(() => {
    if (
      monitoringScreeningSettings &&
      monitoringScreeningSettings.length === 1 &&
      screeningType === 'Self Service'
    ) {
      setValue(
        'monitoringScreeningSettings',
        monitoringScreeningSettings[0].value
      );
      return;
    }

    setValue('monitoringScreeningSettings', 'DEFAULT');
    // ignoring monitoringScreeningSettings, to avoid extra renderings and to avoid clearing monitoringScreeningSettings form value.
    // eslint-disable-next-line
  }, [setValue, monitoringValue]);

  useEffect(() => {
    if (relatedEntityData?.type) {
      fetchLazyDDTypes();
    }
  }, [relatedEntityData?.type, fetchLazyDDTypes]);

  if (responseStatus === 404) {
    return <Redirect to="/not-found" />;
  }

  if (responseStatus === 401) {
    return <Redirect to="/unauthorised" />;
  }

  if (loadingRelatedEntity || loading || loadingDueDiligenceData) {
    return (
      <AppLayout>
        <div>
          <Loading />
        </div>
      </AppLayout>
    );
  }

  if (createDueDiligenceData?.state) {
    if (screeningType === 'Self Service') {
      return (
        <Redirect
          to={`/third-party/screening/${createDueDiligenceData.screeningId}`}
        />
      );
    }
    return (
      <AppLayout
        topBarSlot={
          <StyledLink to={`/third-party/profile/${thirdPartyId}`} dark>
            Back to {terminologyConfig?.thirdPartySingularNaming} manager
          </StyledLink>
        }
      >
        <PageTitle title={`Related Entity: ${relatedEntityData?.name || ''}`} />
        <Wrapper>
          <ThankYouMessage>
            <p className="description">
              <Icon
                className="success-icon"
                icon="check"
                colour={theme.colours.poisonIvy}
                size="md"
              />
              <span>
                <strong>
                  Your{' '}
                  {screeningType === 'Self Service'
                    ? 'Self Service'
                    : 'Managed Service'}
                  : Screening order{' '}
                </strong>
                has been successfully submitted and is currently being
                processed. One of our team members will reach out to you to
                confirm costs and timeframes shortly.
              </span>
            </p>

            <Button secondary>
              <Link to={`/third-party/profile/${thirdPartyId}`}>
                Return to {terminologyConfig?.thirdPartySingularNaming} Profile
                Page
              </Link>
            </Button>
          </ThankYouMessage>
        </Wrapper>
      </AppLayout>
    );
  }
  if (!loadingRelatedEntity || !loading) {
    return (
      <AppLayout
        topBarSlot={
          <StyledLink to={`/third-party/profile/${thirdPartyId}`} dark>
            Back to {terminologyConfig?.thirdPartySingularNaming} manager
          </StyledLink>
        }
      >
        <PageTitle title={`Related Entity: ${relatedEntityData?.name || ''}`} />
        <Grid>
          <Wrapper>
            <FormHeader>Initiate compliance check</FormHeader>
            {loadingDueDiligenceData ? (
              <Loading />
            ) : (
              <DueDiligenceForm
                onSubmit={handleSubmit((data) => {
                  triggerDueDiligenceData({
                    urlParams: {
                      screeningType:
                        data?.screeningType === 'Self Service'
                          ? 'selfservicescreening'
                          : 'managedservicescreening',
                    },
                    body: JSON.stringify({
                      screeningSearchTypeId: Number(
                        getValues('screeningSettings')
                      ),
                      isGlobalSearch: isGlobalSearchEnabled
                        ? getValues('isGlobalSearch')
                        : null,
                      isMonitoringEnabled:
                        screeningType === 'Self Service'
                          ? getValues('monitoring') === 'enabled'
                          : isManagingServiceMonitoringEnabled,
                      ongoingScreeningSearchTypeId:
                        getValues('monitoringScreeningSettings') ===
                          'DEFAULT' || getValues('monitoring') === 'disabled'
                          ? null
                          : Number(getValues('monitoringScreeningSettings')),
                    }),
                  });
                })}
              >
                <Accordion
                  title="Due diligence type"
                  isOpen={openDueDiligenceAccordion}
                  setIsOpen={() => {}}
                  onClick={(event) => {
                    event.preventDefault();
                    setOpenDueDiligenceAccordion((prevState) => !prevState);
                  }}
                  completed={goToSecondSection}
                >
                  <DueDiligenceType>
                    <Fieldset className="radio-group">
                      <FieldRadio
                        label="Screening"
                        id="screening"
                        value="Screening"
                        name="dueDiligenceType"
                        register={register}
                      >
                        <Abbreviation abbreviation="SelfServiceScreening" />
                        <span>Screening</span>
                      </FieldRadio>
                    </Fieldset>
                  </DueDiligenceType>
                  <FormControl helperText="*Mandatory Fields">
                    <label>Select Screening Type</label>
                    <ResponsiveContainer className="radio-group">
                      {dueDiligenceTypes?.selfService &&
                        hasPermission(
                          GatewayPermissions.InitiateDueDiligenceSelfServiceScreening
                        ) && (
                          <FieldRadio
                            label="Self Service Screening"
                            id="selfServiceScreening"
                            value="Self Service"
                            name="screeningType"
                            register={register}
                            checked={!dueDiligenceTypes?.managedService}
                          >
                            <span>Self Service Screening</span>
                          </FieldRadio>
                        )}
                      {dueDiligenceTypes?.managedService &&
                        hasPermission(
                          GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
                        ) && (
                          <FieldRadio
                            label="Managed Service Screening"
                            id="managedServiceScreening"
                            value="Managed Service"
                            name="screeningType"
                            register={register}
                          >
                            <span>Managed Service Screening</span>
                          </FieldRadio>
                        )}
                    </ResponsiveContainer>
                  </FormControl>
                  <div className="buttons">
                    <Button
                      onClick={() =>
                        history.push(`/third-party/profile/${thirdPartyId}`)
                      }
                      type="button"
                      text
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!dueDiligenceType || !screeningType}
                      onClick={(event) => {
                        event.preventDefault();
                        setOpenDueDiligenceAccordion((prevState) => !prevState);
                        if (!goToSecondSection) {
                          setGoToSecondSection(true);
                          setOpenScreenSettingsAccordion(true);
                        }
                      }}
                      secondary
                    >
                      Next step
                    </Button>
                  </div>
                </Accordion>
                <Accordion
                  title="Screening settings"
                  isOpen={openScreenSettingsAccordion}
                  onClick={(event) => {
                    event.preventDefault();
                    if (goToSecondSection) {
                      setOpenScreenSettingsAccordion((prevState) => !prevState);
                    }
                  }}
                >
                  <FormControl helperText="*Mandatory Fields">
                    <>
                      <ResponsiveContainer>
                        <FormControl
                          errorMessage={
                            formValidationErrors?.screeningSettings?.message
                          }
                        >
                          <FieldSelect
                            valid={
                              formValidationErrors?.screeningSettings ===
                              undefined
                            }
                            ariaLabel="Initial screening settings *"
                            id="screeningSettings"
                            label="Initial screening settings *"
                            onChange={() => {}}
                            options={initialScreeningSettings}
                            placeholder="select..."
                            placeholderValue="DEFAULT"
                            register={register}
                            disabled={loading}
                          />
                        </FormControl>
                        {isGlobalSearchEnabled && (
                          <GlobalSearch>
                            <FieldCheckbox
                              ariaLabel="isGlobalSearch"
                              id="isGlobalSearch"
                              name="isGlobalSearch"
                              label="Perform global search"
                              register={register}
                            />
                          </GlobalSearch>
                        )}
                      </ResponsiveContainer>
                      {screeningType === 'Self Service' && (
                        <FormControl
                          errorMessage={
                            formValidationErrors?.monitoring?.message
                          }
                        >
                          <ComboBoxContainer>
                            <FieldSelect
                              valid={
                                formValidationErrors?.monitoring === undefined
                              }
                              ariaLabel="Monitoring *"
                              id="monitoring"
                              label="Monitoring *"
                              placeholder="select..."
                              disabled={
                                isSelfServiceMonitoringEnabledByDefault ||
                                (!isSelfServiceMonitoringEnabledByDefault &&
                                  !isSelfServiceMonitoringEnabled)
                              }
                              fullWidth
                              onChange={() => {}}
                              placeholderValue="DEFAULT"
                              options={[
                                {
                                  name: 'Enabled',
                                  value: 'enabled',
                                },
                                {
                                  name: 'Disabled',
                                  value: 'disabled',
                                },
                              ]}
                              register={register}
                            />
                          </ComboBoxContainer>
                        </FormControl>
                      )}
                      {monitoringValue === 'enabled' &&
                        screeningType === 'Self Service' && (
                          <FormControl
                            errorMessage={
                              formValidationErrors?.monitoringScreeningSettings
                                ?.message
                            }
                          >
                            <FieldSelect
                              valid={
                                formValidationErrors?.monitoringScreeningSettings ===
                                undefined
                              }
                              ariaLabel="Monitoring screening settings *"
                              id="monitoringScreeningSettings"
                              label="Monitoring screening settings *"
                              onChange={() => {}}
                              options={monitoringScreeningSettings}
                              placeholder="select..."
                              placeholderValue="DEFAULT"
                              register={register}
                              disabled={loading}
                            />
                          </FormControl>
                        )}
                    </>
                    <div className="buttons">
                      <Button
                        onClick={() =>
                          history.push(`/third-party/profile/${thirdPartyId}`)
                        }
                        type="button"
                        text
                      >
                        Cancel
                      </Button>
                      <Button type="submit" secondary>
                        Submit
                      </Button>
                    </div>
                  </FormControl>
                </Accordion>
              </DueDiligenceForm>
            )}
          </Wrapper>
          <SideModulesWrapper>
            {relatedEntityData && (
              <ThirdPartyOverviewSideModule
                thirdParty={relatedEntityData}
                thirdPartyType="related entity"
              />
            )}
          </SideModulesWrapper>
        </Grid>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <PageTitle title="Oops something went wrong!" />
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, InitiateDueDiligence);
