export const urlParams = (
  queryString: string
): { email?: string; token?: string } => {
  const params = {};

  queryString
    .replace('?', '')
    .split('&')
    .forEach((param) => {
      const i = param.indexOf('=');
      params[param.substring(0, i)] = param.substring(i + 1, param.length);
    });

  return params;
};
