import React from 'react';
import {
  FooterComponent,
  Copyright,
  FooterLinks,
  SocialMediaLinks,
} from './Footer.styles';

import { Link, Icon } from 'components/atoms';

import { SocialMediaLinkType, FooterTypes } from './types';
import { LinkType } from '../../../types/link-type';

const Footer = ({ copyright, footerLinks, socialLinks }: FooterTypes) => {
  return (
    <FooterComponent>
      <Copyright>{copyright}</Copyright>
      <FooterLinks>
        {footerLinks?.length && (
          <>
            {footerLinks.map((link: LinkType) => {
              return (
                <li key={link.name}>
                  <Link
                    href={link.url}
                    target={link.target}
                    rel={link.target === '_blank' ? 'nofollow noreferer' : ''}
                  >
                    {link.name}
                  </Link>
                </li>
              );
            })}
          </>
        )}
      </FooterLinks>
      <SocialMediaLinks>
        {socialLinks?.length && (
          <>
            {socialLinks.map((link: SocialMediaLinkType) => (
              <li key={link.name}>
                <Link
                  href={link.url}
                  target={link.target}
                  rel={link.target === '_blank' ? 'nofollow noreferer' : ''}
                  aria-label={link.name}
                >
                  <Icon icon={link.icon} aria-hidden size="sm" />
                </Link>
              </li>
            ))}
          </>
        )}
      </SocialMediaLinks>
    </FooterComponent>
  );
};

export default Footer;
