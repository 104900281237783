import { Button } from 'components/atoms';
import styled from 'styled-components';

const gap = 14;

export const Layout = styled.div`
  display: grid;
  // Percentage is important for right column to constrain the message carousel width.
  grid-template-columns: 375px 66.667%;
  grid-gap: ${({ theme }) => theme.spacing(30)};
  margin-bottom: ${({ theme }) => theme.spacing(100)};

  @media screen and (width > 1400px) {
    grid-gap: ${({ theme }) => theme.spacing(40)};
  }
`;

export const Column = styled.div`
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(gap)};
  }
`;

type SectionProps = { large?: boolean };

export const Section = styled.section<SectionProps>`
  padding: ${({ large = false, theme }) =>
    theme.spacing(large ? gap * 2 : gap)};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.black};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(44)} 0;

  h2 {
    margin-top: ${({ theme }) => theme.spacing(48)};
    color: ${({ theme }) => theme.colours.crowberryBlue};
    text-align: center;
  }
`;

export const SimpleTable = styled.table<{ dark?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colours.gallery}33;

  th,
  td {
    padding: ${({ theme }) => theme.spacing(20)};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: 1.5;
    text-align: left;
  }

  th {
    background-color: ${({ theme }) => theme.colours.whiteSolid};
    color: ${({ theme }) => theme.colours.charcoalDust};

    &:first-child {
      border-radius: ${({ theme }) => theme.pxToRem(5)} 0 0
        ${({ theme }) => theme.pxToRem(5)};
    }

    &:last-child {
      border-radius: 0 ${({ theme }) => theme.pxToRem(5)}
        ${({ theme }) => theme.pxToRem(5)} 0;
    }
  }

  td,
  td > span {
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.white : theme.colours.crowberryBlue};
  }

  a {
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.white : theme.colours.pacificBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: 1.5;
    text-decoration-color: currentColor;
  }
`;

export const BackLinkText = styled.span`
  color: ${({ theme: { colours } }) => colours.white};
`;

interface TabContentProps {
  label: string;
}

export const TabContent = styled.div<TabContentProps>`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  margin-top: ${({ theme }) => theme.pxToRem(30)};

  section {
    margin-top: ${({ theme }) => theme.pxToRem(15)};
  }
`;

export const InitiateButton = styled(Button)`
  padding: ${({ theme }) => theme.pxToRem(15)};

  * {
    cursor: pointer;
  }

  & > span {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.pxToRem(8)};
  }
`;
