export const typography = {
  fontFamily: {
    primary: ['Open Sans', 'sans-serif'].join(','),
    secondary: ['SF Pro Text', 'sans-serif'].join(','),
  },
  fonts: `
  @font-face {
    font-family: 'SF Pro Text';
    src: url("/sf-text-light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: url("/sf-text-regular.woff") format('woff');
    font-weight: 400;
    font-style: normal;
  }
  `,
  fontWeight: {
    light: 300,
    semiRegular: 400,
    regular: 500,
    bold: 700,
  },
  fontSize: {
    small: `14px`,
    medium: `24px`,
  },
};
