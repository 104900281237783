import styled, { css } from 'styled-components';
import { TooltipArrowTypes } from './types';

export const TooltipBody = styled.div<{ bodyStyles?: string }>`
  background: ${({ theme }) => theme.colours.crowberryBlue};
  color: ${({ theme }) => theme.colours.white};
  font-weight: 400;
  font-size: 14px;
  padding: 15px;
  padding-bottom: 20px;
  border-radius: 4px;
  max-width: 400px;
  z-index: ${({ theme }) => theme.zIndex.level_1};

  ${({ bodyStyles }) =>
    bodyStyles &&
    css`
      ${bodyStyles}
    `}
`;

export const CloseIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.1;
    border-radius: 20%;
  }
`;

export const TooltipArrow = styled.div<TooltipArrowTypes>`
  position: absolute;
  ${({ placement, theme }) => {
    const color = theme.colours.crowberryBlue;
    switch (placement) {
      case 'bottom':
        return css`
          border-color: transparent transparent ${color} transparent;
          bottom: 100%;
          left: 50%;
        `;
      case 'top':
        return css`
          border-color: ${color} transparent transparent transparent;
          top: 100%;
          left: 50%;
        `;
      case 'left':
        return css`
          border-color: transparent transparent transparent ${color};
          left: 100%;
          top: 50%;
        `;
      case 'right':
        return css`
          border-color: transparent ${color} transparent transparent;
          right: 100%;
          top: 50%;
        `;
    }
  }}

  border-width: 5px;
  border-style: solid;
`;

export const TriggerSpan = styled.span<{ stretchChildren: boolean }>`
  ${({ stretchChildren }) =>
    stretchChildren &&
    css`
      display: flex;
    `}
`;
