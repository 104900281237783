import styled from 'styled-components';

export const UploadedDocumentsContainer = styled.div`
  margin: ${({ theme }) => theme.pxToRem(10)} 0 ${({ theme }) =>
  theme.pxToRem(10)}; 0;
  display: flex;
  flex-direction: row;
  max-width: 500px;
`;
export const IconBox = styled.div`
  display: flex;
  padding: 20px;
`;
export const UploadedDocumentsList = styled.div`
  flex: 2;
`;
export const UploadedDocumentsLabelBox = styled.div`
  flex: 1.2;
`;
export const UploadedDocumentsLabel = styled.p`
  color: ${({ theme }) => theme.colours.charcoalDust};
`;
export const ErrorMessage = styled.p`
  font-size: 15px;
  font-weight: 300;
  color: ${({ theme }) => theme.colours.indianRed};
`;
