import styled, { css } from 'styled-components';
import { MultiStepNavigationItemProps } from './types';

export const MultiStepNavigationComponent = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(20)} 0;
  border: solid ${({ theme }) => `${theme.colours.gallery}33`}; // 0.2 alpha.
  border-width: 1px 0;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(30)};
  }
`;

export const MultiStepNavigationItemComponent = styled.button<MultiStepNavigationItemProps>`
  display: flex;
  align-items: center;

  div,
  span {
    color: #fff;
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.spacing(14)};
    letter-spacing: 0;
  }

  span {
    margin-left: ${({ theme }) => theme.spacing(10)};
    line-height: 1.715;
  }

  ${({ muted }) =>
    muted &&
    css`
      span {
        opacity: 0.5;
      }
    `}

  &:hover{
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')}
  }$
`;

const Indicator = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing(40)};
  height: ${({ theme }) => theme.spacing(40)};
  border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
  border-radius: 50%;
  text-align: center;
`;

export const CompletedIndicator = styled.div`
  ${Indicator}
  background-color: ${({ theme }) => theme.colours.swimmersPool};
`;

export const CurrentIndicator = styled.div`
  ${Indicator}
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: ${({ theme }) => theme.spacing(24)};
    height: ${({ theme }) => theme.spacing(24)};
    border-radius: 50%;
    background-color: #fff;
    content: '';
    transform: translate(-50%, -50%);
  }
`;

export const NextIndicator = styled.div`
  ${Indicator}
`;
