import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';

export const refreshTokenOnDemand = async (
  vantageWebApi: string,
  headers: Record<string, any>,
  authTokens: AuthTokens,
  onError?: (err: Error) => void
) => {
  try {
    const { accessTokenId, refreshTokenId, refreshToken } = authTokens;
    const response = await fetch(`${vantageWebApi}/account/token`, {
      method: 'POST',
      headers: headers,
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: 'internal_api offline_access',
      }).toString(),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const jsonResponse = await response.json();

    localStorage.setItem(accessTokenId, jsonResponse?.access_token);
    localStorage.setItem(refreshTokenId, jsonResponse?.refresh_token);

    return {
      accessToken: localStorage.getItem(accessTokenId),
      refreshToken: localStorage.getItem(refreshTokenId),
    };
  } catch (error) {
    onError?.(error);
    if (process.env.NODE_ENV === 'development') {
      console.log('Authentication Error ', error);
    }
  }
};

export const isTokenExpired = (accessToken: string) => {
  return (
    accessToken &&
    jwt.decode(accessToken)?.exp &&
    dayjs(jwt.decode(accessToken).exp * 1000).isBefore(new Date())
  );
};

export type AuthTokens = {
  accessTokenId: string;
  refreshTokenId: string;
  refreshToken: string;
};
