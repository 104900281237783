import ConfirmationModal from 'components/organisms/confirmation-modal';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RelatedEntityDeleteModalProps } from './types';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';

const RelatedEntityDeleteModal: React.FC<RelatedEntityDeleteModalProps> = ({
  isOpen,
  onClose,
  relatedEntityId,
  thirdPartyId,
}) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const [loadingStatus, setLoadingStatus] = useState<string>('not started');
  const [title, setTitle] = useState<string>('Are you sure?');
  const [message, setMessage] = useState<string>(
    'Please note that this will delete the selected Related Entity and any associated screening reports.'
  );
  const history = useHistory();

  const { loading, trigger: triggerDelete } = useFetch(
    `${vantageWebApi}/relatedEntity/thirdParty/${thirdPartyId}/relatedEntity/${relatedEntityId}`,
    {
      method: 'DELETE',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  useEffect(() => {
    if (loading) {
      setLoadingStatus('started');
      setTitle('Deleting, please wait');
    }
    if (!loading && loadingStatus === 'started') {
      setLoadingStatus('completed');
    }
    if (loadingStatus === 'completed') {
      setLoadingStatus('not started');
      setTitle('Deleted, reloading page');
      setMessage('');
      history.go(0);
    }
  }, [loading, loadingStatus, history]);

  const handleProceed = (): void => {
    if (relatedEntityId == null || thirdPartyId == null) {
      return;
    }
    triggerDelete();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      title={title}
      onProceed={handleProceed}
      message={message}
      loading={loading}
    ></ConfirmationModal>
  );
};

export default RelatedEntityDeleteModal;
