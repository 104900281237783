import React, { useState } from 'react';
import { useFetch } from 'hooks';

import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useHistory } from 'react-router-dom';
import Modal from 'components/molecules/modal';
import { Button, Loading, FormControl, FieldDate } from 'components/atoms';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { NextReviewDateModalProps } from './types';
import {
  ButtonsContainer,
  DatePickerContainer,
  ModalTitle,
} from './NextReviewDateModal.styles';

const NextReviewDateModal: React.FC<NextReviewDateModalProps> = ({
  isOpen,
  onClose,
  loading,
  title,
  thirdPartyId,
  proceedButtonText = 'Initiate Compliance Checks',
  cancelButtonText = 'Not now',
  bodyContainerRef,
}) => {
  const history = useHistory();
  const { vantageWebApi } = useConfig();
  const { accessToken } = useAuth();
  const [dateFieldText, setDateFieldText] = useState('');
  const [dateFieldError, setDateFieldError] = useState(undefined);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const defaultValues = {
    nextReviewDate: null,
  };

  const { getValues, control, trigger } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
  });

  const parseData = (data, thirdPartyId) => {
    const updatedData = { ...data };
    updatedData.ThirdPartyId = thirdPartyId;
    updatedData.NextReviewDate = getValues('nextReviewDate')
      ? dayjs(getValues('nextReviewDate')).format()
      : null;
    return JSON.stringify(updatedData);
  };

  const {
    loading: editNextReviewLoading,
    trigger: editNextReview,
    error: editNextReviewError,
  } = useFetch(
    `${vantageWebApi}/thirdParty/${thirdPartyId}/edit-nextReviewDate`,
    {
      method: 'POST',
      lazy: true,
      headers,
    }
  );

  const handleSubmit = (isForInitiateDueDiligence) => {
    editNextReview({ body: parseData(getValues(), thirdPartyId) }).then(() => {
      editNextReviewSuccess(isForInitiateDueDiligence);
    });
  };

  const editNextReviewSuccess = (shouldNavigate) => {
    if (shouldNavigate) {
      history.push(`/initiate-due-diligence/${thirdPartyId}`);
    } else {
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  };

  const onProceedSubmit = () => {
    onSubmit(true);
  };
  const onCancelSubmit = () => {
    onSubmit(false);
  };

  const onSubmit = (isForInitiateDueDiligence) => {
    const date = getValues().nextReviewDate;
    if (date === null && dateFieldText !== '') {
      setDateFieldError('Date not valid');
    } else {
      handleSubmit(isForInitiateDueDiligence);
      setDateFieldError(undefined);
    }
  };

  const dateOnChange = (date, field) => {
    field.onChange(date ? new Date(date) : null);
  };

  return (
    <Modal
      onClose={() => onClose()}
      isOpen={isOpen}
      title={<ModalTitle>{title}</ModalTitle>}
      bodyContainerRef={bodyContainerRef}
    >
      {(loading || editNextReviewLoading) && <Loading />}
      {!loading && !editNextReviewLoading && (
        <>
          <DatePickerContainer>
            <FormControl errorMessage={dateFieldError}>
              <Controller
                control={control}
                name="nextReviewDate"
                render={({ field }) => {
                  return (
                    <FieldDate
                      fullWidth={true}
                      valid={!dateFieldError}
                      label="Set the next review date (Optional)"
                      format="DD/MM/YYYY"
                      value={field.value}
                      onChange={(date) => dateOnChange(date, field)}
                      onTextChange={(date) => {
                        trigger();
                        setDateFieldText(date);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </DatePickerContainer>
          <ButtonsContainer>
            <Button
              onClick={onCancelSubmit}
              text={true}
              type="button"
              disabled={editNextReviewError || editNextReviewLoading}
              secondary={true}
              outline={true}
              style={{
                marginRight: '3rem',
              }}
            >
              {cancelButtonText}
            </Button>
            <Button
              secondary
              onClick={onProceedSubmit}
              disabled={editNextReviewError || editNextReviewLoading}
            >
              {proceedButtonText}
            </Button>
          </ButtonsContainer>
        </>
      )}
    </Modal>
  );
};

export default NextReviewDateModal;
