import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getName } from 'country-list';
import { Tooltip } from 'components/atoms';
import { AppLayout } from 'layouts';
import { useDebounce, useFetch } from 'hooks';
import FilterContext from 'context/third-party-filter';
import { headings as headingsConfig } from './config';
import {
  Abbreviation,
  Button,
  Callout,
  Country,
  Date,
  Link,
  PageTitle,
  RiskRating,
  SectionedList,
} from 'components/atoms';
import {
  ActionMenu,
  Indicator,
  Pagination,
  Search,
  Table,
} from 'components/molecules';
import {
  PaginationWrapper,
  ThirdPartyName,
  ThirdPartyDetail,
  ChildContainer,
  ActionMenuWrapper,
  ThirdpartyNameLink,
} from './ThirdParty.styles';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { GatewayPermissions } from 'auth/constants/permissions';
import ReportActionsDrawer from './report-actions-drawer/ReportsActionsDrawer';
import { ActionMenuAction } from 'components/molecules/action-menu/types';
import ThirdPartyDeleteModal from './third-party-delete-modal';
import { ButtonToggle } from 'components/atoms/button/Button.styles';
import { RegisteredClientUsers, ThirdpartySearchResponse } from './types';
import { FlagIconCode } from 'react-flag-kit';
import UsersCounter from 'components/organisms/users-counter';
import { ellipsisText } from 'helpers/strings-functions';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import useDeepState from 'hooks/useDeepState';

const ThirdParty = () => {
  const [sortingIndex, setSortingIndex] = useState(-1);
  const [isDescending, setIsDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filters, setFilters] = useDeepState({
    pageNumber: 1,
  });
  const { accessToken, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const history = useHistory();
  const isAdmin: boolean = hasPermission(GatewayPermissions.Admin);
  const [showAll, setShowAll] = useState<boolean>(isAdmin);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const storedPreference = JSON.parse(
    localStorage.getItem('show-actions-guide-tooltip')
  );
  const [tooltipVisible, setTooltipVisible] = useState(
    storedPreference !== null ? storedPreference : true
  );
  const [thirdPartyToBeDeleted, setThirdPartyToBeDelete] =
    useState<number>(null);
  const [resultData, setResultData] = useState<
    ThirdpartySearchResponse['results']
  >([]);

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useFetch(`${vantageWebApi}/account/team`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const headings = useMemo(() => {
    if (!headingsConfig[0] || !headingsConfig[7]) {
      throw 'headings config needs index 0 and 7';
    }

    headingsConfig[0].name = terminologyConfig?.thirdPartySingularNaming;
    const users = (
      Array.isArray(usersData) ? usersData : []
    ) as RegisteredClientUsers[];

    const possibleUsersOptions = users.map((user) => ({
      name: `${user.firstName} ${user.lastName}`,
      value: user.id.toString(),
    }));

    if (Array.isArray(usersData)) {
      // enable filter when userData is available
      headingsConfig[7].filter = {
        title: 'Filter by internal owners',
        field: 'internalOwnerIds',
        options: [{ name: 'None', value: '0' }, ...possibleUsersOptions],
        height: '280px',
        width: '300px',
      };
    }

    return [...headingsConfig];
  }, [usersData, terminologyConfig]);

  const sortingHeadingOrder = useCallback(
    (sortingIndex, headings, isDescending) => {
      if (sortingIndex >= 0 && headings?.[sortingIndex]?.field) {
        return { sortType: headings?.[sortingIndex]?.field, isDescending };
      }
      return null;
    },
    []
  );

  const parseThirdPartyOwners = (thirdPartyOwners: string[]) => {
    const [first, ...restOfOwners] = thirdPartyOwners;
    return (
      <>
        <span title={first}>{`${ellipsisText(first, 20) ?? ''}${
          restOfOwners.length > 0 ? ',' : ''
        } `}</span>
        <UsersCounter names={restOfOwners} />
      </>
    );
  };

  const {
    data: thirdPartySearchData,
    loading: thirdPartySearchLoading,
    error: thirdPartySearchError,
    trigger: thirdPartySearchFetchLazy,
  } = useFetch(`${vantageWebApi}/thirdParty/search`, {
    method: 'POST',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  useEffect(() => {
    if (thirdPartySearchData && thirdPartySearchData?.results) {
      setCurrentPage(thirdPartySearchData.currentPage);
      setResultData(thirdPartySearchData.results);
    }
  }, [thirdPartySearchData]);

  useEffect(() => {
    if (!usersData) {
      return;
    }
    thirdPartySearchFetchLazy({
      body: JSON.stringify({
        page: filters.pageNumber,
        pageSize: 20,
        query: debouncedSearchTerm,
        filter: {
          ...filters,
        },
        sort: sortingHeadingOrder(sortingIndex, headings, isDescending),
        showAll: showAll,
      }),
    });
  }, [
    debouncedSearchTerm,
    vantageWebApi,
    thirdPartySearchFetchLazy,
    showAll,
    sortingIndex,
    headings,
    isDescending,
    sortingHeadingOrder,
    usersData,
    filters,
  ]);

  const updateResultsAfterDelete = () => {
    if (!resultData) {
      return;
    }
    const newResultData = resultData.filter((thirdParty) => {
      if (thirdParty.id !== thirdPartyToBeDeleted) {
        return thirdParty;
      }
    });
    setResultData(newResultData);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setThirdPartyToBeDelete(null);
  };

  const setTableOrder = ({ index }: { index: number }) => {
    if (sortingIndex === index) {
      if (isDescending === false) {
        // Change order direction.
        setIsDescending(true);
      } else {
        // Remove any ordering.
        setIsDescending(false);
        setSortingIndex(-1);
      }
      return;
    }
    setIsDescending(false);
    setSortingIndex(index);
  };

  const canDeleteThirdParties: boolean = hasPermission(
    GatewayPermissions.DeleteThirdParty
  );

  const canEditThirdParties: boolean = hasPermission(
    GatewayPermissions.EditThirdPartyRelatedEntity
  );

  const canViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const hasActions: boolean = canDeleteThirdParties || canEditThirdParties;

  interface ActionMenuActions extends Array<ActionMenuAction> {}

  function getPermittedActions(id: number): ActionMenuActions {
    let actions = [];

    if (canEditThirdParties) {
      actions.push({
        name: 'Edit',
        onClick: () => history.push(`/third-party/edit/${id}`),
      });
    }

    if (canDeleteThirdParties) {
      actions.push({
        name: 'Delete',
        onClick: () => {
          setThirdPartyToBeDelete(id);
          setDeleteModalOpen(true);
        },
      });
    }

    return actions;
  }

  const handleTooltipClose = () => {
    setTooltipVisible(false);
    localStorage.setItem('show-actions-guide-tooltip', JSON.stringify(false));
  };

  return (
    <AppLayout bgColor="whiteSolid">
      <FilterContext.Provider value={[filters, setFilters]}>
        <PageTitle
          title={`${terminologyConfig?.thirdPartySingularNaming} manager`}
        ></PageTitle>
        <div style={{ marginBottom: '1.5rem' }}>
          <ChildContainer>
            <div>
              <Search
                ariaLabel={`Search ${terminologyConfig?.thirdPartyPluralNaming.toLowerCase()}`}
                onChange={(event) => {
                  setSearchTerm(event.currentTarget.value);
                  setFilters({ ...filters, pageNumber: 1 });
                }}
              />
              {hasPermission(
                GatewayPermissions.CreateThirdPartyAndRelatedEntity
              ) && (
                <Link
                  to="/third-party/create"
                  style={{ paddingLeft: '1.25rem' }}
                >
                  Create new {terminologyConfig?.thirdPartySingularNaming}
                </Link>
              )}
            </div>
          </ChildContainer>

          <ChildContainer>
            <ButtonToggle>
              <div>
                {isAdmin && (
                  <Button
                    type="button"
                    style={{ borderRadius: '0.3rem 0rem 0rem 0.3rem' }}
                    disabled={!showAll ? true : false}
                    onClick={() => {
                      setShowAll(!showAll);
                      setCurrentPage(1);
                      setFilters({ ...filters, pageNumber: 1 });
                    }}
                  >
                    My {terminologyConfig?.thirdPartyPluralNaming}
                  </Button>
                )}
                {isAdmin && (
                  <Button
                    type="button"
                    style={{ borderRadius: '0rem 0.3rem 0.3rem  0rem' }}
                    disabled={showAll ? true : false}
                    onClick={() => {
                      setShowAll(!showAll);
                      setCurrentPage(1);
                      setFilters({ ...filters, pageNumber: 1 });
                    }}
                  >
                    All {terminologyConfig?.thirdPartyPluralNaming}
                  </Button>
                )}
              </div>
              <div>
                <Tooltip
                  trigger="click"
                  bodyStyles={'padding: 10px; padding-bottom: 10px;'}
                  tooltipText={
                    (
                      <>
                        You can now search for a report /<br /> screening here
                      </>
                    ) as any
                  }
                  placement="top"
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [-100, 20],
                      },
                    },
                  ]}
                  tooltipShown={tooltipVisible}
                  onClose={handleTooltipClose}
                >
                  <Button
                    type="button"
                    onClick={() => {
                      setDrawerOpen(true);
                    }}
                  >
                    Actions
                  </Button>
                </Tooltip>
              </div>
            </ButtonToggle>
          </ChildContainer>
        </div>
        <Table
          headings={headings}
          showPlaceholder={
            thirdPartySearchLoading ||
            thirdPartySearchError ||
            usersLoading ||
            usersError
          }
          onHeadingClick={setTableOrder}
          sortedColumn={sortingIndex}
        >
          {resultData.map(
            ({
              alternativeName,
              createdDate,
              dueDiligenceTypes = [],
              id,
              internalId,
              name,
              nextReviewDate,
              primaryCountry,
              relatedEntityThirdParty = [],
              tasks = [],
              taskCount,
              thirdPartyApprovals = [],
              thirdPartyOwners = [],
              type,
            }) => {
              return (
                <tr key={id}>
                  <td>
                    <ThirdPartyName>
                      <ThirdpartyNameLink to={`/third-party/profile/${id}`}>
                        {name || alternativeName || id}
                      </ThirdpartyNameLink>
                      <Callout
                        name={`Additional information for ${name}`}
                        direction="right"
                        trigger={() => (
                          <Indicator
                            name={name}
                            icon={type === 'Individual' ? 'user' : 'company'}
                            value={canViewTaskList ? taskCount : 0}
                          />
                        )}
                        maxHeight="400px"
                      >
                        {canViewTaskList && (
                          <ThirdPartyDetail>
                            <h3>{name}</h3>
                            <div>
                              {tasks && tasks.length > 0 ? (
                                tasks.map((task) => (
                                  <p key={task.id}>
                                    {task.level && <span>{task.level}: </span>}
                                    {task.status === 'Next Review Date'
                                      ? 'Next Review Date Due'
                                      : task.status}
                                  </p>
                                ))
                              ) : (
                                <p>No tasks</p>
                              )}
                              <Link to="/">View details</Link>
                            </div>
                          </ThirdPartyDetail>
                        )}
                      </Callout>
                    </ThirdPartyName>
                  </td>
                  <td>{internalId}</td>
                  <td>
                    <Country
                      countryCode={primaryCountry as FlagIconCode}
                      fullWidth
                    />
                  </td>
                  <td>
                    <Callout
                      name={`Related third parties: ${
                        relatedEntityThirdParty?.length || 0
                      }`}
                      direction="right"
                      trigger={() => (
                        <span className="highlight">
                          {relatedEntityThirdParty?.length || 0}
                        </span>
                      )}
                      maxHeight="300px"
                    >
                      {relatedEntityThirdParty &&
                      relatedEntityThirdParty.length > 0 ? (
                        <SectionedList
                          sections={relatedEntityThirdParty.map(
                            ({ linkType, relatedEntity }) => ({
                              heading: relatedEntity?.name,
                              items: [
                                {
                                  key: 'Entity type',
                                  value:
                                    relatedEntity?.type === 'Business'
                                      ? 'Company'
                                      : relatedEntity?.type,
                                },
                                {
                                  key: 'Country',
                                  value: relatedEntity?.primaryCountry
                                    ? getName(relatedEntity?.primaryCountry)
                                    : null,
                                },
                                { key: 'Relationship', value: linkType },
                              ],
                            })
                          )}
                        />
                      ) : (
                        <p style={{ padding: '0.652rem' }}>
                          No related entities
                        </p>
                      )}
                    </Callout>
                  </td>
                  <td>
                    {dueDiligenceTypes &&
                      dueDiligenceTypes.length > 0 &&
                      [
                        ...new Set(
                          dueDiligenceTypes.map((ddt) =>
                            ddt.includes('Screening')
                              ? 'Screening'
                              : 'DueDiligence'
                          )
                        ),
                      ].map((dueDiligence) => (
                        <Abbreviation
                          key={dueDiligence}
                          abbreviation={dueDiligence}
                          outline
                        />
                      ))}
                  </td>
                  <td>
                    {thirdPartyApprovals &&
                      thirdPartyApprovals.length > 0 &&
                      thirdPartyApprovals[0].approvalStatus}
                  </td>
                  <td>
                    {thirdPartyApprovals && thirdPartyApprovals.length > 0 && (
                      <RiskRating risk={thirdPartyApprovals[0].riskRating} />
                    )}
                  </td>

                  <td>{parseThirdPartyOwners(thirdPartyOwners)}</td>
                  <td>{createdDate && <Date dateTime={createdDate} />}</td>
                  <td>
                    {nextReviewDate && <Date dateTime={nextReviewDate} />}
                  </td>
                  <ActionMenuWrapper>
                    {hasActions && (
                      <ActionMenu
                        actions={getPermittedActions(id)}
                        name={`Manage ${name}`}
                      />
                    )}
                  </ActionMenuWrapper>
                </tr>
              );
            }
          )}
        </Table>
        {thirdPartySearchData && thirdPartySearchData?.pageCount > 1 && (
          <PaginationWrapper>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPages={thirdPartySearchData.pageCount || 1}
              onPageChange={({ currentPage }) => {
                setFilters({ ...filters, pageNumber: currentPage });
              }}
            />
          </PaginationWrapper>
        )}
        <ReportActionsDrawer
          thirdpartySearchTerm={searchTerm}
          isDownloadEnabled={!thirdPartySearchLoading}
          showAllThirdparties={showAll}
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onProceed={() => {
            setDrawerOpen(false);
          }}
          onCancel={() => setDrawerOpen(false)}
          sort={sortingHeadingOrder(sortingIndex, headings, isDescending)}
        />
      </FilterContext.Provider>
      {canDeleteThirdParties && (
        <ThirdPartyDeleteModal
          isOpen={deleteModalOpen}
          onClose={closeDeleteModal}
          thirdPartyId={thirdPartyToBeDeleted}
          updateResults={updateResultsAfterDelete}
        ></ThirdPartyDeleteModal>
      )}
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, ThirdParty);
