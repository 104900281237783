import { useAuth } from 'auth';
import { Modal } from 'components/molecules';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useFetch } from 'hooks';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ReportModalTypes } from './types';
import Loading from 'components/atoms/loading';
import { useParams } from 'react-router-dom';
import { Button } from 'components/atoms';
import { WhiteOutlineButton } from './../ThirdPartyScreening.styles';
import { Link } from 'components/atoms';
import {
  ButtonContainer,
  ModalParagraph,
  ModalTopContentContainer,
  ReportProceedingModalContainer,
} from './ReportModal.styles';
import { GatewayPermissions } from 'auth/constants/permissions';

type ReportURLParams = {
  id: string;
};
type TopContentParams = {
  onFinalize?: any | null;
  onClose?: any | null;
  showFinalise?: boolean;
  thirdPartyNaming: string;
};

const ModalTopContent: FC<TopContentParams> = ({
  onFinalize = null,
  onClose = null,
  showFinalise = true,
  thirdPartyNaming = 'third party',
}) => {
  return (
    <ModalTopContentContainer>
      <Link onClick={onClose} to={'#'} dark>
        {`Back to ${thirdPartyNaming.toLowerCase()} manager`}
      </Link>
      {showFinalise && (
        <WhiteOutlineButton key="finaliseReview">
          <Button small={true} outline={true} onClick={() => onFinalize()}>
            Finalise review
          </Button>
        </WhiteOutlineButton>
      )}
    </ModalTopContentContainer>
  );
};

const ReportModal: FC<ReportModalTypes> = ({
  isOpen,
  onClose,
  onFinalizeClick = null,
  onApproveClick = null,
  isPdf = false,
  showFinalise,
  approvalStatusModalEnabled,
}: ReportModalTypes) => {
  const { accessToken, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const reportContainer = useRef<HTMLDivElement>(null);
  const { id } = useParams<ReportURLParams>();
  const [pdf, setPdf] = useState(null);

  const { data, loading, error, responseStatus } = useFetch(
    isPdf
      ? `${vantageWebApi}/onlinereport/${id}/pdf`
      : `${vantageWebApi}/onlinereport/${id}/html`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    isPdf ? 'blob' : 'text'
  );

  if (data && !pdf && isPdf) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);
    setPdf(pdfUrl);
  }

  const {
    data: finalizeResponse,
    loading: finalizeLoading,
    trigger: finalizeLazy,
    error: finalizeError,
  } = useFetch(
    `${vantageWebApi}/onlinescreening/${id}/finalise`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    'text'
  );

  const beforeFinalizeHandler = () => {
    setIsConfirmationModalOpen(true);
  };
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  useEffect(() => {
    if (!isPdf) {
      const shadowRoot = reportContainer.current.attachShadow({ mode: 'open' });
      shadowRoot.innerHTML = data;
    }
  }, [data, isPdf]);

  useEffect(() => {
    if (finalizeResponse && !finalizeError) {
      if (
        hasPermission(GatewayPermissions.ApproveRejectThirdParty) &&
        approvalStatusModalEnabled
      ) {
        setIsConfirmationModalOpen(false);
        setIsApprovalModalOpen(true);
      } else {
        onFinalizeClick();
      }
    }
  }, [
    finalizeResponse,
    onFinalizeClick,
    finalizeError,
    setIsConfirmationModalOpen,
    hasPermission,
    setIsApprovalModalOpen,
    approvalStatusModalEnabled,
  ]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        transparent={true}
        isClosable={false}
        removeBorderTop={true}
        removeSeparator={true}
        topContent={
          <ModalTopContent
            onFinalize={beforeFinalizeHandler}
            onClose={onClose}
            showFinalise={showFinalise}
            thirdPartyNaming={terminologyConfig?.thirdPartySingularNaming}
          />
        }
      >
        {loading ? (
          <Loading />
        ) : error || (responseStatus && responseStatus !== 200) ? (
          <p>Error loading report</p>
        ) : isPdf ? (
          <object
            data={pdf}
            type="application/json"
            style={{ width: '100%', height: '100vh' }}
          />
        ) : (
          <div ref={reportContainer}></div>
        )}
        <Modal
          title="Are you sure?"
          isOpen={isConfirmationModalOpen}
          onClose={onClose}
        >
          <ReportProceedingModalContainer>
            <ModalParagraph>
              Please note that this action will set the review status to
              'Complete' and remove the outstanding action from the Task list.
            </ModalParagraph>
            {finalizeLoading ? (
              <Loading />
            ) : (
              <ButtonContainer>
                <Button className="button" outline={true} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="button"
                  onClick={() => {
                    finalizeLazy();
                  }}
                >
                  Proceed
                </Button>
              </ButtonContainer>
            )}
          </ReportProceedingModalContainer>
        </Modal>

        <Modal
          title="Update approval status"
          isOpen={isApprovalModalOpen}
          onClose={onFinalizeClick}
        >
          <ReportProceedingModalContainer>
            <ModalParagraph>
              Do you want to update the approval status of this third party?
            </ModalParagraph>
            {finalizeLoading ? (
              <Loading />
            ) : (
              <ButtonContainer>
                <Button
                  className="button"
                  outline={true}
                  onClick={onFinalizeClick}
                >
                  No
                </Button>
                <Button className="button" onClick={onApproveClick}>
                  Yes - Update
                </Button>
              </ButtonContainer>
            )}
          </ReportProceedingModalContainer>
        </Modal>
      </Modal>
    </>
  );
};

export default ReportModal;
