import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import {
  ComboBoxContainer,
  Stroke,
  DocumentUploadsContainer,
  DocumentsInfoText,
  DocumentsSectionHeading,
  ErrorContainer,
} from './MultipleDocumentUploadWrapper.styles';

import { MultipleDocumentUploadWrapperTypes } from './types';
import MultipleUploadOption from 'components/molecules/multiple-upload-option';
import { ThirdPartyType } from 'types/thirdPartyType';
import DocumentUploadModal from '../document-upload-modal';
import DocumentSelectModal from '../document-select-modal';
import {
  Document,
  FileInfo,
  validateRequiredDocumentData,
} from 'helpers/documentHelper';
import FieldSelect from 'components/atoms/field-select';
import { useTheme } from 'styled-components';
import { Icon, Loading, Typography } from 'components/atoms';
import { SelectableDocument } from '../document-select-modal/types';
import useFetch from 'hooks/useFetch';
import { useConfig } from 'config';
import { useAuth } from 'auth';
import { getFileExtension } from 'helpers/documentHelper';
import { LoadingContainer } from '../document-upload-modal/DocumentUploadModal.styles';
import {
  VerificationDateConfig,
  isVerificationDateValid,
} from 'helpers/verificationDateHelper';

interface UploadModalState {
  isOpen: boolean;
  categoryId?: string;
  thirdPartyType: ThirdPartyType;
  thirdPartyId: string;
  maxUploads: number;
  index: number;
  configKey: string;
  verificationDateConfig: VerificationDateConfig | null;
}

const defaultUploadModalState: UploadModalState = {
  isOpen: false,
  categoryId: undefined,
  thirdPartyType: ThirdPartyType.Business,
  thirdPartyId: '',
  maxUploads: 0,
  index: 0,
  configKey: '',
  verificationDateConfig: null,
};

interface SelectModalState {
  isOpen: boolean;
  thirdPartyType: ThirdPartyType;
  thirdPartyId: string;
  maxUploads: number;
  index: number;
  configKey: string;
  files: SelectableDocument[];
}

const defaultSelectModalState: SelectModalState = {
  isOpen: false,
  thirdPartyType: ThirdPartyType.Business,
  thirdPartyId: '',
  maxUploads: 0,
  index: 0,
  configKey: '',
  files: [],
};

const MultipleDocumentUploadWrapper: FC<MultipleDocumentUploadWrapperTypes> = ({
  thirdPartyId,
  configAndFilesData,
  errorMessage,
  isDirty,
}: MultipleDocumentUploadWrapperTypes) => {
  const theme = useTheme();
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const [selectedThirdPartyOrEntity, setSelectedThirdPartyOrEntity] =
    useState('');
  const [configAndFiles, setConfigAndFiles] = useState(configAndFilesData);
  const [documentUploadModalState, setDocumentUploadModalState] =
    useState<UploadModalState>(defaultUploadModalState);
  const [documentSelectModalState, setDocumentSelectModalState] =
    useState<SelectModalState>(defaultSelectModalState);
  const [ThirdPartyOrEntitySelection, setThirdPartyOrEntitySelection] =
    useState([]);

  const filterFilesByCategory = (
    files: SelectableDocument[],
    categoryId: number,
    verificationDateConfig: VerificationDateConfig
  ): SelectableDocument[] => {
    if (!categoryId) {
      return files.map((file) => ({ ...file, selected: false }));
    }
    return files
      .filter((file) => file.categoryId === categoryId)
      .filter((file) => {
        if (!verificationDateConfig) {
          return true;
        }

        if (!file.verificationDate) {
          return false;
        }

        return isVerificationDateValid(
          verificationDateConfig,
          file.verificationDate
        );
      })
      .map((file) => ({ ...file, selected: false }));
  };

  const { data: filesData, loading: filesDataLoading } = useFetch(
    `${vantageWebApi}/thirdparty/${thirdPartyId}/files`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const files: SelectableDocument[] = useMemo(() => {
    if (!Array.isArray(filesData)) {
      return [];
    }

    return filesData.map((file: FileInfo) => {
      return {
        ...file,
        type: getFileExtension(file.fileDownloadName),
        date: file.uploadedDate,
        selected: false,
      } as SelectableDocument;
    });
  }, [filesData]);

  const handleExistingDocumentUpload = (
    categoryId: number,
    maxUploads: number,
    thirdPartyId: string,
    index: number,
    configKey: 'mandatory' | 'optional',
    selectableFiles: SelectableDocument[],
    verificationDateConfig: VerificationDateConfig
  ) => {
    setDocumentSelectModalState((prev) => ({
      ...prev,
      ...defaultSelectModalState,
      categoryId: categoryId,
      isOpen: true,
      maxUploads: maxUploads,
      thirdPartyId: thirdPartyId,
      index: index,
      configKey: configKey,
      files: filterFilesByCategory(
        selectableFiles,
        categoryId,
        verificationDateConfig
      ),
    }));
  };

  const handleNewDocumentUpload = (
    thirdPartyType: ThirdPartyType,
    index: number,
    thirdPartyId: string,
    configKey: 'mandatory' | 'optional',
    verificationDateConfig: VerificationDateConfig,
    categoryId?: string
  ) => {
    setDocumentUploadModalState((prev) => ({
      ...prev,
      ...defaultUploadModalState,
      categoryId: categoryId,
      isOpen: true,
      thirdPartyType: thirdPartyType,
      thirdPartyId: thirdPartyId,
      index: index,
      configKey: configKey,
      verificationDateConfig,
    }));
  };

  const handleRemoveDocument = (
    configKey: string,
    index: number,
    id: string
  ) => {
    const newConfigAndFiles = { ...configAndFiles };
    newConfigAndFiles[selectedThirdPartyOrEntity][configKey][index].documents =
      newConfigAndFiles[selectedThirdPartyOrEntity][configKey][
        index
      ].documents.filter((file) => file.id !== id);

    if (isDirty) {
      setConfigAndFiles(validateRequiredDocumentData(newConfigAndFiles));
    }

    setConfigAndFiles(newConfigAndFiles);
  };

  const handleAddFiles = useCallback(
    (configKey: string, index: number, documentsToAdd: Document[]) => {
      const newConfigAndFiles = { ...configAndFiles };
      newConfigAndFiles[selectedThirdPartyOrEntity][configKey][
        index
      ].documents = [
        ...newConfigAndFiles[selectedThirdPartyOrEntity][configKey][index]
          .documents,
        ...documentsToAdd,
      ];
      if (isDirty) {
        setConfigAndFiles(validateRequiredDocumentData(newConfigAndFiles));
      } else {
        setConfigAndFiles(newConfigAndFiles);
      }
    },
    [isDirty, configAndFiles, selectedThirdPartyOrEntity]
  );

  const sortOptions = (data) => {
    data.sort((a, b) => {
      if (a.root && !b.root) {
        return -1; // a comes before b
      }
      if (!a.root && b.root) {
        return 1; // b comes before a
      }
      // If both have the same "root" value or both don't have "root" property, sort by "name"
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1; // a comes before b
      }
      if (nameA > nameB) {
        return 1; // b comes before a
      }
      return 0; // same order
    });

    return data;
  };

  const mapOptions = (data) => {
    if (!data) {
      return [];
    }
    const transformedData = [];
    for (const key in data) {
      if (
        data[key]['mandatory'].length > 0 ||
        data[key]['optional'].length > 0
      ) {
        const obj = {
          name: data[key].name,
          value: data[key].id.toString(),
          root: data[key].root,
        };
        transformedData.push(obj);
      }
    }
    return transformedData;
  };

  const totalRequiredDocumentsToUpload = (obj) => {
    let count = 0;
    for (const key in obj) {
      const configArray = obj[key].mandatory;
      for (const m of configArray) {
        if (m.required === true) {
          count++;
        }
      }
    }
    return count;
  };

  const renderDocumentUploadFields = (configKey) => {
    if (selectedThirdPartyOrEntity) {
      let thirdParty = configAndFiles[selectedThirdPartyOrEntity];
      if (!thirdParty) {
        return null;
      }

      let fields = thirdParty[configKey];

      if (!fields && fields.length == 0) {
        return null;
      }

      const shouldRenderSeparator =
        thirdParty?.mandatory.length > 0 && thirdParty?.optional.length > 0;

      return fields?.map((field, index) => (
        <>
          {shouldRenderSeparator && configKey == 'optional' && <Stroke />}
          <MultipleUploadOption
            key={index}
            label={field.name}
            required={field.required}
            showSidebar={
              configAndFiles[selectedThirdPartyOrEntity][configKey][index]
                ?.documents.length > 0
            }
            showSeparator={
              configAndFiles[selectedThirdPartyOrEntity][configKey][index]
                ?.documents.length > 0 && index !== fields.length - 1
            }
            maxUploads={field.maxUploads}
            valid={field.valid}
            documents={
              configAndFiles[selectedThirdPartyOrEntity][configKey][index]
                ?.documents
            }
            onExistingDocumentUpload={() =>
              handleExistingDocumentUpload(
                field.categoryId,
                field.maxUploads -
                  configAndFiles[selectedThirdPartyOrEntity][configKey][index]
                    ?.documents?.length,
                thirdPartyId,
                index,
                configKey,
                files,
                field.verificationDateEnabled
                  ? field.verificationDateConfig
                  : null
              )
            }
            onNewDocumentUpload={() => {
              handleNewDocumentUpload(
                ThirdPartyType[configAndFiles[selectedThirdPartyOrEntity].type],
                index,
                thirdPartyId,
                configKey,
                field.verificationDateEnabled
                  ? field.verificationDateConfig
                  : null,
                field.categoryId?.toString()
              );
            }}
            onRemoveDocument={(id) =>
              handleRemoveDocument(configKey, index, id)
            }
            showExistingDocumentsOption={
              filterFilesByCategory(
                files,
                field.categoryId,
                field.verificationDateEnabled
                  ? field.verificationDateConfig
                  : null
              ).length > 0
            }
          />
        </>
      ));
    }
  };

  const handleAddDocuments = (
    configKey: string,
    index: number,
    documents: Document[]
  ) => {
    handleAddFiles(configKey, index, documents);
  };

  useEffect(() => {
    setConfigAndFiles(configAndFilesData);
    let options = sortOptions(mapOptions(configAndFilesData));
    if (options.length !== 0) {
      setThirdPartyOrEntitySelection(options);
      const exists = options.some(
        (obj) => obj.value === selectedThirdPartyOrEntity
      );
      if (!exists) {
        setSelectedThirdPartyOrEntity(options[0].value);
      }
    }
  }, [configAndFilesData, selectedThirdPartyOrEntity]);

  const entitySelectOptions = ThirdPartyOrEntitySelection.map((option) => {
    if (option.root) {
      return { ...option, name: option.name + ' (This Thirdparty)' };
    } else {
      return option;
    }
  });

  return (
    <>
      {filesDataLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <DocumentUploadsContainer>
          <DocumentsSectionHeading>Document upload</DocumentsSectionHeading>
          {ThirdPartyOrEntitySelection &&
          Object.keys([
            ...ThirdPartyOrEntitySelection.filter((item) => item.root == false),
          ]).length > 0 ? (
            <>
              <DocumentsInfoText>
                You have {totalRequiredDocumentsToUpload(configAndFiles)}{' '}
                documents to upload for all related entities created
              </DocumentsInfoText>
              <ComboBoxContainer>
                <FieldSelect
                  valid={!errorMessage}
                  fullWidth
                  placeholder={''}
                  ariaLabel="Document Category Dropdown"
                  id="ThirdPartyAndEntities"
                  options={entitySelectOptions}
                  onChange={(event) => {
                    setSelectedThirdPartyOrEntity(event.target.value);
                  }}
                />
              </ComboBoxContainer>
            </>
          ) : null}

          {renderDocumentUploadFields('mandatory')}

          {renderDocumentUploadFields('optional')}

          {errorMessage && (
            <ErrorContainer>
              <Icon
                className="risk-icon"
                icon="risk"
                size="xxs"
                colour={theme.colours.redRampage}
              />
              <Typography value={errorMessage} tag="p" />
            </ErrorContainer>
          )}
          {documentUploadModalState.isOpen && (
            <DocumentUploadModal
              thirdPartyId={documentUploadModalState.thirdPartyId}
              categoryId={documentUploadModalState.categoryId}
              entityType={documentUploadModalState.thirdPartyType}
              isOpen={true}
              onClose={() =>
                setDocumentUploadModalState(defaultUploadModalState)
              }
              onSuccessDocumentUpload={(document) => {
                handleAddDocuments(
                  documentUploadModalState.configKey,
                  documentUploadModalState.index,
                  [document]
                );
              }}
              verificationDateConfig={
                documentUploadModalState.verificationDateConfig
              }
            />
          )}
          <DocumentSelectModal
            thirdPartyId={documentSelectModalState.thirdPartyId}
            maxUploads={documentSelectModalState.maxUploads}
            isOpen={documentSelectModalState.isOpen}
            selectableFiles={documentSelectModalState.files}
            onClose={() => setDocumentSelectModalState(defaultSelectModalState)}
            onAttachDocuments={(documents) => {
              handleAddDocuments(
                documentSelectModalState.configKey,
                documentSelectModalState.index,
                documents
              );
            }}
          />
        </DocumentUploadsContainer>
      )}
    </>
  );
};

export default MultipleDocumentUploadWrapper;
