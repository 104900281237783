import styled from 'styled-components';

export const Container = styled.div<{ selected; fullWidth }>`
  display: flex;
  flex-direction: row;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colours.crowberryBlue : theme.colours.spanishGrey};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ theme }) => theme.colours.whiteSolid};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '288px')};
  height: 80px;
`;

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(20)};
  padding-right: 0px;
`;

export const CategoryTextContainer = styled.p`
    text-overflow: ellipsis;
    display block;
    width: 100%;
    overflow: hidden;     
    white-space: nowrap;
`;

export const InfoContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(20)};
  max-width: 240px;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
  text-overflow: ellipsis;
  display block;
  width: 100%;
  overflow: hidden;     
  white-space: nowrap;
`;

export const SizeCategoryContainer = styled.div`
  color: ${({ theme }) => theme.colours.agedGray};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  letter-spacing: 0;
  line-height: 1.5;
  user-select: none;
`;

export const ActionContainer = styled.div<{ isSelected }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};

  .close-box {
    display: flex;
    padding: ${({ theme }) => theme.spacing(4)};
  }

  .select-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: ${({ theme }) => theme.pxToRem(3)};
    border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colours.crowberryBlue : theme.colours.white};
    margin: ${({ theme }) => theme.spacing(2)};
  }
`;
