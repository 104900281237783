import { ExpandButton } from 'components/molecules/card/Card.styles';
import { Spacer } from 'components/molecules/screening-table/ScreeningTable.styles';
import React, { FC, useState } from 'react';
import Typography from '../typography';
import { ReadMoreTextAreaTypes } from './types';

const ReadMoreTextArea: FC<ReadMoreTextAreaTypes> = ({
  maxTextLength,
  text,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {text && (
        <Typography
          aria-label="paragraph"
          tag="p"
          value={
            text && text.length > maxTextLength && !expanded
              ? `${text.substring(0, maxTextLength)}…`
              : text
          }
        />
      )}
      {text && text.length <= maxTextLength && <Spacer />}
      {text && text.length > maxTextLength && (
        <ExpandButton spaceAfter={true} onClick={() => setExpanded(!expanded)}>
          show {expanded ? 'less' : 'more'}
        </ExpandButton>
      )}
    </>
  );
};

export default ReadMoreTextArea;
