import React, { FC } from 'react';
import { CardGridComponent } from './CardGrid.styles';
import { CardGridProps } from './types';
import { Card } from 'components/molecules';

const CardGrid: FC<CardGridProps> = ({ cards, children }) => (
  <CardGridComponent>
    {cards && cards.length > 0
      ? cards.map((card, index) => (
          <Card key={`${card.title}-${index}`} {...card} />
        ))
      : children}
  </CardGridComponent>
);

export default CardGrid;
