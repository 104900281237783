import React from 'react';
import { SideModuleProps } from './types';
import {
  SideModuleHeader,
  SideModuleWrapper,
  SideModuleHeaderContainer,
} from './SideModule.styles';
import { EntityActionButton } from '../../../form/form.styles';
import { Icon } from 'components/atoms';
const SideModule: React.FC<SideModuleProps> = ({
  children,
  header,
  onEdit,
}) => (
  <SideModuleWrapper>
    <SideModuleHeaderContainer>
      {header && <SideModuleHeader>{header}</SideModuleHeader>}
      {onEdit && header && (
        <EntityActionButton aria-label="edit" onClick={onEdit ?? (() => {})}>
          <Icon icon="edit-pencil" aria-hidden size="xxs" />
        </EntityActionButton>
      )}
    </SideModuleHeaderContainer>
    {children}
  </SideModuleWrapper>
);

export default SideModule;
