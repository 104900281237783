import styled, { css } from 'styled-components';
import { ModalOverlayProps } from './types';

export const ModalComponent = styled.div<
  Pick<ModalOverlayProps, 'fullScreen' | 'opaqueOverlay'>
>`
  &:before {
    z-index: ${({ theme }) => theme.zIndex.level_3};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    ${({ opaqueOverlay = false, theme }) =>
      opaqueOverlay
        ? `background-image: linear-gradient(
          270deg,
          ${theme.colours.porpoisePlace} 0%,
          ${theme.colours.crowberryBlue} 100%
        );`
        : 'background-color: rgba(0, 0, 0, 0.9)'}
  }
`;

export const ModalOverlayComponent = styled.div<
  Pick<
    ModalOverlayProps,
    | 'fullScreen'
    | 'opaqueOverlay'
    | 'transparent'
    | 'customOverlayWidth'
    | 'removeBorderTop'
  >
>`
  z-index: ${({ theme }) => theme.zIndex.level_3 + 1};
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  border-top: ${({ removeBorderTop, theme }) =>
    removeBorderTop ? 0 : `10px solid ${theme.colours.swimmersPool};`};

  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.colours.white};
  transform: translate(-50%, -50%);
  box-shadow: 0 ${({ theme }) => theme.pxToRem(10)}
    ${({ theme }) => theme.pxToRem(30)} 0 rgba(0, 0, 0, 0.05);

  ${({ fullScreen = false, theme }) =>
    fullScreen
      ? css`
          width: calc(100vw - ${theme.pxToRem(140)});
          height: calc(100vh - ${theme.pxToRem(140)});
        `
      : css`
          width: 100%;
          max-width: min(90vw, ${theme.pxToRem(984)});
          max-height: 90vh;
        `}
  width: ${({ customOverlayWidth }) =>
    customOverlayWidth ? customOverlayWidth : null};
`;

export const ModalHeading = styled.div<{ compact?: boolean }>`
  display: flex;

  ${({ compact = false, theme: { spacing } }) =>
    compact
      ? css`
          padding: ${spacing(30)} ${spacing(40)} ${spacing(20)};
        `
      : css`
          position: absolute;
          width: 100%;
          padding: ${spacing(20)};
          top: 0;
          right: 0;
        `}
`;

export const ModalSeparator = styled.div`
  margin-left: ${({ theme }) => theme.spacing(40)};
  margin-right: ${({ theme }) => theme.spacing(40)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.concreteGray};
  width: auto;
`;

export const ModalClose = styled.button`
  margin-left: auto;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const ModalTitle = styled.p`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  letter-spacing: 0;
  line-height: 1.25;
`;

export const ModalBody = styled.div<{
  removePadding?: boolean;
  scrollable?: boolean;
}>`
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  ${({ removePadding, theme }) =>
    removePadding
      ? css`
          padding: 0rem;
        `
      : css`
          padding: ${theme.spacing(25)} ${theme.spacing(40)}
            ${theme.spacing(30)};
        `};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalButtonsContainer = styled.div`
  height: 20px;
  width: 100%;
  left: 0px;
  top: 25px;
  padding-left: 5px;
  padding-right: 10px;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: ${({ theme }) => theme.zIndex.level_3 + 2};
`;
