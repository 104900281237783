import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing(40)};

  .info-text {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    letter-spacing: 0;
    line-height: 1.5;
    user-select: none;
  }
`;

export const DefaultText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  margin: 0 ${({ theme }) => theme.spacing(10)};
`;

export const LoadingText = styled.p`
  color: ${({ theme }) => theme.colours.black};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  margin: 0 ${({ theme }) => theme.spacing(10)};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(32)};

  .button {
    width: ${({ theme }) => theme.pxToRem(166)};

    &:has(.spinner) {
      padding: 10px;
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }
`;
