import React, { FC } from 'react';
import {
  PerformEntityResolutionComponent,
  PerformEntityResolutionContainer,
} from './PerformEntityResolution.styles';

import { PerformEntityResolutionTypes } from './types';
import { Icon, Tooltip } from 'components/atoms';
import { ReactComponent as InfoIcon } from './../../../assets/svg/Icons/Info.svg';
import { useTheme } from 'styled-components';

const PerformEntityResolution: FC<PerformEntityResolutionTypes> = ({
  enabled,
  onClick,
  tooltipText,
}: PerformEntityResolutionTypes) => {
  const theme = useTheme();
  return (
    <PerformEntityResolutionContainer>
      <PerformEntityResolutionComponent
        type="button"
        clickable={enabled}
        disabled={!enabled}
        onClick={onClick}
      >
        <Icon
          icon="search"
          colour={
            enabled ? theme.colours.crowberryBlue : theme.colours.agedGray
          }
          size="xxs"
        />
        <span
          className="entityResolutionLabel bold"
          style={{
            color: enabled
              ? theme.colours.crowberryBlue
              : theme.colours.agedGray,
          }}
        >
          Perform Company Lookup
        </span>
      </PerformEntityResolutionComponent>
      <Tooltip
        trigger="click"
        tooltipText={tooltipText as any}
        hideArrow
        placement="right-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        <InfoIcon className="clickable" />
      </Tooltip>
    </PerformEntityResolutionContainer>
  );
};

export default PerformEntityResolution;
