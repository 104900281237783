import React, { FC, PropsWithChildren } from 'react';
import { Container } from './Avatar.styles';

import { AvatarTypes } from './types';

const Avatar: FC<AvatarTypes> = ({
  size = 'sm',
  children,
}: PropsWithChildren<AvatarTypes>) => {
  return (
    <Container className="avatar" size={size} bgColor="#226CA6">
      {children}
    </Container>
  );
};

export default Avatar;
