import React, { useEffect, FC } from 'react';
import FocusTrap from 'focus-trap-react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  DrawerComponent as DrawerComponent,
  DrawerOverlayComponent as DrawerOverlayComponent,
  DrawerHeading,
  DrawerTitle,
  DrawerClose,
  DrawerBody,
  DrawerButtonsContainer,
} from './Drawer.styles';
import { DrawerOverlayProps, DrawerProps } from './types';
import { Icon } from 'components/atoms';

const DrawerOverlay: FC<DrawerOverlayProps> = ({
  children,
  onClose,
  title,
  transparent,
  compact = false,
  removePadding,
  bodyContainerRef,
}) => {
  useEffect(() => {
    if (!onClose) {
      return;
    }
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);
  return (
    <DrawerOverlayComponent transparent={transparent}>
      <DrawerHeading compact={!!title}>
        {title && <DrawerTitle>{title}</DrawerTitle>}
        <DrawerClose aria-label="Close" onClick={onClose}>
          <Icon icon="close" aria-hidden size="xxs" cursor="pointer" />
        </DrawerClose>
      </DrawerHeading>
      <DrawerBody
        ref={bodyContainerRef}
        compact={!!title || compact}
        removePadding={removePadding}
      >
        {children}
      </DrawerBody>
    </DrawerOverlayComponent>
  );
};

const Drawer: FC<DrawerProps> = ({
  children,
  isOpen = false,
  onClose,
  title,
  opaqueOverlay = false,
  topContent,
  transparent,
  compact = false,
  removePadding,
  bodyContainerRef,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, zIndex: 20 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap>
            <DrawerComponent opaqueOverlay={opaqueOverlay}>
              <DrawerButtonsContainer>{topContent}</DrawerButtonsContainer>
              <DrawerOverlay
                title={title}
                onClose={onClose}
                transparent={transparent}
                compact={compact}
                removePadding={removePadding}
                bodyContainerRef={bodyContainerRef}
              >
                {children}
              </DrawerOverlay>
            </DrawerComponent>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
