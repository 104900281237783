import styled from 'styled-components';

export const ActionMenuItem = styled.button`
  display: block;
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(45)};
  padding: 0 ${({ theme }) => theme.spacing(20)};
  color: ${({ theme }) => theme.colours.charcoalDust};
  cursor: pointer;
  font-family: 'Open Sans';
  font-size: ${({ theme }) => theme.pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.714;
  text-align: left;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colours.whiteSolid};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.whiteSolid};
  }
`;
