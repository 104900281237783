import styled from 'styled-components';

export const FooterComponent = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(30)};
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(64)};
  padding: 0 ${({ theme }) => theme.spacing(60)};
  marging-top: ${({ theme }) => theme.spacing(30)};
  color: ${({ theme }) => theme?.colours?.white};
  background-color: ${({ theme }) => theme?.colours?.crowberryBlue};
  font-size: ${({ theme }) => theme?.pxToRem(14)};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
`;

export const Copyright = styled.p`
  opacity: 0.5;
`;

export const FooterLinks = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li + li {
    margin-left: ${({ theme }) => theme.spacing(30)};
  }

  a {
    color: ${({ theme }) => theme.colours.white};
    text-decoration: none;
    font-size: ${({ theme }) => theme?.pxToRem(14)};

    &:hover {
      color: ${({ theme }) => theme.colours.swimmersPool};
      transition: all 0.25s ease-in-out;
    }
  }
`;

export const SocialMediaLinks = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li + li {
    margin-left: ${({ theme }) => theme.spacing(20)};
  }

  a {
    display: block;
    color: ${({ theme }) => theme.colours.white};
    font-size: ${({ theme }) => theme.pxToRem(25)};

    &:hover {
      color: ${({ theme }) => theme.colours.swimmersPool};
      transition: all 0.25s ease-in-out;
    }
  }

  svg {
    color: inherit;
  }
`;
