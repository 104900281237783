import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useDebounce, useFetch } from 'hooks';

import { FormControl } from 'components/atoms';
import Input from 'components/atoms/field-input';
import Loading from 'components/atoms/loading';
import { useTerminologyConfig } from 'context/terminologyConfig';

import {
  ChildContainer,
  FixedWidthButton,
  HelperText,
  ReportIdResult,
} from '../ThirdParty.styles';

const SearchReportTab = () => {
  const { accessToken } = useAuth();
  const { vantageWebApi, msalSubscriptionKey } = useConfig();
  const terminologyConfig = useTerminologyConfig();

  const searchReportSchema = yup.object().shape({
    reportId: yup.string().required('Report ID is required'),
  });

  const searchReportForm = useForm({
    mode: 'all',
    resolver: yupResolver(searchReportSchema),
    defaultValues: {
      reportId: null,
    },
  });

  const {
    watch: watchSearchReport,
    register: registerSearchReport,
    formState: {
      errors: errorsSearchReport,
      touchedFields: isTouchedReportField,
      isValid: isValidReportId,
    },
  } = searchReportForm;

  const reportIdWatch = watchSearchReport('reportId');
  const debouncedReportId = useDebounce(reportIdWatch, 500);

  const {
    data: reportIdLookup,
    loading: reportIdLoading,
    responseStatus: responseStatusReport,
    error: reportIdError,
    trigger: fetchReportByIdLazy,
  } = useFetch(
    debouncedReportId
      ? `${vantageWebApi}/onlinescreening/${debouncedReportId}/status`
      : undefined,
    {
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'GET',
    }
  );

  useEffect(() => {
    if (debouncedReportId && !reportIdLookup) {
      fetchReportByIdLazy();
    }
  }, [debouncedReportId, fetchReportByIdLazy, reportIdLookup]);

  return (
    <form>
      <HelperText>
        Search for a specific ordered screening or due diligence by entering the
        report ID
      </HelperText>
      <FormControl
        errorMessage={!isValidReportId && errorsSearchReport?.reportId?.message}
      >
        <Input
          label="Report ID"
          id="reportId"
          name="reportId"
          register={registerSearchReport}
          fullWidth
          valid={isTouchedReportField?.reportId && isValidReportId}
          required={true}
        />
      </FormControl>
      <>
        {reportIdLoading ? (
          <Loading />
        ) : (
          <>
            {(reportIdLookup || reportIdError) &&
              (reportIdError ? (
                <HelperText>
                  {responseStatusReport && responseStatusReport === 401
                    ? 'You don’t have permission to view this report.'
                    : 'There is no order with a report ID matching your search query.'}
                </HelperText>
              ) : (
                <ReportIdResult
                  reportId={reportIdLookup?.screeningId}
                  thirdParty={reportIdLookup?.thirdParty}
                  thirdPartyTerminology={
                    terminologyConfig?.thirdPartySingularNaming ?? 'Thirdparty'
                  }
                />
              ))}
          </>
        )}
      </>
      <ChildContainer>
        <FixedWidthButton
          name="viewReportIDOrder"
          secondary
          type="button"
          onClick={() => {
            const url = `/third-party/screening/${reportIdLookup?.screeningId}`;
            window.open(url, '_blank');
          }}
          disabled={
            !reportIdLookup?.screeningId ||
            !isValidReportId ||
            responseStatusReport != 200
          }
        >
          Go to the order details page
        </FixedWidthButton>
      </ChildContainer>
    </form>
  );
};

export default SearchReportTab;
