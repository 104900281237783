import { Button } from 'components/atoms';
import styled from 'styled-components';

export const IconButtonWithLabel = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 44px;
`;

export const RecordsLabel = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colours.darkToneInk};
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: ${({ theme }) => theme.spacing(15)};

  .documentCard {
    flex: 1 1 auto;
  }

  @media screen and (width > 1400px) {
    .documentCard {
      flex: 0 1 ${({ theme }) => theme.pxToRem(350)};
    }
  }
`;

export const DocumentsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 60px;
  color: ${({ theme }) => theme.colours.crowberryBlue};

  .title {
    margin-top: 24px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
