import { useCallback, useEffect } from 'react';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { appInsights } from 'services/applicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  refreshTokenOnDemand,
  isTokenExpired,
} from 'services/refreshTokenService';
import {
  FetchActionTypes,
  FetchOptions,
  // eslint-disable-next-line
  FetchState,
  TriggerOptions,
} from './types';
import { useFetchReducer } from './reducer';

const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * Hook designed to fetching data from a URL.
 * @param {string} url - The target URL for fetching data.
 * @param {FetchOptions<T>} [options] - Customizable settings.
 *                                      the HTTP method, request body, initial state, and set up callbacks for handling
 *                                      success or failure responses.
 * @param {'json' | 'text' | 'blob'} [responseBody='json'] - Specifies the expected format of the response,
 *                                                           defaulting to 'json'.
 *
 * @returns {{
 *   trigger: (options?: TriggerOptions<P>) => Promise<T | null>,
 *   reset: () => void,
 *   data: T | null
 * }} Returns an object with three properties, `trigger` to start the fetch, `reset` to clear the fetch state,
 *      `data` to get teh response data, a state with the loading status, error a bolean that is true when a error happened and errorData, the response body wen the request is not OK
 *
 * @template T - The type of data expected in the response
 * @template U - The type of data expected in the response when a error occurs
 * @template P - Specifies the parameter keys used in `urlParams`.
 *
 * @description
 * - Dynamically replacing parts of the URL
 * - Adding query parameters to the URL
 * - Refreshing tokens when needed
 * - Cancelling active fetch requests with an AbortController
 *
 * Important Note: Changes to `headers` in the options object are not tracked by the hook's dependencies,
 * so updates to headers will not trigger a new fetch until another dependency changes.
 */
export const useFetch = <T = any, U = any, P extends string = string>(
  url: string,
  options?: FetchOptions<T, U>,
  responseBody: 'json' | 'text' | 'blob' = 'json'
) => {
  const { accessTokenId, msalSubscriptionKey, refreshTokenId, vantageWebApi } =
    useConfig();
  const {
    setToken,
    accessToken,
    refreshToken,
    setRefreshToken,
    setRedirectUrl,
  } = useAuth();
  const [state, dispatch] = useFetchReducer<T, U>(options.initialState);

  const isLazyTrigger = options.lazy;

  /**
   * @description
   * This method trigger the current fetch, if lazy is set to False you don't need to use it
   *
   * - If `lazy` is set to `false`, this function is executed on the first mount.
   * - If `lazy` is set to `true`, It won't fetch anything until you explicitly call the trigger function
   *
   * Yo also can send body, urlParams or queryParams to be replaced or inserted on the URL
   */
  const trigger = useCallback(
    async ({
      body,
      urlParams,
      queryParams,
    }: TriggerOptions<P> | undefined = {}) => {
      if (!url) return;

      let parsedUrl = url;
      if (urlParams && Object.keys(urlParams).length > 0) {
        for (const [key, value] of Object.entries(urlParams)) {
          parsedUrl = parsedUrl.replace(
            `:${key}`,
            encodeURIComponent(String(value))
          );
        }
      }

      if (queryParams && Object.keys(queryParams).length > 0) {
        const queryString = Object.entries(queryParams)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
          )
          .join('&');
        parsedUrl += (parsedUrl.includes('?') ? '&' : '?') + queryString;
      }

      const controller = new AbortController();
      const { signal } = controller;

      dispatch({ type: FetchActionTypes.FETCH_INIT });
      const headers = {
        'Ocp-Apim-Subscription-Key': msalSubscriptionKey,
        ...options?.headers,
      };

      let responseStatus = null;
      if (isTokenExpired(accessToken)) {
        const tokens = await refreshTokenOnDemand(
          vantageWebApi,
          {
            'Content-Type': 'application/x-www-form-urlencoded',
            mode: 'no-cors',
            signal,
          },
          { accessTokenId, refreshTokenId, refreshToken },
          (err) => {
            console.error(err);
            setRedirectUrl('/login');
          }
        );
        setToken(tokens?.accessToken);
        setRefreshToken(tokens?.refreshToken);
        if (!tokens?.accessToken) {
          controller.abort();
          return;
        }
      }
      try {
        const response = await fetch(parsedUrl, {
          method: options?.method,
          headers,
          body: body ?? options?.body,
          signal,
        });
        responseStatus = response.status;
        const contentType = response.headers?.get('content-type');
        const data = contentType
          ? ((await response[responseBody]()) as T)
          : null;

        if (!response.ok) {
          throw data;
        }

        options.onSuccess && options.onSuccess(data);
        dispatch({
          type: FetchActionTypes.FETCH_SUCCESS,
          payload: data,
          responseStatus,
        });
        return data;
      } catch (error) {
        if (isDevelopment) {
          console.error(error);
        }
        options.onError && options.onError(error);
        appInsights.trackException(
          {
            exception: error,
            severityLevel: SeverityLevel.Error,
          },
          {
            method: options?.method,
            url: parsedUrl,
            body: body ?? options?.body,
          }
        );
        dispatch({
          type: FetchActionTypes.FETCH_FAILURE,
          payload: error,
          responseStatus,
        });
        return null;
      } finally {
        controller.abort();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      url,
      accessToken,
      setToken,
      refreshToken,
      setRefreshToken,
      accessTokenId,
      msalSubscriptionKey,
      refreshTokenId,
      vantageWebApi,
      responseBody,
      dispatch,
      options?.body,
      // options?.headers,
      options?.method,
    ]
  );

  const reset = () => {
    dispatch({ type: FetchActionTypes.FETCH_RESET });
  };

  useEffect(() => {
    if (!isLazyTrigger) {
      trigger();
    }
  }, [trigger, isLazyTrigger]);

  return { trigger, reset, ...state };
};

export default useFetch;
