export const setNullWhenIsEmpty = (value) => {
  if (!value) {
    return null;
  }
  return value.trim() === '' ? null : value.trim();
};

export const ellipsisText = (inputText, maxLength) => {
  if (!inputText || inputText.length <= maxLength) {
    return inputText;
  }

  const shortenedText = inputText.slice(0, maxLength - 3);
  return shortenedText + '...';
};
