import React, { FC } from 'react';
import { Risks } from 'components/atoms/risk-rating/types';
import { Card, SectionHeading } from 'components/molecules';
import { CardGrid } from 'components/organisms';
import { ReadMoreTextArea } from 'components/atoms';
import { ScreeningRiskSummary } from './config';

interface ScreeningRisksProps {
  adverseNotes: string;
  adverseNotesRiskScore?: Risks;
  createdDate: string;
  enforcementsNotes: string;
  enforcementsNotesRiskScore?: Risks;
  finalisedDate: string;
  politicalNotes: string;
  politicalNotesRiskScore?: Risks;
  resolutionNotes: string;
  watchlistNotes: string;
  watchlistRiskScore?: Risks;
  type: string;
}

const ScreeningRisks: FC<ScreeningRisksProps> = ({
  adverseNotes,
  adverseNotesRiskScore,
  enforcementsNotes,
  enforcementsNotesRiskScore,
  politicalNotes,
  politicalNotesRiskScore,
  resolutionNotes,
  watchlistNotes,
  watchlistRiskScore,
  type,
}) => {
  const text = resolutionNotes;
  const maxTextLength = 400;
  return (
    <section>
      <SectionHeading text="Screening Findings" />
      <ReadMoreTextArea text={text} maxTextLength={maxTextLength} />
      <CardGrid>
        <Card
          compact={true}
          title="Adverse Media"
          risk={adverseNotesRiskScore || 'Unknown'}
          text={adverseNotes}
          icon="adverseMedia"
          tooltip={ScreeningRiskSummary.AdverseMedia}
        />
        <Card
          compact={true}
          title="Regulatory & Enforcement"
          risk={enforcementsNotesRiskScore || 'Unknown'}
          text={enforcementsNotes}
          icon="enforcements"
          tooltip={ScreeningRiskSummary.Enforcement}
        />
        <Card
          compact={true}
          title="Political Exposure"
          risk={politicalNotesRiskScore || 'Unknown'}
          text={politicalNotes}
          icon="political"
          tooltip={ScreeningRiskSummary[`PoliticalExposure${type}`]}
        />
        <Card
          compact={true}
          title="Watchlists"
          risk={watchlistRiskScore || 'Unknown'}
          text={watchlistNotes}
          icon="sanctions"
          tooltip={ScreeningRiskSummary.WatchList}
        />
      </CardGrid>
    </section>
  );
};

export default ScreeningRisks;
