import React, { FC, useState } from 'react';

import {
  EntityResolutionModalTypes,
  MatchRadioTypes,
  MatchResult,
} from './types';
import { Modal, Pagination } from 'components/molecules';
import {
  ButtonsContainer,
  EntityResolutionModalContainer,
  MatchRadioWrapper,
  ModalTitle,
  PaginationWrapper,
} from './EntityResolutionModal.styles';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks/useFetch';
import { Button, Icon, Loading } from 'components/atoms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from 'styled-components';

const MatchRadio: FC<MatchRadioTypes> = ({
  id,
  value,
  name,
  register,
  required = false,
  children,
}) => {
  return (
    <MatchRadioWrapper>
      <label htmlFor={id} aria-label={id}>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          tabIndex={-1}
          {...(register ? { ...register(name, { required }) } : {})}
        />
        <span
          className="radio"
          role="radio"
          tabIndex={-1}
          aria-label={id}
          aria-checked={false}
        />
        <p>{children}</p>
      </label>
    </MatchRadioWrapper>
  );
};

const EntityResolutionModal: FC<EntityResolutionModalTypes> = ({
  isOpen,
  onProceed,
  onCancel,
  onClose,
  searchPayload,
}: EntityResolutionModalTypes) => {
  const { vantageWebApi } = useConfig();
  const { accessToken } = useAuth();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        selectedMatchDUNS: yup
          .string()
          .required('Please select one company to proceed')
          .nullable(),
      })
    ),
    defaultValues: {
      selectedMatchDUNS: null,
    },
  });

  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 5;

  const { data, loading } = useFetch(`${vantageWebApi}/company/search`, {
    onSuccess: () => {},
    method: 'POST',
    headers,
    body: JSON.stringify(searchPayload),
  });

  const matchResults = data as MatchResult[];

  const onSubmit = ({ selectedMatchDUNS }: { selectedMatchDUNS: string }) => {
    const match = matchResults.find(
      (match) => match.organization.duns === selectedMatchDUNS
    );
    onProceed(match.organization);
  };

  return (
    <Modal
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      isOpen={isOpen}
      title={<ModalTitle>Company Lookup</ModalTitle>}
    >
      <EntityResolutionModalContainer>
        {loading && <Loading />}

        {!loading && matchResults && (
          <>
            {matchResults.length > 0 ? (
              <>
                <h3 className="title">Potential Matches</h3>

                {matchResults
                  .slice(
                    PAGESIZE * (currentPage - 1),
                    PAGESIZE * (currentPage - 1) + PAGESIZE
                  )
                  .map((matchResult) => {
                    const { organization } = matchResult;
                    const {
                      duns,
                      primaryName,
                      primaryAddress,
                      registrationNumbers,
                    } = organization;

                    const [registrationNumber] = registrationNumbers;
                    return (
                      <MatchRadio
                        key={duns}
                        id={duns}
                        value={duns}
                        name="selectedMatchDUNS"
                        register={register}
                      >
                        <>
                          <span className="bold">{primaryName},</span>{' '}
                          {primaryAddress?.addressCountry?.name}{' '}
                          {primaryAddress?.streetAddress?.lineOne}{' '}
                          {primaryAddress?.postalCode}
                          {registrationNumber && (
                            <>
                              <br />
                              <span>
                                Registration number:{' '}
                                {registrationNumber.registrationNumber}
                              </span>
                            </>
                          )}
                          <br />
                          DUNS: {duns}
                        </>
                      </MatchRadio>
                    );
                  })}
                <PaginationWrapper>
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={Math.ceil(matchResults.length / PAGESIZE) || 1}
                    onPageChange={() => {}}
                  />
                </PaginationWrapper>
                {errors?.selectedMatchDUNS?.message && (
                  <p className="error">
                    <Icon
                      icon="risk"
                      colour={theme.colours.redRampage}
                      size="xxs"
                    />
                    {errors.selectedMatchDUNS.message}
                  </p>
                )}

                <ButtonsContainer>
                  <Button type="button" onClick={onCancel} text>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleSubmit(onSubmit)()}
                    secondary
                  >
                    Select Company
                  </Button>
                </ButtonsContainer>
              </>
            ) : (
              <>
                <h3 className="title bold">
                  No matching company records identified
                </h3>
                <p>
                  Based on the provided information, we have not been able to
                  identify any matching company records.
                  <br />
                </p>
                <ButtonsContainer>
                  <Button type="button" onClick={() => onClose()} secondary>
                    Close
                  </Button>
                </ButtonsContainer>
              </>
            )}
          </>
        )}
      </EntityResolutionModalContainer>
    </Modal>
  );
};

export default EntityResolutionModal;
