export const headings = [
  {
    name: 'Date',
    orderable: true,
    field: 'CreatedDate',
    width: '9%',
  },
  {
    name: 'Internal Owner',
    width: '10%',
  },
  {
    name: 'Third Party',
    width: '16%',
  },
  {
    name: 'Related Entity',
    width: '16%',
  },
  {
    name: 'Due Dilligence Type',
    width: '16%',
  },
  {
    name: 'Due Dilligence Level',
    width: '16%',
  },
  {
    name: 'Status',
    width: '16%',
  },
];
