import React, { FC } from 'react';
import { Wrapper } from './FieldToggle.styles';
import { FieldToggleProps } from './types';
import { Icon } from 'components/atoms';
import { colours } from 'theme/@config';

const FieldToggle: FC<FieldToggleProps> = ({
  id = '',
  label,
  name = '',
  disabled = false,
  register,
  inputRef,
  onChange,
  ...props
}: FieldToggleProps) => (
  <Wrapper>
    <label htmlFor={id}>
      <input
        type="checkbox"
        id={id}
        name={name}
        tabIndex={-1}
        onChange={onChange}
        {...(disabled ? { disabled } : {})}
        {...(inputRef ? { ref: inputRef } : {})}
        {...(register ? { ...register(id) } : {})}
        {...props}
      />
      <span className={`toggleSpace ${disabled && 'disabled'}`}>
        <span className="toggleCircle">
          <Icon
            className="toggled"
            icon="check"
            aria-hidden
            size="xxxs"
            colour={colours.crowberryBlue}
          />
          <Icon
            className="untoggled"
            icon="close"
            aria-hidden
            size="xxxs"
            colour="white"
          />
        </span>
      </span>
      {label && <span>{label}</span>}
    </label>
  </Wrapper>
);

export default FieldToggle;
