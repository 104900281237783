import React, { FC } from 'react';
import { NamesListTypes } from './types';
import Avatar from 'components/atoms/avatar';
import { NamesListComponent, UserItem } from './NamesList.styles';

const NamesList: FC<NamesListTypes> = ({ names }: NamesListTypes) => {
  return (
    <NamesListComponent>
      {names.map((name, index) => {
        const [firstname, lastname] = name.split(' ');

        const firstInitial = firstname?.at(0)?.toUpperCase() ?? '';
        const secondInitial = lastname?.at(0)?.toUpperCase() ?? '';

        return (
          <UserItem key={index}>
            <Avatar>{`${firstInitial}${secondInitial}`}</Avatar>
            <span>{name}</span>
          </UserItem>
        );
      })}
    </NamesListComponent>
  );
};

export default NamesList;
