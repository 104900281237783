import React, { FC } from 'react';
import { Icon } from 'components/atoms';
import { colours } from 'theme/@config';
import { BannerBackground, BannerText } from './Banner.styles';
import { BannerProps } from './types';

const Banner: FC<BannerProps> = ({ icon, text }) => {
  return (
    <BannerBackground>
      <Icon icon={icon} size="xs" colour={colours.swimmersPool} />
      <BannerText>{text}</BannerText>
    </BannerBackground>
  );
};

export default Banner;
