import styled from 'styled-components';

export const PerformEntityResolutionComponent = styled.button<{
  clickable: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  padding: 0;

  .entityResolutionLabel {
    margin: 0 5px;
  }
`;

export const PerformEntityResolutionContainer = styled.span`
  display: flex;
  align-items: center;
`;
