import React, { useState } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { useParams } from 'react-router';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { Button, Loading } from 'components/atoms';
import { LoadingScreen } from './Report.styles';

import Modal from 'components/molecules/modal';

type ReportURLParams = {
  id: string;
};

const ReportPdf = () => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const [pdf, setPdf] = useState(null);
  const { id } = useParams<ReportURLParams>();
  const { data, loading, error, responseStatus } = useFetch(
    `${vantageWebApi}/onlinereport/${id}/pdf`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    'blob'
  );
  if (data && !pdf) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const pdf = URL.createObjectURL(blob);
    setPdf(pdf);
  }
  if (loading) {
    return (
      <LoadingScreen>
        <Loading />
      </LoadingScreen>
    );
  }
  if (error || (responseStatus && responseStatus !== 200)) {
    return <h1>Something isn't working</h1>;
  }

  return (
    <LoadingScreen>
      <Modal
        isOpen={true}
        title="Download PDF"
        onClose={() => {
          window.close();
        }}
      >
        <Button as="a" download={`report-${id}.pdf`} href={pdf}>
          Download
        </Button>
      </Modal>
    </LoadingScreen>
  );
};

export default withAITracking(reactPlugin, ReportPdf);
