import React, { FC } from 'react';
import { Wrapper } from './FieldSelect.styles';
import { FieldSelectTypes, Option } from './types';
import Icon from '../icon';

const FieldSelect: FC<FieldSelectTypes> = ({
  label = '',
  ariaLabel = '',
  tabIndex = 0,
  id = '',
  placeholder = '',
  disabled = false,
  options = [],
  inputRef,
  register,
  required = false,
  fullWidth = false,
  valid,
  placeholderValue,
  placeholderDisabled = false,
  onChange,
}: FieldSelectTypes) => {
  const checkValidationIsBool = typeof valid === 'boolean';
  return (
    <>
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span className="required"> *</span>}
        </label>
      )}
      <Wrapper
        {...(checkValidationIsBool
          ? { className: !valid ? 'invalid' : '' }
          : {})}
        $fullWidth={fullWidth}
        disabled={disabled}
      >
        <select
          id={id}
          name={id}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
          ref={inputRef}
          onChange={onChange}
          {...(disabled ? { disabled } : {})}
          {...(register ? { ...register(id, { required }) } : {})}
        >
          {placeholder && (
            <option
              key={`${id}-placeholder`}
              className="placeholder"
              value={placeholderValue}
              disabled={placeholderDisabled}
            >
              {placeholder}
            </option>
          )}

          {options?.map((option: Option) => (
            <option key={`${id}-${option.name}`} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {!disabled ? (
          <Icon icon="chevron-down" size="xxs" className="dropdown-icon" />
        ) : null}
      </Wrapper>
    </>
  );
};

export default FieldSelect;
