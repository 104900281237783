import React from 'react';
import Drawer from 'components/molecules/drawer';
import { Loading } from 'components/atoms';
import { DrawerModal as DrawerModalProps } from './types';
import { ChildContainer } from './DrawerModal.styles';

const DrawerModal: React.FC<DrawerModalProps> = ({
  isOpen,
  onClose,
  onCloseDisabled,
  title,
  loading,
  children,
  bodyContainerRef,
}) => {
  return (
    <Drawer
      onClose={onCloseDisabled ? null : () => onClose()}
      isOpen={isOpen}
      title={title}
      bodyContainerRef={bodyContainerRef}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          <ChildContainer>{children}</ChildContainer>
        </>
      )}
    </Drawer>
  );
};

export default DrawerModal;
