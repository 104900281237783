import React, { useContext, useState } from 'react';

import Modal from 'components/molecules/modal';

import { StepOne, StepTwo, StepThree } from './steps';
import { RelatedEntityModalContext } from './Context';
import { Loading } from 'components/atoms';
import { RelatedEntityModalFormValues } from './types';

type RelatedEntitiesFormModalProps = {
  isOpen?: boolean;
  onClose?: (boolean, object?) => void;
  title?: string;
  relatedEntity: Record<string, any> | undefined;
  loading?: boolean;
  onInitiateScreening?: (data: RelatedEntityModalFormValues) => void;
  onSubmit?: (data: RelatedEntityModalFormValues) => void;
  performScreening?: boolean;
  noIndividualRelatedEntitiesAllowed?: boolean;
  noBusinessRelatedEntitiesAllowed?: boolean;
  relatedEntityFieldsConfigs: {
    isIndividualRelationshipToThirdPartyMandatory: boolean;
    isCompanyRelationshipToThirdPartyMandatory: boolean;
    isIndividualDateOfBirthMandatory: boolean;
  };
};
const RelatedEntitiesFormModal: React.FC<RelatedEntitiesFormModalProps> = ({
  isOpen,
  onClose,
  title,
  relatedEntity,
  onInitiateScreening,
  loading = false,
  onSubmit,
  performScreening,
  noIndividualRelatedEntitiesAllowed = false,
  noBusinessRelatedEntitiesAllowed = false,
  relatedEntityFieldsConfigs,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formTitle, setFormTitle] = useState(title);

  const { updateForm, resetForm } = useContext(RelatedEntityModalContext);

  const handleOnCloseModal = (isComplete: boolean) => {
    onClose(isComplete);
    setCurrentStep(1);
    resetForm();
  };

  const handleStepOneSubmit = (data: RelatedEntityModalFormValues) => {
    updateForm(data);
    setCurrentStep(2);
  };

  const handleStepTwoSubmit = (data: RelatedEntityModalFormValues) => {
    updateForm(data);
    onSubmit(data);
    if (performScreening) {
      setCurrentStep(3);
    } else {
      handleOnCloseModal(true);
    }
  };

  const handleInitiateScreening = (data: RelatedEntityModalFormValues) => {
    onInitiateScreening(data);
    handleOnCloseModal(true);
  };

  const handleStepTwoBack = () => setCurrentStep(1);

  return (
    <Modal
      isOpen={isOpen}
      customOverlayWidth="800px"
      onClose={() => handleOnCloseModal(false)}
      title={formTitle}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          {currentStep === 1 && (
            <StepOne
              noIndividualRelatedEntitiesAllowed={
                noIndividualRelatedEntitiesAllowed
              }
              noBusinessRelatedEntitiesAllowed={
                noBusinessRelatedEntitiesAllowed
              }
              isIndividualRelationshipToThirdPartyMandatory={
                relatedEntityFieldsConfigs.isIndividualRelationshipToThirdPartyMandatory
              }
              isCompanyRelationshipToThirdPartyMandatory={
                relatedEntityFieldsConfigs.isCompanyRelationshipToThirdPartyMandatory
              }
              relatedEntity={relatedEntity}
              onSubmit={handleStepOneSubmit}
              onCancel={() => handleOnCloseModal(false)}
            />
          )}
          {currentStep === 2 && (
            <StepTwo
              relatedEntity={relatedEntity}
              isIndividualDateOfBirthMandatory={
                relatedEntityFieldsConfigs.isIndividualDateOfBirthMandatory
              }
              onSubmit={handleStepTwoSubmit}
              onBackClick={handleStepTwoBack}
            />
          )}
          {currentStep === 3 && (
            <StepThree
              onSubmit={handleInitiateScreening}
              setFormTitle={setFormTitle}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default RelatedEntitiesFormModal;
