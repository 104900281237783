import styled from 'styled-components';

export const PageTitleComponent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacing(30)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const PageTitleChildren = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(20)};
  }
`;
