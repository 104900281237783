import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { GlobalConfig } from './types';

const localConfig: GlobalConfig = {
  vantageWebApi: process.env.REACT_APP_VANTAGE_WEB_API,
  msalSubscriptionKey: process.env.REACT_APP_MSAL_SUBSCRIPTION_KEY,
  signalRSubscriptionKey: process.env.REACT_APP_SIGNALR_SUBSCRIPTION_KEY,
  signalRUrl: process.env.REACT_APP_SIGNALR_URL,
};

/**
 * Context for global configuration, which can include both default settings
 * and dynamically set environment-specific values.
 */
export const Config: React.Context<
  (GlobalConfig & { refreshTokenId: string; accessTokenId: string }) | null
> = createContext(null);

/**
 * Provides a React context provider for global configuration.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components that will have access to the context.
 * @returns {JSX.Element} The provider component that wraps children and provides them access to the global config.
 */
export const ConfigProvider = ({ children }) => {
  return (
    <Config.Provider
      value={
        /**
         * If the local env is not set we use
         * the options set on the window
         */
        {
          ...(process.env.REACT_APP_LOCAL === 'true'
            ? localConfig
            : (window.ReactAppConfig as GlobalConfig)),
          accessTokenId: 'cr-vantage-online-access-token',
          refreshTokenId: 'cr-vantage-online-refresh-token',
        }
      }
    >
      {children}
    </Config.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * Custom React hook to access the global configuration context.
 * @returns {GlobalConfig & {refreshTokenId: string; accessTokenId: string} | null} The current global configuration context.
 */
export const useConfig = () => useContext(Config);
