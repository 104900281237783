import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import GlobalStyles from './globalStyles/GlobalStyles';

import { ConfigProvider } from 'config';

import generateTheme from './theme';
import { ToastProvider } from 'toast';
import { AuthProvider } from 'auth';
import { TerminologyConfigProvider } from 'context/terminologyConfig';
import ErrorBoundary from 'containers/ErrorBoundary';
import 'utils/fetchInterceptor';

const theme = generateTheme();

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <GlobalStyles />
            <AuthProvider>
              <TerminologyConfigProvider>
                <App />
              </TerminologyConfigProvider>
            </AuthProvider>
          </ToastProvider>
        </ThemeProvider>
      </ConfigProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);
