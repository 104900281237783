import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';

export const statusMapper = (
  status: String,
  state: String,
  dueDiligenceType: String,
  reportStatus?: String
) => {
  const isSelfService =
    dueDiligenceType === 'Self Service' ||
    dueDiligenceType === GatewaySubscriptionTypeNames.SelfServiceScreening;

  const isSelfServiceAndReportStatusNotSet = !reportStatus || isSelfService;

  if (state === 'Archived') return state;
  if (state === 'Errored') return state;
  if (state === 'InProgress') return 'In progress';

  const mapState = (currentStatus) => {
    if (state === 'UnresolvedMatches') {
      return isSelfService
        ? 'Incomplete - (Unresolved matches)'
        : reportStatus === null
        ? 'In progress'
        : statusMapper(reportStatus, null, 'Managed Service');
    } else if (state === 'UnresolvedAlerts') {
      return isSelfService
        ? 'Incomplete - (Monitoring alert)'
        : statusMapper(reportStatus, null, 'Managed Service');
    } else {
      return currentStatus;
    }
  };

  let statusToMap: String;

  switch (status) {
    case 'Incomplete':
      statusToMap = 'Incomplete';
      break;
    case 'CompleteAllMatchesDiscarded':
      statusToMap = 'Complete - All matches discarded';
      break;
    case 'CompletePossibleMatchesFound':
      statusToMap = 'Complete - Possible matches';
      break;
    case 'CompleteMatchesFound':
      statusToMap = 'Complete - Confirmed matches';
      break;
    case 'CompleteNoMatchesFound':
      statusToMap = 'Complete - No matches found';
      break;
    case 'Complete':
      statusToMap = 'Complete';
      break;
    default:
      return status;
  }

  if (statusToMap == 'Incomplete' || isSelfServiceAndReportStatusNotSet) {
    return mapState(statusToMap);
  } else {
    return mapState(reportStatus);
  }
};
