import React, { useState, FC } from 'react';
import Chip from 'components/atoms/chip';
import Icon from 'components/atoms/icon';

import {
  Wrapper,
  SubHeader,
  EntityContainer,
  EntityWrapper,
  EntityHeader,
  EntityTray,
  ActionButton,
  EntityActionButton,
} from '../form.styles';

import { useAuth } from 'auth';
import { DescriptionList, FieldCheckbox } from 'components/atoms';
import { Pagination } from 'components/molecules';
import { CarouselNavigationComponent } from 'components/molecules/carousel-navigation/CarouselNavigation.styles';
import { ReactComponent as RelatedEntityIndividual } from './../../assets/svg/Icons/RelatedEntityIndividual.svg';
import { ReactComponent as RelatedEntityCompany } from './../../assets/svg/Icons/RelatedEntityCompany.svg';
import { GatewayPermissions } from 'auth/constants/permissions';
import {
  PaginationWrapper,
  AddRelatedEntityWrapper,
  RelatedEntityOptionContainer,
  RelatedEntityInfoContainer,
  FooterContainer,
  EntityIconWrapper,
} from './RelatedEntitiesForm.styles';
import RelatedEntitiesModal from 'pages/third-party-profile/sections/related-entities/related-entity-modal';
import { getName } from 'country-list';
import { SelectedRelatedEntity } from 'pages/initiate-due-diligence/types';
import { useFormContext, Controller } from 'react-hook-form';

interface RelatedEntitiesForm {
  thirdPartyId: string;
  numberOfIndividualPermitted: number;
  numberOfCompanyPermitted: number;
  relatedEntitiesData: SelectedRelatedEntity[];
  notifyToAnalist?: boolean;
  relatedEntityFieldsConfigs: {
    isIndividualRelationshipToThirdPartyMandatory: boolean;
    isCompanyRelationshipToThirdPartyMandatory: boolean;
    isIndividualDateOfBirthMandatory: boolean;
  };
  onSuccessCreated: (entity: Record<string, any>) => void;
  onSuccessUpdated: (entity: Record<string, any>) => void;
}

const RelatedEntitiesForm: FC<RelatedEntitiesForm> = ({
  thirdPartyId,
  numberOfIndividualPermitted,
  numberOfCompanyPermitted,
  relatedEntitiesData,
  notifyToAnalist,
  relatedEntityFieldsConfigs,
  onSuccessCreated,
  onSuccessUpdated,
}) => {
  const totalPerPage = 10;
  const { hasPermission } = useAuth();
  const [indexPage, setIndexPage] = useState(1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [relatedEntity, setRelatedEntity] = useState(null);
  const { control, getValues, setValue } = useFormContext();

  const formatGenderOutput = (gender) => {
    switch (gender) {
      case 0:
        return 'Male';
      case 1:
        return 'Female';
      default:
        return 'Unknown';
    }
  };

  const generateAttributesList = (entity) => {
    const attributesList = [];

    if (entity.type === 'Individual') {
      attributesList.push(
        { key: 'Alternative name', value: entity.alternativeName },
        {
          key: 'Primary country',
          value: entity.primaryCountry ? getName(entity.primaryCountry) : null,
        },
        { key: 'Gender', value: formatGenderOutput(entity.gender) },
        {
          key: 'DOB',
          value: entity.dateOfBirth
            ? new Date(entity.dateOfBirth).toLocaleDateString('fr-CA') // we are using fr-CA to establish format yyyy-mm-dd since we were usig ISO standards
            : null,
        },
        { key: 'Address', value: entity.addressLineOne },
        { key: 'Post/Zip code', value: entity.zipCode },
        { key: 'City', value: entity.city },
        { key: 'State/Region/Province', value: entity.county },
        {
          key: 'Country 2',
          value: entity.countryTwo ? getName(entity.countryTwo) : null,
        },
        {
          key: 'Country 3',
          value: entity.countryThree ? getName(entity.countryThree) : null,
        },
        {
          key: 'Nationality',
          value: entity.nationality ? getName(entity.nationality) : null,
        },
        {
          key: 'Passport Number',
          value: entity.passportNumber,
        },
        { key: 'National ID number', value: entity.nationalIDNumber },
        { key: 'Other ID number', value: entity.otherIDNumber }
      );
    } else if (entity.type === 'Business') {
      attributesList.push(
        { key: 'Alternative name', value: entity.alternativeName },
        {
          key: 'Primary country',
          value: entity.primaryCountry ? getName(entity.primaryCountry) : null,
        },
        { key: 'Address', value: entity.addressLineOne },
        { key: 'Post/Zip code', value: entity.zipCode },
        { key: 'City', value: entity.city },
        {
          key: 'Country 2',
          value: entity.countryTwo ? getName(entity.countryTwo) : null,
        },
        {
          key: 'Country 3',
          value: entity.countryThree ? getName(entity.countryThree) : null,
        },
        { key: 'DUNS', value: entity.duns },
        { key: 'Registration Number', value: entity.registrationNumber },
        { key: 'SWIFT', value: entity.swift }
      );
    }

    return attributesList;
  };

  const handleOnCloseModal = () => {
    setOpenCreateModal(false);
    setOpenEditModal(null);
  };

  const canAddRelatedEntity = (entity) => {
    const allowedIndividualLimit = 10;
    const allowedCompanyLimit = 10;

    if (entity.type === 'Individual') {
      return (
        entity.isChecked ||
        relatedEntitiesData.filter(
          (e) => e.isChecked && e.type === 'Individual'
        ).length < allowedIndividualLimit
      );
    }
    if (entity.type === 'Business') {
      return (
        entity.isChecked ||
        relatedEntitiesData.filter((e) => e.isChecked && e.type === 'Business')
          .length < allowedCompanyLimit
      );
    }
  };

  const handleSelectedRelatedEntities = (id) => {
    const selectedEntities = getValues('relatedEntities');
    const isCurrentIdSelected = selectedEntities.some((en) => en === id);
    isCurrentIdSelected
      ? setValue('relatedEntities', [
          ...selectedEntities.filter((en) => en != id),
        ])
      : setValue('relatedEntities', [...selectedEntities, id]);
  };

  const allowedIndividualsMessage =
    numberOfIndividualPermitted > 0 &&
    `${numberOfIndividualPermitted} individuals `;

  const allowedCompaniesMessage =
    numberOfCompanyPermitted > 0 && `${numberOfCompanyPermitted} companies `;

  const allowedAdditionalType =
    allowedIndividualsMessage && allowedCompaniesMessage && ' and ';

  const noIndividualRelatedEntitiesAllowed = numberOfIndividualPermitted === 0;
  const noBusinessRelatedEntitiesAllowed = numberOfCompanyPermitted === 0;

  return (
    <Wrapper>
      <SubHeader>
        Select up to <strong>{allowedIndividualsMessage}</strong>
        {allowedAdditionalType}
        <strong>{allowedCompaniesMessage}</strong>
        to be included in the report investigation.
      </SubHeader>
      <>
        <Controller
          control={control}
          name="relatedEntities"
          render={({ field: { value } }) => {
            return (
              <EntityWrapper>
                {relatedEntitiesData
                  .sort((i) => i.id)
                  .map((entity, index) => {
                    if (
                      index >= (indexPage - 1) * totalPerPage &&
                      index < (indexPage - 1) * totalPerPage + totalPerPage
                    ) {
                      return (
                        <EntityContainer key={index} selected={value}>
                          <RelatedEntityOptionContainer>
                            <FieldCheckbox
                              key={`relatedEntities.${index}`}
                              ariaLabel={entity.name}
                              data-testid={`re-${entity.id}`}
                              id={entity?.id?.toString()}
                              checked={value.some((id) => id === entity.id)}
                              disabled={!canAddRelatedEntity(entity)}
                              onChange={() => {
                                handleSelectedRelatedEntities(entity.id);
                              }}
                            />
                            <RelatedEntityInfoContainer>
                              <EntityHeader marginBottom="0px">
                                <EntityIconWrapper background={true}>
                                  {entity?.type === 'Individual' ? (
                                    <RelatedEntityIndividual />
                                  ) : (
                                    <RelatedEntityCompany />
                                  )}
                                </EntityIconWrapper>
                                <strong>{entity?.name?.toLowerCase()}</strong>
                                {entity?.jobTitle && (
                                  <Chip className="chip">
                                    {entity?.jobTitle}
                                  </Chip>
                                )}
                                <EntityTray>
                                  {hasPermission(
                                    GatewayPermissions.EditThirdPartyRelatedEntity
                                  ) && (
                                    <>
                                      <EntityActionButton
                                        type="button"
                                        onClick={() => {
                                          setOpenEditModal(true);
                                          setRelatedEntity(entity);
                                        }}
                                        aria-label="Edit related entity"
                                      >
                                        <Icon
                                          icon="edit-pencil"
                                          size="xxs"
                                          cursor="pointer"
                                          aria-hidden
                                        />
                                      </EntityActionButton>
                                    </>
                                  )}
                                </EntityTray>
                              </EntityHeader>
                              <DescriptionList
                                items={generateAttributesList(entity)}
                                enableShowMore={true}
                                emptyValue={' '}
                                valueFontWeight={'bold'}
                                fullInformation={true}
                              />
                            </RelatedEntityInfoContainer>
                          </RelatedEntityOptionContainer>
                        </EntityContainer>
                      );
                    }
                  })}
              </EntityWrapper>
            );
          }}
        />
        <FooterContainer>
          <AddRelatedEntityWrapper>
            {hasPermission(
              GatewayPermissions.CreateThirdPartyAndRelatedEntity
            ) && (
              <ActionButton
                type="button"
                onClick={() => setOpenCreateModal(true)}
              >
                <Icon
                  className="icon"
                  icon="plus-circle"
                  size="xs"
                  cursor="pointer"
                />
                Create a New Related Entity
              </ActionButton>
            )}
            <RelatedEntitiesModal
              isOpen={openCreateModal}
              onClose={handleOnCloseModal}
              onSuccess={onSuccessCreated}
              thirdPartyId={parseInt(thirdPartyId)}
              performScreening={false}
              notifyToAnalist={notifyToAnalist}
              noIndividualRelatedEntitiesAllowed={
                noIndividualRelatedEntitiesAllowed
              }
              noBusinessRelatedEntitiesAllowed={
                noBusinessRelatedEntitiesAllowed
              }
              relatedEntityFieldsConfigs={relatedEntityFieldsConfigs}
            />
            {relatedEntity && (
              <RelatedEntitiesModal
                relatedEntity={relatedEntity}
                relatedEntityFieldsConfigs={relatedEntityFieldsConfigs}
                thirdPartyId={parseInt(thirdPartyId)}
                isOpen={openEditModal}
                onClose={handleOnCloseModal}
                onSuccess={onSuccessUpdated}
                performScreening={false}
              />
            )}
          </AddRelatedEntityWrapper>
          <PaginationWrapper>
            {relatedEntitiesData?.length > 0 && (
              <CarouselNavigationComponent dark={true}>
                <Pagination
                  setCurrentPage={setIndexPage}
                  totalPages={Math.ceil(
                    relatedEntitiesData?.length / totalPerPage
                  )}
                  currentPage={indexPage}
                  label={'Screening pagination'}
                  onPageChange={({ currentPage }) => setIndexPage(currentPage)}
                  useDots={true}
                  pagesToShow={-1}
                ></Pagination>
              </CarouselNavigationComponent>
            )}
          </PaginationWrapper>
        </FooterContainer>
      </>
    </Wrapper>
  );
};

export default RelatedEntitiesForm;
