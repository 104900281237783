import styled, { css } from 'styled-components';
import { TableSortButtonProps } from './types';

export const TableBody = styled.tbody`
  td {
    font-weight: 300;
  }

  tr:nth-child(even) td {
    background: ${({ theme }) => theme.colours.white};
  }
  tr:nth-child(odd) td {
    background-color: ${({ theme }) => theme.colours.whiteSolid};
  }
`;

export const NoResults = styled.div`
  color: black;
  text-align: center;
  height: 300px;
  padding-top: 5%;
`;

export const TableHeader = styled.thead`
  border-collapse: separate;

  th {
    background-color: ${({ theme }) => theme.colours.paleBlueLily};
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
  }
`;

export const TableFilterComponent = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    padding: ${({ theme }) => theme.spacing(15)}
      ${({ theme }) => theme.spacing(20)};
    border-bottom: 1px solid ${({ theme }) => theme.colours.whiteSolid};
    color: ${({ theme }) => theme.colours.carbonFiber};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    font-size: ${({ theme }) => theme.pxToRem(14)};
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1;
  }

  .options {
    flex: 1 1 0;
    padding: ${({ theme }) => theme.spacing(20)};
    overflow: auto;

    > *:last-child span {
      margin-bottom: 0;
    }
  }

  .actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(10)};
    border-top: 1px solid ${({ theme }) => theme.colours.whiteSolid};

    .clear {
      font-family: ${({ theme }) => theme.typography.fontFamily.primary};
      font-size: ${({ theme }) => theme.pxToRem(14)};
      letter-spacing: 0;
      line-height: 1.714;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: ${({ theme }) => theme.colours.swimmersPool};
      cursor: pointer;

      &:disabled {
        text-decoration: none;
      }
    }
  }
`;

export const TableComponent = styled.table`
  border-collapse: separate;
  width: 100%;
  th,
  td {
    width: max-content;
    min-height: ${({ theme }) => theme.pxToRem(48)};
    padding: ${({ theme }) => theme.spacing(18)}
      ${({ theme }) => theme.spacing(16)};
    color: ${({ theme }) => theme.colours.eerieBlack};
    font-weight: 400;
    text-align: left;
    vertical-align: top;

    span {
      color: ${({ theme }) => theme.colours.eerieBlack};
      font-weight: 400;
    }

    &,
    a {
      font-family: 'Open Sans';
      font-size: ${({ theme }) => theme.pxToRem(14)};
      letter-spacing: 0;
      line-height: 1.714;
    }

    a {
      color: ${({ theme }) => theme.colours.deepWater};
      text-decoration-color: currentColor;

      &:hover,
      &:focus {
        color: #000;
      }
    }

    &:first-child {
      border-radius: 8px 0 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  align-items: first baseline;
  justify-content: space-between;

  .callout-trigger svg {
    width: ${({ theme }) => theme.pxToRem(12)};
    height: ${({ theme }) => theme.pxToRem(12)};
    color: ${({ theme }) => theme.colours.carbonFiber};
  }

  > * + * {
    margin-left: ${({ theme }) => theme.pxToRem(10)};
  }
`;

export const TableFilterCallout = styled.div`
  margin-top: ${({ theme }) => theme.pxToRem(1)}; ;
`;

const TableHeadingBase = css`
  padding: 0;
  color: ${({ theme }) => theme.colours.carbonFiber};
  text-align: left;
  width: max-content;
  font-size: ${({ theme }) => theme.spacing(16)};
`;

export const TableHeadingText = styled.span`
  ${TableHeadingBase}
`;

export const TableSortButton = styled.button<TableSortButtonProps>`
  display: flex;
  align-items: first baseline;
  cursor: pointer;
  ${TableHeadingBase}

  svg {
    margin-left: ${({ theme }) => theme.spacing(6)};
    color: inherit;
    opacity: 0.3;
    transform: translateY(${({ theme }) => theme.spacing(3)});
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      svg {
        opacity: 1;
      }
    `}
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  margin: ${({ theme }) => theme.spacing(20)} 0;
  justify-content: center;
`;

export const ScrollableContainer = styled.div`
  min-height: 50vh;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  border-radius: 8px;

  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  table thead th:first-child {
    position: sticky;
    border-right: 1px solid ${({ theme }) => theme.colours.farsighted};
    left: 0;
    z-index: 2;
  }
  table tr td:first-child {
    position: sticky;
    border-right: 1px solid ${({ theme }) => theme.colours.farsighted};
    left: 0;
    z-index: 1;
  }

  table thead th:last-child {
    position: sticky;
    border-left: 1px solid ${({ theme }) => theme.colours.farsighted};
    right: 0;
    z-index: 2;
  }

  table tr td:last-child {
    position: sticky;
    border-left: 1px solid ${({ theme }) => theme.colours.farsighted};
    right: 0;
    z-index: 1;
  }
`;
