import React, { useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { Redirect } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from 'auth';
import { useFetch } from 'hooks/useFetch';

import jwt from 'jsonwebtoken';

import {
  Button,
  FieldCheckbox,
  FieldInput,
  FormControl,
  Link,
} from 'components/atoms';

import LoginLayout from 'layouts/login-layout';
import {
  LoginForm,
  ButtonWrapper,
  ErrorMessage,
  LoadingScreen,
} from './Login.styles';

import { useConfig } from 'config';
import Loading from 'components/atoms/loading';

const Login = () => {
  const {
    setToken,
    accessToken,
    setRefreshToken,
    redirectUrl,
    setRedirectUrl,
  } = useAuth();
  const { vantageWebApi, accessTokenId, refreshTokenId } = useConfig();
  const schema = yup.object().shape({
    emailAddress: yup
      .string()
      .required('You must enter a valid email address')
      .email('You must enter a valid email address'),
    password: yup.string().required('Please enter a password'),
  });

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors: formValidationErrors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      emailAddress: localStorage.getItem('savedEmail')
        ? localStorage.getItem('savedEmail')
        : ``,
      password: '',
      rememberEmail: localStorage.getItem('savedEmail') ? true : false,
    },
  });

  const email = watch('emailAddress');
  const password = watch('password');

  const disableSubmit = () => {
    const emailLength = email.length;
    const passwordLength = password.length;
    if (!emailLength && !passwordLength) return true;
    if (emailLength === 0 || passwordLength === 0) return true;
    return false;
  };

  const { data, loading, trigger, errorData, responseStatus, error } = useFetch(
    `${vantageWebApi}/account/token`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        mode: 'no-cors',
      },
    }
  );

  const fetchedToken = data?.access_token;

  useEffect(() => {
    if (fetchedToken) {
      setToken(fetchedToken);
      setRefreshToken(data?.refresh_token);
      localStorage.setItem(refreshTokenId, data?.refresh_token);
      localStorage.setItem(accessTokenId, fetchedToken);
      setRedirectUrl(null);
    }
  }, [
    fetchedToken,
    setToken,
    accessTokenId,
    data?.refresh_token,
    refreshTokenId,
    setRefreshToken,
    setRedirectUrl,
  ]);

  if (jwt.decode(accessToken) || fetchedToken) {
    return <Redirect to={redirectUrl ? redirectUrl : `/third-party`} />;
  }

  if (loading) {
    return (
      <LoadingScreen>
        <h1>Loading...</h1>
      </LoadingScreen>
    );
  }

  if (error && responseStatus !== 401) {
    return (
      <div>
        <h1>Error</h1>
      </div>
    );
  }

  const onSubmit = async (formData: any) => {
    const email = formData?.emailAddress;
    const rememberEmail = formData?.rememberEmail;
    if (rememberEmail) {
      localStorage.setItem('savedEmail', email);
    }
    if (!rememberEmail) {
      localStorage.removeItem('savedEmail');
    }
    trigger({
      body: new URLSearchParams({
        scope: 'internal_api offline_access',
        grant_type: 'password',
        username: getValues('emailAddress'),
        password: getValues('password'),
      }).toString(),
    });
  };

  return (
    <LoginLayout>
      <LoginForm>
        <h1>
          Welcome back <br />
          Login to your account
        </h1>
        {errorData?.title?.includes('invalid') && (
          <div className="login-error">
            <p>
              Email address or password is not recognised, please enter valid
              details.
            </p>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            errorMessage={formValidationErrors?.emailAddress?.message}
          >
            <FieldInput
              name="emailAddress"
              id="emailAddress"
              required
              register={register}
              placeholder="Email Address"
              label="Email"
            />
          </FormControl>
          <FormControl errorMessage={formValidationErrors?.password?.message}>
            <FieldInput
              name="password"
              id="password"
              required
              type="password"
              register={register}
              placeholder="Password"
              label="Password"
            />
          </FormControl>
          <FieldCheckbox
            id="rememberEmail"
            register={register}
            label="Save Email Address"
          />
          <ButtonWrapper>
            <Button type="submit" disabled={loading || disableSubmit()}>
              Login Securely
            </Button>
            {loading && <Loading />}
          </ButtonWrapper>
          {error && responseStatus !== 401 && (
            <ErrorMessage>
              There has been an error, please try again
            </ErrorMessage>
          )}
        </form>
        <p>
          <Link href="/forgot-password">Forgotten password</Link>
        </p>
        <div className="support-links">
          <p className="bold">Need help?</p>
          <Button as="a" href="mailto:vantagesupport@controlrisks.com" outline>
            Contact support
          </Button>
          <Button as="a" href="mailto:vantage@controlrisks.com" outline>
            Request a sales callback
          </Button>
        </div>
      </LoginForm>
    </LoginLayout>
  );
};

export default withAITracking(reactPlugin, Login);
