import styled from 'styled-components';
import { detailValues } from './types';

export const ScreeningDetailsComponent = styled.section`
  padding: ${({ theme }) => theme.pxToRem(30)};
  width: 100%;
`;

export const ScreeningDetailsData = styled.div`
  margin-bottom: ${({ theme }) => theme.pxToRem(10)};
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.pxToRem(24)};
  line-height: ${({ theme }) => theme.pxToRem(30)};
  padding-bottom: ${({ theme }) => theme.pxToRem(30)};
  border-bottom: 1px solid ${({ theme }) => theme.colours.gallery};
`;

export const Subheading = styled.div`
  color: ${({ theme }) => theme.colours.charcoalDust};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(21)};
  font-weight: 600;
  padding: ${({ theme }) => theme.pxToRem(10)} 0;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colours.charcoalDust};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(21)};
`;
export const Value = styled.span<detailValues>`
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(21)};
  ${({ lineBreaks }) => lineBreaks && 'white-space: pre;'}
`;

export const Detail = styled.div`
  padding-bottom: ${({ theme }) => theme.pxToRem(10)};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colours.gallery};
    margin-bottom: ${({ theme }) => theme.pxToRem(30)};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.pxToRem(20)};
`;
