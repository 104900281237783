import styled from 'styled-components';

export const ResultsListComponent = styled.table`
  padding-bottom: ${({ theme }) => theme.pxToRem(40)};
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: ${({ theme }) => theme.pxToRem(40)};
    right: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(1)};
    background-color: ${({ theme }) => theme.colours.gallery};
  }
`;

export const ResultItemLabel = styled.div`
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }) => theme.pxToRem(18)};
  padding-bottom: ${({ theme }) => theme.pxToRem(5)};
  color: ${({ theme }) => theme.colours.charcoalDust};
`;

export const ResultItemValue = styled.div`
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(18)};
  color: ${({ theme }) => theme.colours.crowberryBlue};
`;

interface RowProps {
  active: boolean;
  isNew: boolean;
}

export const Row = styled.tr<RowProps>`
  position: relative;
  background: ${({ theme, active, isNew }) => {
    switch (true) {
      case active:
        return theme.colours.gallery;
      case isNew:
        return theme.colours.friendlyFrost;
      default:
        return theme.colours.white;
    }
  }};

  &:hover {
    background: ${({ theme }) => theme.colours.gallery};
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    left: ${({ theme }) => theme.pxToRem(40)};
    right: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(1)};
    background-color: ${({ theme }) => theme.colours.gallery};
    top: 0;
  }

  td {
    padding-left: ${({ theme }) => theme.pxToRem(10)};
    padding-right: ${({ theme }) => theme.pxToRem(10)};
  }

  td:first-child {
    padding-left: ${({ theme }) => theme.pxToRem(40)};
  }

  td:last-child {
    padding-right: ${({ theme }) => theme.pxToRem(40)};
  }

  span {
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(18)};
    padding-left: ${({ theme }) => theme.pxToRem(5)};
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }
`;

export const ResultItem = styled.td`
  padding: ${({ theme }) => theme.pxToRem(15)} 0;
`;

export const ResultItemMatch = styled.div`
  display: flex;
  flex-direction: row;

  div {
    padding-right: ${({ theme }) => theme.pxToRem(15)};
  }
`;
