import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PanelComponent = styled.div`
  padding: ${({ theme }) => theme.spacing(25)}
    ${({ theme }) => theme.spacing(20)};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ theme }) => theme.colours.whiteSolid};
  color: ${({ theme }) => theme.colours.black};
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PanelLink = styled(Link)`
  display: block;
  float: right;
  margin: 0 0 ${({ theme }) => theme.spacing(10)}
    ${({ theme }) => theme.spacing(10)};
  line-height: 1;

  svg {
    color: ${({ theme }) => theme.colours.swimmersPool};
  }
`;
