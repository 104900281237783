import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  ${reset}

  /* Importing the self hosted fonts */
  ${({ theme }) => theme.typography.fonts}

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: ${({ theme }) => theme.spacing(24)};
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  }

  .bold {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  h1 {
    font-size: ${({ theme }) => theme.spacing(32)};
    line-height: ${({ theme }) => theme.spacing(71)};
    font-weight: 400;
  }

  h2 {
    font-size: ${({ theme }) => theme.spacing(24)};
    line-height: 1.25;
    font-weight: 400;
  }

  strong {
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
  }

  /* A elements that don't have a class get default styles */
  a {
    font-size: ${({ theme }) => theme.spacing(18)};
    line-height: ${({ theme }) => theme.spacing(24)};
    text-decoration-skip-ink: auto;
    color: ${({ theme }) => theme.colours.crowberryBlue};
    text-decoration-color: ${({ theme }) => theme.colours.swimmersPool};
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  del {
    color: ${({ theme }) => theme.colours.spicedPurple};
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  label {
    display: block;
    margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
    color: ${({ theme: { colours } }) => colours.charcoalDust};
    font-weight: ${({ theme: { typography } }) =>
      typography.fontWeight.regular};

    .required {
      color: ${({ theme: { colours } }) => colours.redRampage};
      font-size: ${({ theme: { typography } }) => typography.fontSize.small};
    }
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /* Button style reset */
  button {
    background: none;
    border: none;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .clickable {
    cursor: pointer;
  }
`;

export default GlobalStyles;

export const ButtonBase = css`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(20)};
  background: none;
  appearance: none;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  color: ${({ theme }) => theme.colours.crowberryBlue};
  font-size: ${({ theme }) => theme.pxToRem(16)};
  line-height: 1;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-height: ${({ theme }) => theme.pxToRem(54)};
  border-radius: ${({ theme }) => theme.pxToRem(6)};
`;
