// This must match ControlRisks.Vantage.Models.Constants.GatewayPermissions
export enum GatewayPermissions {
  CreateThirdPartyAndRelatedEntity = 1,
  SelfServiceFinalizeReview = 2,
  ApproveRejectThirdParty = 3,
  EditThirdPartyRelatedEntity = 4,
  DeleteThirdParty = 5,
  InitiateDueDiligenceSelfServiceScreening = 6,
  InitiateDueDiligenceManagedServiceScreening = 7,
  EditMonitoring = 8,
  ManagedServiceFinalizeReview = 9,
  EditCase = 10,
  Admin = 11,
  ThirdPartyLookup = 12,
  OrderManagedDueDiligence = 13,
  ManagedDueDiligenceFinalizeReview = 14,
  ViewDueDiligenceReport = 15,
  ViewDocuments = 16,
  UploadDocuments = 17,
  DeleteDocuments = 18,
  ViewAudit = 19,
  ViewTaskList = 20,
  EditDocument = 21,
  OrderOwnershipManagedDueDiligence = 22,
}
