import styled from 'styled-components';

interface TabProps {
  active: boolean;
  dark?: boolean;
}

export const TabComponent = styled.button<TabProps>`
  color: ${({ theme, dark }) =>
    dark ? theme.colours.white : theme.colours.crowberryBlue};
  font-weight: ${({ active }) => active && '600'};
  border-bottom: ${({ active, theme }) =>
    active && `${theme.pxToRem(4.5)} solid ${theme.colours.swimmersPool}`};
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(8)}`};
  margin-right: ${({ theme }) => theme.spacing(30)};
  font-size: ${({ theme }) => theme.pxToRem(18)};

  &:hover {
    cursor: pointer;
  }
`;
