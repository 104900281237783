import React, { FC, useState, useEffect } from 'react';
import {
  DescriptionListComponent,
  DescriptionListItem,
  ShowMore,
} from './DescriptionList.styles';
import { DescriptionListProps } from './types';

const DescriptionList: FC<DescriptionListProps> = ({
  children,
  items = [],
  spaced = false,
  titleFontWeight = 'normal',
  valueFontWeight = 'normal',
  detailsMargin = true,
  detailsFlexDirection = 'row',
  enableShowMore = false,
  truncateText = false,
  filterProperties = true,
  emptyValue = '-',
  initialVisibleItems = 2,
  fullInformation = false,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [propertyList, setPropertyList] = useState(items);

  useEffect(() => {
    let filteredItems = items;

    if (filterProperties && !fullInformation) {
      filteredItems = items?.filter(({ value }) => value);
    }

    if (enableShowMore && !showMore && filteredItems.length > 0) {
      const newItems = filteredItems.slice(0, initialVisibleItems);
      setPropertyList(newItems);
      return;
    }

    setPropertyList(filteredItems);
  }, [
    showMore,
    items,
    enableShowMore,
    filterProperties,
    initialVisibleItems,
    fullInformation,
  ]);

  return (
    <DescriptionListComponent>
      {propertyList.map(({ key, value, separator = ':' }, index) => (
        <DescriptionListItem
          key={`${key}${value}${index}`}
          spaced={spaced}
          titleFontWeight={titleFontWeight}
          valueFontWeight={valueFontWeight}
          detailsMargin={detailsMargin}
          detailsFlexDirection={detailsFlexDirection}
          fullInformation={fullInformation}
          truncateText={truncateText}
        >
          <dt title={`${key}${separator} ${value ? value : emptyValue}`}>
            {key}
            {separator}
          </dt>

          <dd title={`${key}${separator} ${value ? value : emptyValue}`}>
            {value !== null || fullInformation
              ? typeof value === 'function'
                ? value()
                : value || emptyValue
              : emptyValue}
          </dd>
        </DescriptionListItem>
      )) || children}
      {enableShowMore && (
        <ShowMore onClick={() => setShowMore(!showMore)}>
          {showMore ? 'View less' : 'View more'}
        </ShowMore>
      )}
    </DescriptionListComponent>
  );
};

export default DescriptionList;
