import styled from 'styled-components';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  border-radius: 5px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme?.colours?.concreteGray : theme?.colours?.white};

  select {
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
    border: 1px solid ${({ theme }) => theme?.colours?.charcoalDust};
    border-radius: 5px;
    font-size: ${({ theme }) => theme.spacing(14)};
    line-height: ${({ theme }) => theme.spacing(22)};
    padding: ${({ theme }) =>
      theme.spacing({ left: 20, top: 15, right: 20, bottom: 15 })};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding-right: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme?.colours?.darkToneInk};
  }

  &.invalid {
    select {
      border: 1px solid ${({ theme }) => theme?.colours?.redRampage};
      outline: 1px solid ${({ theme }) => theme?.colours?.redRampage};
    }
  }

  &.valid {
    select {
      border: 1px solid ${({ theme }) => theme?.colours?.swimmersPool};
      outline: none;
    }
  }

  &:not(.invalid) select:focus-visible {
    border: 1px solid ${({ theme }) => theme.colours.swimmersPool};
    background-color: #f6fdfc;
    outline: none;
  }

  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;
