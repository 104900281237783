import React from 'react';
import { useReducer, createContext } from 'react';
import { RelatedEntityType } from '../types';

export const UPDATE_FORM = 'UPDATE_FORM';
export const RESET_FORM = 'RESET_FROM';

const initialState: RelatedEntityType = {};

const modalContextReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_FORM:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export const ModalContext = createContext({
  state: initialState,
  dispatchAction: undefined,
});

export const ThirdPartyProfileREModalContextProvider = ({ children }) => {
  const [state, dispatchAction] = useReducer(modalContextReducer, initialState);
  return (
    <ModalContext.Provider value={{ state, dispatchAction }}>
      {children}
    </ModalContext.Provider>
  );
};
