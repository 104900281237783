import styled from 'styled-components';

export const DefaultText = styled.p`
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
  color: ${({ theme }) => theme.colours.charcoalDust};
`;

export const Container = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(15)};
  border-top: 1px solid ${({ theme }) => theme.colours.charcoalDust}50;
`;
