import React, { useContext } from 'react';
import AppLayout from 'layouts/app-layout';

import { FormsWrapper } from './ThirdPartyCreate.styles';
import { FormProvider, FormContext } from 'context/form';
import { Link, PageTitle } from 'components/atoms';
import { FormSummary, MultiStepNavigation } from 'components/molecules';

import Step1 from 'form/step1/step1';
import Step2 from 'form/step2/step2';
import Step3 from 'form/step3/step3';
import { ThirdPartyType } from 'types/thirdPartyType';
import { useTerminologyConfig } from 'context/terminologyConfig';
// import { withAITracking } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin } from 'services/applicationInsightsService';

function ThirdPartyCreate() {
  const terminologyConfig = useTerminologyConfig();
  return (
    <FormProvider>
      <AppLayout
        topBarSlot={
          <Link to="/third-party" dark>
            Back to {terminologyConfig?.thirdPartySingularNaming.toLowerCase()}
            &nbsp;manager
          </Link>
        }
      >
        <ThirdPartyForms />
      </AppLayout>
    </FormProvider>
  );
}

export const ThirdPartyForms = () => {
  const {
    formState: { activeStep, formData, viewedStep },
  } = useContext(FormContext);
  const terminologyConfig = useTerminologyConfig();

  const isIndividual = formData?.type === ThirdPartyType.Business;

  const companySteps = [
    { title: `${terminologyConfig?.thirdPartySingularNaming} type` },
    { title: `${terminologyConfig?.thirdPartySingularNaming} details` },
    { title: 'Additional information' },
  ];

  const individualSteps = [
    { title: `${terminologyConfig?.thirdPartySingularNaming} type` },
    { title: `${terminologyConfig?.thirdPartySingularNaming} details` },
    { title: 'Additional information' },
  ];

  const getSteps = () => (isIndividual ? individualSteps : companySteps);

  return (
    <>
      <PageTitle
        title={`Create a new ${terminologyConfig?.thirdPartySingularNaming.toLowerCase()}`}
      />
      <MultiStepNavigation
        steps={getSteps()}
        activeStep={activeStep}
        viewedStep={viewedStep}
      />
      <FormsWrapper>
        {viewedStep == 1 && <Step1 />}
        {viewedStep == 2 && <Step2 />}
        {viewedStep == 3 && <Step3 />}
        {viewedStep >= 2 && (
          <>{!!formData && <FormSummary formData={formData} />}</>
        )}
      </FormsWrapper>
    </>
  );
};

export default ThirdPartyCreate;
