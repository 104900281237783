import React, { FC } from 'react';
import { RiskRatingComponent } from './RiskRating.styles';
import { RiskRatingProps } from './types';

const RiskRating: FC<RiskRatingProps | any> = ({ risk }) => {
  const styledRisks = [
    'Low',
    'Clear findings',
    'Medium',
    'Moderate findings',
    'High',
    'Significant findings',
    'N/A',
    'Unknown',
  ];
  return styledRisks.includes(risk) ? (
    <RiskRatingComponent risk={risk}>{risk}</RiskRatingComponent>
  ) : (
    risk || ''
  );
};

export default RiskRating;
