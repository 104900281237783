import React, { FC, useEffect, useRef, useState } from 'react';
import ConfirmationModal from '../confirmation-modal';
import DocumentViewerModal from '../document-viewer-modal';
import {
  ContextMenu,
  ContextMenuItem,
  IconButton,
} from './ThreeDotsContextMenu.styles';
import { ReactComponent as ThreeDotsIcon } from './../../../assets/svg/Icons/ThreeDots.svg';
import { ReactComponent as ExternalIcon } from './../../../assets/svg/Icons/External.svg';
import { Icon } from 'components/atoms';
import { FileFormat } from 'helpers/documentHelper';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useTheme } from 'styled-components';
import { downloadBlob } from 'helpers/downloadHelper';
import { ThreeDotsContextMenuProps } from './types';

const ThreeDotsContextMenu: FC<ThreeDotsContextMenuProps> = ({
  fileInfo,
  onEdit,
  onSuccessView,
  onFailView,
  onSuccessDownload,
  onFailDownload,
  onSuccessDelete,
  onFailDelete,
  showDeleteOption,
  showEditOption = false,
}: ThreeDotsContextMenuProps) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi, msalSubscriptionKey } = useConfig();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [isViewLoading, setIsViewLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextRef.current &&
        !contextRef.current.contains(event.target as Node)
      ) {
        setIsContextMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const viewerCanRenderIt =
    fileInfo.extension.includes(FileFormat.PDF) ||
    fileInfo.extension.includes(FileFormat.PNG) ||
    fileInfo.extension.includes(FileFormat.JPG) ||
    fileInfo.extension.includes(FileFormat.JPEG);

  const handleViewClick = async () => {
    if (viewerCanRenderIt) {
      setIsDocumentViewerOpen(true);
    }

    setIsViewLoading(true);
    const response = await fetch(`${vantageWebApi}/file/${fileInfo.id}/view`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    setIsViewLoading(false);

    if (response.ok) {
      const file = await response.clone().blob();

      if (viewerCanRenderIt) {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      } else {
        downloadBlob(document.body, file, fileInfo.name);
      }

      onSuccessView && onSuccessView();
    } else {
      setIsDocumentViewerOpen(false);
      onFailView &&
        onFailView(response.status, response.statusText, await response.json());
    }
    setIsContextMenuOpen(false);
  };

  const handleDownloadClick = async () => {
    const response = await fetch(`${vantageWebApi}/file/${fileInfo.id}/view`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const file = await response.clone().blob();
      downloadBlob(document.body, file, fileInfo.name);
      onSuccessDownload && onSuccessDownload();
    } else {
      onFailDownload &&
        onFailDownload(
          response.status,
          response.statusText,
          await response.json()
        );
    }
    setIsContextMenuOpen(false);
  };

  const handleDeleteClick = () => {
    setIsContextMenuOpen(false);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleConfirmationOnProceed = async () => {
    setIsDeleteLoading(true);
    const response = await fetch(
      `${vantageWebApi}/file/${fileInfo.id}/delete`,
      {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    setIsDeleteLoading(false);

    if (response.ok) {
      onSuccessDelete && onSuccessDelete();
    } else {
      onFailDelete &&
        onFailDelete(
          response.status,
          response.statusText,
          await response.json()
        );
    }
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleOnEditClick = () => {
    setIsContextMenuOpen(false);
    onEdit();
  };

  return (
    <>
      <IconButton
        aria-label="three-dots"
        isOpen={isContextMenuOpen}
        onClick={() => setIsContextMenuOpen(true)}
      >
        <ThreeDotsIcon />
        {isContextMenuOpen && (
          <ContextMenu ref={contextRef}>
            <ContextMenuItem title="view" onClick={handleViewClick}>
              <ExternalIcon />
              <span className="text">View</span>
            </ContextMenuItem>
            <ContextMenuItem title="download" onClick={handleDownloadClick}>
              <Icon icon="download" size="xxs" />
              <span className="text">Download</span>
            </ContextMenuItem>
            {showEditOption && (
              <ContextMenuItem title="edit" onClick={handleOnEditClick}>
                <Icon icon="edit-pencil" size="xxs" />
                <span className="text">Edit</span>
              </ContextMenuItem>
            )}
            {showDeleteOption && (
              <ContextMenuItem title="delete" onClick={handleDeleteClick}>
                <Icon
                  icon="trash"
                  size="xxs"
                  colour={theme.colours.redRampage}
                />
                <span className="text danger">Delete</span>
              </ContextMenuItem>
            )}
          </ContextMenu>
        )}
      </IconButton>
      <DocumentViewerModal
        isOpen={isDocumentViewerOpen}
        onClose={() => setIsDocumentViewerOpen(false)}
        isLoading={isViewLoading}
        blobUrl={blobUrl}
        backMessage={`Back to ${terminologyConfig?.thirdPartySingularNaming} profile`}
      />
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onProceed={handleConfirmationOnProceed}
        onCancel={() => setIsDeleteConfirmationModalOpen(false)}
        title="Delete Confirmation"
        message="Please note that this document will be permanently deleted. Do you want to proceed?"
        proceedButtonText="Delete"
        proceedButtonLoading={isDeleteLoading}
        onProceedAriaLabel="Delete"
        loading={false}
      />
    </>
  );
};

export default ThreeDotsContextMenu;
