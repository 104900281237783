import styled from 'styled-components';

export const IndicatorComponent = styled.section`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.pxToRem(30)};
  height: ${({ theme }) => theme.pxToRem(30)};
  border: 1px solid ${({ theme }) => theme.colours.crowberryBlue};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colours.white};

  svg {
    color: ${({ theme }) => theme.colours.crowberryBlue};
  }

  .value {
    position: absolute;
    top: 0;
    right: ${({ theme }) => theme.pxToRem(-10)};
    min-width: ${({ theme }) => theme.pxToRem(17)};
    height: ${({ theme }) => theme.pxToRem(17)};
    border-radius: ${({ theme }) => theme.pxToRem(20)};
    background-color: ${({ theme }) => theme.colours.spicedPurple};
    color: ${({ theme }) => theme.colours.white};
    font-family: 'SF Pro Text';
    font-size: ${({ theme }) => theme.pxToRem(10)};
    font-weight: bold;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.pxToRem(17)};
    text-align: center;
  }

  .name {
    color: ${({ theme }) => theme.colours.crowberryBlue};
    font-family: 'Open Sans';
    font-size: ${({ theme }) => theme.pxToRem(10)};
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.4;
    text-align: center;
  }
`;
