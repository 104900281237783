import dayjs, { Dayjs } from 'dayjs';

export enum VerificationDateSelectedOption {
  Later = 1,
  NoEarlier = 2,
  NoEarlierNoLater = 3,
}

export interface VerificationDateConfig {
  label: string;
  selectedOption: number;
  later: number | null;
  noEarlier: number | null;
  noEarlierNoLater: number[] | null;
  laterCalculatedDate: string | null;
  noEarlierCalculatedDate: string | null;
  noEarlierNoLaterCalculatedDate: string[] | null;
}

export const isVerificationDateValid = (
  config: VerificationDateConfig,
  dateToValidate: string | number | Date | Dayjs
) => {
  switch (config.selectedOption) {
    case VerificationDateSelectedOption.Later:
      return dayjs(dateToValidate).isAfter(config.laterCalculatedDate, 'days');

    case VerificationDateSelectedOption.NoEarlier:
      return !dayjs(dateToValidate).isBefore(
        config.noEarlierCalculatedDate,
        'days'
      );

    case VerificationDateSelectedOption.NoEarlierNoLater:
      const noEarlier = config.noEarlierNoLaterCalculatedDate[0];
      const noLater = config.noEarlierNoLaterCalculatedDate[1];

      return (
        !dayjs(dateToValidate).isBefore(noEarlier, 'days') &&
        !dayjs(dateToValidate).isAfter(noLater, 'days')
      );

    default:
      throw new Error('Unexpected VerificationDate SelectedOption');
  }
};

export const getVerificationDateTimeFrameMessage = (
  config: VerificationDateConfig
) => {
  const selectedOption = config.selectedOption;

  switch (selectedOption) {
    case VerificationDateSelectedOption.Later:
      return `later than ${config.later} days`;
    case VerificationDateSelectedOption.NoEarlier:
      return `not earlier than ${config.noEarlier} days`;
    case VerificationDateSelectedOption.NoEarlierNoLater:
      return `not earlier than ${config.noEarlierNoLater[0]} days and not later than ${config.noEarlierNoLater[1]} days`;
    default:
      throw new Error('Unexpected VerificationDate SelectedOption');
  }
};
