import { useAuth } from 'auth';
import { Button, FieldSelect, FormControl } from 'components/atoms';
import { Modal } from 'components/molecules';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { EditMonitoringForm } from './EditMonitoringModal.styles';
import { EditMonitoringModalTypes } from './types';
import Loading from 'components/atoms/loading';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const EditMonitoringModal: FC<EditMonitoringModalTypes> = ({
  isOpen,
  onClose,
  hasMonitoring,
  ongoingScreeningSearchTypeId,
  screeningId,
  reload,
  dueDiligenceTypes,
}: EditMonitoringModalTypes) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object().shape({
        monitoring: yup.string().required('This field is required'),
        monitoringScreeningSettings: yup
          .number()
          .typeError('Please select a monitoring setting')
          .nullable()
          .when('monitoring', {
            is: 'enabled',
            then: yup
              .number()
              .typeError('Please select a monitoring setting')
              .required('Please select a monitoring setting'),
          }),
      })
    ),
    defaultValues: {
      monitoring: useMemo(() => {
        return hasMonitoring ? 'enabled' : 'disabled';
      }, [hasMonitoring]),
      monitoringScreeningSettings: useMemo(() => {
        return hasMonitoring ? ongoingScreeningSearchTypeId : null;
      }, [hasMonitoring, ongoingScreeningSearchTypeId]),
    },
  });

  useEffect(() => {
    reset({
      monitoring: hasMonitoring ? 'enabled' : 'disabled',
      monitoringScreeningSettings: hasMonitoring
        ? ongoingScreeningSearchTypeId
        : null,
    });
  }, [hasMonitoring, ongoingScreeningSearchTypeId, reset]);

  const settingsDisabled = watch('monitoring') === 'disabled';
  const {
    data: submitData,
    loading: submitLoading,
    trigger: triggerSubmit,
    reset: submitReset,
  } = useFetch(
    `${vantageWebApi}/onlinescreening/${screeningId}/edit-monitoring`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      lazy: true,
      body: JSON.stringify({
        ongoingScreeningSearchTypeId: settingsDisabled
          ? null
          : Number(getValues('monitoringScreeningSettings')),
        monitoring: getValues('monitoring') === 'enabled',
      }),
    }
  );

  let monitoringScreeningSettings = [];
  const onCloseCall = useCallback(() => {
    submitReset();
    onClose();
  }, [onClose, submitReset]);

  if (dueDiligenceTypes) {
    const screeningDuediligence = dueDiligenceTypes.filter(
      (d) => d.name === 'Screening'
    );
    monitoringScreeningSettings =
      dueDiligenceTypes &&
      screeningDuediligence.length > 0 &&
      screeningDuediligence[0].dueDiligenceTypes
        ? screeningDuediligence[0].dueDiligenceTypes
            ?.find(({ name }) => name === 'Self Service')
            ?.ongoingScreeningSearchTypes?.map(({ id, description }) => ({
              name: description,
              value: id,
            }))
        : [];
  }

  useEffect(() => {
    if (submitData) {
      reload();
      if (isOpen) {
        onCloseCall();
      }
    }
  }, [submitData, reload, onCloseCall, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseCall} title="Edit monitoring">
      {!submitLoading && (
        <EditMonitoringForm onSubmit={handleSubmit(() => triggerSubmit())}>
          <FormControl errorMessage={errors?.monitoring?.message}>
            <FieldSelect
              ariaLabel="Monitoring"
              id="monitoring"
              label="Monitoring"
              placeholder="select..."
              options={[
                {
                  name: 'Enabled',
                  value: 'enabled',
                },
                {
                  name: 'Disabled',
                  value: 'disabled',
                },
              ]}
              register={register}
              fullWidth
              valid={!errors?.monitoring?.message}
            />
          </FormControl>
          <FormControl
            errorMessage={errors?.monitoringScreeningSettings?.message}
          >
            <FieldSelect
              ariaLabel="Monitoring screening settings"
              id="monitoringScreeningSettings"
              label="Monitoring screening settings"
              placeholder="select..."
              options={monitoringScreeningSettings}
              register={register}
              disabled={settingsDisabled}
              fullWidth
              valid={!errors?.monitoringScreeningSettings?.message}
            />
          </FormControl>
          <Button type="submit" secondary>
            Submit
          </Button>
          <Button type="button" text onClick={onCloseCall}>
            Cancel
          </Button>
        </EditMonitoringForm>
      )}
      {submitLoading && <Loading />}
    </Modal>
  );
};

export default EditMonitoringModal;
