import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useFetch } from 'hooks';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { AppLayout } from 'layouts';
import { Button, Date, Link, PageTitle, Tooltip } from 'components/atoms';
import { Pagination, Table } from 'components/molecules';
import { headings as configHeadings } from './config';
import { statusMapper } from 'services/status-mapper';
import { GatewayPermissions } from 'auth/constants/permissions';
import NextReviewDateModal from 'components/organisms/next-review-date-modal';
import { NoWrapText } from './TaskList.styles';
import { ButtonToggle } from 'components/atoms/button/Button.styles';
import { Redirect } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';

function TaskList() {
  const [sortingIndex, setSortingIndex] = useState(-1);
  const [isDescending, setIsDescending] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { accessToken, hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const isAdmin: boolean = hasPermission(GatewayPermissions.Admin);
  const [showAll, setShowAll] = useState<boolean>(isAdmin);

  const hasViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const headings = [...configHeadings];
  headings[1].name = terminologyConfig?.internalOwnerSingularNaming;
  headings[2].name = terminologyConfig?.thirdPartySingularNaming;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedThirdPartyid, setSelectedScreeningId] = useState(null);
  const { data, loading, error } = useFetch(`${vantageWebApi}/task/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      page: pageNumber,
      pageSize: 15,
      sort:
        sortingIndex >= 0 && headings?.[sortingIndex]?.field
          ? {
              sortType: headings?.[sortingIndex]?.field,
              isDescending,
            }
          : null,
      showAll,
    }),
  });

  useEffect(() => {
    setPageNumber(1);
  }, [showAll]);

  const shouldShowReportLink = (status) => {
    return status !== 'Next Review Date';
  };

  const hasReportAvailable = (status) => {
    return status.toLowerCase().includes('report ready for review');
  };

  const setTableOrder = ({ index }: { index: number }) => {
    if (sortingIndex === index) {
      if (isDescending === false) {
        // Change order direction.
        setIsDescending(true);
      } else {
        // Remove any ordering.
        setIsDescending(false);
        setSortingIndex(-1);
      }
      return;
    }
    setIsDescending(false);
    setSortingIndex(index);
  };

  const handleNextReviewModalOpen = (screeningId) => {
    setSelectedScreeningId(screeningId);
    setModalOpen(true);
  };

  const handleNextReviewModalClose = () => {
    setModalOpen(false);
  };

  const handleNextReviewModalProceed = () => {
    handleNextReviewModalClose();
  };

  return (
    <>
      {hasViewTaskList ? (
        <AppLayout bgColor="whiteSolid">
          <PageTitle title="Task List">
            <ButtonToggle>
              {isAdmin && (
                <Button
                  style={{ borderRadius: '0.3rem 0rem 0rem 0.3rem' }}
                  disabled={!showAll}
                  onClick={() => {
                    setShowAll(false);
                  }}
                >
                  My tasks
                </Button>
              )}
              {isAdmin && (
                <Button
                  style={{ borderRadius: '0rem 0.3rem 0.3rem  0rem' }}
                  disabled={showAll}
                  onClick={() => {
                    setShowAll(true);
                  }}
                >
                  All tasks
                </Button>
              )}
            </ButtonToggle>
            {data && data?.pageCount > 1 && (
              <Pagination
                setCurrentPage={setPageNumber}
                currentPage={data?.currentPage || 1}
                totalPages={data?.pageCount || 1}
                onPageChange={({ currentPage }) => setPageNumber(currentPage)}
              />
            )}
          </PageTitle>
          <Table
            headings={headings}
            showPlaceholder={loading || error}
            onHeadingClick={setTableOrder}
            sortedColumn={sortingIndex}
          >
            {data &&
              data?.results?.length > 0 &&
              data.results.map(
                ({
                  createdDate,
                  id,
                  relatedEntity,
                  screeningRequest,
                  thirdParty,
                  status,
                }) => (
                  <tr key={id}>
                    <td>
                      {createdDate && (
                        <Date dateTime={createdDate} format="MMM D, YYYY" />
                      )}
                    </td>
                    <td>
                      {thirdParty?.owners?.length > 0 &&
                        thirdParty?.owners.map((x) => {
                          if (x.length > 15)
                            return (
                              <div key={x}>
                                <Tooltip
                                  tooltipText={x}
                                  placement="right"
                                  trigger="hover"
                                >
                                  {x.length > 15
                                    ? `${x.substring(0, 13)}..`
                                    : x}
                                </Tooltip>
                              </div>
                            );

                          return <NoWrapText key={x}>{x}</NoWrapText>;
                        })}
                    </td>
                    <td>
                      {thirdParty && (
                        <RouterLink
                          to={`/third-party/profile/${thirdParty?.id}`}
                        >
                          {thirdParty?.name ||
                            -thirdParty?.alternativeName ||
                            -thirdParty?.id}
                        </RouterLink>
                      )}
                    </td>
                    <td>
                      {relatedEntity && relatedEntity.id !== 0 && (
                        <Link
                          to={`/third-party/screening/${screeningRequest?.screeningId}`}
                        >
                          {relatedEntity?.name ||
                            relatedEntity?.alternativeName}
                        </Link>
                      )}
                    </td>
                    <td>{screeningRequest?.dueDiligenceType}</td>
                    <td>{screeningRequest?.dueDiligenceLevel}</td>
                    <td>
                      {shouldShowReportLink(status) ? (
                        <Link
                          to={`/third-party/screening/${screeningRequest?.screeningId}`}
                        >
                          {hasReportAvailable(status)
                            ? status
                            : statusMapper(
                                screeningRequest?.screeningReportStatusId,
                                screeningRequest?.currentScreeningStateId,
                                screeningRequest?.dueDiligenceLevel,
                                screeningRequest?.reportStatus
                              )}
                        </Link>
                      ) : (
                        <Button
                          text={true}
                          style={{ display: 'block' }}
                          onClick={() =>
                            handleNextReviewModalOpen(thirdParty?.id)
                          }
                        >
                          <Link>{'Next Review Date Due'}</Link>
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              )}
          </Table>
          {modalOpen && (
            <NextReviewDateModal
              isOpen={modalOpen}
              title={`Initiate ${terminologyConfig?.thirdPartySingularNaming} review now?`}
              onClose={handleNextReviewModalClose}
              loading={false}
              onProceed={handleNextReviewModalProceed}
              thirdPartyId={selectedThirdPartyid}
            />
          )}
        </AppLayout>
      ) : (
        <Redirect to="/not-found" />
      )}
    </>
  );
}

export default withAITracking(reactPlugin, TaskList);
