import styled from 'styled-components';

export const PaginationDot = styled.button<{
  isActive?: boolean;
  dark?: boolean;
}>`
  width: ${({ theme }) => theme.spacing(10)};
  height: ${({ theme }) => theme.spacing(10)};
  aspect-ratio: 1;
  padding: 0;
  border: 1px solid
    ${({ isActive = false, dark = false, theme }) =>
      isActive
        ? 'var(--nav-accent-colour)'
        : dark
        ? theme.colours.crowberryBlue
        : 'var(--nav-base-colour)'};
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  background-color: ${({ isActive = false }) =>
    isActive ? 'var(--nav-accent-colour)' : 'transparent'};

  & + & {
    margin-left: ${({ theme }) => theme.spacing(20)};
  }
`;
